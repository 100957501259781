import React, { Fragment, useMemo, useState } from "react";
import styled from "styled-components";

// Global
import Label from "shared/src/components/Label/Label";
import { RadioButton } from "shared/src/components/RadioButtons/RadioButton";

// Local
import { JWTAuthorityDTO } from "@rtslabs/field1st-fe-common";
import { Components } from "../../../../../../shared/src/qa-slugs";
import { Role, roles } from "../constants";
import { GroupPicker } from "./groups/drawers/GroupPicker";
import { GroupsContainerPerRole } from "./groups/GroupsContainerPerRole";

type WrapperType = {
  hideBorder?: boolean;
  noPaddingTop?: boolean;
};

const Wrapper = styled.div<WrapperType>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-top: ${({ hideBorder, theme }) =>
    (!hideBorder && `1px solid ${theme.colors.lightGrey}`) || ""};
  padding-left: 0;
  padding-right: 0;
  padding-top: ${({ noPaddingTop }) => (!noPaddingTop ? "23px" : "")};
`;

interface Props {
  authorities: JWTAuthorityDTO[];
  updateAuthority: (authority: JWTAuthorityDTO) => void;
  removeAuthority: (authority: string) => void;
}

export const RolesAndGroups = ({
  authorities,
  updateAuthority,
  removeAuthority,
}: Props) => {
  // Drawer state
  const [selectedRole, setSelectedRole] = useState<Role>();

  const authoritiesPerRole: { [key: string]: JWTAuthorityDTO } = useMemo(() => {
    return authorities.reduce(
      (result, authority) => ({
        ...result,
        [authority.authority]: authority,
      }),
      {}
    );
  }, [authorities]);

  return (
    <Wrapper>
      {/* Drawer */}
      {selectedRole && (
        <GroupPicker
          groupsToExclude={authoritiesPerRole[selectedRole.id]?.groups || []}
          handleClose={(authority?: JWTAuthorityDTO) => {
            if (authority) updateAuthority(authority);
            setSelectedRole(undefined);
          }}
          role={selectedRole}
        />
      )}
      {/* Global roles */}
      {roles
        .filter((r) => r.isGlobal && !r.backendManaged)
        .map((r) => (
          <div key={r.id}>
            <Label>{r.value}</Label>
            <RadioButton
              checked={!!authoritiesPerRole[r.id]}
              label="Yes"
              onChange={() => {
                updateAuthority({
                  authority: r.id,
                  isGlobal: true,
                  groups: [],
                });
              }}
              qa={`${Components.RolesAndGroups}-${r.id}`}
            />
            <RadioButton
              checked={!authoritiesPerRole[r.id]}
              label="No"
              onChange={() => {
                removeAuthority(r.id);
              }}
              qa={`${Components.RolesAndGroups}-${r.id}`}
            />
          </div>
        ))}
      {/* Roles with groups */}
      {roles
        .filter((r) => !r.isGlobal && !r.backendManaged)
        .map((r) => (
          <div key={r.id}>
            <GroupsContainerPerRole
              openGroupSelector={() => {
                setSelectedRole(r);
              }}
              groups={authoritiesPerRole[r.id]?.groups || []}
              isGlobal={authoritiesPerRole[r.id]?.isGlobal || false}
              key={r.id}
              role={r}
              updateAuthority={updateAuthority}
            />
          </div>
        ))}
    </Wrapper>
  );
};
