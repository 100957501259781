import { ContentWrapper } from "shared/src/components/Wrappers/Wrappers";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  API,
  CoreRedux,
  FormTypeDTO,
  ReportingFormTypeDocumentCountVm,
  SortDirection,
  useAPI,
} from "@rtslabs/field1st-fe-common";
import Loader from "shared/src/components/Loader/Loader";
import { GroupsListDrawer } from "../groups/GroupsListDrawer";
import {
  generateGroupOptions,
  getStatsForFormType,
} from "shared/src/components/clientAdmin/helpers";
import { ReportsFilters } from "./ReportsFilters";
import { ReportsTable } from "./ReportsTable";
import { FormTypeSummary } from "./statistics/FormTypeSummary";
import { Components, ElementType } from "shared/src/qa-slugs";
import s from "./styles.module.scss";
import {
  DateRangeValue,
  getMinMaxSubmissionDatesFromTimeFilter,
  TimeFilter,
} from "shared/src/data/timeFilters";
import { getPastDays } from "shared/src/helpers/filters";
import { PageHeader } from "shared/src/components/PageHeader/PageHeader";
import styles from "./Reports.module.scss";
import { TableSummary } from "shared/src/components/TableUI/TableSummary/TableSummary";

export const Reports: FC = () => {
  const ownClientGroups = useSelector(CoreRedux.getUserGroups);
  const [queryParams] = useSearchParams();
  const groupParam = queryParams.get("group");
  const defaultGroupId = groupParam ? Number(groupParam) : undefined;
  const groupFilterOptions = generateGroupOptions(ownClientGroups);

  const [documentType, setDocumentType] = useState<number>();
  const [clientGroupId, setClientGroupId] = useState<number | undefined>(
    defaultGroupId
  );
  const [dateRange, setDateRange] = useState<DateRangeValue>();
  const [timeFilter, setTimeFilter] = useState<TimeFilter>(
    TimeFilter.THIRTY_DAYS
  );
  const [query, setQuery] = useState<string>("");
  const [remoteClear, setRemoteClear] = useState<boolean>(false);
  const [newPageNum, setNewPageNum] = useState<number>(0);
  const [newPageSize, setNewPageSize] = useState<number>(10);
  const [sortingType, setSortingType] = useState<string>("name");
  const [sortingDirection, setSortingDirection] =
    useState<SortDirection>("asc");
  const [showGroupsDrawer, setShowGroupsDrawer] = useState<boolean>(false);
  const [selectedFormType, setSelectedFormType] = useState<FormTypeDTO | null>(
    null
  );
  const [queryString, setQueryString] = useState<string>("");
  const [formTypeData, setFormTypeData] = useState<
    ReportingFormTypeDocumentCountVm[] | null
  >(null);
  const [selectedFormTypeData, setSelectedFormTypeData] =
    useState<ReportingFormTypeDocumentCountVm | null>(null);

  const { minSubmissionDate, maxSubmissionDate } =
    getMinMaxSubmissionDatesFromTimeFilter(timeFilter, dateRange);

  const listContainerRef = useRef<HTMLDivElement>(null);
  const [shouldScroll, setShouldScroll] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  const handleClearFilters = () => {
    setNewPageNum(0);
    setClientGroupId(defaultGroupId);
    setDocumentType(undefined);
    setTimeFilter(TimeFilter.THIRTY_DAYS);
    setQuery("");
    setRemoteClear(true);
    setIsFiltered(false);
  };

  useEffect(() => {
    setRemoteClear(false);
  }, [remoteClear]);

  const setStateAndScroll =
    <T,>(setter: Dispatch<SetStateAction<T>>) =>
    (value: T) => {
      setter(value);
      setShouldScroll(true);
    };

  // get form type data
  const allFormTypeStatsCall = useAPI(API.getFormTypesReport, {
    minSubmissionDate,
    maxSubmissionDate,
    clientGroupId: !showGroupsDrawer ? clientGroupId : undefined,
  });

  // get form list
  const allFormStatsCall = useAPI(API.getFormsReport, {
    clientGroupId: !showGroupsDrawer ? clientGroupId : undefined,
    minSubmissionDate,
    maxSubmissionDate,
    formTypeId: documentType,
    query,
    page: newPageNum,
    size: newPageSize,
    sort: [sortingType, sortingDirection],
  });

  const checkIfFiltered = (): void => {
    const isDefault =
      clientGroupId === defaultGroupId &&
      timeFilter === TimeFilter.THIRTY_DAYS &&
      !query &&
      !documentType;

    setIsFiltered(!isDefault);
  };

  useEffect(() => {
    checkIfFiltered();
  }, [clientGroupId, timeFilter, query, documentType]);

  // Scroll to the listContainer after data loads
  useEffect(() => {
    if (
      !allFormStatsCall.isLoading &&
      shouldScroll &&
      listContainerRef.current
    ) {
      listContainerRef.current.scrollIntoView({
        block: "nearest",
      });
      setShouldScroll(false);
    }
  }, [allFormStatsCall.isLoading]);

  useEffect(() => {
    // build and store a query string for persisting filters to FormReport
    if (timeFilter === "CUSTOM_RANGE" && !dateRange) {
      setQueryString("");
    } else {
      const pastDays = getPastDays(timeFilter);
      setQueryString(
        "?" +
          [
            pastDays && `pastDays=${pastDays}`,
            clientGroupId && `clientGroupId=${clientGroupId}`,
          ]
            .filter(Boolean)
            .join("&")
      );
    }
  }, [dateRange, timeFilter, clientGroupId]);

  useEffect(() => {
    if (allFormTypeStatsCall.data) {
      if (showGroupsDrawer && selectedFormType?.id) {
        const data = allFormTypeStatsCall.data.find(
          (d) => d.formType.id === selectedFormType.id
        );
        if (data) {
          setSelectedFormTypeData(data);
          return;
        }
      } else {
        setFormTypeData(allFormTypeStatsCall.data);
      }
    }
    setSelectedFormTypeData(null);
  }, [allFormTypeStatsCall.data, selectedFormType]);

  /**
   * Set current form type in state and open the groups drawer
   * @param formType  - formType selected from summary
   */
  const openGroupsDrawer = (formType: FormTypeDTO): void => {
    setSelectedFormType(formType);
    setNewPageNum(0);
    setDocumentType(formType.id);
    setShowGroupsDrawer(true);
  };

  const closeGroupsDrawer = (): void => {
    setSelectedFormType(null);
    setShowGroupsDrawer(false);
    setDocumentType(undefined);
  };

  const qaBase = Components.Reports;
  const paths = [{ pathName: "Reports", href: "/reports" }];

  return (
    <ContentWrapper id="mainContent">
      {selectedFormType && selectedFormTypeData && (
        <GroupsListDrawer
          qa={`${Components.Reports}-${
            ElementType.Drawer
          }-viewAllGroups-${selectedFormType.name.replaceAll(/\s/g, "")}`}
          closeDrawer={closeGroupsDrawer}
          formStats={getStatsForFormType(selectedFormTypeData)}
          formType={selectedFormType}
          onGroupClick={(groupId: number): void => {
            setNewPageNum(0);
            setClientGroupId(groupId);
            setDocumentType(selectedFormType.id);
            setShowGroupsDrawer(false);
          }}
          show={showGroupsDrawer}
          isLoading={allFormTypeStatsCall.isLoading}
        />
      )}
      <PageHeader pageTitle="Reports" paths={paths} qa={qaBase} />
      <Loader loading={!selectedFormType && allFormTypeStatsCall.isLoading}>
        <div className={s.formTypesContainer}>
          {formTypeData
            ?.sort((a, b) => b.totalCount - a.totalCount)
            .map((d) => {
              const formStats = getStatsForFormType(d);
              return formStats.totalCount > 0 ? (
                <FormTypeSummary
                  key={d.formType.id}
                  formType={d.formType}
                  formStats={formStats}
                  loadingStats={false}
                  onGroupClick={(id: number) => {
                    setDocumentType(d.formType.id);
                    setClientGroupId(id);
                  }}
                  onAllGroupsClick={() => openGroupsDrawer(d.formType)}
                  viewingAllGroups={false}
                />
              ) : null;
            }) || <p>something went wrong</p>}
        </div>
      </Loader>
      <div className={styles.listContainer} ref={listContainerRef}>
        <ReportsFilters
          idPrefix="reportsFilters"
          setNewPageNum={setNewPageNum}
          clientGroupId={clientGroupId}
          setClientGroupId={setStateAndScroll(setClientGroupId)}
          groupFilterOptions={groupFilterOptions}
          documentType={documentType}
          setDocumentType={setStateAndScroll(setDocumentType)}
          formTypes={formTypeData?.map((d) => d.formType) || []}
          handleChangeDateRange={setStateAndScroll(setDateRange)}
          setQuery={setStateAndScroll(setQuery)}
          timeFilter={timeFilter}
          setTimeFilter={setStateAndScroll(setTimeFilter)}
          remoteClear={remoteClear}
        />
        <TableSummary
          pageSize={newPageSize}
          currentPage={newPageNum}
          totalElements={allFormStatsCall.data?.totalElements}
          ofWhat="reports"
          isFiltered={isFiltered}
          onClearFilters={handleClearFilters}
        />
        <ReportsTable
          sortingType={sortingType}
          setSortingType={setSortingType}
          sortingDirection={sortingDirection}
          setSortingDirection={setSortingDirection}
          loadingStats={allFormStatsCall.isLoading}
          formStats={allFormStatsCall.data}
          setNewPageNum={setNewPageNum}
          setNewPageSize={setNewPageSize}
          queryString={queryString}
        />
      </div>
    </ContentWrapper>
  );
};
