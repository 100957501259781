import React, { FC } from "react";
import { Link } from "../Link/Link";

import styles from "./PrivacyPolicyContent.module.scss";
import { Components, Page } from "../../qa-slugs";

interface PrivacyPolicyContent {
  terms: {
    employee: {
      singular: string;
      plural: string;
    };
  };
}

const PrivacyPolicyContent: FC<PrivacyPolicyContent> = ({ terms }) => {
  // Group config terms
  const { singular: employeeTerm, plural: employeesTerm } = terms.employee;
  const employeesTermLower = employeesTerm.toLowerCase();

  const personalInformationListItems = [
    "Name",
    "Email",
    "Company Name",
    "Phone/mobile number",
    "Mailing address",
    "Work address",
    "Work phone / mobile number",
    "Website address",
    "Payment information",
    `${employeeTerm} group or division`,
    "Professional role or title",
    "Professional industry",
  ];

  const legalBasesListItems = [
    `it’s necessary for the performance of a contract to which you are a party or to take steps at your request before
     entering into such a contract (for example, when we provide a service you request from us);`,
    `it satisfies a legitimate interest (which is not overridden by your data protection interests), such as for
     research and development, to market and promote our services, and to protect our legal rights and interests;`,
    `you give us consent to do so for a specific purpose (for example, you might consent to us sending you our 
    newsletter); or`,
    "we need to process your data to comply with a legal obligation.",
  ];

  const useOfInfoListItems = [
    "to provide you with our platform's core features;",
    "to process any transactional or ongoing payments;",
    "to enable you to access and use our website and associated applications and platforms;",
    "to contact and communicate with you;",
    "for internal record keeping and administrative purposes;",
    `for analytics, market research and business development, including to operate and improve our website, and 
    associated applications;`,
    "to offer additional benefits to you;",
    `for advertising and marketing, including to send you promotional information about our products and services;
     and`,
    "to comply with our legal obligations and resolve any disputes that we may have.",
  ];

  const thirdPartyDisclosureListItems = [
    `third party service providers for the purpose of enabling them to provide their services, including (without 
    limitation) IT service providers, data storage, hosting and server providers, analytics, error loggers, maintenance 
    or problem-solving providers, marketing or advertising providers, professional advisors and payment systems 
    operators;`,
    `our ${employeesTermLower}, contractors and/or related entities;", "credit reporting agencies, courts, tribunals and regulatory 
    authorities, in the event you fail to pay for goods or services we have provided to you;`,
    `courts, tribunals, regulatory authorities and law enforcement officers, as required by law, in connection with any
    actual or prospective legal proceedings, or in order to establish, exercise or defend our legal rights;`,
    `third parties, including agents or sub-contractors, who assist us in providing information, products, services or 
    direct marketing to you; and", "third parties to collect and process data.`,
  ];

  return (
    <div className={styles.privacy}>
      <div className={styles.lastUpdated}>Last updated: June 19, 2020</div>
      <div className={styles.textBlock}>
        Your privacy is important to us. It is RTS Labs&#39; policy to respect
        your privacy regarding any information we may collect from you across
        our website,{" "}
        <span>
          <Link external={true} to="https://field1st.com">
            https://field1st.com
          </Link>
        </span>
        , and other sites we own and operate.
      </div>

      {/* Section 1 - Information We Collect */}
      <h2 className={styles.sectionHeader}>1. Information we collect</h2>
      <h3 className={styles.subheader}>Log data</h3>
      <div className={styles.textBlock}>
        When you visit our website, our servers may automatically log the
        standard data provided by your web browser. It may include your
        computer’s Internet Protocol (IP) address, your browser type and
        version, the pages you visit, the time and date of your visit, the time
        spent on each page, and other details.
      </div>
      <h3 className={styles.subheader}>Device data</h3>
      <div className={styles.textBlock}>
        We may also collect data about the device you’re using to access our
        website. This data may include the device type, operating system, unique
        device identifiers, device settings, and geo-location data. What we
        collect can depend on the individual settings of your device and
        software. We recommend checking the policies of your device manufacturer
        or software provider to learn what information they make available to
        us.
      </div>
      <h3 className={styles.subheader}>Personal information</h3>
      <div className={styles.textBlock}>
        <div className={styles.listHeading}>
          We may ask for personal information, such as your:
        </div>
        <ul className={styles.listBlock}>
          {personalInformationListItems.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      </div>
      <h3 className={styles.subheader}>Business data</h3>
      <div className={styles.textBlock}>
        Business data refers to data that accumulates over the normal course of
        operation on our platform. This may include transaction records,
        information capture, stored files, user profiles, analytics data and
        other metrics, as well as other types of information, created or
        generated, as users interact with our services.
      </div>

      {/* Section 2 - Legal Bases for Processing */}
      <h2 className={styles.sectionHeader}>2. Legal bases for processing</h2>
      <div className={styles.textBlock}>
        We will process your personal information lawfully, fairly and in a
        transparent manner. We collect and process information about you only
        where we have legal bases for doing so.
      </div>
      <div className={styles.listHeading}>
        These legal bases depend on the services you use and how you use them,
        meaning we collect and use your information only where:
      </div>
      <ul className={styles.listBlock}>
        {legalBasesListItems.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ul>
      <div className={styles.textBlock}>
        Where you consent to our use of information about you for a specific
        purpose, you have the right to change your mind at any time (but this
        will not affect any processing that has already taken place).
      </div>
      <div className={styles.textBlock}>
        We don’t keep personal information for longer than is necessary. While
        we retain this information, we will protect it within commercially
        acceptable means to prevent loss and theft, as well as unauthorized
        access, disclosure, copying, use or modification. That said, we advise
        that no method of electronic transmission or storage is 100% secure and
        cannot guarantee absolute data security. If necessary, we may retain
        your personal information for our compliance with a legal obligation or
        in order to protect your vital interests or the vital interests of
        another natural person.
      </div>

      {/* Section 3 - Collection and Use of Information */}
      <h2 className={styles.sectionHeader}>
        3. Collection and use of information
      </h2>
      <div className={styles.listHeading}>
        We may collect, hold, use and disclose information for the following
        purposes and personal information will not be further processed in a
        manner that is incompatible with these purposes:
      </div>
      <ul className={styles.listBlock}>
        {useOfInfoListItems.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ul>

      {/* Section 4 - Disclosure of Personal Information to Third Parties */}
      <h2 className={styles.sectionHeader}>
        4. Disclosure of personal information to third parties
      </h2>
      <div className={styles.listHeading}>
        We may disclose personal information to:
      </div>
      <ul className={styles.listBlock}>
        {thirdPartyDisclosureListItems.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ul>

      {/* Section 5 - International Transfers of Personal Information */}
      <h2 className={styles.sectionHeader}>
        5. International transfers of personal information
      </h2>
      <div className={styles.textBlock}>
        The personal information we collect is stored and processed in United
        States, or where we or our partners, affiliates and third-party
        providers maintain facilities. By providing us with your personal
        information, you consent to the disclosure to these third-party
        providers.
      </div>

      {/* Section 6 - Your Rights and Controlling Your Personal Information */}
      <h2 className={styles.sectionHeader}>
        6. Your rights and controlling your personal information
      </h2>
      <div className={styles.textBlock}>
        Choice and consent: By providing personal information to us, you consent
        to us collecting, holding, using and disclosing your personal
        information in accordance with this privacy policy. If you are under 16
        years of age, you must have, and warrant to the extent permitted by law
        to us, that you have your parent or legal guardian’s permission to
        access and use the website and they (your parents or guardian) have
        consented to you providing us with your personal information. You do not
        have to provide personal information to us, however, if you do not, it
        may affect your use of this website or the products and/or services
        offered on or through it.
      </div>
      <div className={styles.textBlock}>
        Information from third parties: If we receive personal information about
        you from a third party, we will protect it as set out in this privacy
        policy. If you are a third party providing personal information about
        somebody else, you represent and warrant that you have such person’s
        consent to provide the personal information to us.
      </div>
      <div className={styles.textBlock}>
        Restrict: You may choose to restrict the collection or use of your
        personal information. If you have previously agreed to us using your
        personal information for direct marketing purposes, you may change your
        mind at any time by contacting us using the details below. If you ask us
        to restrict or limit how we process your personal information, we will
        let you know how the restriction affects your use of our website or
        products and services.
      </div>
      <div className={styles.textBlock}>
        Access and data portability: You may request details of the personal
        information that we hold about you. You may request a copy of the
        personal information we hold about you. Where possible, we will provide
        this information in CSV format or other easily readable machine format.
        You may request that we erase the personal information we hold about you
        at any time. You may also request that we transfer this personal
        information to another third party.
      </div>
      <div className={styles.textBlock}>
        Correction: If you believe that any information we hold about you is
        inaccurate, out of date, incomplete, irrelevant or misleading, please
        contact us using the details below. We will take reasonable steps to
        correct any information found to be inaccurate, incomplete, misleading
        or out of date.
      </div>
      <div className={styles.textBlock}>
        Notification of data breaches: We will comply laws applicable to us in
        respect of any data breach.
      </div>
      <div className={styles.textBlock}>
        Complaints: If you believe that we have breached a relevant data
        protection law and wish to make a complaint, please contact us using the
        details below and provide us with full details of the alleged breach. We
        will promptly investigate your complaint and respond to you, in writing,
        setting out the outcome of our investigation and the steps we will take
        to deal with your complaint. You also have the right to contact a
        regulatory body or data protection authority in relation to your
        complaint.
      </div>
      <div className={styles.textBlock}>
        Unsubscribe: To unsubscribe from our e-mail database or opt-out of
        communications (including marketing communications), please contact us
        using the details below or opt-out using the opt-out facilities provided
        in the communication.
      </div>

      {/* Section 7 - Cookies */}
      <h2 className={styles.sectionHeader}>7. Cookies</h2>
      <div className={styles.textBlock}>
        We use “cookies” to collect information about you and your activity
        across our site. A cookie is a small piece of data that our website
        stores on your computer, and accesses each time you visit, so we can
        understand how you use our site. This helps us serve you content based
        on preferences you have specified. Please refer to our Cookie Policy for
        more information.
      </div>

      {/* Section 8 - Business Transfers */}
      <h2 className={styles.sectionHeader}>8. Business transfers</h2>
      <div className={styles.textBlock}>
        If we or our assets are acquired, or in the unlikely event that we go
        out of business or enter bankruptcy, we would include data among the
        assets transferred to any parties who acquire us. You acknowledge that
        such transfers may occur, and that any parties who acquire us may
        continue to use your personal information according to this policy.
      </div>

      {/* Section 9 - Limits of Our Policy */}
      <h2 className={styles.sectionHeader}>9. Limits of our policy</h2>
      <div className={styles.textBlock}>
        Our website may link to external sites that are not operated by us.
        Please be aware that we have no control over the content and policies of
        those sites, and cannot accept responsibility or liability for their
        respective privacy practices.
      </div>

      {/* Section 10 - Changes to this Policy */}
      <h2 className={styles.sectionHeader}>10. Changes to this policy</h2>
      <div className={styles.textBlock}>
        At our discretion, we may change our privacy policy to reflect current
        acceptable practices. We will take reasonable steps to let users know
        about changes via our website. Your continued use of this site after any
        changes to this policy will be regarded as acceptance of our practices
        around privacy and personal information.
      </div>
      <div className={styles.textBlock}>
        If we make a significant change to this privacy policy, for example
        changing a lawful basis on which we process your personal information,
        we will ask you to re-consent to the amended privacy policy.
      </div>

      {/* Signature */}
      <div className={styles.signatureWrapper}>
        <div>RTS Labs</div>
        <div>4951 Lake Brook Dr #225</div>
        <div>Glen Allen VA 23060</div>
      </div>
    </div>
  );
};

export default PrivacyPolicyContent;
