import React, { FC } from "react";
import { Components } from "shared/src/qa-slugs";
import { ContentWrapper } from "shared/src/components/Wrappers/Wrappers";
import { PageHeader } from "shared/src/components/PageHeader/PageHeader";
import { SettingsTabs } from "./SettingsTabs";
import styles from "./Settings.module.scss";
import { useLocation } from "react-router-dom";

export const settingsPaths = {
  companyInfo: "/settings/company-info",
  permissions: "/settings/permissions",
};

const pathDetails = {
  [settingsPaths.companyInfo]: {
    qaBase: Components.CompanyInfo,
    pageTitle: "Company Info",
  },
  [settingsPaths.permissions]: {
    qaBase: Components.Permissions,
    pageTitle: "Permissions",
  },
};

export const SettingsScreen: FC = () => {
  const { pathname } = useLocation();
  const { qaBase, pageTitle } = pathDetails[pathname] || {};

  const paths = [
    { pathName: "Settings", href: pathname },
    { pathName: pageTitle, href: pathname },
  ];

  return (
    <ContentWrapper id="mainContent">
      <PageHeader pageTitle={pageTitle} paths={paths} qa={qaBase} />
      <div className={styles.contentBody}>
        <SettingsTabs />
      </div>
    </ContentWrapper>
  );
};
