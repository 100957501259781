import React, { FC } from "react";
import styles from "./BarChartResponse.module.scss";
import { useTheme } from "styled-components";

export interface BarChartResponseColorProps {
  textColor: string;
  backColor: string;
}

interface BarChartResponseProps {
  data: {
    label: string;
    percentage: number;
    value: number;
  };
  index: number;
  colorObject?: BarChartResponseColorProps;
}

export const BarChartResponse: FC<BarChartResponseProps> = ({
  data,
  index,
  colorObject,
}) => {
  const theme = useTheme();

  return (
    <div key={index} className={styles.responseBarContainer}>
      <div
        className={styles.responseBar}
        style={{
          width: `${data.percentage}%`,
          backgroundColor:
            data.percentage === 0 ? "transparent" : colorObject?.backColor,
          color: colorObject?.textColor,
        }}
      >
        <div className={styles.percentOfResponses}>
          <h4>{data.percentage}%</h4> <span>{data.label}</span>
        </div>
      </div>
      <div className={styles.totalResponses}>
        <div>
          {data.value} response{data.value !== 1 ? "s" : ""}
        </div>
      </div>
    </div>
  );
};
