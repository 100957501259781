import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import styles from "./SearchFilters.module.scss";
import { FilterSelect } from "shared/src/components/Select/FilterSelect";
import {
  API,
  DocumentOwnerVm,
  DocumentRequestParameters,
  FormTypeDTO,
} from "@rtslabs/field1st-fe-common";
import {
  DocumentOwnersAPIArgs,
  useDocumentOwnersAPI,
} from "shared/src/util/hooks/useDocumentOwnersAPI";
import {
  DateRangeValue,
  TimeFilter,
  getMinMaxSubmissionDatesFromTimeFilter,
  timeFilterOptions,
} from "shared/src/data/timeFilters";
import { mapFormTypesToFilter } from "shared/src/helpers/filters";
import { Components, ElementType } from "shared/src/qa-slugs";
import { DateRange } from "shared/src/components/DateRange/DateRange";
import moment from "moment";
import { joinClassNames } from "shared/src/helpers/theme.helpers";
import TextInputWithSuggestions from "shared/src/components/TextInput/TextInputWithSuggestions";
import { useMediaQuery } from "react-responsive";
import scssVariables from "shared/src/sass/jsExports.module.scss";
import { AppliedFilters } from "./AppliedFilters";
import { AppliedFiltersProps } from "./types";

interface SearchFiltersProps {
  filters: API.GetDocumentsArgs;
  formTypes: Array<FormTypeDTO>;
  onFilterChange: (params: Partial<DocumentRequestParameters>) => void;
  className?: string;
  appliedFilters: AppliedFiltersProps;
  setAppliedFilters: Dispatch<SetStateAction<AppliedFiltersProps>>;
}

export const SearchFilters: FC<SearchFiltersProps> = ({
  filters,
  formTypes,
  onFilterChange,
  className,
  appliedFilters,
  setAppliedFilters,
}) => {
  // owner filter
  const [ownerQuery, setOwnerQuery] = useState<DocumentOwnersAPIArgs>({
    queryText: appliedFilters.ownerName ?? "",
    selected: !!appliedFilters.ownerName,
  });
  const ownersCall = useDocumentOwnersAPI(ownerQuery);

  function handleOwnerSearch(queryText: string) {
    setOwnerQuery({ queryText, selected: false });
    onFilterChange({ ownerId: undefined });
  }

  function handleSelectOwner(owner: DocumentOwnerVm) {
    setOwnerQuery({ queryText: owner.fullName ?? owner.name, selected: true });
    setAppliedFilters({ ...appliedFilters, ownerName: owner.fullName });
    onFilterChange({ ownerId: owner.participantId });
  }

  // min/max submission date filters
  const [timeFilter, setTimeFilter] = useState<TimeFilter | undefined>(
    appliedFilters.timeFilter ?? undefined
  );
  const [dateRange, setDateRange] = useState<DateRangeValue | undefined>();
  const { maxSubmissionDate, minSubmissionDate } =
    getMinMaxSubmissionDatesFromTimeFilter(timeFilter, dateRange);
  useEffect(() => {
    onFilterChange({ maxSubmissionDate, minSubmissionDate });
  }, [maxSubmissionDate, minSubmissionDate]);

  const formTypeOptions = mapFormTypesToFilter(formTypes);

  const handleSelectFormType = (selected: any) => {
    const formTypeName = formTypes.find((ft) => ft.id === selected.value)?.name;
    setAppliedFilters({ ...appliedFilters, formTypeName });
    onFilterChange(
      selected?.value
        ? { formTypeId: selected?.value }
        : { formTypeId: undefined }
    );
  };

  const handleRemoveFilter = (filterName: string) => {
    if (filterName === "formTypeName") {
      setAppliedFilters({ ...appliedFilters, formTypeName: undefined });
      onFilterChange({ formTypeId: undefined });
    }
    if (filterName === "timeFilter") {
      setAppliedFilters({ ...appliedFilters, timeFilter: undefined });
      onFilterChange({
        minSubmissionDate: undefined,
        maxSubmissionDate: undefined,
      });
    }

    if (filterName === "ownerName") {
      setAppliedFilters({ ...appliedFilters, ownerName: undefined });
      onFilterChange({ ownerId: undefined });
    }
  };

  const isPhone = useMediaQuery({
    query: `(max-width: ${scssVariables.maxPhone})`,
  });

  useEffect(() => {
    // const timeFilterName = timeFilterOptions.find(
    //   (tfo) => tfo.value === timeFilter
    // )?.label;
    setAppliedFilters({ ...appliedFilters, timeFilter });
  }, [timeFilter]);

  useEffect(() => {
    !filters.ownerId &&
      setAppliedFilters({ ...appliedFilters, ownerName: undefined });
  }, [filters.ownerId]);

  const CustomDateTime = () => (
    <div className={styles.filter}>
      <div className={styles.dateRange}>
        <span className="visually-hidden" id="custom-range-label">
          Custom Range
        </span>
        <DateRange
          className={joinClassNames(isPhone && styles.containerPhone)}
          handleChange={setDateRange}
          labelId="custom-range-label"
          name="customRange"
          initialValues={{
            from: minSubmissionDate ? moment(minSubmissionDate) : undefined,
            to: maxSubmissionDate ? moment(maxSubmissionDate) : undefined,
          }}
          onClear={() => {
            setTimeFilter(TimeFilter.ALL_TIME);
            setDateRange(undefined);
          }}
          qa={`${Components.MyDocuments}-${ElementType.TextInput}-dateRangeFilter`}
        />
      </div>
    </div>
  );

  return (
    <>
      <AppliedFilters
        appliedFilters={appliedFilters}
        onRemoveFilter={handleRemoveFilter}
      />
      <div className={joinClassNames(styles.searchFiltersContainer, className)}>
        <div className={styles.filter}>
          <FilterSelect
            name="formTypeId"
            placeholder="All Document Types"
            value={filters.formTypeId}
            label=""
            options={[
              { value: 0, label: "All Document Types" },
              ...formTypeOptions,
            ]}
            onChange={handleSelectFormType}
            qa={`${Components.MyDocuments}-${ElementType.SelectInput}-formTypeId`}
            className={styles.select}
          />
        </div>
        <div className={styles.filter}>
          <FilterSelect
            name="timeFilter"
            placeholder="All Time"
            value={timeFilter}
            label=""
            options={timeFilterOptions}
            onChange={(selected) =>
              setTimeFilter(selected?.value || TimeFilter.ALL_TIME)
            }
            qa={`${Components.MyDocuments}-${ElementType.SelectInput}-timeFilterType`}
            className={styles.select}
          />
        </div>
        {isPhone && timeFilter === "CUSTOM_RANGE" && <CustomDateTime />}
        <div className={joinClassNames(styles.filter, styles.searchInput)}>
          <TextInputWithSuggestions<DocumentOwnerVm>
            idField="id"
            labelField="fullName"
            wrapperClassName={styles.owner}
            placeholder="Search Document Owner"
            value={ownerQuery.queryText}
            onInputChange={handleOwnerSearch}
            onSelectSuggestion={handleSelectOwner}
            suggestions={
              ownerQuery.selected ? [] : ownersCall.data?.content || []
            }
            showSuggestions={ownerQuery.queryText.length >= 3}
            qa={`${Components.MyDocuments}-${ElementType.SelectInput}-ownerFilter`}
          />
        </div>
        {!isPhone && timeFilter === "CUSTOM_RANGE" && (
          <div className={styles.newRow}>
            <CustomDateTime />
          </div>
        )}
      </div>
    </>
  );
};
