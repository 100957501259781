import React, { useRef, useState } from "react";

import Label from "../../../Label/Label";
import { AssistiveText } from "../../../AssistiveText/AssistiveText";
import { TextInputProps } from "../../../TextInput/types";
import styles from "./FileUploadField.module.scss";
import * as S from "../../../clientAdmin/resources/styles";

export interface UploadedFile {
  title: string | null;
  url: string;
}

export function isUploadedFile(
  file: File | UploadedFile
): file is UploadedFile {
  return "url" in file;
}

export function isFile(file: File | UploadedFile): file is File {
  return "name" in file;
}

const FileLabel = ({ file }: { file: File | UploadedFile }) => {
  if (isUploadedFile(file)) {
    return (
      <a
        href={file.url}
        rel="noopener noreferrer"
        target="_blank"
        className={styles.fileLink}
      >
        {file.title}
      </a>
    );
  } else {
    return <span>{file.name}</span>;
  }
};

const AddedFile = ({
  file,
  onRemove,
}: {
  file: File | UploadedFile;
  onRemove: () => void;
}) => (
  <S.AddedFile>
    <S.AddedFileLabel>
      <FileLabel file={file} />
    </S.AddedFileLabel>
    <S.IconForEndAdornment
      className="icon icon-icons8-delete_sign"
      onClick={onRemove}
    />
  </S.AddedFile>
);

export interface FileUploadFieldProps extends Omit<TextInputProps, "value"> {
  files: (File | UploadedFile)[];
  maxFileSize?: number;
  onUpdateFiles: (files: (File | UploadedFile)[]) => void;
}

const FileUploadField = ({
  files,
  assistiveText,
  label,
  labelClass,
  maxFileSize,
  name,
  onUpdateFiles,
  placeholder,
  qa,
}: FileUploadFieldProps) => {
  const fileUploaderRef = useRef<HTMLInputElement>(null);
  const [fileUploadError, setFileUploadError] = useState<string>("");

  const handleAddFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    const targetFiles = e.target.files ? Array.from(e.target.files) : [];
    let addedFiles = [...targetFiles];
    if (maxFileSize) {
      let fileSizeError = fileUploadError;
      addedFiles = targetFiles.filter((file) => {
        if (file.size > maxFileSize) {
          fileSizeError += `\n${file.name} is larger than maximum file size`;
          return false;
        }
        return true;
      });
      setFileUploadError(fileSizeError);
    }
    // @todo check if we've already added any of these files and extend the name
    onUpdateFiles([...files, ...addedFiles]);
  };

  const handleRemoveFile = (removedFile: File | UploadedFile) => {
    let updatedFiles: (File | UploadedFile)[];

    if (isUploadedFile(removedFile)) {
      // if it's a previously uploaded document
      updatedFiles = files.filter(
        (file) => !isUploadedFile(file) || file.title !== removedFile.title
      );
    } else {
      // if it's a newly added document
      updatedFiles = files.filter(
        (file) => !isFile(file) || file.name !== removedFile.name
      );
    }
    onUpdateFiles(updatedFiles);
  };

  return (
    <S.FileUploadFieldWrapper>
      <Label className={labelClass} htmlFor={name?.toString() || ""}>
        {label}
      </Label>
      <S.FileUploadItemsContainer>
        <S.FileUploadItems>
          {files?.length ? (
            files.map((file, index) => (
              <AddedFile
                key={index}
                onRemove={() => handleRemoveFile(file)}
                file={file}
              />
            ))
          ) : (
            <S.FileUploadFieldPlaceholder htmlFor="file">
              {placeholder}
            </S.FileUploadFieldPlaceholder>
          )}
        </S.FileUploadItems>
      </S.FileUploadItemsContainer>
      {assistiveText && <AssistiveText>{assistiveText}</AssistiveText>}
      {fileUploadError && <S.ErrorText>{fileUploadError}</S.ErrorText>}

      {/* hidden input field to handle the file uploader */}
      <input
        type="file"
        id="file"
        multiple
        ref={fileUploaderRef}
        style={{ display: "none" }}
        onChange={handleAddFiles}
        accept=".pdf,.jpg,.png"
        data-testid={qa}
      />

      <S.UploadFileButton htmlFor="file">Choose Files</S.UploadFileButton>
    </S.FileUploadFieldWrapper>
  );
};

export default FileUploadField;
