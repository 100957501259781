import { ReportingFormTypeDocumentCountVm } from "@rtslabs/field1st-fe-common";
import { orderBy } from "lodash";
import React, { FC, useMemo } from "react";
import MediaQuery from "react-responsive";
import { components, ControlProps, GroupBase } from "react-select";
import { IconBox } from "shared/src/components/Icon/IconBox";
import { TimeFilter } from "shared/src/data/timeFilters";
import { PieChart } from "shared/src/components/Charts/PieChart/PieChart";
import Loader from "shared/src/components/Loader/Loader";
import { Select } from "shared/src/components/Select/Select";
import { joinClassNames } from "shared/src/helpers/theme.helpers";
import { Components } from "shared/src/qa-slugs";
import { timeFilterOptions } from "shared/src/data/timeFilters";
import scssVariables from "shared/src/sass/jsExports.module.scss";
import { Icon } from "shared/src/components/Icon/Icon";
import { PercentLineGraph } from "./PercentLineGraph";
import styles from "./ReportsCard.module.scss";
import { roundPercentages } from "./RoundingUtils";
import { useReportData } from "./useReportData";
import { sliceColors } from "../../clientAdmin/reports/reportsConstants";

interface DataPoint {
  color: string;
  id: string;
  qa: string;
  value: number;
  source?: ReportingFormTypeDocumentCountVm;
}

interface Props extends ControlProps {
  children: React.ReactNode;
}

const Control: FC<Props> = ({ children, ...props }) => (
  <components.Control
    {...props}
    data-testid={`${Components.ReportsCard}-dropdownControl`}
  >
    <Icon
      className={styles.reportIcon}
      type={"icon-icons8-calendar"}
      color={"#0172CE"}
    />
    {children}
  </components.Control>
);

export const ReportsCard: FC = () => {
  const reportData = useReportData();

  const viewGroupsSorted = useMemo(
    () =>
      orderBy(reportData.selectedFormTypeData?.groupCounts).filter(
        (g) => g.count !== 0
      ),
    [reportData.selectedFormTypeData]
  );

  const topFourReportTypes = useMemo(
    () =>
      reportData.formTypeData?.slice(0, 4).filter((r) => r.totalCount !== 0) ??
      [],
    [reportData.formTypeData]
  );

  let data = useMemo(() => {
    let _data: DataPoint[] = topFourReportTypes.map((r, i) => ({
      color: sliceColors[i].value,
      id: r.formType.name,
      qa: sliceColors[i].name,
      value: r.totalCount,
      source: r,
    }));

    let allReportTypesWithData = reportData.formTypeData?.filter(
      (r) => r.totalCount > 0
    );

    if (allReportTypesWithData && allReportTypesWithData.length > 4) {
      _data.push({
        color: sliceColors[4].value,
        id: "other",
        qa: sliceColors[4].name,
        value:
          reportData.reportCountTotal -
          _data.reduce((acc, d) => (acc += d.value), 0),
      });
    }
    return _data;
  }, [
    topFourReportTypes,
    reportData.formTypeData,
    reportData.reportCountTotal,
  ]);

  const viewIndex = reportData.selectedFormTypeData
    ? Math.max(0, topFourReportTypes.indexOf(reportData.selectedFormTypeData))
    : 0;

  const viewGroupPercentages = useMemo(
    () =>
      roundPercentages(
        viewGroupsSorted
          .slice(0, 4)
          .map(
            (group) =>
              (100 * group.count) / reportData.selectedFormTypeData!.totalCount
          )
      ),
    [viewGroupsSorted]
  );

  const formattedReportCountTotal = useMemo(
    () =>
      new Intl.NumberFormat("en-US", {
        compactDisplay: "short",
        maximumFractionDigits: 0,
        notation: "compact",
      })
        .format(reportData.reportCountTotal)
        .toLocaleLowerCase(),
    [reportData.reportCountTotal]
  );

  return (
    <section className={styles.profileCard}>
      <div className={styles.header}>
        <h2 className={styles.sectionLabel}>REPORTS</h2>
        <Select
          aria-label="Filter reports by time period"
          qa={`${Components.ReportsCard}-dropdown`}
          className={styles.dropdown}
          options={timeFilterOptions.filter(
            (o) =>
              o.value !== TimeFilter.CUSTOM_RANGE &&
              o.value !== TimeFilter.ALL_TIME
          )}
          value={reportData.timeFilter}
          onChange={(time) => {
            reportData.setTimeFilter(time!.value);
          }}
          components={{
            // This type cast is gross. Figure out something better that isn't <any>.
            Control: Control as React.ComponentType<
              ControlProps<
                { value: TimeFilter; label: string },
                false,
                GroupBase<{ value: TimeFilter; label: string }>
              >
            >,
          }}
          styles={{
            control: (provided, state) => {
              return {
                ...provided,
              };
            },
            container: (provided, state) => ({
              ...provided,
            }),
            valueContainer: (provided) => ({
              ...provided,
            }),
            menu: (provided) => ({ ...provided }),
            indicatorSeparator: (provided) => ({
              ...provided,
              display: "none",
            }),
            dropdownIndicator: (provided) => ({
              ...provided,
              color: "#0172CE",
            }),
          }}
        />
      </div>
      <MediaQuery maxWidth={scssVariables.maxPhone}>
        <div className={styles.percentLineGraphRow}>
          <p className={styles.total}>
            <span className={styles.label}>Total:</span>
            <span className={styles.count}>{formattedReportCountTotal}</span>
          </p>
          <PercentLineGraph
            ariaLabel="Bar chart showing the proportion of reports for each form type."
            className={styles.percentLineGraph}
            data={data}
            onClick={(datum) => {
              if (datum.id !== "other") {
                reportData.setSelectedFormType(datum.source);
              }
            }}
            qa={`${Components.ReportsCard}-Chart`}
          />
        </div>
      </MediaQuery>
      <div
        className={styles.reportBody}
        style={{ borderLeft: "3px solid " + sliceColors[viewIndex].value }}
      >
        <div
          className={joinClassNames(
            styles.reportSummary,
            !reportData.selectedFormTypeData &&
              reportData.reportDataLoading &&
              styles.loader
          )}
        >
          <Loader
            loading={
              !reportData.selectedFormTypeData && reportData.reportDataLoading
            }
          >
            {reportData.selectedFormTypeData && (
              <>
                <table className={styles.table}>
                  <colgroup span={3}></colgroup>
                  <thead>
                    <tr>
                      <th colSpan={3} scope="colgroup">
                        <div className={styles.primaryHeading}>
                          {reportData.selectedFormTypeData.formType
                            .iconName && (
                            <IconBox
                              className={styles.reportIcon}
                              type={
                                reportData.selectedFormTypeData.formType
                                  .iconName
                              }
                              color={
                                reportData.selectedFormTypeData.formType
                                  .iconColor || ""
                              }
                            />
                          )}
                          <div className={styles.primaryHeadingName}>
                            <div
                              data-testid={`${Components.ReportsCard}-ReportTypeName`}
                            >
                              {reportData.selectedFormTypeData.formType.name}
                            </div>
                            <div className={styles.reportTypeCount}>
                              {reportData.selectedFormTypeData.totalCount}{" "}
                              <span
                                className={styles.reportTypeCountDescription}
                              >
                                Total
                              </span>
                            </div>
                          </div>
                        </div>
                      </th>
                    </tr>
                    <tr className="visually-hidden">
                      <th>Submission count</th>
                      <th>Group name</th>
                      <th>Submission percentage</th>
                    </tr>
                  </thead>
                  <tbody>
                    {viewGroupsSorted.slice(0, 4).map((group, index) => (
                      <tr key={group.group.id}>
                        <td className={styles.typeCount}>{group.count}</td>
                        <td className={styles.typeDescription}>
                          {group.group.name}
                        </td>
                        <td className={styles.typePercentage}>
                          {viewGroupPercentages[index]}%
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <a
                  className={styles.viewAllReportsButton}
                  href="/reports"
                  data-testid={`${Components.ReportsCard}-viewAllReportsButton`}
                >
                  VIEW ALL REPORTS <Icon type="chevron-right" size="28px" />
                </a>
              </>
            )}
          </Loader>
        </div>
        <MediaQuery minWidth={scssVariables.maxPhone}>
          <div className={styles.pieChartWrapper}>
            <div className={styles.pieChart}>
              <PieChart
                ariaLabel="Pie chart showing the proportion of reports for each form type."
                data={data}
                labels={false}
                innerRadius={0.7}
                startAngle={-180}
                endAngle={180}
                borderWidth={0.01}
                fill={{ type: "Solid" }}
                sliceColors={{ datum: "data.color" }}
                centeredContent={
                  <div
                    className={joinClassNames(
                      styles.pieChartCenterText,
                      styles.overlay
                    )}
                  >
                    <div className={styles.centerTextWrapper}>
                      <div className={styles.statusText}>Total:</div>
                      <div className={joinClassNames(styles.countText)}>
                        {formattedReportCountTotal}
                      </div>
                    </div>
                  </div>
                }
                onClick={(r) => {
                  if (r.data.id !== "other") {
                    reportData.setSelectedFormType(r.data.source);
                  }
                }}
                qa={`${Components.ReportsCard}-Chart`}
              />
            </div>
          </div>
        </MediaQuery>
      </div>
      <div className={styles.footerLegend}>
        {topFourReportTypes?.map((r, i) => {
          return (
            <button
              aria-label={`Filter by ${r.formType.name}`}
              data-testid={`${Components.ReportsCard}-${r.formType.name}-legendButton`}
              key={r.formType.name}
              className={styles.legendItem}
              onClick={() => {
                reportData.setSelectedFormType(r);
              }}
              type="button"
            >
              <div
                className={styles.legendItemColor}
                style={{ backgroundColor: sliceColors[i].value }}
              ></div>
              <span
                style={{
                  borderBottom:
                    reportData.selectedFormType === r
                      ? "1px solid " + sliceColors[i]
                      : "",
                }}
              >
                {r.formType.name}
              </span>
            </button>
          );
        })}
      </div>
    </section>
  );
};
