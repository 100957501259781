import React from "react";
import {
  errorToastOptions,
  loadingToastOptions,
  successToastOptions,
  Toast,
  ToastStatus,
  updateToast,
} from "./Toastify";
import { serializeError } from "serialize-error";
import { API } from "@rtslabs/field1st-fe-common";

interface UseAPIwithToastsProps {
  loadingMessage: string;
  successMessage: string;
  errorMessage?: string;
  qaToast: string;
  onClose: () => void;
  setLoading: (loading: boolean) => void;
}

export const useAPIwithToasts = async <T, A>(
  apiCall: (apiArgs: A) => Promise<T>,
  apiArgs: A,
  {
    loadingMessage,
    successMessage,
    errorMessage,
    qaToast,
    onClose,
    setLoading,
  }: UseAPIwithToastsProps
) => {
  try {
    setLoading(true);
    updateToast(
      <Toast
        status={ToastStatus.Loading}
        message={loadingMessage}
        qa={`${qaToast}-loading`}
      />,
      qaToast,
      loadingToastOptions
    );
    await apiCall(apiArgs);
    updateToast(
      <Toast
        status={ToastStatus.Success}
        message={successMessage}
        qa={qaToast}
      />,
      qaToast,
      { ...successToastOptions, autoClose: 5000, onClose }
    );
    setLoading(false);
    onClose();
  } catch (err) {
    const error = errorMessage ? errorMessage : API.getErrorMessage(err);
    updateToast(
      <Toast
        status={ToastStatus.Error}
        message={error}
        qa={`${qaToast}-failure`}
      />,
      qaToast,
      errorToastOptions
    );
    const errorString = JSON.stringify(serializeError(err));
    console.error(errorString);
    setLoading(false);
  }
};
