import React from "react";
import { orderBy, camelCase } from "lodash";
import { StatsCard } from "../../../common/StatsCard/ReportStatsCard";
import Loader from "shared/src/components/Loader/Loader";
import { FormTypeStats } from "shared/src/components/clientAdmin/helpers";
import {
  FormTypeDTO,
  ReportingFormTypeDocumentCountVm,
} from "@rtslabs/field1st-fe-common";
import { Wrapper as StatsCardWrapper } from "../../../common/StatsCard/styles";
import { Components, ElementType } from "shared/src/qa-slugs";
import styles from "../../../common/StatsCard/ReportStatsCard.module.scss";
import { Icon } from "shared/src/components/Icon/Icon";
import { sliceColors } from "../reportsConstants";
import { PercentLineGraph } from "../../../dashboard/Desktop/PercentLineGraph";

interface DataPoint {
  color: string;
  id: string;
  qa: string;
  value: number;
  source?: ReportingFormTypeDocumentCountVm;
}

type Props = {
  formStats?: FormTypeStats;
  formType: FormTypeDTO;
  loadingStats?: boolean;
  onGroupClick: (g: number) => void;
  onAllGroupsClick: () => void;
  viewingAllGroups: boolean;
};

export const FormTypeSummary = ({
  formStats,
  formType,
  loadingStats,
  onGroupClick,
  onAllGroupsClick,
  viewingAllGroups,
}: Props) => {
  // TODO refactor - could be more sensibly placed (such as within StatsCard) -JA
  if (loadingStats || !formStats) {
    return (
      <StatsCardWrapper>
        <Loader loading />
      </StatsCardWrapper>
    );
  }

  if (formStats) {
    const groupsSorted = orderBy(
      formStats.groupCounts,
      ["percent"],
      ["desc"]
    ).filter((g) => g.total !== 0);

    const data: DataPoint[] = groupsSorted.map((group, i) => ({
      color: sliceColors[i].value,
      id: group.name,
      qa: group.name,
      value: group.percent,
    }));

    return (
      <StatsCard
        header={formType.name}
        value={formStats.totalCount}
        iconName={formType.iconName}
        iconColor={formType.iconColor}
      >
        <>
          <PercentLineGraph
            ariaLabel="Bar chart showing the proportion of groups in each form type."
            data={data}
            className={styles.percentLineGraph}
          />

          {/* only display top four groups on an individual card */}
          <div className={styles.groupsContainer}>
            {groupsSorted.slice(0, 4).map((group, i) => (
              <div
                key={group.name}
                className={styles.groupWrapper}
                onClick={() => onGroupClick(group.id)}
                data-testid={`${Components.Reports}-${
                  ElementType.Button
                }-group-${camelCase(formType.name)}`}
              >
                <span
                  className={styles.dot}
                  style={{ backgroundColor: sliceColors[i].value }}
                />
                <span className={styles.count}>{group.total}</span>
                <span className={styles.groupName}>{group.name}</span>
                <span className={styles.percentage}>{group.percent}%</span>
              </div>
            ))}
          </div>
          {!viewingAllGroups && (
            <a
              className={styles.viewAllGroups}
              onClick={onAllGroupsClick}
              data-testid={`${Components.Reports}-${
                ElementType.Button
              }-allGroups-${camelCase(formType.name)}`}
            >
              View All Groups <Icon type="chevron-right" size="24px" />
            </a>
          )}
        </>
      </StatsCard>
    );
  }
  // formStats is falsy
  return null;
};
