import { Person, ResourceType } from "@rtslabs/field1st-fe-common";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { DateRange } from "../../DateRange/DateRange";
import { SearchBar } from "../../SearchBar/SearchBar";
import { FilterSelect } from "../../Select/FilterSelect";
import { SelectOption } from "../../Select/Select";
import TextInputWithSuggestions from "../../TextInput/TextInputWithSuggestions";
import { DateRangeValue, TimeFilter } from "../../../data/timeFilters";
import { Components, ElementType } from "../../../qa-slugs";
import { INPUT_SEARCH_DELAY } from "../../../util/debounceDelays";
import useGroupTerm from "../../../util/hooks/useGroupTerm";
import { timeFilterOptions } from "../../../data/timeFilters";
import styles from "../../Filters/Filters.module.scss";
import { joinClassNames } from "../../../helpers/theme.helpers";

export interface ResourceFilters {
  author?: string;
  pastDays?: number;
  resourceType?: ResourceType;
  timeFilter?: TimeFilter;
  dateRange?: DateRangeValue;
  createdByEmailAddress?: string;
  query?: string;
}

const initialFilters: ResourceFilters = {
  author: undefined,
  pastDays: undefined,
  resourceType: undefined,
  timeFilter: undefined,
  dateRange: undefined,
};

type ResourceQuery = {
  query: string;
  selected: boolean;
};

type ResourcesFiltersProps = {
  authors: Person[];
  onSearchAuthors: (query: string) => void;
  onSelectAuthor: () => void;
  onUpdateFilters: (filters: ResourceFilters) => void;
  remoteClear: boolean;
};

const ResourcesFilters = ({
  authors = [],
  onSearchAuthors,
  onSelectAuthor,
  onUpdateFilters,
  remoteClear,
}: ResourcesFiltersProps) => {
  // Resource Author Filter
  const [authorQuery, setAuthorQuery] = useState<ResourceQuery>({
    query: "",
    selected: false,
  });

  function handleAuthorSearch(queryText: string) {
    setAuthorQuery({ query: queryText, selected: false });
    onSearchAuthors(queryText);
    authorQuery.selected && updateFilters("createdByEmailAddress", "");
  }

  function handleSelectAuthor(author: Person) {
    setAuthorQuery({ query: author.name, selected: true });
    onSelectAuthor();
    updateFilters("createdByEmailAddress", author.email);
  }

  // Group config terms
  const operationExperiencesTerm = useGroupTerm(
    "operationalExperiences",
    "noun",
    "plural",
    "Operational Experiences"
  );
  const defensesTerm = useGroupTerm("defenses", "noun", "plural", "Defenses");

  // Resource types for filter
  const resourceTypes: SelectOption<ResourceType | undefined>[] = [
    { value: undefined, label: "All Types" },
    { value: "OPERATIONAL_EXPERIENCE", label: operationExperiencesTerm },
    { value: "DEFENSE", label: defensesTerm },
  ];

  const [filters, setFilters] = useState<typeof initialFilters>(initialFilters);

  const updateFilters = (filter: keyof ResourceFilters, value?: unknown) => {
    const resetDateValue =
      filter === "timeFilter" && value !== TimeFilter.CUSTOM_RANGE;
    const newFilters = {
      ...filters,
      [filter]: value,
      ...(resetDateValue && { dateRange: undefined }),
    };
    setFilters(newFilters);
    onUpdateFilters(newFilters);
  };

  const handleSearch = debounce(
    (query: string) => updateFilters("query", query),
    500
  );

  useEffect(() => {
    if (remoteClear) {
      setFilters({ timeFilter: TimeFilter.ALL_TIME });
      authorQuery.query = "";
    }
  }, [remoteClear]);

  const isDateRange = filters.timeFilter === TimeFilter.CUSTOM_RANGE;

  return (
    <div
      className={joinClassNames(
        styles.filtersContainer,
        isDateRange && styles.withDateRange
      )}
    >
      <div className={styles.searchWrapper}>
        <SearchBar
          qa={`${Components.ResourcesFilters}-${ElementType.TextInput}-searchBar`}
          onSearch={handleSearch}
          remoteClear={remoteClear}
        />
      </div>
      <div className={styles.filterOptions}>
        <div className={styles.filterSelect}>
          <FilterSelect
            qa={`${Components.ResourcesFilters}-${ElementType.SelectInput}-resourceType`}
            name="resourceType"
            value={filters.resourceType}
            label=""
            placeholder="All Types"
            options={resourceTypes}
            onChange={(option) => updateFilters("resourceType", option?.value)}
          />
        </div>
        <div className={styles.filterSelect}>
          <FilterSelect
            qa={`${Components.ResourcesFilters}-${ElementType.SelectInput}-time`}
            name="timeFilter"
            value={filters.timeFilter}
            label=""
            placeholder="All Time"
            options={timeFilterOptions}
            onChange={(option) =>
              updateFilters("timeFilter", option?.value || TimeFilter.ALL_TIME)
            }
          />
        </div>
        <div className={styles.filterSelect}>
          <TextInputWithSuggestions<Person>
            idField="id"
            labelField="name"
            wrapperClassName={styles.author}
            placeholder="Search Resource Author"
            value={authorQuery.query}
            onInputChange={handleAuthorSearch}
            onSelectSuggestion={handleSelectAuthor}
            suggestions={authors}
            showSuggestions={authorQuery.query.length >= 3}
            debounceDelay={INPUT_SEARCH_DELAY}
            qa={`${Components.ResourcesFilters}-${ElementType.TextInput}-author`}
          />
        </div>
        {isDateRange && (
          <div className={styles.dateRangeRow}>
            <DateRange
              handleChange={(value: DateRangeValue) => {
                updateFilters("dateRange", value);
              }}
              labelId="customRangeId"
              name="customRange"
              onClear={() => {
                updateFilters("timeFilter", TimeFilter.ALL_TIME);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ResourcesFilters;
