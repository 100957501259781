import {
  DocumentVm,
  FormSectionVm,
  Functions,
  isQuestionDTO,
  SectionItem,
} from "@rtslabs/field1st-fe-common";
import moment from "moment";
import { validate } from "./DocumentForm/validation";
import { FormikErrors } from "formik";

export const getErrorsInSection = (
  section: FormSectionVm,
  errors: FormikErrors<DocumentVm>
): SectionItem[] => {
  const errorIds = Object.keys(errors);

  return section.items.filter((item) => {
    if (isQuestionDTO(item) && item.selections) {
      return item.selections.some((selection) =>
        errorIds.includes(`${item.id}_${selection.id}_comment`)
      );
    }
    return errorIds.includes(item.id.toString());
  });
};

export const getDocumentRehuddleInfo = (formValues: DocumentVm) => {
  return `Rehuddle started at ${moment(formValues.dateCreated).format(
    "h:mma M/DD/YYYY"
  )}`;
};

export const getDocumentSubmissionInfo = (
  formValues: DocumentVm,
  altToastTitle?: string
): string => {
  if (formValues.submissionType === "SUBMIT") {
    return `Submitted ${altToastTitle || formValues.title} by ${
      formValues.latestAuthorName || formValues.owner.name
    } at ${moment(formValues.submissionDate).format("h:mma M/DD/YYYY")}`;
  } else {
    return `Saved (draft) ${altToastTitle || formValues.title} by ${
      formValues.latestAuthorName || formValues.owner.name
    } at ${moment(formValues.submissionDate).format("h:mma M/DD/YYYY")}`;
  }
};

export const getIsSectionValidated = (
  section: FormSectionVm,
  formValues: DocumentVm
): boolean => {
  const requiredItems = getRequiredItemsNotValidated(section, formValues);
  return !requiredItems.length;
};

export const getRequiredItemsNotValidated = (
  section: FormSectionVm,
  formValues: DocumentVm
): string[] => {
  const visibleItems = getVisibleItems(section, formValues);
  const requiredItemsIDs = visibleItems
    .filter(
      (vi) =>
        (vi.type === "QUESTION" && vi.formProperties?.isRequired) ||
        (vi.subType === "SIGNATURE" &&
          vi.signatureRequired &&
          formValues.participants.length) ||
        (vi.type === "QUESTION" &&
          vi.selections?.some(
            (selection) => selection.properties?.commentRequired
          ))
    )
    // .map((requiredItem) => requiredItem.id.toString())
    .flatMap((requiredItem) => {
      // Initialize an array to hold the IDs
      const ids = [requiredItem.id.toString()];

      // Check if the item has required comments in its selections
      if (requiredItem.type === "QUESTION" && requiredItem.selections) {
        requiredItem.selections.forEach((selection) => {
          if (selection.properties?.commentRequired) {
            ids.push(`${requiredItem.id}_${selection.id}_comment`);
          }
        });
      }

      return ids;
    });
  const filteredParticipantsNeedToSign = Functions.getParticipantsNeedToSign(
    section.items,
    formValues.participants,
    formValues.responses,
    formValues.form.sections
  );
  const validateItemIDs = Object.keys(
    validate(formValues, filteredParticipantsNeedToSign, true)
  );
  const itemsNotValidated = validateItemIDs.filter((vid) =>
    requiredItemsIDs.includes(vid)
  );

  return itemsNotValidated;
};

export const getVisibleItems = (
  section: FormSectionVm,
  formValues: DocumentVm
): SectionItem[] => {
  return section.items!.filter((item) =>
    Functions.isTargetVisible(
      item.rootId,
      item.type,
      formValues.responses,
      formValues.form.displayConditions
    )
  );
};

export const getVisibleSections = (formValues: DocumentVm): FormSectionVm[] => {
  const visibleSections = formValues.form.sections.reduce(
    (results, section) => {
      if (
        Functions.isSectionVisible(
          section.rootId,
          formValues.responses,
          formValues.form.displayConditions
        )
      ) {
        results.push(section);
      }

      return results;
    },
    [] as FormSectionVm[]
  );

  return visibleSections;
};
