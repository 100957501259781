import {
  API,
  APIState,
  QuestionDTO,
  QuestionType,
  ReportingFormStatsVm,
} from "@rtslabs/field1st-fe-common";
import moment from "moment";
import * as React from "react";
import Loader from "shared/src/components/Loader/Loader";
import { DownloadResultsButton } from "shared/src/components/TableUI/TableSummary/DownloadResultsButton";
import { pluralize } from "shared/src/helpers/utils";
import { createGlobalStyle } from "styled-components";
import { ElementType } from "shared/src/qa-slugs";
import styles from "./ResponseSummaries.module.scss";
import { BarChartResponse } from "./BarChartResponse";

/**
 * When printing, just show the Summary tab contents and header.
 */
const GlobalPrintStyle = createGlobalStyle`
  @media print {
    .sidebar-navigation,
    #caFormTableSummary,
    #caFormFiltersAndStats,
    .caExportAllResponsesLink { 
      display: none !important; 
    }

    #mainContent { 
      display: block; 
      overflow: visible !important;
    }
  }
`;

interface ResponseSummariesProps {
  formId: number;
  formStats: APIState<ReportingFormStatsVm>;
  minMaxDate?: {
    minSubmissionDate: string;
    maxSubmissionDate: string;
  };
  switchToHistory: () => void;
  visible: boolean;
}

const ResponseSummaries = ({
  formId,
  switchToHistory,
  formStats,
  minMaxDate,
  visible,
}: ResponseSummariesProps) => {
  if (formStats.isLoading) {
    return <Loader loading />;
  }

  if (formStats.error) {
    return (
      <>
        An error occurred when loading the form summary. Refresh the page and
        try again.
      </>
    );
  }

  return formStats.data ? (
    <>
      {visible && <GlobalPrintStyle />}
      {formStats.data.questions.map((response) => {
        const hasChangedForRange = moment(
          response.question.lastModifiedDate
        ).isBetween(
          minMaxDate?.minSubmissionDate,
          minMaxDate?.maxSubmissionDate
        );
        return (
          <ResponseSummary
            key={response.question.id}
            formId={formId}
            hasChangedForRange={hasChangedForRange}
            switchToHistory={switchToHistory}
            {...response}
          />
        );
      })}
    </>
  ) : null;
};

type Data = {
  id: string;
  value: number;
  percentage: number;
  label: string;
};

type PreformattedData = {
  id: string;
  value: number;
  percentage: number;
  roundedPercentage: number;
  remainder: number;
  label: string;
};

interface ResponseSummaryType {
  formId: number;
  hasChangedForRange?: boolean;
  question: QuestionDTO;
  topResponses: {
    response: string;
    responseCount: number;
  }[];
  totalResponses?: number;
  switchToHistory: () => void;
}

/**
 * Helper function to get how to display a response for the given question type
 * @param subType
 */
const getResponseDisplayTransform = (
  subType: QuestionType
): ((string) => string) => {
  switch (subType) {
    case "TIME":
      return (s) => moment(s).format("hh:mm A");
    case "DATE":
      return (s) => moment(s).format("MM/DD/YY");
    case "DATE_TIME":
      return (s) => moment(s).format("MM/DD/YY hh:mm A");
    case "YES_OR_NO":
      return (s) =>
        s.toLowerCase() === "true" || s.toLowerCase() === "yes" ? "Yes" : "No";
    default:
      return (s) => s;
  }
};

const ResponseSummary = ({
  formId,
  question,
  totalResponses,
  topResponses,
}: ResponseSummaryType) => {
  const responseTransform = getResponseDisplayTransform(question.subType);
  topResponses = topResponses.map((r) => ({
    ...r,
    response: responseTransform(r.response),
  }));

  let responseDetails, detailsTitle;
  const dataForCharts: Data[] = topResponses.map((r) => ({
    id: r.response,
    value: r.responseCount,
    label: r.response,
    percentage: 0,
  }));

  switch (question.subType) {
    case "TEXT_LINE":
    case "PARTICIPANT":
    case "TEXT_AREA":
    case "TIME":
    case "DATE":
    case "DATE_TIME":
      responseDetails = <MostPopularResponseDetails responses={topResponses} />;
      detailsTitle = "Most Popular Responses";
      break;

    case "RADIO_BUTTONS":
    case "CHECKBOX":
    case "YES_OR_NO":
    case "DROP_DOWN":
    case "MULTI_SELECT":
      detailsTitle = "Results";
      responseDetails = (
        <BarChartResponseDetails
          data={dataForCharts}
          totalResponses={totalResponses}
        />
      );
      break;

    default:
      responseDetails = null;
      break;
  }

  if (responseDetails) {
    return (
      <div className={styles.responseSummaries}>
        <div className={styles.responseSummaryHeader}>
          <div className={styles.row}>
            <h4>{question.title}</h4>
            {!!totalResponses && (
              <div className={styles.exportAll}>
                <DownloadResultsButton
                  onExportPress={API.downloadQuestionResponses}
                  exportParams={{
                    formId,
                    questionId: question.id,
                  }}
                  mediaType="text/csv"
                  iconType="export_csv"
                  isTextVariant={true}
                  label="Export All Responses"
                  qa={`${ElementType.Button}-export-all-responses`}
                />
              </div>
            )}
          </div>
          <div className={styles.row}>
            <div className={styles.detailsTitle}>{detailsTitle}</div>
            <div className={styles.totalResponses}>
              {pluralize("response", totalResponses || 0, true)}
            </div>
          </div>
        </div>
        <div className={styles.responses}>
          {topResponses && topResponses.length > 0 ? (
            responseDetails
          ) : (
            <>No responses</>
          )}
        </div>
      </div>
    );
  }
  return null;
};

const MostPopularResponseDetails = ({ responses }) => (
  <div className={styles.mostPopular}>
    <div className={styles.column}>
      {responses.slice(0, 3).map((popular) => (
        <span key={popular.response}>
          {popular.response} ({popular.responseCount})
        </span>
      ))}
    </div>
    <div className={styles.column}>
      {responses.slice(3, 6).map((popular) => (
        <span key={popular.response}>
          {popular.response} ({popular.responseCount})
        </span>
      ))}
    </div>
  </div>
);

const BarChartResponseDetails = ({ data, totalResponses }) => {
  const initialData: PreformattedData[] = data.slice(0, 5).map((d) => {
    const percentage = (d.value / totalResponses) * 100;
    const roundedPercentage = Math.round(percentage);
    return {
      id: `${d.id} (${d.value})`,
      label: d.label,
      value: d.value,
      percentage: roundedPercentage,
      roundedPercentage,
      remainder: percentage - roundedPercentage,
    };
  });

  let totalRounded = initialData.reduce((sum, d) => sum + d.percentage, 0);

  if (totalRounded > 100) {
    const difference = totalRounded - 100;

    let maxIndex = 0;
    for (let i = 1; i < initialData.length; i++) {
      if (initialData[i].percentage > initialData[maxIndex].percentage) {
        maxIndex = i;
      }
    }

    initialData[maxIndex].percentage -= difference;
  }

  const formattedData: Data[] = initialData.map((d) => ({
    id: `${d.id} (${d.value})`,
    label: d.label,
    value: d.value,
    percentage: d.percentage,
  }));

  return (
    <div className={styles.responsesChart}>
      {formattedData.map((data, index) => (
        <BarChartResponse data={data} index={index} key={index} />
      ))}
    </div>
  );
};

export default ResponseSummaries;
