import React, { FC } from "react";
import styles from "../DeleteUserModal.module.scss";
import { Alert } from "shared/src/components/Alert/Alert";
import { GenericButton } from "shared/src/components/Generic/Button/GenericButton";

interface DeleteUserConfirmationProps {
  displayName: string;
  loading: boolean;
  onCancel: () => void;
  onSubmit: () => void;
}

export const DeleteUserConfirmation: FC<DeleteUserConfirmationProps> = ({
  displayName,
  loading,
  onCancel,
  onSubmit,
}) => (
  <>
    <Alert
      variant={"error"}
      message={"This change cannot be undone."}
      title={`Do you wish to permanently remove ${displayName}? `}
    />
    <div className={styles.buttonWrapper}>
      <GenericButton buttonStyle="tertiary" label="Cancel" onClick={onCancel} />
      <GenericButton label="Remove User" onClick={onSubmit} loading={loading} />
    </div>
  </>
);
