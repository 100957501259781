import ClickAwayListener from "@mui/material/ClickAwayListener";
import {
  API,
  ClientGroupStatsVm,
  SortDirection,
} from "@rtslabs/field1st-fe-common";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ActionButton } from "shared/src/components/ActionButton/ActionButton";
import Loader from "shared/src/components/Loader/Loader";
import { SearchBar } from "shared/src/components/SearchBar/SearchBar";
import BackToTop from "shared/src/components/TableUI/BackToTop/BackToTop";
import {
  Pagination,
  PaginationWrapper,
} from "shared/src/components/TableUI/Pagination/Pagination";
import { RowsPerPage } from "shared/src/components/TableUI/RowsPerPage/RowsPerPage";
import { TableSummary } from "shared/src/components/TableUI/TableSummary/TableSummary";
import { Components, ElementType } from "shared/src/qa-slugs";
import { GenericButton } from "shared/src/components/Generic/Button/GenericButton";
import {
  formTemplateReadRoles,
  groupWriteRoles,
} from "../../../routes/constants/permissionSets";
import * as UsersStyles from "../../clientAdmin/users/styles";
import WriteContent from "shared/src/components/common/permissions/WriteContent";
import Popper from "shared/src/components/common/Popper";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "shared/src/components/common/styled/Table";
import DefaultCell from "../../common/TableUI/DefaultCell";
import NoResults from "shared/src/components/common/TableUI/NoResults";
import { Text } from "shared/src/components/clientAdmin/styles";
import * as S from "./styles";
import { ContentWrapper } from "shared/src/components/Wrappers/Wrappers";
import { PageHeader } from "shared/src/components/PageHeader/PageHeader";
import styles from "./Groups.module.scss";
import { AddEditGroup } from "./modals/AddEditGroup";

const Groups: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const canViewFormTemplates = API.Environment.hasRoleAccess(
    formTemplateReadRoles
  );

  const [visible, setVisible] = useState(false);

  // Current page of group stats
  const [groupStats, setGroupStats] = useState<ClientGroupStatsVm[]>([]);

  // Pagination
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);

  // Group stats table is loading
  const [groupStatsLoading, setGroupStatsLoading] = useState(true);

  // Filter
  const [searchQuery, setSearchQuery] = useState("");

  // Sort
  const [primarySort, setPrimarySort] = useState({
    direction: "asc",
    type: "groupName",
  });
  const [sortingByGroupName, setSortingByGroupName] =
    useState<SortDirection>("asc");
  const [sortingByTeamMembers, setSortingByTeamMembers] =
    useState<SortDirection>("asc");
  const [sortingByFeedback, setSortingByFeedback] =
    useState<SortDirection>("asc");
  const [sortingBySubmissions, setSortingBySubmissions] =
    useState<SortDirection>("asc");
  const sortQuery = useMemo(
    () => `${primarySort.type},${primarySort.direction}`,
    [primarySort.type, primarySort.direction]
  );

  const sortBy = (type, direction) => {
    setPrimarySort({
      direction,
      type,
    });
    switch (type) {
      case "groupName":
        setSortingByGroupName(direction);
        break;
      case "feedback":
        setSortingByFeedback(direction);
        break;
      case "submissions":
        setSortingBySubmissions(direction);
        break;
      case "teamMembers":
        setSortingByTeamMembers(direction);
        break;
      default:
        return "";
    }
  };

  // Popper context menu
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [anchorGroup, setAnchorGroup] = useState<ClientGroupStatsVm>();
  const [popperIsOpen, setPopperIsOpen] = useState(false);

  // Request groups
  const requestGroups = useCallback(
    async (page) => {
      setGroupStatsLoading(true);
      try {
        const response = await API.getClientGroupStats({
          page,
          query: searchQuery,
          size,
          sort: sortQuery,
        });
        const { content, number, totalElements, totalPages } = response;
        setGroupStats(content);
        setTotalPages(totalPages);
        setTotalElements(totalElements);
        setPage(number);
      } catch (error) {
        console.error(error);
        // todo handle properly
      }
      setGroupStatsLoading(false);
    },
    [page, searchQuery, sortQuery, size]
  );

  // On filter or size change
  useEffect(() => {
    requestGroups(0);
  }, [size, searchQuery, sortQuery]);

  const qaBase = Components.Groups;
  const paths = [
    { pathName: "People", href: "/people/groups" },
    { pathName: "Groups", href: "/people/groups" },
  ];

  return (
    <>
      <Popper
        qa={`${qaBase}-${ElementType.MenuModal}-${anchorGroup?.clientGroupId}`}
        anchorEl={anchorEl}
        onClose={() => {
          // setPopperIsOpen(false);
        }}
        open={popperIsOpen}
        placement="left"
      >
        <UsersStyles.PopperWrapper>
          <WriteContent roles={groupWriteRoles}>
            <UsersStyles.PopperRow
              data-testid={`${qaBase}-${ElementType.MenuItem}-viewEditProfile-${anchorGroup?.clientGroupId}`}
              onClick={() =>
                navigate(
                  `/people/groups/view/${
                    anchorGroup && anchorGroup.clientGroupId
                  }`
                )
              }
            >
              <span>View/edit group profile</span>
              <UsersStyles.PopperChevron />
            </UsersStyles.PopperRow>
          </WriteContent>
          <UsersStyles.PopperRow
            data-testid={`${qaBase}-${ElementType.MenuItem}-viewDetailedReport-${anchorGroup?.clientGroupId}`}
            onClick={() =>
              navigate(
                `/reports?group=${anchorGroup && anchorGroup.clientGroupId}`
              )
            }
          >
            <span>View detailed reports</span>
            <UsersStyles.PopperChevron />
          </UsersStyles.PopperRow>

          {canViewFormTemplates && (
            <UsersStyles.PopperRow
              data-testid={`${qaBase}-${ElementType.MenuItem}-viewFormsTemplates-${anchorGroup?.clientGroupId}`}
              onClick={() =>
                navigate(
                  `/forms?group=${anchorGroup && anchorGroup.clientGroupId}`
                )
              }
            >
              <span>View active forms & templates</span>
              <UsersStyles.PopperChevron />
            </UsersStyles.PopperRow>
          )}
        </UsersStyles.PopperWrapper>
      </Popper>

      <ContentWrapper id="mainContent">
        <AddEditGroup
          visible={visible}
          onClose={() => {
            setVisible(false);
            requestGroups(0);
          }}
        />
        <PageHeader pageTitle="Groups" paths={paths} qa={qaBase}>
          <WriteContent roles={groupWriteRoles}>
            <GenericButton
              onClick={() => setVisible(true)}
              label="Add Group"
              qa={`${qaBase}-${ElementType.Button}-add-group`}
            />
          </WriteContent>
        </PageHeader>

        <div className={styles.contentBody}>
          {/* Filters */}
          <div className={styles.filtersContainer}>
            <SearchBar
              qa={`${qaBase}-${ElementType.TextInput}-searchBar`}
              onSearch={setSearchQuery}
            />
          </div>
          <Loader loading={groupStatsLoading}>
            <TableSummary
              pageSize={size}
              currentPage={page}
              totalElements={totalElements}
              ofWhat="groups"
              exports={["print", "xls", "csv"]}
              onExport={API.downloadClientGroupStats}
              exportParams={{ query: searchQuery }}
            />
            <Table>
              <TableHead>
                <TableRow noBottomBorder>
                  <TableCell
                    qa={`${qaBase}-${ElementType.TableCell}-sort-groupName`}
                    active={primarySort.type === "groupName"}
                    align="left"
                    minWidth="200px"
                    onClick={(dir) => sortBy("groupName", dir)}
                    sortDirection={sortingByGroupName}
                    className={styles.firstCell}
                  >
                    Group Name
                  </TableCell>
                  <TableCell
                    qa={`${qaBase}-${ElementType.TableCell}-sort-teamMembers`}
                    active={primarySort.type === "teamMembers"}
                    align="left"
                    onClick={(dir) => sortBy("teamMembers", dir)}
                    sortDirection={sortingByTeamMembers}
                    width="210px"
                  >
                    Team Members
                  </TableCell>
                  <TableCell
                    qa={`${qaBase}-${ElementType.TableCell}-sort-feedback`}
                    active={primarySort.type === "feedback"}
                    align="left"
                    onClick={(dir) => sortBy("feedback", dir)}
                    sortDirection={sortingByFeedback}
                    width="200px"
                  >
                    Feedback
                  </TableCell>
                  <TableCell
                    qa={`${qaBase}-${ElementType.TableCell}-sort-submissions`}
                    active={primarySort.type === "submissions"}
                    align="left"
                    onClick={(dir) => sortBy("submissions", dir)}
                    sortDirection={sortingBySubmissions}
                    width="200px"
                  >
                    Submissions
                  </TableCell>
                  <TableCell align="left" className={styles.lastCell} />
                </TableRow>
              </TableHead>
              <TableBody>
                {groupStats && groupStats.length > 0 ? (
                  groupStats.map((group) => (
                    <TableRow key={group.clientGroupId}>
                      <TableCell className={styles.firstCell}>
                        <WriteContent
                          altComponent={
                            <Text
                              data-testid={`${qaBase}-${ElementType.Text}-groupName-${group.clientGroupId}`}
                              fontSize={"0.875rem"}
                              fontWeight={400}
                            >
                              {group.groupName}
                            </Text>
                          }
                          roles={groupWriteRoles}
                        >
                          <Text
                            data-testid={`${qaBase}-${ElementType.Link}-groupName-${group.clientGroupId}`}
                            fontSize={"0.875rem"}
                            fontWeight={400}
                            onClick={() =>
                              navigate(
                                `/people/groups/view/${group.clientGroupId}`
                              )
                            }
                          >
                            {group.groupName}
                          </Text>
                        </WriteContent>
                        <S.GroupId
                          data-testid={`${qaBase}-${ElementType.Text}-externalGroupId-${group.clientGroupId}`}
                        >
                          {`ID: ${group.externalGroupId}` || "-"}
                        </S.GroupId>
                      </TableCell>
                      <TableCell align="left">
                        <DefaultCell
                          qa={`${qaBase}-${ElementType.Link}-teamMembers-${group.clientGroupId}`}
                          color="primary"
                          onClick={() =>
                            navigate(
                              `/people/users?group=${group.clientGroupId}`
                            )
                          }
                          value={group.teamMembers}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <div className="d-flex flex-row justify-content-left align-items-left">
                          <DefaultCell
                            qa={`${qaBase}-${ElementType.Text}-feedback-${group.clientGroupId}`}
                            value={group.feedback}
                          />
                          <i
                            className="icon-icons8-flag"
                            style={{ marginLeft: "10px" }}
                          />
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        <DefaultCell
                          qa={`${qaBase}-${ElementType.Link}-submissions-${group.clientGroupId}`}
                          color="primary"
                          onClick={() =>
                            navigate(`/reports?group=${group.clientGroupId}`)
                          }
                          value={group.submissions}
                        />
                      </TableCell>

                      <TableCell align="left" className={styles.lastCell}>
                        <ClickAwayListener
                          onClickAway={() => {
                            if (popperIsOpen) {
                              setPopperIsOpen(false);
                            }
                          }}
                        >
                          <div className="d-flex flex-row justify-content-end">
                            <ActionButton
                              qa={`${qaBase}-${ElementType.Menu}-${group.clientGroupId}`}
                              label={t("common:table.rowMenuButtonLabel")}
                              onClick={(e) => {
                                setAnchorEl(e.currentTarget);
                                setAnchorGroup(group);
                                setTimeout(() => {
                                  setPopperIsOpen(true);
                                }, 100);
                              }}
                            />
                          </div>
                        </ClickAwayListener>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <NoResults
                    icon="users"
                    header="No groups were found."
                    body="Please update your filters, or create a new group."
                    button={{
                      children: "Create a group",
                      onClick: () => navigate("/people/groups/add-group"),
                    }}
                    qa={`${qaBase}-${Components.NotFound}`}
                  />
                )}
              </TableBody>
            </Table>

            {/* // Pagination */}
            <div className={styles.paginationWrapper}>
              <PaginationWrapper>
                <RowsPerPage
                  qaBase={`${qaBase}-view`}
                  pageSize={size}
                  onClick={(size) => {
                    setSize(size);
                  }}
                />
                <Pagination
                  qaBase={`${qaBase}-pagination`}
                  currentPage={page}
                  totalPages={totalPages}
                  onClick={(page) => {
                    setPage(page);
                    requestGroups(page);
                  }}
                />
                <BackToTop qa={`${qaBase}-${ElementType.Link}-backToTop`} />
              </PaginationWrapper>
            </div>
          </Loader>
        </div>
      </ContentWrapper>
    </>
  );
};

export default Groups;
