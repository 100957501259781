import { TagDTO } from "@rtslabs/field1st-fe-common";
import moment from "moment";
import React, { FC } from "react";
import { Components, ElementType } from "shared/src/qa-slugs";
import {
  DataItemLabel,
  DataItemText,
  DisableUserButtonsWrapper,
  DisableUserDataItem,
  ModalPromptSubtitle,
  ModalPromptWrapper,
} from "../../../clientAdmin/users/styles";
import { actionPerformed } from "../helpers";
import { TagStatusOutcomeModal } from "./TagStatusOutcomeModal";
import { GenericButton } from "shared/src/components/Generic/Button/GenericButton";

export interface TagStatusModalProps {
  onCancel: () => void;
  onSubmit: () => void;
  status: "request" | "failure" | "success" | null;
  tag: TagDTO | null;
}

const qaBase = `${Components.TagLibraryTable}-${ElementType.Button}`;

export const TagStatusModal: FC<TagStatusModalProps> = ({
  onCancel,
  onSubmit,
  status,
  tag,
}) => {
  const successOrFailure = status === "success" || status === "failure";

  if (successOrFailure) {
    return (
      <TagStatusOutcomeModal
        archived={tag?.archived}
        onClose={onCancel}
        status={status}
      />
    );
  }

  return (
    <>
      <ModalPromptWrapper>
        <ModalPromptSubtitle>
          You are about to {actionPerformed(tag?.archived, "present")} this tag:
        </ModalPromptSubtitle>
        <div className="d-flex flex-row">
          <DisableUserDataItem>
            <DataItemLabel>Name</DataItemLabel>
            <DataItemText>{tag?.name || "-"}</DataItemText>
          </DisableUserDataItem>

          <DisableUserDataItem>
            <DataItemLabel>Uses</DataItemLabel>
            <DataItemText>{tag?.useCount || "-"}</DataItemText>
          </DisableUserDataItem>

          <DisableUserDataItem>
            <DataItemLabel>Date Added</DataItemLabel>
            <DataItemText>
              {tag ? moment(tag.createdDate).format("MM/DD/YYYY") : "-"}
            </DataItemText>
          </DisableUserDataItem>
        </div>

        <DisableUserButtonsWrapper margin="24px 0 0 0">
          <GenericButton
            data-testid={`${qaBase}-cancel`}
            buttonStyle="tertiary"
            disabled={false}
            onClick={onCancel}
            label="Cancel"
          />
          <GenericButton
            data-testid={`${qaBase}-submit`}
            disabled={false}
            loading={status === "request"}
            onClick={onSubmit}
            label="Yes"
          />
        </DisableUserButtonsWrapper>
      </ModalPromptWrapper>
    </>
  );
};
