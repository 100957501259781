import { API, DefenseDTO } from "@rtslabs/field1st-fe-common";
import { useField, useFormikContext } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { LazyLoadSelect } from "../../../../../../Select/LazyLoadSelect";
import { SelectOption } from "../../../../../../Select/Select";
import {
  CustomAnswerOptionsDrawerState,
  DefenseType,
} from "../../drawers/CustomAnswerOptionsDrawer/customAnswerOptionsFormUtils";
import styles from "./AddDefense.module.scss";
import CustomDefenseForm from "./CustomDefenseForm";

interface Props {
  defensesLoading: boolean;
  defenseType: DefenseType | null;
  globalDefenses: DefenseDTO[];
  isFinalPage: boolean;
  onLoadMore: () => void;
}

const AddDefense = ({
  defensesLoading,
  defenseType,
  globalDefenses,
  isFinalPage,
  onLoadMore,
}: Props) => {
  const { values, touched } =
    useFormikContext<CustomAnswerOptionsDrawerState>();
  const [defenseField, defenseMeta, defenseHelpers] = useField<
    DefenseDTO | undefined
  >("newGlobalDefense");
  const [defense, setDefense] = useState<DefenseDTO>();

  function handleSelectDefense(defenseOption?: SelectOption | null) {
    const defense = globalDefenses.find(
      (gDefense) => gDefense.id === defenseOption?.value
    );
    if (defense) {
      defenseHelpers.setValue({ ...defense });
    }
  }

  const globalDefenseSelectOptions = useMemo(() => {
    const options = globalDefenses
      .filter((gDefense) => gDefense.status === "ACTIVE")
      .map((gDefense) => ({
        label: gDefense.title || "Defense does not have title",
        value: gDefense.id,
      }));

    if (defense) {
      if (
        options.findIndex((gDefense) => gDefense.value === defense.id) === -1
      ) {
        options.unshift({
          label: defense.title || "Defense does not have title",
          value: defense.id,
        });
      }
    }

    return options;
  }, [globalDefenses, defense]);

  const getDefenseValue = async (id: number) => {
    try {
      const res = await API.getDefense({ id });
      setDefense(res);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (values.previousDefense?.id) {
      getDefenseValue(values.previousDefense?.id);
    }
  }, [values.previousDefense?.id]);

  switch (defenseType) {
    case DefenseType.Custom:
      return <CustomDefenseForm />;
    case DefenseType.Global:
      return (
        <LazyLoadSelect
          {...defenseField}
          overrideStyles={{
            valueContainer: (provided) => ({
              ...provided,
              padding: 5,
            }),
          }}
          label="Global Defense"
          labelClassName={styles.label}
          placeholder="Please select a defense"
          options={globalDefenseSelectOptions}
          onLoadMore={onLoadMore}
          isLoadingMore={defensesLoading}
          isFinalPage={isFinalPage}
          onBlur={() => defenseHelpers.setTouched(true)}
          onChange={handleSelectDefense}
          value={
            defenseField.value
              ? defenseField.value.id
              : values.previousDefense?.id
          }
          error={touched.selectedDefenseType ? defenseMeta.error : undefined}
          required
        />
      );
    default:
      return null;
  }
};

export default AddDefense;
