import React, { FC, ReactNode, useState } from "react";
import { Icon } from "shared/src/components/Icon/Icon";
import styles from "./HelpDesk.module.scss";

interface QuestionItemProps {
  title: string;
  children: ReactNode;
}

export const QuestionItem: FC<QuestionItemProps> = ({ title, children }) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div className={styles.mainQuestion}>
      <div className={styles.questionContent} onClick={toggleVisibility}>
        <span>{title}</span>
        <Icon
          className={styles.chevron}
          type={isVisible ? "expand_arrow" : "chevron-right"}
          role="img"
        />
      </div>
      {isVisible && (
        <div className={styles.newDocumentContainer}>{children}</div>
      )}
    </div>
  );
};
