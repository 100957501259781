import React, { useEffect } from "react";
import moment from "moment";
import styled, { useTheme } from "styled-components";
import { prettifyDocumentStatus } from "../../../Documents/helpers";
import {
  errorToastOptions,
  successToastOptions,
  Toast,
  updateToast,
  warningToastOptions,
} from "shared/src/components/Toast/Toastify";
import { toast, ToastOptions } from "react-toastify";
import { Icon } from "shared/src/components/Icon/Icon";

const EditLink = styled.a`
  color: ${({ theme }) => theme.masterColors.primary};
  margin-left: 10px;
  text-decoration: underline !important;
  text-transform: uppercase;
`;

const SubmissionStatus = ({ document }) => {
  const submittedAt = moment(document.submissionDate).format("h:mma M/D/YYYY");
  const qaToastId = "submission-status-toast";
  const theme = useTheme();

  const documentStatus = prettifyDocumentStatus(document.status);

  let iconColor;
  let toastOptions: ToastOptions;

  switch (documentStatus) {
    case "In Progress":
    case "New":
      iconColor = theme.colors.warning;
      toastOptions = warningToastOptions;
      break;
    case "Submitted":
      iconColor = theme.colors.success;
      toastOptions = successToastOptions;
      break;
    default:
      iconColor = theme.colors.error;
      toastOptions = errorToastOptions;
      break;
  }

  const CustomIcon = <Icon color={iconColor} size={24} type="checkmark" />;

  const message = `${
    documentStatus !== "N/A" ? documentStatus : "N/A status"
  } ${document.formSummary.name} at ${submittedAt}  `;

  const right = !document.readOnly && (
    <EditLink
      onClick={() =>
        window.open(`/document/${document.id}`, "", "target=blank")
      }
    >
      edit
    </EditLink>
  );

  useEffect(() => {
    updateToast(
      <Toast right={right} message={message} qa={qaToastId} />,
      qaToastId,
      {
        ...toastOptions,
        icon: () => CustomIcon,
        autoClose: false,
        closeOnClick: false,
      }
    );

    return () => {
      toast.dismiss(qaToastId);
    };
  }, [documentStatus]);

  return null;
};

export default SubmissionStatus;
