import {
  API,
  PageableRequest,
  Pagination as PaginationType,
  PaginationParams,
  Person,
  ResourceDTO,
  ResourceStatus,
} from "@rtslabs/field1st-fe-common";
import { omit } from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GenericButton } from "../../Generic/Button/GenericButton";
import { ContentWrapper } from "../../Wrappers/Wrappers";
import { Components, ElementType } from "../../../qa-slugs";
import usePrevious from "../../../util/hooks/usePrevious";
import Loader from "../../Loader/Loader";
import { getMinMaxSubmissionDatesFromTimeFilter } from "../../../data/timeFilters";
import { resourceWriteRoles } from "../../routes/constants/permissionSets";
import WriteContent from "../../common/permissions/WriteContent";
import ResourcesFilters, { ResourceFilters } from "./ResourcesFilters";
import ResourcesTable from "./ResourcesTable";
import ResourceTablePagination from "./ResourceTablePagination";
import { PageHeader } from "../../PageHeader/PageHeader";
import styles from "./MaintainResources.module.scss";

const initialPaginationParams = {
  page: 0,
  size: 10,
  sort: "lastModifiedDate,desc",
};

export const MaintainResources: React.FC = () => {
  const [authors, setAuthors] = useState<Person[]>([]);
  const [filters, setFilters] = useState<API.GetResourcesArgs>({});
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [paginationValues, setPaginationValues] = useState<
    Omit<PaginationType, "content"> | undefined
  >(undefined);
  const [params, setParams] = useState<PageableRequest>(
    initialPaginationParams
  );
  const [resources, setResources] = useState<ResourceDTO[]>([]);

  const fetchResources = async () => {
    setIsLoading(true);
    const res = await API.getResources({ ...params, ...filters });
    setResources(res.content);
    setPaginationValues(omit(res, ["content"]));
    setIsLoading(false);
  };

  const fetchAuthors = async (query: string) => {
    const authorsRes = await API.getResourceAuthors({ query });
    setAuthors(authorsRes.content);
  };

  const handleSelectAuthor = () => {
    setAuthors([]);
  };

  const onToggleResourceStatus = (
    resourceId: number,
    status: ResourceStatus
  ) => {
    const updatedResources = resources.map((resource) => {
      if (resource.id === resourceId) {
        return { ...resource, status };
      }
      return resource;
    });
    setResources(updatedResources);
  };

  const handleUpdateFilters = (filters: ResourceFilters) => {
    // renamed filters will be removed from the filters spread
    const renamedFilters = ["timeFilter", "dateRange"];
    const passableFilters = omit(filters, renamedFilters);

    const { minSubmissionDate, maxSubmissionDate } =
      getMinMaxSubmissionDatesFromTimeFilter(
        filters.timeFilter,
        filters.dateRange
      );

    const mappedFilters = {
      ...passableFilters,
      maxLastUpdatedDate: maxSubmissionDate,
      minLastUpdatedDate: minSubmissionDate,
    };
    setFilters(mappedFilters);
    // return to page 1 when we change the filters
    setParams({ ...params, page: 0 });
  };

  const handleSort = (sort: string) => setParams({ ...params, sort });

  const handleUpdatePaginationParams = (param: PaginationParams) =>
    setParams({ ...params, ...param });

  const handleAddResource = () => navigate("/content/resources/new");

  const prevParamsSize = usePrevious(params.size);

  // get the resources on mount and when search/pagination params change
  useEffect(() => {
    // When we change the page size, reset page to 0
    if (prevParamsSize !== params.size) {
      setParams({ ...params, page: 0 });
    } else {
      fetchResources();
    }
  }, [params, filters]);

  const [remoteClear, setRemoteClear] = useState<boolean>(false);
  const [isFiltered, setIsFiltered] = useState(false);

  const handleClearFilters = () => {
    setParams({ page: 0 });
    setFilters({});
    setRemoteClear(true);
  };

  useEffect(() => {
    setRemoteClear(false);
  }, [remoteClear]);

  const checkIfFiltered = (): void => {
    const isDefault =
      !filters.query &&
      !filters.resourceType &&
      !filters.minLastUpdatedDate &&
      !filters.maxLastUpdatedDate &&
      !filters.createdByEmailAddress;

    setIsFiltered(!isDefault);
  };

  useEffect(() => {
    checkIfFiltered();
  }, [filters]);

  const qaBase = Components.MaintainResources;
  const paths = [
    { pathName: "Content", href: "/content/resources" },
    { pathName: "Resources", href: "/content/resources" },
  ];

  return (
    <ContentWrapper id="mainContent">
      <PageHeader pageTitle="Resources" qa={qaBase} paths={paths}>
        <WriteContent roles={resourceWriteRoles}>
          <GenericButton
            onClick={handleAddResource}
            qa={`${Components.MaintainResources}-${ElementType.Button}-addResource`}
            label="Add Resource"
          />
        </WriteContent>
      </PageHeader>

      <div className={styles.listContainer}>
        <ResourcesFilters
          authors={authors}
          onSearchAuthors={fetchAuthors}
          onSelectAuthor={handleSelectAuthor}
          onUpdateFilters={handleUpdateFilters}
          remoteClear={remoteClear}
        />
        <Loader loading={isLoading}>
          <ResourcesTable
            onSort={handleSort}
            params={params}
            totalElements={paginationValues?.totalElements || 0}
            resources={resources}
            onToggleResourceStatus={onToggleResourceStatus}
            ifFiltered={isFiltered}
            onClearFilters={handleClearFilters}
          />
        </Loader>
        <div className={styles.paginationWrapper}>
          <ResourceTablePagination
            params={params}
            paginationValues={paginationValues}
            onUpdateParams={handleUpdatePaginationParams}
          />
        </div>
      </div>
    </ContentWrapper>
  );
};
