import { FormTypeDTO } from "@rtslabs/field1st-fe-common";
import React from "react";
import { DateRange } from "shared/src/components/DateRange/DateRange";
import { SearchBar } from "shared/src/components/SearchBar/SearchBar";
import { FilterSelect } from "shared/src/components/Select/FilterSelect";
import { SelectOption } from "shared/src/components/Select/Select";
import { DateRangeValue, TimeFilter } from "shared/src/data/timeFilters";
import { mapFormTypesToFilter } from "shared/src/helpers/filters";
import { Components, ElementType } from "shared/src/qa-slugs";
import { timeFilterOptions } from "shared/src/data/timeFilters";
import styles from "shared/src/components/Filters/Filters.module.scss";
import { joinClassNames } from "shared/src/helpers/theme.helpers";

type Props = {
  idPrefix: string;
  setNewPageNum: (n: number) => void;
  clientGroupId?: number;
  setClientGroupId: (i?: number) => void;
  groupFilterOptions: SelectOption<number | undefined>[];
  documentType?: number;
  setDocumentType: (t?: number) => void;
  formTypes: FormTypeDTO[];
  handleChangeDateRange: (dateRange?: DateRangeValue) => void;
  setQuery: (q: string) => void;
  timeFilter: TimeFilter;
  setTimeFilter: (type: TimeFilter) => void;
  remoteClear?: boolean;
};

/** Filter bar for reports */
export const ReportsFilters = ({
  idPrefix,
  setNewPageNum,
  clientGroupId,
  setClientGroupId,
  groupFilterOptions,
  documentType,
  setDocumentType,
  formTypes,
  handleChangeDateRange,
  setQuery,
  timeFilter,
  setTimeFilter,
  remoteClear,
}: Props) => {
  const customRangeId = `${idPrefix}-customRange`;

  const onClear = () => {
    setTimeFilter(TimeFilter.THIRTY_DAYS);
    handleChangeDateRange();
  };

  const isDateRange = timeFilter === TimeFilter.CUSTOM_RANGE;

  return (
    <div
      className={joinClassNames(
        styles.filtersContainer,
        isDateRange && styles.withDateRange
      )}
    >
      <div className={styles.searchWrapper}>
        <SearchBar
          qa={`${Components.Reports}-${ElementType.TextInput}-reportSearch`}
          onSearch={(query) => {
            setNewPageNum(0);
            setQuery(query);
          }}
          remoteClear={remoteClear}
        />
      </div>

      <div className={styles.filterOptions}>
        <div className={styles.filterSelect} style={{ minWidth: "185px" }}>
          <FilterSelect
            name="groups"
            value={clientGroupId ?? 0}
            label=""
            placeholder="All Groups"
            options={[{ value: 0, label: "All Groups" }, ...groupFilterOptions]}
            onChange={(option) => {
              setNewPageNum(0);
              // 0 is a possible value that we need to handle
              option?.value === 0
                ? setClientGroupId(undefined)
                : setClientGroupId(option?.value);
            }}
            qa={`${Components.Reports}-${ElementType.SelectInput}-groupsFilter`}
          />
        </div>

        <div className={styles.filterSelect} style={{ minWidth: "215px" }}>
          <FilterSelect
            name="documentType"
            value={documentType ?? 0}
            label=""
            placeholder="All Document Types"
            options={[
              { value: 0, label: "All Document Types" },
              ...mapFormTypesToFilter(formTypes),
            ]}
            onChange={(option) => {
              setNewPageNum(0);
              // 0 is a possible value that we need to handle
              option?.value === 0
                ? setDocumentType(undefined)
                : setDocumentType(option?.value);
            }}
            qa={`${Components.Reports}-${ElementType.SelectInput}-typeFilter`}
          />
        </div>

        <div className={styles.filterSelect} style={{ minWidth: "165px" }}>
          <FilterSelect
            placeholder="All Time"
            name="timeFilter"
            value={timeFilter}
            label=""
            options={timeFilterOptions}
            onChange={(option) => {
              setNewPageNum(0);
              setTimeFilter(option?.value || TimeFilter.ALL_TIME);
            }}
            qa={`${Components.Reports}-${ElementType.SelectInput}-timeFilter`}
            isSelected={timeFilter !== TimeFilter.THIRTY_DAYS}
          />
        </div>

        {isDateRange && (
          <div className={styles.dateRangeRow}>
            <DateRange
              handleChange={handleChangeDateRange}
              labelId={customRangeId}
              name={`${idPrefix}-customRange`}
              onClear={onClear}
              data-testid={`${Components.Reports}-${ElementType.SelectInput}-dateRangeFilter`}
            />
          </div>
        )}
      </div>
    </div>
  );
};
