import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
:root {
  --activeFill: ${({ theme }) => theme.colors.activeFill};
  --activeHeader: ${({ theme }) => theme.colors.activeHeader};
  --assistiveText: ${({ theme }) => theme.colors.assistiveText};
  --background: ${({ theme }) => theme.colors.background};
  --black: ${({ theme }) => theme.colors.black};
  --border: ${({ theme }) => theme.colors.border};
  --darkGrey: ${({ theme }) => theme.colors.darkGrey};
  --darkGreyBlue: ${({ theme }) => theme.colors.darkGreyBlue};
  --error: ${({ theme }) => theme.colors.error};
  --errorFill: ${({ theme }) => theme.colors.errorFill};
  --extraDarkGrey: ${({ theme }) => theme.colors.extraDarkGrey};
  --fieldInputBackground: ${({ theme }) => theme.colors.fieldInputBackground};
  --fieldInputBorder: ${({ theme }) => theme.colors.fieldInputBorder};
  --grey100: ${({ theme }) => theme.colors.grey100};
  --greyBlue: ${({ theme }) => theme.colors.greyBlue};
  --header: ${({ theme }) => theme.colors.header};
  --lightBlue: ${({ theme }) => theme.colors.lightBlue};
  --lightGrey: ${({ theme }) => theme.colors.lightGrey};
  --lightWhite: ${({ theme }) => theme.colors.lightWhite};
  --link: ${({ theme }) => theme.colors.link};
  --mediumGrey: ${({ theme }) => theme.colors.mediumGrey};
  --primary: ${({ theme }) => theme.colors.primary};
  --primaryFill: ${({ theme }) => theme.colors.primaryFill};
  --secondary: ${({ theme }) => theme.colors.secondary};
  --snow: ${({ theme }) => theme.colors.snow};
  --success: ${({ theme }) => theme.colors.success};
  --successFill: ${({ theme }) => theme.colors.successFill};
  --warning: ${({ theme }) => theme.colors.warning};
  --warningFill: ${({ theme }) => theme.colors.warningFill};
  --white: ${({ theme }) => theme.colors.white};
  --yellow: ${({ theme }) => theme.colors.yellow};
}

html, body, #root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

html {
  font-size: 16px;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'Roboto', sans-serif;
}

// Set outline to 0, an issue in MUI components and too many to fix manually
button {
  outline: 0;
}

button:focus {
  outline: 0;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// For tutorial dots
.slick-dots li {
  opacity: 1 !important;
}
.slick-dots li button:before {
  color: #E0E0E0 !important;
  font-size: 16px !important;
  opacity: 1 !important;
}
.slick-dots li.slick-active button:before {
  color: #0172CE !important;
  font-size: 16px !important;
  opacity: 1 !important;
}

// Fonts are in _fonts.scss, if you're moving on with SASS
// // Fonts

// /* Roboto regular */
// @font-face {
//     font-family: "Roboto";
//     src: url("/assets/fonts/Roboto-Regular.ttf") format('truetype');
//     font-weight: 400;
//     font-style: normal;
// }

// /* Roboto regular italic */

// @font-face {
//     font-family: "Roboto";
//     src: url('/assets/fonts/Roboto-Italic.ttf') format('truetype');
//     font-weight: 400;
//     font-style: italic;
// }

// /* Roboto medium */
// @font-face {
//   font-family: "Roboto";
//   src: url("/assets/fonts/Roboto-Medium.ttf") format('truetype');
//   font-weight: 500;
//   font-style: normal;
// }

// /* Roboto medium italic */
// @font-face {
//   font-family: "Roboto";
//   src: url("/assets/fonts/Roboto-MediumItalic.ttf") format('truetype');
//   font-weight: 500;
//   font-style: italic;
// }

// /* Roboto bold */
// @font-face {
//   font-family: "Roboto";
//   src: url("/assets/fonts/Roboto-Bold.ttf") format('truetype');
//   font-weight: 700;
//   font-style: normal;
// }

// /* Roboto bold */
// @font-face {
//   font-family: "Roboto";
//   src: url("/assets/fonts/Roboto-BoldItalic.ttf") format('truetype');
//   font-weight: 700;
//   font-style: italic;
// }

/* Screen reader only */
.sr-only {
  border: 0; 
  clip: rect(0 0 0 0); 
  height: 1px; 
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

`;

const GLOBAL_MAX_WIDTH = "960px";

export { GlobalStyle as default, GLOBAL_MAX_WIDTH };
