import {
  CoreRedux,
  LoginFailureResponse,
  LoginVM,
  LogoImgProps,
} from "@rtslabs/field1st-fe-common";
import React, { FC, useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import SplashScreen from "./SplashScreen";
import { oauthRedirect } from "./helpers";
import { Link } from "../Link/Link";
import { Button } from "../Button/Button";
import { Components, ElementType } from "../../qa-slugs";
import Logo from "../Logo";
import styles from "./auth.module.scss";
import FormikLogin from "./FormikLogin";
import { getVersionName } from "../../helpers/version";
import { useAppSelector } from "../../redux/reduxHooks";

interface LoginProps {
  onLogin: (args: LoginVM) => Promise<void>;
  logo?: LogoImgProps;
  error?: LoginFailureResponse;
  handleGetAdfsUserConfigs: (
    adfsToken: string,
    redirect: string | null
  ) => void;
  authType?: string;
  redirect?: string;
}

const Login: FC<LoginProps> = ({
  onLogin,
  error,
  logo,
  handleGetAdfsUserConfigs,
  authType,
  redirect,
}) => {
  const company = useAppSelector(CoreRedux.selectClientConfigProps);
  const [showSplash, setShowSplash] = useState<boolean>(true);

  const { hash } = useLocation();
  const hashParams = new URLSearchParams(hash.substring(1));
  const [params] = useSearchParams();

  const adfsToken = params.get("id_token") || hashParams.get("id_token");
  const adfsError = params.get("error");

  const redirectAfterLogin = params.get("redirectAfterLogin");

  const splashStatus = (hasToken: boolean, authType?: string) => {
    if (company?.adfsEnabled) {
      const adfsMessage = hasToken
        ? `Configuring ${authType || "ADFS"} login...`
        : `Checking if ${authType || "ADFS"} is enabled...`;

      return adfsMessage;
    }

    return "Loading...";
  };

  useEffect(() => {
    if (adfsToken) {
      handleGetAdfsUserConfigs(adfsToken, redirectAfterLogin);
    }
    if (setShowSplash) {
      setTimeout(() => setShowSplash(false), 2000);
    }
  }, [adfsToken]);

  if (showSplash) {
    <SplashScreen status={splashStatus(!!adfsToken, authType)} logo={logo} />;
  }

  return (
    <div className={styles.login}>
      <div className={styles.container}>
        <div className={styles.imgVersion}>
          <Logo logo={logo} />
          <span className={styles.version}>VERSION {getVersionName()}</span>
          <span className={styles.welcome}>Welcome</span>
        </div>

        {error?.resetPassword && (
          <div className={styles.resetPasswordBubble}>
            <div className={`icon-icons8-error ${styles.icon}`} />
            For security, continued failed attempts will result in your account
            being locked.
            <Link
              to="/help"
              className={styles.resetPasswordBubble}
              data-testid={`${Components.Login}-${ElementType.Link}-resetPassword`}
            >
              Reset your password.
            </Link>
          </div>
        )}

        <div className={styles.form}>
          <FormikLogin onLogin={onLogin} error={error} />

          {company?.adfsEnabled && (
            <>
              {/* temp ADFS code for testing -JA */}
              {adfsError && <span>{adfsError}</span>}

              <Button
                onClick={oauthRedirect("azure", redirect)}
                className={styles.adfsButton}
                qa={`${Components.Login}-${ElementType.Button}-adfs`}
              >
                ADFS LOGIN
              </Button>
              {/* end temp */}
            </>
          )}

          <Link
            className={styles.needHelp}
            to="/help"
            qa={`${Components.Login}-${ElementType.Link}-help`}
          >
            Need help logging in?
          </Link>
        </div>
      </div>
    </div>
  );
};

Login.displayName = "Login";
export default Login;
