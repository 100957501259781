import React, { FC, ReactNode } from "react";
import { toast, ToastContent, ToastOptions } from "react-toastify";
import scssVariables from "shared/src/sass/jsExports.module.scss";
import { Icon } from "../Icon/Icon";
import styles from "./Toastify.module.scss";
import { QAProps } from "../../qa-slugs";
import { joinClassNames } from "../../helpers/theme.helpers";
import { LoadingToast } from "./LoadingToast";

export enum ToastStatus {
  Success = "SUCCESS",
  Error = "ERROR",
  Warning = "WARNING",
  Info = "INFO",
  Redirecting = "REDIRECTING",
  ReadOnly = "READ ONLY",
  Loading = "",
}

interface ToastProps extends QAProps {
  status: ToastStatus;
  message: string;
  right?: never;
}

interface ToastPropsRightOverride extends QAProps {
  status?: never;
  message: string;
  right: ReactNode | string;
}

const toastDefaultOptions: ToastOptions = {
  autoClose: false,
  closeButton: false,
  closeOnClick: true,
  isLoading: false,
  position: "top-left",
};

export const errorToastOptions: ToastOptions = {
  ...toastDefaultOptions,
  bodyClassName: styles.errorBody,
  className: joinClassNames(styles.toastContainer, styles.errorBackground),
  icon: () => (
    <Icon
      className={styles.icon}
      color={scssVariables.error}
      size={24}
      type="high_priority"
    />
  ),
  type: "error",
};

export const infoToastOptions: ToastOptions = {
  ...toastDefaultOptions,
  bodyClassName: styles.infoBody,
  className: joinClassNames(styles.toastContainer, styles.infoBackground),
  icon: () => {
    const htmlTag = document.documentElement;
    const theme = htmlTag.getAttribute("data-theme");
    const isAdmin = theme === "admin";

    return (
      <Icon
        className={styles.icon}
        color={isAdmin ? scssVariables.primaryAdmin : scssVariables.primary}
        size={24}
        type="info"
      />
    );
  },
  type: "info",
};

export const successToastOptions: ToastOptions = {
  ...toastDefaultOptions,
  bodyClassName: styles.successBody,
  className: joinClassNames(styles.toastContainer, styles.successBackground),
  icon: () => (
    <Icon
      className={styles.icon}
      color={scssVariables.success}
      size={24}
      type="checkmark"
    />
  ),
  type: "success",
  autoClose: 5000,
};

export const readOnlyToastOptions: ToastOptions = {
  ...toastDefaultOptions,
  bodyClassName: styles.successBody,
  className: joinClassNames(styles.toastContainer, styles.successBackground),
  icon: () => (
    <Icon
      className={styles.icon}
      color={scssVariables.success}
      size={24}
      type="lock1"
    />
  ),
  type: "success",
  closeOnClick: false,
};

export const loadingToastOptions: ToastOptions = {
  ...toastDefaultOptions,
  bodyClassName: styles.loadingBody,
  className: joinClassNames(styles.toastContainer, styles.loadingBackground),
  icon: () => <LoadingToast />,
  type: "default",
};

export const warningToastOptions: ToastOptions = {
  ...toastDefaultOptions,
  bodyClassName: styles.warningBody,
  className: joinClassNames(styles.toastContainer, styles.warningBackground),
  icon: () => (
    <Icon
      className={styles.icon}
      color={scssVariables.warning}
      size={24}
      type="error-filled"
    />
  ),
  type: "warning",
};

export const updateToast = (
  content: ToastContent,
  toastId: string,
  options: ToastOptions
) => {
  if (toast.isActive(toastId)) {
    toast.update(toastId, { ...options, render: content });
  } else {
    toast(content, { toastId, ...options });
  }
};

export const Toast: FC<ToastProps | ToastPropsRightOverride> = ({
  message,
  status,
  right,
  qa,
}) => {
  return (
    <div className={styles.toastWrapper} data-testid={qa}>
      <span className={styles.toastMessage}>{message}</span>
      <span className={styles.toastStatus}>{right ?? status}</span>
    </div>
  );
};
