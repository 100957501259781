// Local
import {
  API,
  FilterParams,
  Pagination as PaginationType,
  PaginationParams,
  TagDTO,
} from "@rtslabs/field1st-fe-common";
import { omit } from "lodash";
import React, { useEffect, useState } from "react";
// Common
import { Components, ElementType } from "shared/src/qa-slugs";
import { GenericButton } from "shared/src/components/Generic/Button/GenericButton";
import Loader from "shared/src/components/Loader/Loader";
import { ContentWrapper } from "shared/src/components/Wrappers/Wrappers";
import usePrevious from "shared/src/util/hooks/usePrevious";
import { tagWriteRoles } from "../../../../routes/constants/permissionSets";
import WriteContent from "shared/src/components/common/permissions/WriteContent";
import TagFilters from "../components/TagFilters";
import { TagLibraryTable } from "../components/TagLibraryTable";
import TagLibraryTablePagination from "../components/TagLibraryTablePagination";
import { PageHeader } from "shared/src/components/PageHeader/PageHeader";
import styles from "./TagLibrary.module.scss";
import { AddTag } from "../components/AddTag";
import { useLocation, useNavigate } from "react-router-dom";

const initialPaginationParams = {
  page: 0,
  size: 10,
  sort: "name,asc",
};

export const TagLibrary = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [addTagVisible, setAddTagVisible] = useState<boolean>(false);
  const [filters, setFilters] = useState<FilterParams>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [paginationValues, setPaginationValues] = useState<
    Omit<PaginationType, "content"> | undefined
  >(undefined);
  const [params, setParams] = useState<PaginationParams>(
    initialPaginationParams
  );
  const [tags, setTags] = useState<TagDTO[]>([]);

  useEffect(() => {
    if (location.pathname === "/content/tag-library/add-tag") {
      setAddTagVisible(true);
    }
  }, [location.pathname]);

  const handleUpdateFilters = (filters) => {
    setFilters(filters);
    setParams({ ...params, page: 0 });
  };

  const handleSort = (sort: string) => setParams({ ...params, sort });

  const fetchTags = async () => {
    setIsLoading(true);
    try {
      const res = await API.getOETags({ ...params, ...filters });
      setTags(res.content);
      setPaginationValues(omit(res, ["content"]));
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const prevParamsSize = usePrevious(params.size);

  const handleUpdatePaginationParams = (param: PaginationParams) =>
    setParams({ ...params, ...param });

  const handleRefreshTags = (refresh: boolean) => {
    console.log("🚀 ~ handleRefreshTags ~ refresh:", refresh);
    setAddTagVisible(false);
    navigate("/content/tag-library", { replace: true });
    if (refresh) {
      setParams(initialPaginationParams);
      fetchTags();
    }
  };

  const handleOpenAddTag = () => {
    console.log("here");
    setAddTagVisible(true);
    navigate("/content/tag-library/add-tag", { replace: true });
  };

  // get the tags on mount and when search/pagination params change
  useEffect(() => {
    // When we change the page size, reset page to 0
    if (prevParamsSize !== params.size) {
      setParams({ ...params, page: 0 });
    } else {
      fetchTags();
    }
  }, [params, filters]);

  const qaBase = Components.TagLibrary;
  const paths = [
    { pathName: "Content", href: "/content/tag-library" },
    { pathName: "Tag Library", href: "/content/tag-library" },
  ];

  return (
    <ContentWrapper id="mainContent">
      <AddTag visible={addTagVisible} onClose={handleRefreshTags} />
      <PageHeader pageTitle="Tag Library" paths={paths} qa={qaBase}>
        <WriteContent roles={tagWriteRoles}>
          <GenericButton
            onClick={handleOpenAddTag}
            qa={`${qaBase}-${ElementType.Button}-addTag`}
            label="Add Tag"
          />
        </WriteContent>
      </PageHeader>

      <div className={styles.listContainer}>
        <TagFilters onUpdateFilters={handleUpdateFilters} />

        <Loader loading={isLoading}>
          <TagLibraryTable
            onFetch={fetchTags}
            onSort={handleSort}
            params={params}
            tags={tags}
            totalElements={paginationValues?.totalElements || 0}
            onAddTag={handleOpenAddTag}
          />
        </Loader>
        <div className={styles.paginationWrapper}>
          <TagLibraryTablePagination
            params={params}
            paginationValues={paginationValues}
            onUpdateParams={handleUpdatePaginationParams}
          />
        </div>
      </div>
    </ContentWrapper>
  );
};
