import React, { useState } from "react";
import { debounce } from "lodash";
import { SearchBar } from "shared/src/components/SearchBar/SearchBar";
import { Components, ElementType } from "shared/src/qa-slugs";
import styles from "shared/src/components/Filters/Filters.module.scss";

const initialFilters = {
  query: "",
};

type Props = {
  onUpdateFilters: (filters: typeof initialFilters) => void;
};

const TagFilters = ({ onUpdateFilters }: Props) => {
  const [filters, setFilters] = useState<typeof initialFilters>(initialFilters);

  const updateFilters = (filter: string, value: string) => {
    const newFilters = {
      ...filters,
      [filter]: value,
    };
    setFilters(newFilters);
    onUpdateFilters(newFilters);
  };

  const handleSearch = debounce(
    (query: string) => updateFilters("query", query),
    500
  );

  return (
    <div className={styles.filtersContainer}>
      <SearchBar
        qa={`${Components.TagFilters}-${ElementType.TextInput}-searchBar`}
        onSearch={handleSearch}
      />
    </div>
  );
};

export default TagFilters;
