import { CircularProgress } from "@mui/material";
import React, { FC, useEffect } from "react";
import { Components, ElementType, QAProps } from "../../qa-slugs";
import { TertiaryButton } from "../Button/Button";
import {
  PhotosHandling,
  UploadingPhoto,
} from "../Document/DocumentForm/usePhotos";
import { ProgressBar } from "../Document/ProgressBar/ProgressBar";
import { Icon } from "../Icon/Icon";
import styles from "./PhotoInProgress.module.scss";
import {
  errorToastOptions,
  Toast,
  ToastStatus,
  updateToast,
} from "../Toast/Toastify";
import { toast } from "react-toastify";

interface Props extends QAProps {
  photo: UploadingPhoto;
  onRetryPhoto: PhotosHandling["handleRetryPhoto"];
}

export const PhotoInProgress: FC<Props> = ({ photo, onRetryPhoto, qa }) => {
  useEffect(() => {
    if (photo.error) {
      updateToast(
        <Toast message={photo.error} status={ToastStatus.Error} />,
        "photo-error-toast",
        errorToastOptions
      );
    }

    return () => toast.dismiss("photo-error-toast");
  }, [photo.error]);

  return photo.error ? (
    <div
      className={styles.errorWrapper}
      data-testid={qa ? `${qa}-${Components.PhotoInProgress}` : undefined}
    >
      <Icon color="#C8102E" type="high_priority1" size={32} />
      <div className={styles.errorProgressWrapper}>
        <span className={styles.errorProgressText}>
          <p>{photo.error}</p>
          <TertiaryButton
            onClick={() => onRetryPhoto(photo.file)}
            qa={
              qa
                ? `${qa}-${Components.PhotoArea}-${ElementType.Button}-retry-upload-${photo.file.name}`
                : undefined
            }
          >
            retry
          </TertiaryButton>
        </span>
        <ProgressBar className={styles.errorProgressBar} />
      </div>
    </div>
  ) : (
    <div
      className={styles.progressWrapper}
      data-testid={qa ? `${qa}-${Components.PhotoInProgress}` : undefined}
    >
      <CircularProgress />
      <div className={styles.progressArea}>
        <span className={styles.progressTextLine}>
          <p>{photo.file.name}</p>
          <p>{Math.floor((photo.loaded / photo.total) * 100)}%</p>
        </span>
        <ProgressBar value={(photo.loaded / photo.total) * 100} />
      </div>
    </div>
  );
};
