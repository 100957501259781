import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import Tooltip from "@mui/material/Tooltip";
import PasswordRequirements from "./PasswordRequirements";
import AuthAnimatedButton from "./AuthAnimatedButton";
import styles from "./PasswordResetForm.module.scss";
import { newPasswordSchema } from "./helpers";
import { lightTooltipClasses } from "../Tooltip/Tooltip";
import { PASSWORD_REGEX } from "./constants";
import { TextInput } from "../TextInput/TextInput";
import { ElementType, QAProps } from "../../qa-slugs";
import { AuthRequestStatus } from "./types";

interface PasswordResetFormProps extends QAProps {
  onSubmit: (password: string) => void;
  loading: boolean;
  submitStatus?: AuthRequestStatus;
  onReturnToLogin: () => void;
  buttonText: string;
}

export const PasswordResetForm: React.FC<PasswordResetFormProps> = ({
  onSubmit,
  loading,
  submitStatus,
  onReturnToLogin,
  buttonText,
  qa,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Formik
      initialValues={{ password: "", confirmPassword: "" }}
      validationSchema={newPasswordSchema}
      onSubmit={(values, { resetForm }) => {
        onSubmit(values.password);
        resetForm();
      }}
    >
      {({ values, errors, touched, dirty }) => (
        <Form className={styles.formContainer}>
          <Tooltip
            classes={lightTooltipClasses}
            placement="bottom-start"
            open={showTooltip}
            title={
              <PasswordRequirements
                newPassword={values.password}
                passwordRegex={{
                  DIGIT: PASSWORD_REGEX.DIGIT,
                  FULL: PASSWORD_REGEX.FULL,
                  LOWERCASE: PASSWORD_REGEX.LOWERCASE,
                  SPECIAL: PASSWORD_REGEX.SPECIAL,
                  UPPERCASE: PASSWORD_REGEX.UPPERCASE,
                }}
              />
            }
          >
            <Field
              as={TextInput}
              name="password"
              label="New Password"
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              error={!showTooltip && errors["password"]}
              onBlur={() => setShowTooltip(false)}
              onFocus={() => setShowTooltip(true)}
              endAdornment={{
                visible: true,
                className: styles.endAdornment,
                label: showPassword ? "Hide" : "Show",
                handleClick: () => setShowPassword((sp) => !sp),
              }}
              qa={`${qa}-${ElementType.TextInput}-password`}
            />
          </Tooltip>
          <Field
            as={TextInput}
            name="confirmPassword"
            label="Confirm New Password"
            placeholder="Re-enter Your New Password"
            type="password"
            error={touched.confirmPassword && errors.confirmPassword}
            qa={`${qa}-${ElementType.TextInput}-confirmPassword`}
          />

          <div className={styles.buttonWrapper}>
            <AuthAnimatedButton
              loading={!!loading}
              qa={`${qa}-${ElementType.Button}-submit`}
              onComplete={onReturnToLogin}
              submitStatus={submitStatus}
              label={buttonText}
              disabled={!dirty || !!errors.password || !!errors.confirmPassword}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};
