import {
  BuilderFormSummaryVM,
  SortParams,
  WorkflowType,
} from "@rtslabs/field1st-fe-common";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IconBox } from "../../Icon/IconBox";
import { Components, ElementType } from "../../../qa-slugs";
import { ActionMenu, ActionMenuItem } from "../../common/ActionMenu";
import WriteContent from "../../common/permissions/WriteContent";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "../../common/styled/Table";
import NoResults from "../../common/TableUI/NoResults";
import { FormListStyles as S } from "./styles";
import styles from "./FormList.module.scss";
import { joinClassNames } from "../../../helpers/theme.helpers";

interface Props {
  sort: SortParams;
  setSort: (sp: SortParams) => void;
  forms: BuilderFormSummaryVM[];
  onCreateNew: (mo: boolean) => void;
  setDesiredWorkflowType: (workflowType: WorkflowType) => void;
  setCurrSummary: (s: BuilderFormSummaryVM) => void;
  cloneForm: (id: number) => void;
  formTemplateWriteRoles: any;
}

// helper method to get the desired workflowType for the activation toggle
function getActivationToggle(currentWorkflowType: WorkflowType): WorkflowType {
  switch (currentWorkflowType) {
    // draft/final to deactivated
    case "DRAFT":
      return "DEACTIVATED_DRAFT";
    case "FINAL":
      return "DEACTIVATED_FINAL";

    // deactivated to draft/final
    case "DEACTIVATED_DRAFT":
      return "DRAFT";
    case "DEACTIVATED_FINAL":
    case "DEACTIVATED":
      return "FINAL";
    default:
      // shouldn't be able to get here
      return currentWorkflowType;
  }
}

export function FormListTable({
  sort,
  setSort,
  forms,
  onCreateNew,
  setDesiredWorkflowType,
  setCurrSummary,
  cloneForm,
  formTemplateWriteRoles,
}: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Table>
      <TableHead>
        <TableRow noBottomBorder>
          <TableCell
            qa={`${Components.FormList}-${ElementType.TableCell}-sort-type`}
            active={sort[0] === "type"}
            onClick={(dir) => setSort(["type", dir])}
            sortDirection={sort[1]}
            className={styles.firstCell}
          >
            Type
          </TableCell>
          <TableCell
            qa={`${Components.FormList}-${ElementType.TableCell}-sort-name`}
            active={sort[0] === "name"}
            onClick={(dir) => setSort(["name", dir])}
            sortDirection={sort[1]}
          >
            Name
          </TableCell>
          <TableCell
            qa={`${Components.FormList}-${ElementType.TableCell}-sort-submissions`}
            active={sort[0] === "submissions"}
            onClick={(dir) => setSort(["submissions", dir])}
            sortDirection={sort[1]}
          >
            Submissions
          </TableCell>
          <TableCell
            qa={`${Components.FormList}-${ElementType.TableCell}-sort-groupUsage`}
            active={sort[0] === "groupUsage"}
            onClick={(dir) => setSort(["groupUsage", dir])}
            sortDirection={sort[1]}
          >
            Group Usage
          </TableCell>
          <TableCell
            qa={`${Components.FormList}-${ElementType.TableCell}-sort-status`}
            active={sort[0] === "status"}
            onClick={(dir) => setSort(["workflowType", dir])}
            sortDirection={sort[1]}
          >
            Status
          </TableCell>
          <TableCell className={styles.lastCell} />
        </TableRow>
      </TableHead>
      <TableBody>
        {forms.length > 0 ? (
          forms.map((form, idx) => (
            <TableRow
              key={`${form.name}_${idx}`}
              qa={`${Components.FormList}-${ElementType.TableRow}-${form.id}`}
            >
              <TableCell
                qa={`${Components.FormList}-${ElementType.TableRow}-type-${form.id}`}
                className={styles.firstCell}
              >
                {form.type.iconName ? (
                  <IconBox
                    type={form.type.iconName}
                    color={form.type.iconColor || ""}
                  />
                ) : (
                  <span>{form.name}</span>
                )}
              </TableCell>
              <TableCell>
                <WriteContent
                  altComponent={<>{form.name}</>}
                  roles={formTemplateWriteRoles}
                >
                  <S.FormLink
                    data-testid={`${Components.FormList}-${ElementType.Link}-formName-${form.name}`}
                    variant="link"
                    onClick={() => navigate(`/forms/form/${form.id}`)}
                  >
                    {form.name}
                  </S.FormLink>
                </WriteContent>
              </TableCell>
              <TableCell
                qa={`${Components.FormList}-${ElementType.TableCell}-submissions-${form.id}`}
              >
                {form.submissions}
              </TableCell>
              <TableCell
                qa={`${Components.FormList}-${ElementType.TableCell}-groupUsage-${form.id}`}
              >
                {form.groupUsage}
              </TableCell>
              <TableCell
                qa={`${Components.FormList}-${ElementType.TableCell}-status-${form.id}`}
                className={styles.statusCell}
              >
                <span
                  className={joinClassNames(
                    form.workflowType === "FINAL" && styles.final,
                    form.workflowType === "DRAFT" && styles.draft,
                    form.workflowType === "DELETED" && styles.deleted
                  )}
                >
                  {form.workflowType.startsWith("DEACTIVATED")
                    ? "DEACTIVATED"
                    : form.workflowType}
                </span>
              </TableCell>
              <TableCell className={styles.lastCell}>
                <ActionMenu
                  buttonLabel={t("common:table.rowMenuButtonLabel")}
                  qaBase={`${Components.FormList}-${form.id}`}
                >
                  {/* Form builder link */}
                  <WriteContent roles={formTemplateWriteRoles}>
                    <ActionMenuItem
                      qa={`${Components.FormList}-${ElementType.MenuItem}-viewEdit-${form.id}`}
                      onClick={() => navigate(`/forms/form/${form.id}`)}
                      label="View/Edit form"
                    />
                  </WriteContent>

                  {/* Clone form */}
                  <WriteContent roles={formTemplateWriteRoles}>
                    <ActionMenuItem
                      qa={`${Components.FormList}-${ElementType.MenuItem}-cloneForm-${form.id}`}
                      onClick={() => cloneForm(form.id)}
                      label="Clone form"
                    />
                  </WriteContent>

                  {/* Reports link */}
                  {form.submissions > 0 && (
                    <ActionMenuItem
                      qa={`${Components.FormList}-${ElementType.MenuItem}-viewReport-${form.id}`}
                      onClick={() => navigate(`/reports/${form.id}`)}
                      label="View detailed form report"
                    />
                  )}

                  {/* Toggle Deactivated */}
                  <WriteContent roles={formTemplateWriteRoles}>
                    <ActionMenuItem
                      qa={`${Components.FormList}-${ElementType.MenuItem}-enableOrDisableForm-${form.id}`}
                      danger
                      onClick={() => {
                        setCurrSummary(form);
                        setDesiredWorkflowType(
                          getActivationToggle(form.workflowType)
                        );
                      }}
                      label={
                        (form.workflowType.startsWith("DEACTIVATED")
                          ? "Enable"
                          : "Disable") + " form (permissions required)"
                      }
                    />
                  </WriteContent>

                  {/* Delete */}
                  {form.submissions > 0 ||
                  form.workflowType === "FINAL" ||
                  form.outstanding > 0 ? null : (
                    <WriteContent roles={formTemplateWriteRoles}>
                      <ActionMenuItem
                        qa={`${Components.FormList}-${ElementType.MenuItem}-deleteForm-${form.id}`}
                        danger
                        onClick={() => {
                          setCurrSummary(form);
                          setDesiredWorkflowType("DELETED");
                        }}
                        label={"Delete form (permissions required)"}
                      />
                    </WriteContent>
                  )}

                  {/* Discard Draft */}
                  {form.workflowType != "DRAFT" ? null : (
                    <WriteContent roles={formTemplateWriteRoles}>
                      <ActionMenuItem
                        qa={`${Components.FormList}-${ElementType.MenuItem}-discardChanges-${form.id}`}
                        danger
                        onClick={() => {
                          setCurrSummary(form);
                          setDesiredWorkflowType("DISCARDED_DRAFT");
                        }}
                        label={"Discard changes (permissions required)"}
                      />
                    </WriteContent>
                  )}
                </ActionMenu>
              </TableCell>
            </TableRow>
          ))
        ) : (
          <NoResults
            icon="document"
            header="No forms found"
            body="Create a new form"
            button={{
              children: "Create a form",
              onClick: () => onCreateNew(true),
            }}
            qa={`${Components.FormList}-${Components.NotFound}`}
            roles={formTemplateWriteRoles}
          />
        )}
      </TableBody>
    </Table>
  );
}
