import styled from "styled-components";

import { toRgba } from "shared/src/themes/helpers";

import { SmallText } from "shared/src/components/clientAdmin/styles";

// @ts-ignore TODO circular reference -JA
export const AreaText = styled(SmallText)`
  color: ${({ theme }) => theme.masterColors.darkGrey};
`;
export const Dot = styled.div<{ color: string }>`
  background: ${({ color }) => color};
  border-radius: 50%;
  height: 1rem;
  margin-right: 0.5rem;
  width: 1rem;
`;
// @ts-ignore TODO circular reference -JA
export const PercentageText = styled(SmallText)`
  color: ${({ theme }) => toRgba(theme.masterColors.darkGrey, 0.87)};
`;
export const QuestionText = styled(SmallText)`
  font-size: 1rem;
`;

// export const SendCommentIcon: React.FC = () => {
//   const theme = useContext(ThemeContext);
//   return (
//     <Icon
//       color={theme.colors.primary}
//       framed={false}
//       listItem
//       size="1rem"
//       type="send_comment"
//     />
//   );
// };

export const SendCommentIcon = styled.img.attrs({
  src: "/assets/images/comments-icon.png",
})`
  margin-left: 8px;
`;

export const StyledDownloadButton = styled.button<{ bold?: boolean }>`
  background: transparent;
  outline: 0;
  border: 0;
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: underline;
  font-weight: 400;
  ${({ bold }) => (bold ? "" : "font-size: 0.75rem")}
  padding: 0;
  font-size: 14px;
  :focus {
    outline: 0;
  }
`;
