import React from "react";
import * as S from "./styles";
import { ReportingStatsByFormTypeVm } from "@rtslabs/field1st-fe-common";

const getTotal = (submissions) => {
  let result = 0;
  if (submissions) {
    submissions.forEach((submission) => (result += submission.totalDocuments));
  }
  return result;
};

interface SubmissionsCardProps {
  submissions?: ReportingStatsByFormTypeVm[];
  title: string;
}

/** @deprecated - Due for a redesign. See Figma mock */
const SubmissionsCard = (props: SubmissionsCardProps) => {
  const { submissions = [], title } = props;
  return (
    <S.Wrapper>
      <S.Title>{title}</S.Title>
      <S.Total>{getTotal(submissions)}</S.Total>
      <S.Bar>
        {submissions.map((e, i) => {
          return (
            <S.BarItem
              color={e.formTypeIconColor!}
              width={e.percentageOfDocuments}
              key={i}
            />
          );
        })}
      </S.Bar>
      {submissions.map((e, i) => {
        return (
          <S.Row key={i}>
            <S.RowNameWrapper>
              <S.RowNameIcon color={e.formTypeIconColor!}></S.RowNameIcon>
              <S.RowNameContent>{e.formTypeName}</S.RowNameContent>
            </S.RowNameWrapper>
            <S.RowValueWrapper>
              <S.RowValueAmount>{e.totalDocuments}</S.RowValueAmount>
              <S.RowValuePercentage>
                {e.percentageOfDocuments && e.percentageOfDocuments.toFixed(0)}%
              </S.RowValuePercentage>
            </S.RowValueWrapper>
          </S.Row>
        );
      })}
    </S.Wrapper>
  );
};

export default SubmissionsCard;
