import {
  CoreRedux,
  DocumentPhotoVm,
  useDebouncedValue,
} from "@rtslabs/field1st-fe-common";
import React, { FC, useEffect, useState } from "react";
import { Components, ElementType, QAProps } from "../../qa-slugs";
import { PhotosHandling } from "../Document/DocumentForm/usePhotos";
import { Icon } from "../Icon/Icon";
import { ModalPromptCloseButton } from "../Modal/ModalPromptCloseButton";
import { TextInput } from "../TextInput/TextInput";
import {
  errorToastOptions,
  successToastOptions,
  Toast,
  ToastStatus,
  updateToast,
} from "../Toast/Toastify";
import { DeletePhotoConfirmationModal } from "./DeletePhotoConfirmationModal";
import styles from "./Photo.module.scss";
import { useAppSelector } from "../../redux/reduxHooks";
import { HazardAnalysis } from "../HazardAnalysis/HazardAnalysis";
import { useHazardContext } from "../HazardAnalysis/hooks/useHazardContext";
import {
  formatToHtmlTags,
  updateImageUrl,
} from "../HazardAnalysis/hazard.helpers";
import RichTextEditor from "../common/RichTextEditor";

interface Props extends QAProps {
  photo: DocumentPhotoVm;
  onClick: () => void;
  onDeletePhoto: PhotosHandling["handleDeletePhoto"];
  onUpdatePhoto: PhotosHandling["handleUpdatePhoto"];
  type: "IMAGES_ABOVE" | "IMAGES_BELOW";
  questionTitle: string;
  readOnly?: boolean;
}

export const Photo: FC<Props> = ({
  photo,
  onClick,
  onDeletePhoto,
  onUpdatePhoto,
  type,
  questionTitle,
  qa,
  readOnly,
}) => {
  const [openHazardAnalysis, setOpenHazardAnalysis] = useState<boolean>(false);
  const { setHazardImageUrl } = useHazardContext();
  const chatConfig = useAppSelector(CoreRedux.selectChatConfigProps);
  const [value, setValue] = useState(photo.description);
  const [hazardAnalysisValue, setHazardAnalysisValue] = useState<string>();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const caption = useDebouncedValue(value, 300);

  const hasOpenAi = chatConfig?.provider === "openai" && chatConfig?.token;

  useEffect(() => {
    if (caption !== photo.description) {
      onUpdatePhoto({ ...photo, description: caption });
    }
  }, [caption]);

  const handleDeletePhoto = async () => {
    const qaToastId = `${Components.PhotoArea}-deletePhoto`;

    try {
      setDeleteLoading(true);
      await onDeletePhoto(photo.id);
      setDeleteLoading(false);
      setDeleteModalVisible(false);
      updateToast(
        <Toast
          status={ToastStatus.Success}
          message={`Photo Deleted from ${questionTitle}`}
        />,
        qaToastId,
        successToastOptions
      );
    } catch (err) {
      updateToast(
        <Toast
          status={ToastStatus.Error}
          message={"Unable to delete photo. Please refresh and try again."}
        />,
        qaToastId,
        errorToastOptions
      );
    }
  };

  return (
    <div
      data-testid={qa}
      className={
        type === "IMAGES_ABOVE"
          ? styles.galleryPhotoWrapper
          : styles.photoWrapper
      }
    >
      {hasOpenAi && openHazardAnalysis && (
        <HazardAnalysis
          openHazardAnalysis={openHazardAnalysis}
          onSaveHazardInfoToDoc={(messages) => {
            const formattedMessages = formatToHtmlTags(messages);
            setHazardAnalysisValue(formattedMessages);
            onUpdatePhoto({ ...photo, hazardAnalysis: formattedMessages });
            setOpenHazardAnalysis(false);
          }}
          onCloseHazardAnalysis={() => {
            setOpenHazardAnalysis(false);
            setHazardImageUrl(null);
          }}
          hideUpload
        />
      )}
      <DeletePhotoConfirmationModal
        loading={deleteLoading}
        onCancel={() => setDeleteModalVisible(false)}
        onConfirm={handleDeletePhoto}
        visible={deleteModalVisible}
      />
      {/* Photo Gallery Photo View */}
      {type === "IMAGES_ABOVE" && (
        <div className={styles.galleryPhoto} onClick={onClick}>
          <img src={photo.imageUrl} className={styles.galleryPhoto} />
          <ModalPromptCloseButton
            qa={qa ? `${qa}-delete` : undefined}
            onClick={(event?: React.MouseEvent) => {
              event?.stopPropagation();
              setDeleteModalVisible(true);
            }}
            className={styles.galleryDeleteButton}
          />
        </div>
      )}
      {/* Question Photo View */}
      {type === "IMAGES_BELOW" && (
        <div className={styles.photoAndCaptionContainer}>
          <img
            src={photo.imageUrl}
            className={styles.photo}
            onClick={onClick}
          />
          <div className={styles.captionArea}>
            <div className={styles.captionFirstLine}>
              <span>
                Photo Caption
                {hasOpenAi && (
                  <span className={styles.analyzeHazards}>
                    (
                    <span
                      onClick={() => {
                        setHazardImageUrl(updateImageUrl(photo.imageUrl));
                        setOpenHazardAnalysis(true);
                      }}
                    >
                      Analyze photo for potential hazards
                    </span>
                    )
                  </span>
                )}
              </span>
              {!readOnly && (
                <button
                  data-testid={qa ? `${qa}-delete` : undefined}
                  className={styles.deleteButton}
                  onClick={(event) => {
                    event.stopPropagation();
                    setDeleteModalVisible(true);
                  }}
                  type="button"
                >
                  <Icon type="icons8-delete_sign" />
                </button>
              )}
            </div>
            {!readOnly && (
              <TextInput
                qa={qa ? `${qa}-${ElementType.TextInput}` : undefined}
                value={value}
                onChange={(
                  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => setValue(e.target.value)}
              />
            )}
            {!readOnly && (hazardAnalysisValue || photo.hazardAnalysis) && (
              <>
                <div
                  className={styles.captionFirstLine}
                  style={{ paddingTop: 10 }}
                >
                  <span>Hazard Analysis</span>
                </div>
                <RichTextEditor
                  initialContent={photo.hazardAnalysis || ""}
                  updateContent={hazardAnalysisValue}
                  className={styles.hazardAnalysis}
                  onChangeContent={(content?: string) => {
                    onUpdatePhoto({ ...photo, hazardAnalysis: content });
                  }}
                />
              </>
            )}

            {readOnly && <div>{value}</div>}
          </div>
        </div>
      )}
    </div>
  );
};

// background image loading lazy?
