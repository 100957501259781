import { rgbToHex } from "@mui/material/styles";
import { css } from "aphrodite";
import React from "react";
import Label from "../../../Label/Label";
import { TextInput } from "../../../TextInput/TextInput";
import { joinClassNames } from "../../../../helpers/theme.helpers";
import * as QA from "../../../../qa-slugs";
import styles from "./styles";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  withText?: boolean;
  onColorChange: (val: string) => void;
  colorValue: string | null;
  ["data-testid"]?: string;
  propStyles?: {
    colorInput?: string;
    textInput?: string;
  };
  hasError: boolean;
}

/**
 * Shorthand for converting rgb to hex
 * @param value
 */
function getHex(value: string | null): string {
  if (value?.includes("rgb")) {
    return rgbToHex(value);
  }
  return value || "";
}

function ColorPicker({
  label,
  name,
  id,
  onColorChange,
  colorValue,
  withText,
  ["data-testid"]: dataTestId = QA.Components.ColorPicker,
  disabled,
  propStyles,
  hasError,
}: Props) {
  const s = styles();

  if (withText) {
    return (
      <div className={css(s.col)}>
        {label && (
          <Label className={css(s.label)} htmlFor={name || ""}>
            {label}
          </Label>
        )}
        <div className={css(s.row)}>
          <input
            name={name}
            type="color"
            value={getHex(colorValue)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onColorChange(e.target.value)
            }
            className={joinClassNames(css(s.picker), propStyles?.colorInput)}
            data-testid={`${dataTestId}-${QA.Components.ColorPickerButton}`}
            disabled={disabled}
          />
          <TextInput
            name={name}
            placeholder="Hexadecimal value"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onColorChange(e.target.value)
            }
            value={getHex(colorValue)}
            qa={`${dataTestId}-${QA.Components.ColorPickerText}`}
            disabled={disabled}
            wrapperClassName={propStyles?.textInput}
            error={hasError}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={css(s.row)}>
      <input
        name={name}
        type="color"
        value={getHex(colorValue)}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onColorChange(e.target.value)
        }
        className={joinClassNames(css(s.picker), propStyles?.colorInput)}
        data-testId={`${dataTestId}-${QA.Components.ColorPickerButton}`}
        disabled={disabled}
      />
      {label && (
        <Label className={css(s.label)} htmlFor={id || ""}>
          {label}
        </Label>
      )}
    </div>
  );
}

export default ColorPicker;
