import React, { FC } from "react";
import { GenericButton } from "shared/src/components/Generic/Button/GenericButton";
import styles from "../DeleteUserModal.module.scss";

interface Props {
  onClose: () => void;
}
export const DeleteUserSuccess: FC<Props> = ({ onClose }) => (
  <>
    <div className={styles.modalContent}>
      The selected user's account has been successfully deleted. User will no
      longer be able to log in.
    </div>
    <div className={styles.buttonWrapper}>
      <GenericButton onClick={onClose} label="Close" />
    </div>
  </>
);
