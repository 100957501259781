import { API, FormTypeDTO } from "@rtslabs/field1st-fe-common";
import React, { Dispatch, FC, SetStateAction, useState } from "react";
import { Alert } from "shared/src/components/Alert/Alert";
import { Components, ElementType } from "shared/src/qa-slugs";
import styles from "./DeleteFormType.module.scss";
import { GenericButton } from "shared/src/components/Generic/Button/GenericButton";
import { useAPIwithToasts } from "shared/src/components/Toast/useAPIwithToasts";

interface DeleteFormTypeConfirmationProps {
  onCancel: () => void;
  onClose: () => void;
  formType: FormTypeDTO;
  setDeleteFormTypeIsVisible: Dispatch<SetStateAction<boolean>>;
}

export const DeleteFormTypeConfirmation: FC<
  DeleteFormTypeConfirmationProps
> = ({ onClose, onCancel, formType, setDeleteFormTypeIsVisible }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    const qaToastDelete = `${Components.FormTypes}-${ElementType.Toast}-delete`;

    await useAPIwithToasts(
      API.deleteFormType,
      { id: formType.id },
      {
        loadingMessage: `Deleting Form Type "${formType.name}"...`,
        successMessage: `Form Type "${formType.name}" Deleted`,
        errorMessage: `Error - Unable to delete "${formType.name}" form type`,
        qaToast: qaToastDelete,
        onClose,
        setLoading,
      }
    );
    setLoading(false);
    setDeleteFormTypeIsVisible(false);
  };

  return (
    <>
      <Alert
        variant={"error"}
        message={
          "Deleting a form type will also result in deleting all of the forms and documents of that type. This change cannot be undone."
        }
        title={`Do you wish to permanently remove "${formType.name}" form type?`}
      />
      <div className={styles.modalFooter}>
        <GenericButton
          buttonStyle="tertiary"
          label="Cancel"
          qa={`${Components.FormTypes}-${ElementType.Button}-cancel`}
          onClick={onCancel}
        />
        <GenericButton
          label="Remove Form Type"
          qa={`${Components.FormTypes}-${ElementType.Button}-delete`}
          onClick={handleSubmit}
          loading={loading}
        />
      </div>
    </>
  );
};
