import {
  API,
  ApplicationConfigKey,
  ClientConfigProperties,
  CoreRedux,
} from "@rtslabs/field1st-fe-common";
import { FormikHelpers } from "formik";
import React, { FC, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateCompany } from "../../../api/admin/updateCompany";
import { settingsWriteRoles } from "../../../routes/constants/permissionSets";
import { Components, ElementType } from "shared/src/qa-slugs";
import { CompanyForm, uploadImages } from "./companyInfoHelpers";
import { FormikCompanyInfo } from "./FormikCompanyInfo";
import {
  errorToastOptions,
  loadingToastOptions,
  successToastOptions,
  Toast,
  ToastStatus,
  updateToast,
} from "shared/src/components/Toast/Toastify";
import styles from "./CompanyInfo.module.scss";

export const CompanyInfo: FC = () => {
  const primaryLogo = useSelector(CoreRedux.selectPrimaryLogoImageProps);
  const condensedLogo = useSelector(CoreRedux.selectCondensedLogoImageProps);
  const company = useSelector(CoreRedux.selectClientConfigProps);
  const isFormReadOnly = !API.Environment.hasRoleAccess(settingsWriteRoles);

  const initialValues = {
    primaryLogo,
    condensedLogo,
    name: company?.clientName || "",
    email: company?.email || "",
    address1: company?.address1 || "",
    address2: company?.address2 || "",
    city: company?.city || "",
    state: company?.state || null,
    zip: company?.zip || null,
  };

  const [primaryImageFile, setPrimaryImageFile] = useState<File | undefined>();
  const [condensedImageFile, setCondensedImageFile] = useState<
    File | undefined
  >();

  const [error, setError] = useState<string | null>(null);
  const topOfForm = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();

  const handleUploadLogos = async () => {
    if (primaryImageFile || condensedImageFile) {
      const newLogoConfigs = await uploadImages({
        newFiles: {
          primary: primaryImageFile,
          condensed: condensedImageFile,
        },
        existingLogos: {
          primary: primaryLogo,
          condensed: condensedLogo,
        },
      });

      dispatch<CoreRedux.GetAppConfigAction>({
        response: newLogoConfigs,
        keyName: ApplicationConfigKey.logoConfigs,
        type: CoreRedux.GET_APPLICATION_CONFIGS.SUCCESS,
      });
    }
  };

  const handleSaveCompany = async (values: CompanyForm) => {
    const updatedCompanyProps: ClientConfigProperties = {
      adfsEnabled: company?.adfsEnabled || false,
      clientName: values.name,
      email: values.email,
      address1: values.address1,
      address2: values.address2,
      city: values.city,
      state: values.state,
      zip: values.zip,
    };
    const newCompanyConfig = await updateCompany(updatedCompanyProps);

    dispatch<CoreRedux.GetAppConfigAction>({
      response: newCompanyConfig,
      keyName: ApplicationConfigKey.clientConfig,
      type: CoreRedux.GET_APPLICATION_CONFIGS.SUCCESS,
    });
  };

  const handleSubmitForm = async (
    values: CompanyForm,
    formikHelpers: FormikHelpers<CompanyForm>
  ) => {
    const qaToastId = `${Components.CompanyInfo}-${ElementType.Toast}`;

    try {
      setError(null);
      updateToast(
        <Toast
          status={ToastStatus.Loading}
          message={"Saving Company Info..."}
          qa={`${qaToastId}-loading`}
        />,
        qaToastId,
        loadingToastOptions
      );
      await handleUploadLogos();
      await handleSaveCompany(values);
      updateToast(
        <Toast
          status={ToastStatus.Success}
          message={"Saved Successfully!"}
          qa={`${Components.CompanyInfo}-${ElementType.Toast}-success`}
        />,
        qaToastId,
        successToastOptions
      );
    } catch (err) {
      updateToast(
        <Toast
          status={ToastStatus.Error}
          message={error || "Failed to Submit"}
          qa={`${Components.CompanyInfo}-${ElementType.Toast}-failure`}
        />,
        qaToastId,
        errorToastOptions
      );
      setError(API.getErrorMessage(err));
    } finally {
      const wrapperDiv = document.getElementById("contentWrapper");
      if (wrapperDiv) {
        return wrapperDiv.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
      formikHelpers.setSubmitting(false);
    }
  };

  return (
    <div ref={topOfForm}>
      {company && (
        <div className={styles.formContainer}>
          <FormikCompanyInfo
            handleSubmitForm={handleSubmitForm}
            companyForm={initialValues}
            isReadOnly={isFormReadOnly}
            setPrimaryImageFile={setPrimaryImageFile}
            setCondensedImageFile={setCondensedImageFile}
          />
        </div>
      )}
    </div>
  );
};
