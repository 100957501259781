import get from "lodash/get";
import React from "react";
import styled from "styled-components";

import CheckboxBase from "@mui/material/Checkbox";
import FormControlBase from "@mui/material/FormControl";
import InputLabelBase from "@mui/material/InputLabel";
import MenuItemBase from "@mui/material/MenuItem";
import SelectBase from "@mui/material/Select";

import { remCalc, toRgba } from "shared/src/themes/helpers";

export const CloseButton = styled.button`
  align-items: center;
  background: transparent;
  border: none;
  display: flex;
  margin-left: 0.5rem;
  padding: 0;
`;

export const ButtonIcon = styled.i<{ iconSize?: number }>`
  color: ${({ theme }) => toRgba(theme.colors.black, 0.5)};
  font-size: ${({ iconSize }) => remCalc(iconSize || 24)};
`;

const SelectInput = styled.div`
  display: flex;
  flex-direction: column;
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 1.25px;
  border-radius: 0.25em;
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  padding: 5px;
  text-transform: capitalize;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.primary};
  width: fit-content;
`;

const FormControl = styled(({ ...otherProps }) => (
  <FormControlBase
    {...otherProps}
    classes={{
      root: "root",
    }}
  />
))`
  &&.root {
  }
`;

const InputLabel = styled(({ ...otherProps }) => (
  <InputLabelBase {...otherProps} classes={{ root: "root" }} />
))`
  &&.root {
    color: ${({ theme }) => theme.colors.formFieldLabel};
    margin-bottom: 8px;
    font-size: 1.25rem;
    font-weight: 400;
  }
`;

const ErrorLabel = styled(({ ...otherProps }) => (
  <InputLabelBase {...otherProps} />
))``;

const MenuItem = styled(({ ...otherProps }) => (
  <MenuItemBase
    classes={{
      selected: "selected",
    }}
    {...otherProps}
  />
))`
  &&.selected {
    background-color: ${(props) => props.theme.colors.activeFill};
    font-weight: 500;
  }
`;
const MenuItemMulti = styled(({ ...otherProps }) => (
  <MenuItemBase
    classes={{
      selected: "selected",
    }}
    {...otherProps}
  />
))`
  &&.selected {
    background-color: ${(props) => props.theme.colors.white};
  }
`;
const Checkbox = styled(({ ...otherProps }) => (
  <CheckboxBase
    classes={{
      checked: "checked",
      root: "root",
    }}
    disableRipple
    disableFocusRipple
    {...otherProps}
  />
))`
  &&.root {
    &:hover {
      background-color: transparent;
    }
  }
  &&.checked {
    color: ${(props) => props.theme.colors.primary};
    &:hover {
      background-color: transparent;
    }
  }
`;

// Sets css properties based on prop value
export const selectHasValue = (value: unknown) => {
  if (typeof value === "number") {
    if (value > -1) return true;
  } else if (Array.isArray(value) && value.length > 0) return false;
  return !!value;
};

const Select = styled(
  ({ className, customColor, hasInitialValue, value, ...otherProps }) => {
    return (
      <SelectBase
        {...otherProps}
        classes={{
          select: `${className} select`,
          icon: "icon",
          // selectMenu: "selectMenu",
        }}
        value={value}
        MenuProps={{
          classes: {
            paper: `${className} paper`,
          },
          ...otherProps.MenuProps,
        }}
      />
    );
  }
)`
  &&.paper {
    max-height: 18.5rem;
  }
  &&.select {
    color: ${({ customColor, theme }) =>
      get(theme, customColor) || theme.masterColors.darkGrey};
    font-weight: 400;
    padding: 13px 30px 13px 10px;
    font-size: 13px;
    :focus {
      background-color: ${({ theme }) => theme.colors.white};
    }
  }
  && .icon {
    color: ${({ customColor, theme }) =>
      get(theme, customColor) || theme.masterColors.darkGrey};
    font-weight: ${({ hasInitialValue }) => (!hasInitialValue ? 500 : 400)};
  }
`;

export {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  MenuItemMulti,
  Select,
  SelectInput,
  ErrorLabel,
};
