import { State } from "@rtslabs/field1st-fe-common";
import { FormikErrors, FormikValues } from "formik";
import React from "react";
import { AssistiveText } from "shared/src/components/AssistiveText/AssistiveText";
import { Select } from "shared/src/components/Select/Select";
import { TextInput } from "shared/src/components/TextInput/TextInput";
import { Components, ElementType } from "shared/src/qa-slugs";
import { stateList } from "./stateList";
import s from "./styles.module.scss";
import styles from "./CompanyInfo.module.scss";

interface CompanyAddressProps {
  address1: string;
  address2?: string;
  city: string;
  state: State | null;
  zip: number | null;
  handleChange: (e: React.ChangeEvent<any>) => void;
  setFieldValue: (property: string, value?: string | string[] | number) => void;
  errors: FormikErrors<FormikValues>;
  disabled?: boolean;
}

const CompanyAddress = ({
  address1,
  address2,
  city,
  state,
  zip,
  handleChange,
  setFieldValue,
  errors,
  disabled,
}: CompanyAddressProps) => {
  return (
    <div className={styles.companyAddress}>
      <TextInput
        qa={`${Components.CompanyInfo}-${ElementType.TextInput}-address1`}
        name="address1"
        assistiveText="address 1"
        label="Company Address"
        onChange={handleChange}
        error={errors["address1"] as string}
        value={address1}
        disabled={disabled}
      />
      <TextInput
        qa={`${Components.CompanyInfo}-${ElementType.TextInput}-address2`}
        name="address2"
        assistiveText="address 2"
        onChange={handleChange}
        value={address2}
        disabled={disabled}
      />
      <TextInput
        qa={`${Components.CompanyInfo}-${ElementType.TextInput}-city`}
        name="city"
        assistiveText="city"
        onChange={handleChange}
        error={errors["city"] as string}
        value={city}
        disabled={disabled}
      />

      {/* State & Zip */}
      <div className={s.stateAndZip}>
        <div className={s.stateDropdown}>
          <Select
            qa={`${Components.CompanyInfo}-${ElementType.SelectInput}-state`}
            options={stateList}
            onChange={(e) => setFieldValue("state", e?.value)}
            error={errors["state"] as string}
            value={state}
            disabled={disabled}
          />
          <AssistiveText>state</AssistiveText>
        </div>
        <TextInput
          qa={`${Components.CompanyInfo}-${ElementType.TextInput}-zip`}
          name="zip"
          assistiveText="zip"
          type="number"
          onChange={handleChange}
          error={errors["zip"] as string}
          value={zip ?? ""}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default CompanyAddress;
