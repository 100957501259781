import React, { FC, useEffect, useState } from "react";
import { API, FormTypeDTO, FormTypeSaveVm } from "@rtslabs/field1st-fe-common";
import { FormTypeForm } from "./FormTypeForm";
import { GenericModal } from "shared/src/components/Generic/Modal/GenericModal";
import { Components } from "shared/src/qa-slugs";
import { loadFormType } from "../helpers";
import Loader from "shared/src/components/Loader/Loader";
import { useAPIwithToasts } from "shared/src/components/Toast/useAPIwithToasts";

interface AddFormTypeProps {
  visible: boolean;
  onClose: () => void;
  editVisible?: never;
  formTypeId?: never;
  readOnly?: never;
}

interface ViewEditFormTypeProps {
  visible?: never;
  editVisible: boolean;
  formTypeId: number;
  onClose: () => void;
  readOnly?: boolean;
}

const initialValues: FormTypeSaveVm = {
  active: true,
  name: "",
  isGlobal: true,
  iconColor: "#000000",
};

export const AddEditViewFormType: FC<
  AddFormTypeProps | ViewEditFormTypeProps
> = ({ onClose, visible, editVisible, formTypeId, readOnly }) => {
  const [loading, setLoading] = useState(false);
  const [loadingFormType, setLoadingFormType] = useState(false);
  const [formType, setFormType] = useState<FormTypeDTO | undefined>();
  const [fetchError, setFetchError] = useState<"save" | "load" | "">("");

  const handleAdd = async (values: FormTypeSaveVm) => {
    const qaToastCreate = `${Components.FormTypes}-${Components.AddModal}-create`;

    const newVals: FormTypeSaveVm = values.isGlobal
      ? { ...values, clientGroups: undefined }
      : values;

    await useAPIwithToasts(
      API.createFormType,
      { formType: newVals },
      {
        loadingMessage: `Creating Form Type "${values.name}"...`,
        successMessage: `Form Type "${values.name}" Created`,
        errorMessage:
          "The system encountered an error while trying to save the form type. Please try again.",
        qaToast: qaToastCreate,
        onClose,
        setLoading,
      }
    );
  };

  const handleUpdate = async (values: FormTypeDTO) => {
    const qaToastUpdate = `${Components.FormTypes}-${Components.EditModal}-create`;

    await useAPIwithToasts(
      API.updateFormType,
      { formType: values },
      {
        loadingMessage: `Saving Form Type "${values.name}"...`,
        successMessage: `Form Type "${values.name}" Updated Successfully`,
        errorMessage:
          "The system encountered an error while trying to save the form type. Please try again.",
        qaToast: qaToastUpdate,
        onClose,
        setLoading,
      }
    );
  };

  useEffect(() => {
    if (
      formTypeId &&
      (!formType || formTypeId !== formType.id) &&
      !loadingFormType &&
      !fetchError
    ) {
      setLoadingFormType(true);
      loadFormType(formTypeId, setLoadingFormType, setFormType, setFetchError);
    }
  }, [formTypeId, loadingFormType, formType]);

  return (
    <>
      <GenericModal
        isOpen={!!visible}
        title="New Form Type"
        onClose={onClose}
        qa={`${Components.FormTypes}-${Components.AddModal}`}
      >
        <FormTypeForm
          initialValues={initialValues}
          loading={loading}
          onSubmit={handleAdd}
          onClose={onClose}
          saveButtonLabel="Add"
        />
      </GenericModal>

      <GenericModal
        isOpen={!!editVisible}
        title={`${readOnly ? "View" : "Edit"} Form Type`}
        onClose={onClose}
        qa={`${Components.FormTypes}-${Components.EditModal}`}
      >
        <Loader loading={loadingFormType}>
          {formType && (
            <FormTypeForm
              readOnly={!!readOnly}
              initialValues={formType}
              loading={loading}
              onSubmit={handleUpdate}
              onClose={onClose}
              saveButtonLabel="Update"
            />
          )}
        </Loader>
      </GenericModal>
    </>
  );
};
