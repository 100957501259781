import React, { FC } from "react";
import styles from "../../Filters/Filters.module.scss";
import { FilterSelect } from "../../Select/FilterSelect";
import SearchBar from "../../SearchBar/SearchBar";
import { ElementType, QAProps } from "../../../qa-slugs";
import { useSelector } from "react-redux";
import { CoreRedux } from "@rtslabs/field1st-fe-common";
import { statusOptions } from "./formConstants";

interface FormListFiltersProps extends QAProps {
  initialQuery?: string;
  debouncedSetQuery: (query: string) => void;
  groupId?: number;
  setFormType: (formType: number | undefined) => void;
  setGroupId: (groupId: number | undefined) => void;
  setPage: (page: number) => void;
  setStatusIndex: (statusIndex: number | undefined) => void;
  formTypeOptions: { value: number; label: string }[];
  formType?: number;
  statusIndex?: number;
  remoteClear: boolean;
}

export const FormListFilters: FC<FormListFiltersProps> = ({
  initialQuery = "",
  debouncedSetQuery,
  groupId,
  setFormType,
  setGroupId,
  setPage,
  setStatusIndex,
  formTypeOptions,
  formType,
  statusIndex,
  remoteClear,
  qa,
}) => {
  const accessibleGroups = useSelector(CoreRedux.getUserGroups);

  /** Format group filter options */
  const groupOptions = accessibleGroups.map((g) => ({
    label: g.name || "No group name",
    value: g.id,
  }));

  return (
    <div className={styles.filtersContainer}>
      <div className={styles.searchWrapper}>
        <SearchBar
          qa={`${qa}-${ElementType.TextInput}-searchBar`}
          initialQuery={initialQuery}
          onSearch={debouncedSetQuery}
          remoteClear={remoteClear}
        />
      </div>
      <div className={styles.filterOptions}>
        <div className={styles.filterSelect} style={{ minWidth: 185 }}>
          <FilterSelect
            qa={`${qa}-${ElementType.SelectInput}-groupsFilter`}
            name="groupId"
            value={groupId ?? 0}
            label=""
            placeholder="All Groups"
            options={[{ value: 0, label: "All Groups" }, ...groupOptions]}
            onChange={(option) => {
              setPage(0);
              setGroupId(option?.value);
            }}
          />
        </div>
        <div className={styles.filterSelect} style={{ minWidth: 215 }}>
          <FilterSelect
            qa={`${qa}-${ElementType.SelectInput}-formTypeFilter`}
            name="formType"
            value={formType ?? 0}
            label=""
            placeholder="All Document Types"
            options={[
              { value: 0, label: "All Document Types" },
              ...formTypeOptions,
            ]}
            onChange={(option) => {
              setPage(0);
              setFormType(option?.value);
            }}
          />
        </div>
        <div className={styles.filterSelect} style={{ minWidth: "215px" }}>
          <FilterSelect
            qa={`${qa}-${ElementType.SelectInput}-statusesFilter`}
            name="status"
            value={statusIndex ?? 0}
            label=""
            placeholder="All Active Statuses"
            options={statusOptions}
            onChange={(option) => {
              setStatusIndex(option?.value);
              setPage(0);
            }}
          />
        </div>
      </div>
    </div>
  );
};
