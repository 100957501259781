import React, { FC } from "react";
import { IconBox } from "shared/src/components/Icon/IconBox";
import { QAProps } from "shared/src/qa-slugs";
import styles from "./ReportStatsCard.module.scss";

type Value = string | number | JSX.Element;

interface StatsCardProps extends QAProps {
  children?: JSX.Element;
  header: string;
  iconColor?: string | null;
  iconName?: string | null;
  value: Value;
}

export const StatsCard: FC<StatsCardProps> = ({
  children,
  header,
  iconColor,
  iconName,
  value,
}) => {
  return (
    <div className={styles.statsCard}>
      <div className={styles.totalContainer}>
        <span className={styles.total}>Total:</span>
        <span className={styles.count}>{value}</span>
      </div>
      <div className={styles.formTypeContainer}>
        {iconName && (
          <IconBox
            color={iconColor ?? undefined}
            type={iconName}
            className={styles.icon}
          />
        )}
        <span className={styles.formType}>{header}</span>
      </div>
      {children}
    </div>
  );
};
