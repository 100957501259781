import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import withTheme from "@mui/styles/withTheme";
import Loader from "shared/src/components/Loader/Loader";
import { ContentWrapper } from "shared/src/components/Wrappers/Wrappers";
import { downloadFile } from "../../../util";
import { TextButton } from "shared/src/components/common/Button";
import s from "./styles.module.scss";
import { RouteParams } from "./types";
import { Components, ElementType } from "shared/src/qa-slugs";
import { API, DataSource } from "@rtslabs/field1st-fe-common";
import { dataSetWriteRoles } from "../../../routes/constants/permissionSets";
import { PageHeader } from "shared/src/components/PageHeader/PageHeader";
import { GenericButton } from "shared/src/components/Generic/Button/GenericButton";

const nonEmptyRow = (row: string[]) => !(row.length === 1 && row[0] === "");

/** Table showing first ten records of an uploaded data source */
const SummaryTable = ({ csvData }: { csvData: string[][] }) =>
  csvData.length > 0 ? (
    <div className={s.tableWrapper}>
      {csvData.map((row, rowIdx) =>
        nonEmptyRow(row) ? (
          <div
            className={`${s.dataRow} ${
              rowIdx % 2 === 0 ? s.evenRow : s.oddRow
            }`}
            key={`summary_row_${rowIdx}`}
          >
            {row.map((data, colIdx) => (
              <div
                className={rowIdx === 0 ? s.th : s.td}
                key={`row${rowIdx}col${colIdx}`}
              >
                <span className={rowIdx === 0 ? s.propTitle : s.propValue}>
                  {rowIdx === 0 ? data.toUpperCase() : data || ""}
                </span>
              </div>
            ))}
          </div>
        ) : null
      )}
    </div>
  ) : null;

/** View an Uploaded Data Set */
function ViewUploadedDS() {
  const params = useParams<RouteParams>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<DataSource | null>(null);
  const [fileSize, setFileSize] = useState<number>(0);
  const [dataSourceValues, setDataSourceValues] = useState<string[][]>([]);
  const [numRecords, setNumRecords] = useState<number>(0);
  const readOnly = !API.Environment.hasRoleAccess(dataSetWriteRoles);

  const downloadCSV = async (id: number, title: string): Promise<void> => {
    try {
      const csv = await API.downloadDataSourceValues({
        dataSourceId: id,
        mediaType: "text/csv",
      });
      downloadFile(csv.blob, csv.fileName);
    } catch (err) {
      // TODO error handling -JA
      console.error(err);
    }
  };

  /** Get data source and CSV if an ID was supplied in params */
  useEffect(() => {
    (async () => {
      const id = Number(params.id);
      if (!isNaN(id)) {
        try {
          setLoading(true);
          const ds = await API.getDataSourceById({ id });
          setDataSource(ds);
          if (ds.id) {
            const dsv = await API.downloadDataSourceValues({
              dataSourceId: ds.id,
              mediaType: "text/csv",
            });
            setFileSize(dsv.blob.size);
            // @ts-ignore TypeScript doesn't see text() property on Blob
            const dsvtxt = await dsv.blob.text();
            const dsvrows = dsvtxt.split("\n");
            setNumRecords(dsvrows.length - 1);
            setDataSourceValues(
              dsvrows.splice(0, 11).map((row) => row.split(","))
            );
          }
        } catch (err) {
          console.error(err);
          // TODO error handling -JA
        } finally {
          setLoading(false);
        }
      }
    })();
  }, []);

  const qaBase = Components.ViewUploadedDS;
  const paths = [
    { pathName: "Forms", href: "/forms/data-sets" },
    { pathName: "Data Sets", href: "/forms/data-sets" },
    {
      pathName: `Uploaded Data Set${dataSource ? `: ${dataSource.title}` : ""}`,
      href: `/forms/data-sets/uploaded/${dataSource?.id}/view`,
    },
  ];

  return (
    <ContentWrapper id="mainContent">
      <PageHeader
        pageTitle={dataSource?.title ?? "Uploaded Data Set"}
        paths={paths}
        qa={qaBase}
      />
      <Loader loading={loading}>
        {dataSource && (
          <div className={s.dataSetContainer}>
            <div className={s.dataSetInfo}>
              <div className={s.dataItemWrapper}>
                <span className={s.propTitle}>ID:</span>
                <span className={s.dataValue}>{dataSource.id}</span>
              </div>
              <div className={s.dataItemWrapper}>
                <span className={s.propTitle}>SOURCE KEY:</span>
                <span className={s.dataValue}>{dataSource.dataSourceKey}</span>
              </div>

              <div className={s.dataItemWrapper}>
                <span className={s.propTitle}>COLUMN HEADERS:</span>
                <span className={s.dataValue}>
                  {(dataSource.csvMappings || [])
                    .map((m) => m.columnName)
                    .join(", ")}
                </span>
              </div>
              <div className={s.dataItemWrapper}>
                <span className={s.propTitle}># OF RECORDS:</span>
                <span className={s.dataValue}>{numRecords}</span>
              </div>
              <div className={s.dataItemWrapper}>
                <span className={s.propTitle}>FILE SIZE:</span>
                <span className={s.dataValue}>{fileSize}B</span>
              </div>
            </div>

            <div className={s.previewContainer}>
              <div className={s.previewHeaderWrapper}>
                <span className={s.previewHeader}>
                  Data set preview: {dataSourceValues.length - 1} of{" "}
                  {numRecords} records
                </span>
                <span className={s.description}>
                  The table below is a preview of the first 10 records in your
                  data set. To view the entire spreadsheet, download the current
                  data set. You can make changes to the table by selecting to
                  import a new data set.
                </span>
              </div>
              <div className={s.tableButtons}>
                <GenericButton
                  qa={`${qaBase}-${ElementType.Button}-downloadDataSetTop`}
                  onClick={() =>
                    dataSource?.id &&
                    downloadCSV(dataSource.id, dataSource.title || "")
                  }
                  label="Download Current Data Set"
                />
                <TextButton
                  className={s.importButton}
                  qa={`${qaBase}-${ElementType.Button}-importDataSetTop`}
                  onClick={() =>
                    dataSource?.id &&
                    navigate(`/forms/data-sets/uploaded/${dataSource.id}`)
                  }
                  disabled={readOnly}
                >
                  IMPORT NEW DATA SET
                </TextButton>
              </div>
              <SummaryTable csvData={dataSourceValues} />
              <div className={s.tableButtons}>
                <GenericButton
                  qa={`${qaBase}-${ElementType.Button}-downloadDataSetBottom`}
                  onClick={() =>
                    dataSource?.id &&
                    downloadCSV(dataSource.id, dataSource.title || "")
                  }
                  label="Download Current Data Set"
                />
                <TextButton
                  className={s.importButton}
                  qa={`${qaBase}-${ElementType.Button}-importDataSetBottom`}
                  onClick={() =>
                    dataSource?.id &&
                    navigate(`/forms/data-sets/uploaded/${dataSource.id}`)
                  }
                  disabled={readOnly}
                >
                  IMPORT NEW DATA SET
                </TextButton>
              </div>
            </div>
          </div>
        )}
      </Loader>
    </ContentWrapper>
  );
}

export default withTheme(ViewUploadedDS);
