import React, { FC } from "react";
import { orderBy } from "lodash";
import styles from "../../common/StatsCard/ReportStatsCard.module.scss";
import { Drawer } from "shared/src/components/Drawer/DrawerNew";
import {
  FormTypeDTO,
  ReportingFormTypeDocumentCountVm,
} from "@rtslabs/field1st-fe-common";
import { FormTypeStats } from "shared/src/components/clientAdmin/helpers";
import Loader from "shared/src/components/Loader/Loader";
import { PercentLineGraph } from "../../dashboard/Desktop/PercentLineGraph";
import { sliceColors } from "../reports/reportsConstants";
import { ElementType } from "shared/src/qa-slugs";
import { StatsCard } from "../../common/StatsCard/ReportStatsCard";
import { useMediaQuery } from "react-responsive";
import scssVariables from "shared/src/sass/jsExports.module.scss";

interface DataPoint {
  color: string;
  id: string;
  qa: string;
  value: number;
  source?: ReportingFormTypeDocumentCountVm;
}

type Props = {
  closeDrawer: () => void;
  formStats: FormTypeStats;
  formType: FormTypeDTO;
  onGroupClick: (g: number) => void;
  show: boolean;
  isLoading: boolean;
  qa?: string;
};

export const GroupsListDrawer: FC<Props> = ({
  closeDrawer,
  formStats,
  formType,
  onGroupClick,
  show,
  isLoading,
  qa,
}) => {
  const groupsSorted = orderBy(
    formStats.groupCounts,
    ["percent"],
    ["desc"]
  ).filter((g) => g.total !== 0);

  const data: DataPoint[] = groupsSorted.map((group, i) => ({
    color: sliceColors[i].value,
    id: group.name,
    qa: group.name,
    value: group.percent,
  }));

  const isDesktop = useMediaQuery({
    query: `(min-width: ${scssVariables.minTablet})`,
  });

  return (
    <Drawer
      anchor={isDesktop ? "right" : "bottom"}
      isOpen={show}
      id="groupListDrawer"
      onClose={closeDrawer}
      title={"All Groups"}
      qa={qa}
    >
      <div className={styles.groupsListDrawerContainer}>
        <Loader loading={isLoading}>
          <StatsCard
            header={formType.name}
            value={formStats.totalCount}
            iconName={formType.iconName}
            iconColor={formType.iconColor}
          >
            <>
              <PercentLineGraph
                data={data}
                ariaLabel="Bar chart showing the proportion of groups in each form type."
                className={styles.percentLineGraph}
              />
              <div className={styles.groupsContainer}>
                {groupsSorted.map((group, i) => (
                  <div
                    key={group.name}
                    className={styles.groupWrapper}
                    onClick={() => onGroupClick(group.id)}
                    data-testid={`${ElementType.Button}-${group.name}`}
                  >
                    <span
                      className={styles.dot}
                      style={{ backgroundColor: sliceColors[i].value }}
                    />
                    <span className={styles.count}>{group.total}</span>
                    <span className={styles.groupName}>{group.name}</span>
                    <span className={styles.percentage}>{group.percent}%</span>
                  </div>
                ))}
              </div>
            </>
          </StatsCard>
        </Loader>
      </div>
    </Drawer>
  );
};
