import React, { FC } from "react";
import { GenericModal } from "shared/src/components/Generic/Modal/GenericModal";
import { AddEditGroupForm } from "../../addGroup/AddEditGroupForm";
import { API } from "@rtslabs/field1st-fe-common";
import { groupWriteRoles } from "../../../../routes/constants/permissionSets";

interface AddEditGroupProps {
  visible: boolean;
  onClose: () => void;
  groupId?: number;
}

export const AddEditGroup: FC<AddEditGroupProps> = ({
  visible,
  groupId,
  onClose,
}) => {
  const hasGroupWriteRoles = API.Environment.hasRoleAccess(groupWriteRoles);

  return (
    <GenericModal
      isOpen={visible}
      title={groupId ? "Edit Group" : "Add Group"}
      onClose={onClose}
      hasAccess={hasGroupWriteRoles}
    >
      <AddEditGroupForm groupId={groupId} onClose={onClose} />
    </GenericModal>
  );
};
