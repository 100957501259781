import React, { FC } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import { usePreloadLottie } from "../LottieFiles/usePreloadLottie";

export const LoadingToast: FC = () => {
  const preloadedAnimationData = usePreloadLottie();

  return (
    <div style={{ position: "relative" }}>
      {preloadedAnimationData && (
        <Player
          autoplay
          loop
          src={preloadedAnimationData}
          style={{
            position: "absolute",
            width: "36px",
            height: "36px",
            top: -18,
            left: -9,
          }}
        />
      )}
    </div>
  );
};
