import React, { FC, ReactNode } from "react";
import styles from "./FieldMessagingWrapper.module.scss";
import { AssistiveText } from "../../AssistiveText/AssistiveText";
import ErrorText from "../../ErrorText/ErrorText";
import { QAProps } from "../../../qa-slugs";
import { Required } from "../../../assets/svg/Required";
import WarningText from "../../WarningText/WarningText";
import SuccessText from "../../SuccessText/SuccessText";
import { joinClassNames } from "../../../helpers/theme.helpers";

interface FieldMessagingWrapperProps extends QAProps {
  assistiveText?: string | null;
  error?: ReactNode | ReactNode[] | string;
  warning?: string;
  success?: string;
  children: ReactNode;
  comments?: ReactNode;
  className?: string;
}

export const FieldMessagingWrapper: FC<FieldMessagingWrapperProps> = ({
  assistiveText,
  error,
  warning,
  success,
  children,
  comments,
  className,
  qa,
}) => {
  return (
    <div className={joinClassNames(styles.fieldMessagingWrapper, className)}>
      <div>{children}</div>
      <div className={styles.bottomRow}>
        <div>
          <AssistiveText qa={`${qa}-assistive`}>{assistiveText}</AssistiveText>
        </div>
        <div className={styles.errorWarningContainer}>
          {error && (
            <ErrorText qa={`${qa}-error`}>
              <Required /> {error}
            </ErrorText>
          )}
          {warning && <WarningText qa={`${qa}-warning`}>{warning}</WarningText>}
          {success && <SuccessText qa={`${qa}-success`}>{success}</SuccessText>}
        </div>
      </div>
      <>{comments}</>
    </div>
  );
};
