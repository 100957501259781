import React, { ReactNode, useState } from "react";
import Tutorial from "../dashboard/Tutorial";
import styles from "./HelpDesk.module.scss";
import { Components } from "shared/src/qa-slugs";
import { getVersionName } from "shared/src/helpers/version";
import scssVariables from "shared/src/sass/jsExports.module.scss";
import { ContentWrapper } from "shared/src/components/Wrappers/Wrappers";
import { PageHeader } from "shared/src/components/PageHeader/PageHeader";
import SearchBar from "shared/src/components/SearchBar/SearchBar";
import FeedbackForm from "../feedback/components/FeedbackForm";
import { ArcadeEmbed as AddUser } from "./arcade/AddUser";
import { ArcadeEmbed as FormTypes } from "./arcade/FormTypes";
import { ArcadeEmbed as DataSets } from "./arcade/DataSets";
import { ArcadeEmbed as FormBuilderBasicsPart1 } from "./arcade/FormBuilderBasicsPart1";
import { ArcadeEmbed as FormBuilderBasicsPart2 } from "./arcade/FormBuilderBasicsPart2";
import { ArcadeEmbed as Groups } from "./arcade/Groups";
import { ArcadeEmbed as NewDocumentWeb } from "./arcade/NewDocumentWeb";
import { ArcadeEmbed as NewDocumentMobile } from "./arcade/NewDocumentMobile";
import { ArcadeEmbed as ShareDocumentWeb } from "./arcade/ShareDocumentWeb";
import { ArcadeEmbed as ShareDocumentMobile } from "./arcade/ShareDocumentMobile";
import { QuestionItem } from "./QuestionItem";
import { useMediaQuery } from "react-responsive";
import { API } from "@rtslabs/field1st-fe-common";
import {
  ALL_ROLES,
  dataSetReadRoles,
  formTypeWriteRoles,
  groupReadRoles,
  userWriteRoles,
} from "../../routes/constants/permissionSets";
import { formTemplateWriteRoles } from "shared/src/components/routes/constants/permissionSets";

interface QuestionItemProps {
  title: string;
  component: ReactNode;
  hasAccess: boolean;
}

export const HelpDesk = () => {
  const [showingTutorial, setShowingTutorial] = useState<boolean>(false);

  const isMobile = useMediaQuery({
    query: `(max-width: ${scssVariables.breakpointExtraLarge})`,
  });

  const questionItems: QuestionItemProps[] = [
    {
      title: "Creating New Documents",
      component: isMobile ? <NewDocumentMobile /> : <NewDocumentWeb />,
      hasAccess: API.Environment.hasRoleAccess(ALL_ROLES),
    },
    {
      title: "Share Document",
      component: isMobile ? <ShareDocumentMobile /> : <ShareDocumentWeb />,
      hasAccess: API.Environment.hasRoleAccess(ALL_ROLES),
    },
    {
      title: "Creating and Editing Groups",
      component: <Groups />,
      hasAccess: API.Environment.hasRoleAccess(groupReadRoles),
    },
    {
      title: "Adding Users",
      component: <AddUser />,
      hasAccess: API.Environment.hasRoleAccess(userWriteRoles),
    },
    {
      title: "Form Builder Basics Part 1",
      component: <FormBuilderBasicsPart1 />,
      hasAccess: API.Environment.hasRoleAccess(formTemplateWriteRoles),
    },
    {
      title: "Form Builder Basics Part 2",
      component: <FormBuilderBasicsPart2 />,
      hasAccess: API.Environment.hasRoleAccess(formTemplateWriteRoles),
    },

    {
      title: "Creating Data Sets",
      component: <DataSets />,
      hasAccess: API.Environment.hasRoleAccess(dataSetReadRoles),
    },
    {
      title: "Create a Form Type",
      component: <FormTypes />,
      hasAccess: API.Environment.hasRoleAccess(formTypeWriteRoles),
    },
  ];

  const qaBase = Components.HelpDesk;
  const paths = [{ pathName: "Help Desk", href: "/help-desk" }];

  return (
    <ContentWrapper id="mainContent">
      <Tutorial
        open={showingTutorial}
        endTutorial={() => setShowingTutorial(false)}
      />

      <PageHeader pageTitle="Help Desk" paths={paths} qa={qaBase}>
        <div className={styles.version}>
          <span>Version:</span>
          <span>{getVersionName()}</span>
        </div>
      </PageHeader>

      <div className={styles.listContainer}>
        <div className={styles.helpTitle}>Tutorials and Walkthroughs</div>
        {/* commented out, search not implemented, but there for styling */}
        {/* <div className={styles.filtersContainer}>
          <SearchBar onSearch={() => {}} />
        </div> */}
        <div className={styles.questionsContainer}>
          {questionItems.map(
            (item, index) =>
              item.hasAccess && (
                <QuestionItem key={index} title={item.title}>
                  {item.component}
                </QuestionItem>
              )
          )}
        </div>
      </div>

      <div className={styles.feedbackContainer}>
        <div className={styles.haveQuestion}>
          <span>Have Question?</span>
          <span>
            Your input is important for improving the app. Select a feedback
            type, enter a comment, and our support team will address it.
          </span>
        </div>

        <FeedbackForm />
      </div>
    </ContentWrapper>
  );
};
