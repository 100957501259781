import React, { FC, ReactNode } from "react";
import ModalBase from "@mui/material/Modal";
import styles from "./GenericModal.module.scss";
import { Components, QAProps } from "../../../qa-slugs";
import { Icon } from "../../Icon/Icon";

interface GenericModalProps extends QAProps {
  isOpen: boolean;
  title: string;
  onClose: () => void;
  hasAccess?: boolean;
  children: ReactNode;
}

export const GenericModal: FC<GenericModalProps> = ({
  isOpen,
  title,
  onClose,
  hasAccess = true,
  children,
  qa,
}) => {
  return (
    <ModalBase onClose={onClose} open={isOpen}>
      <div data-testid={qa} className={styles.modalContainer}>
        <div className={styles.modalHeader}>
          <div className={styles.modalTitle} aria-label={title}>
            {title}
          </div>
          <div
            onClick={onClose}
            aria-label="close"
            data-testid={`${qa}-${Components.Button}-close`}
          >
            <Icon type="delete_sign" className={styles.closeIcon} />
          </div>
        </div>
        <div className={styles.modalContent}>
          {hasAccess ? children : "You don't have access to this page"}
        </div>
      </div>
    </ModalBase>
  );
};
