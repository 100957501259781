import { API, TagDTO } from "@rtslabs/field1st-fe-common";
import { useFormik } from "formik";
import React, { FC, useState } from "react";
import { TextInput } from "shared/src/components/TextInput/TextInput";
import { Components, ElementType, Page } from "shared/src/qa-slugs";
import * as Yup from "yup";
import {
  errorToastOptions,
  Toast,
  ToastStatus,
  updateToast,
} from "shared/src/components/Toast/Toastify";
import { GenericButton } from "shared/src/components/Generic/Button/GenericButton";
import styles from "./TagForm.module.scss";

const validationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });

interface TagForm {
  initialValues: TagDTO;
  onClose: (refresh: boolean) => void;
}

const TagForm: FC<TagForm> = ({ initialValues, onClose }) => {
  const [loading, setLoading] = useState(false);
  const qaToastId = Components.TagForm;

  // const _updateTag = async (values: TagDTO) => {
  //   const res = await dispatch(updateTag(values));
  //   setLoading(false);
  //   if (res.type === UPDATE_TAG.SUCCESS) {
  //     history.replace("/content/tag-library");
  //   } else {
  //     setErrorToastVisible(true);
  //   }
  // };

  const _addTag = async (values: TagDTO) => {
    try {
      await API.createOETag({ tag: values });
      setLoading(false);
      onClose(true);
    } catch (e) {
      console.error(e);
      updateToast(
        <Toast
          status={ToastStatus.Error}
          message="Tag with that name already exists"
        />,
        qaToastId,
        { ...errorToastOptions, autoClose: 5000 }
      );
      setLoading(false);
    }
  };

  const handleSubmit = (values: TagDTO) => {
    setLoading(true);
    // if (values?.id) {
    //   _updateTag(values);
    // } else {
    //   _addTag(values);
    // }
    _addTag(values);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => {
      handleSubmit(values);
    },
    validationSchema,
  });

  const handleClose = () => {
    onClose(false);
  };

  return (
    <div data-testid={`${Page.Desktop}-${Components.TagForm}`}>
      <form onSubmit={formik.handleSubmit} className={styles.formContainer}>
        <TextInput
          error={formik.touched["name"] && formik.errors["name"]}
          label="Tag"
          name="name"
          onChange={formik.handleChange}
          placeholder="Tag"
          value={formik.values["name"]}
          qa={`${Components.TagForm}-${ElementType.TextInput}-add`}
        />
        <div className={styles.buttonContainer}>
          <GenericButton
            qa={`${Components.TagForm}-${ElementType.Button}-save`}
            type="submit"
            loading={loading}
            label="Save"
          />
          <GenericButton
            qa={`${Components.TagForm}-${ElementType.Button}-cancel`}
            buttonStyle="tertiary"
            onClick={handleClose}
            label="Cancel"
          />
        </div>
      </form>
    </div>
  );
};

export default TagForm;
