import { API, FormTypeDTO, useAPI } from "@rtslabs/field1st-fe-common";
import React, { FC, useState } from "react";
import { GenericButton } from "shared/src/components/Generic/Button/GenericButton";
import Loader from "shared/src/components/Loader/Loader";
import BackToTop from "shared/src/components/TableUI/BackToTop/BackToTop";
import {
  Pagination,
  PaginationWrapper,
} from "shared/src/components/TableUI/Pagination/Pagination";
import { RowsPerPage } from "shared/src/components/TableUI/RowsPerPage/RowsPerPage";
import { Table } from "shared/src/components/TableUI/Table/Table";
import { TableHead } from "shared/src/components/TableUI/TableHead/TableHead";
import { TableSummary } from "shared/src/components/TableUI/TableSummary/TableSummary";
import { Components, ElementType } from "shared/src/qa-slugs";
import { formTypeWriteRoles } from "../../../routes/constants/permissionSets";
import { DeleteFormTypeModal } from "./DeleteFormTypeModal";
import { FormTypeRow } from "./FormTypeRow";
import styles from "./FormTypes.module.scss";
import { AddEditViewFormType } from "./modals/AddEditViewFormType";
import { ContentWrapper } from "shared/src/components/Wrappers/Wrappers";
import { PageHeader } from "shared/src/components/PageHeader/PageHeader";

const FormTypes: FC = () => {
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [addFormTypeIsVisible, setAddFormTypeIsVisible] = useState(false);
  const [editFormTypeIsVisible, setEditFormTypeIsVisible] = useState(false);
  const [editViewFormTypeId, setEditViewFormTypeId] = useState<number>();
  const [selectedFormType, setSelectedFormType] = useState<
    FormTypeDTO | undefined
  >();
  const [deleteFormTypeIsVisible, setDeleteFormTypeIsVisible] =
    useState<boolean>(false);
  const hasFormsRole = API.Environment.hasRoleAccess(formTypeWriteRoles);

  const formTypesCall = useAPI(API.getFormTypes, {
    page,
    size: pageSize,
    sort: ["name", "asc"],
  });

  const handleClickAdd = () => {
    setAddFormTypeIsVisible(true);
  };

  const handleDelete = (formType: FormTypeDTO) => {
    setSelectedFormType(formType);
    setDeleteFormTypeIsVisible(true);
  };

  const handleEditView = (formType: FormTypeDTO) => {
    setEditFormTypeIsVisible(true);
    setEditViewFormTypeId(formType.id);
  };

  const handleCloseModal = () => {
    setAddFormTypeIsVisible(false);
    setEditFormTypeIsVisible(false);
    setDeleteFormTypeIsVisible(false);
    formTypesCall.refresh();
  };

  const qaBase = Components.FormTypes;
  const paths = [
    { pathName: "Forms", href: "/forms" },
    { pathName: "Form Types", href: "/forms/form-types" },
  ];

  return (
    <>
      <AddEditViewFormType
        visible={addFormTypeIsVisible}
        onClose={handleCloseModal}
      />

      {editViewFormTypeId && (
        <AddEditViewFormType
          editVisible={editFormTypeIsVisible}
          formTypeId={editViewFormTypeId}
          onClose={handleCloseModal}
          readOnly={!hasFormsRole}
        />
      )}

      {selectedFormType && (
        <DeleteFormTypeModal
          formType={selectedFormType}
          deleteFormTypeIsVisible={deleteFormTypeIsVisible}
          setDeleteFormTypeIsVisible={setDeleteFormTypeIsVisible}
          onClose={handleCloseModal}
        />
      )}

      <ContentWrapper id="mainContent">
        <PageHeader pageTitle="Form Types" paths={paths} qa={qaBase}>
          {hasFormsRole && (
            <GenericButton
              onClick={handleClickAdd}
              qa={`${qaBase}-${ElementType.Button}-create`}
              iconType="add_file"
              label="Create Form Type"
            />
          )}
        </PageHeader>

        <div className={styles.listContainer}>
          <Loader loading={formTypesCall.isLoading}>
            <TableSummary
              currentPage={page}
              ofWhat="form types"
              pageSize={pageSize}
              totalElements={formTypesCall.data?.totalElements}
            />
            <Table>
              <TableHead
                className="sr-only"
                columns={[
                  { id: "name", label: "Name" },
                  { id: "actions", label: "Actions" },
                ]}
              />
              <tbody>
                {formTypesCall.data?.content.map((formType) => (
                  <FormTypeRow
                    formType={formType}
                    key={formType.id}
                    onDelete={handleDelete}
                    onEditView={handleEditView}
                    hasFormsRole={hasFormsRole}
                  />
                ))}
              </tbody>
            </Table>
            {formTypesCall.data && (
              <div className={styles.paginationWrapper}>
                <PaginationWrapper>
                  <RowsPerPage
                    pageSize={pageSize}
                    onClick={(size) => {
                      setPageSize(size);
                    }}
                  />
                  <Pagination
                    currentPage={page}
                    onClick={(page) => setPage(page)}
                    totalPages={formTypesCall.data.totalPages}
                  />
                  <BackToTop />
                </PaginationWrapper>
              </div>
            )}
          </Loader>
        </div>
      </ContentWrapper>
    </>
  );
};

export default FormTypes;
