import React, { FC, useEffect, useState } from "react";
import { Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "shared/src/components/Loader/Loader";
import { TextInput } from "shared/src/components/TextInput/TextInput";
import {
  API,
  ClientGroupConfigDTO,
  ClientGroupDTO,
  DefaultTermDefinitionDTO,
  GroupTerm,
} from "@rtslabs/field1st-fe-common";
import { TabGroup } from "shared/src/components/Tabs/TabGroup/TabGroup";
import {
  getAllDefaultTermDefinitions,
  getClientGroupConfig,
  updateClientGroupConfig,
} from "@rtslabs/field1st-fe-common/dist/main/redux";
import { Components } from "shared/src/qa-slugs";
import {
  errorToastOptions,
  Toast,
  ToastStatus,
  updateToast,
} from "shared/src/components/Toast/Toastify";
import { ContentWrapper } from "shared/src/components/Wrappers/Wrappers";
import { PageHeader } from "shared/src/components/PageHeader/PageHeader";
import styles from "./ViewGroup.module.scss";
import { Icon } from "shared/src/components/Icon/Icon";
import { GenericButton } from "shared/src/components/Generic/Button/GenericButton";
import { useAppDispatch } from "../../../store/hooks";
import { AddEditGroup } from "../groups/modals/AddEditGroup";
import { groupWriteRoles } from "../../../routes/constants/permissionSets";

// For the url params
interface Params extends Record<string, string> {
  id: string;
}

// Constants
const mapDefaultTerms = (
  terms: DefaultTermDefinitionDTO[]
): GroupTerm[] | undefined => {
  if (terms?.length > 0) {
    return terms.map((term) => ({
      id: term.visibleId,
      label: term.label,
      value: term.val,
    }));
  }
};

const getInitialValues = (terms) => {
  // Accepts terms array and returns a cleaned up object
  const result = {};
  if (terms) {
    terms.forEach((term) => {
      result[term.id] = term.value;
    });
  }
  return result;
};

const ViewGroup: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams<Params>();
  const qaToastId = Components.ViewGroup;
  const hasGroupWriteRoles = API.Environment.hasRoleAccess(groupWriteRoles);

  // Get group id from url
  const groupId = params.id ? Number(params.id) : undefined;

  // Current group
  const [currentGroup, setCurrentGroup] = useState<ClientGroupDTO>();
  const [currentGroupLoading, setCurrentGroupLoading] = useState<boolean>(true);

  // Current group configs
  const [defaultTerms, setDefaultTerms] = useState<GroupTerm[]>([]);
  const [defaultTermsLoading, setDefaultTermsLoading] = useState(false);
  const [currentGroupTerms, setCurrentGroupTerms] = useState<GroupTerm[]>([]);
  const [currentGroupTermsLoading, setCurrentGroupTermsLoading] =
    useState(false);

  // Async form submit loading (API)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const [visible, setVisible] = useState(false);

  async function getGroupTerms(clientGroupId: number) {
    setCurrentGroupTermsLoading(true);
    setDefaultTermsLoading(true);
    const res: ClientGroupConfigDTO | undefined = await dispatch(
      getClientGroupConfig({
        clientGroupId,
        keyName: "terms",
      })
    );
    setCurrentGroupTerms(res?.properties.terms || []);
    // Get default terms to distinguish
    const defaultTermDefinitions = await dispatch(
      getAllDefaultTermDefinitions()
    );
    setDefaultTerms(mapDefaultTerms(defaultTermDefinitions) || []);

    setDefaultTermsLoading(false);
    setCurrentGroupTermsLoading(false);
  }

  async function getCurrentGroup(groupId: number) {
    setCurrentGroupLoading(true);
    try {
      const res = await API.getClientGroupById({ id: groupId });
      setCurrentGroup(res);
      // Get current group terms
      getGroupTerms(res.id);
    } catch (error) {
      console.error(error); // todo
    }

    setCurrentGroupLoading(false);
  }

  // On mount
  useEffect(() => {
    if (groupId) getCurrentGroup(groupId);
  }, [groupId]);

  const _handleSubmit = (values) => {
    const properties = defaultTerms.map((e) => {
      return {
        ...e,
        value: values[e.id],
      };
    });

    const payload: ClientGroupConfigDTO = {
      active: true,
      clientGroupId: currentGroup!.id,
      keyName: "terms",
      properties: {
        terms: properties,
      },
    };

    // Do API call
    setSubmitLoading(true);
    try {
      dispatch(updateClientGroupConfig(payload));
    } catch (error) {
      updateToast(
        <Toast status={ToastStatus.Error} message="Failed to save" />,
        qaToastId,
        { ...errorToastOptions, autoClose: 5000 }
      );
    } finally {
      setSubmitLoading(false);
      navigate("/people/groups", { replace: true });
    }
  };

  const qaBase = Components.ViewGroup;
  const paths = [
    { pathName: "People", href: "/people/groups" },
    { pathName: "Groups", href: "/people/groups" },
    {
      pathName: `${currentGroup?.name} Profile`,
      href: `/people/groups/view/${currentGroup?.id}`,
    },
  ];

  return (
    <ContentWrapper id="mainContent">
      <AddEditGroup
        groupId={groupId}
        visible={visible}
        onClose={() => {
          setVisible(false);
          groupId && getCurrentGroup(groupId);
        }}
      />
      <Loader loading={currentGroupLoading}>
        <PageHeader pageTitle={currentGroup?.name} paths={paths} qa={qaBase}>
          {hasGroupWriteRoles && (
            <span className={styles.editIconContainer}>
              <span onClick={() => setVisible(true)}>
                <Icon type="pencil" className={styles.editIcon} />
              </span>
            </span>
          )}
        </PageHeader>
        <div className={styles.groupId}>ID:{currentGroup?.externalGroupId}</div>

        <div className={styles.groupTerms}>
          <TabGroup
            variant="white"
            tabs={[
              {
                label: "Group terms",
                tabId: "group-terms",
                tabPanelId: "group-terms",
                tabPanelContent: (
                  <div className={styles.tabContent}>
                    <div className={styles.description}>
                      <span>
                        Specific terms and phrases within the Fields1st app can
                        be customized for each group. For example, a term
                        referred to as “Refocus” by one group may be called a
                        “Rehuddle” by another. Using the terms and language your
                        team members use will improve adoption and reduce
                        confusion.
                      </span>
                      <span>
                        <b>Note:</b> System defaults exist for all terms, so
                        only specify those you want to vary.
                      </span>
                    </div>
                    <Loader
                      loading={currentGroupTermsLoading || defaultTermsLoading}
                    >
                      <div className={styles.termsContainer}>
                        <div className={styles.termColumnsWrapper}>
                          <div className={styles.termColumnHeader}>
                            Company term
                          </div>
                          <div className={styles.termColumnHeader}>
                            Group term
                          </div>
                        </div>

                        <Formik
                          enableReinitialize
                          initialValues={getInitialValues(currentGroupTerms)}
                          onSubmit={_handleSubmit}
                        >
                          {(props) => {
                            const { handleChange, handleSubmit, values } =
                              props;
                            return (
                              <form onSubmit={handleSubmit}>
                                {defaultTerms.map((config, i) => (
                                  <div
                                    key={i}
                                    className={styles.termColumnsWrapper}
                                  >
                                    <div className={styles.labelWrapper}>
                                      {config.label}
                                    </div>
                                    <div className={styles.fieldWrapper}>
                                      <TextInput
                                        name={config.id}
                                        onChange={handleChange}
                                        placeholder={config.label}
                                        value={values[config.id]}
                                      />
                                    </div>
                                  </div>
                                ))}

                                <div>
                                  <GenericButton
                                    label="Save"
                                    type="submit"
                                    loading={submitLoading}
                                  />
                                </div>
                              </form>
                            );
                          }}
                        </Formik>
                      </div>
                    </Loader>
                  </div>
                ),
              },
            ]}
          />
        </div>
      </Loader>
    </ContentWrapper>
  );
};

export default ViewGroup;
