import {
  PageOfReportingFormDocumentCountVm,
  SortDirection,
} from "@rtslabs/field1st-fe-common";
import React from "react";
import { useNavigate } from "react-router-dom";
import Loader from "shared/src/components/Loader/Loader";
import { StyledLinkCaretRight } from "shared/src/components/StyledLinkCaretRight/StyledLinkCaretRight";
import BackToTop from "shared/src/components/TableUI/BackToTop/BackToTop";
import {
  Pagination,
  PaginationWrapper,
} from "shared/src/components/TableUI/Pagination/Pagination";
import { RowsPerPage } from "shared/src/components/TableUI/RowsPerPage/RowsPerPage";
import { Components, ElementType } from "shared/src/qa-slugs";
import { IconBox } from "shared/src/components/Icon/IconBox";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "shared/src/components/common/styled/Table";
import NoResults from "shared/src/components/common/TableUI/NoResults";
import s from "./styles.module.scss";
import { joinClassNames } from "shared/src/helpers/theme.helpers";

type Props = {
  loadingStats?: boolean;
  formStats?: PageOfReportingFormDocumentCountVm | null;
  sortingType: string;
  setSortingType: (t: string) => void;
  sortingDirection: SortDirection;
  setSortingDirection: (d: SortDirection) => void;
  setNewPageNum: (n: number) => void;
  setNewPageSize: (s: number) => void;
  queryString: string;
};

export const ReportsTable = ({
  loadingStats,
  formStats,
  sortingType,
  setSortingType,
  sortingDirection,
  setSortingDirection,
  setNewPageNum,
  setNewPageSize,
  queryString,
}: Props) => {
  const navigate = useNavigate();
  /**
   * Update sorting parameters of parent
   * @param type      - sort by
   * @param direction - direction
   */
  const sortBy = (type: string, direction: SortDirection) => {
    setSortingDirection(direction);
    setSortingType(type);
  };

  return (
    // Was requested that loader is here -- GK
    <Loader loading={loadingStats}>
      <Table>
        <TableHead>
          <TableRow
            noBottomBorder
            className={joinClassNames(s.tableRow, s.tableRowHeader)}
          >
            <TableCell
              qa={`${Components.TableHeader}-${ElementType.Link}-sortByType`}
              sortDirection={sortingDirection}
              active={sortingType === "type"}
              onClick={(dir) => sortBy("type", dir)}
              width="80px"
              className={s.firstCell}
            >
              Type
            </TableCell>
            <TableCell
              qa={`${Components.TableHeader}-${ElementType.Link}-sortByName`}
              sortDirection={sortingDirection}
              active={sortingType === "name"}
              onClick={(dir) => sortBy("name", dir)}
            >
              Name
            </TableCell>
            <TableCell
              qa={`${Components.TableHeader}-${ElementType.Link}-sortBySubmissions`}
              sortDirection={sortingDirection}
              active={sortingType === "submissions"}
              onClick={(dir) => sortBy("submissions", dir)}
              align="center"
              width="150px"
            >
              Submissions
            </TableCell>
            <TableCell
              qa={`${Components.TableHeader}-${ElementType.Link}-sortByGroupUsage`}
              sortDirection={sortingDirection}
              active={sortingType === "groupUsage"}
              onClick={(dir) => sortBy("groupUsage", dir)}
              align="center"
              width="150px"
              className={s.groupUsageColumn}
            >
              Group Usage
            </TableCell>
            <TableCell align="center" width="125px" className={s.lastCell} />
          </TableRow>
        </TableHead>
        <TableBody>
          {formStats && formStats.content.length > 0 ? (
            formStats.content.map((stats) => (
              <TableRow
                data-testid={`${Components.Reports}-${ElementType.TableRow}`}
                key={stats.id}
              >
                <TableCell
                  qa={`${Components.Reports}-${
                    ElementType.TableCell
                  }-formType-${stats.type.name.replace(/\s/g, "")}`}
                  className={s.firstCell}
                >
                  <IconBox
                    type={stats.type.iconName || ""}
                    color={stats.type.iconColor || ""}
                    className={s.iconBox}
                  />
                </TableCell>
                <TableCell
                  qa={`${Components.Reports}-${ElementType.TableCell}-formName`}
                  className={s.formName}
                >
                  <span>{stats.name}</span>
                  <span>ID: {stats.id}</span>
                </TableCell>
                <TableCell
                  qa={`${Components.Reports}-${ElementType.TableCell}-submissions`}
                  align="left"
                  className={s.alignValue}
                >
                  {stats.submissions}
                </TableCell>
                <TableCell
                  qa={`${Components.Reports}-${ElementType.TableCell}-groupUsage`}
                  align="left"
                  className={joinClassNames(s.alignValue, s.groupUsageColumn)}
                >
                  {stats.groupUsage}
                </TableCell>
                <TableCell className={s.lastCell}>
                  <div
                    className={s.reportLink}
                    onClick={() =>
                      navigate(`/reports/${stats.id}${queryString}`)
                    }
                    data-testid={`${Components.Reports}-${ElementType.Button}-table-viewReport`}
                  >
                    <span>View Report</span>
                    <StyledLinkCaretRight
                      className={s.reportLinkIcon}
                      style={{ color: "inherit" }}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <NoResults
              icon="combo_chart"
              header="No reports were found."
              body="Please adjust your filters to display reports."
              qa={`${Components.Reports}-${Components.NotFound}`}
            />
          )}
        </TableBody>
      </Table>
      <div className={s.paginationWrapper}>
        <PaginationWrapper>
          <RowsPerPage
            pageSize={formStats?.size || 10}
            onClick={(size) => {
              setNewPageNum(0);
              setNewPageSize(size);
            }}
            qaBase="reports"
          />
          <Pagination
            currentPage={formStats?.number || 0}
            totalPages={formStats?.totalPages}
            onClick={(page) => setNewPageNum(page)}
            qaBase="reports"
          />
          <BackToTop qa={`${ElementType.Button}-back-to-top`} />
        </PaginationWrapper>
      </div>
    </Loader>
  );
};
