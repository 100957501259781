import { get } from "lodash";
import {
  Functions,
  DocumentParticipantVm,
  DocumentQuestionResponseVm,
  DocumentVm,
  SignatureType,
  DocumentOEVm,
  DocumentParticipantSaveVm,
} from "@rtslabs/field1st-fe-common";

import {
  getEsriQuestionRootIds,
  getFlattenedQuestions,
  getItemByRootId,
  getRequiredQuestions,
  getRequiredSelectionComments,
} from "./formEntity.helpers";

function isNonEmptyString(p?: string | null): boolean {
  return typeof p === "string" && p !== "";
}

/**
 * Check that a participant has provided either a drawn or typed signature
 * @param participant
 */
export function hasValidDrawnTypedSignature(
  participant: DocumentParticipantVm | DocumentParticipantSaveVm
): boolean {
  return (
    isNonEmptyString(participant.signatureDate) &&
    (isNonEmptyString(participant.signatureTextValue) ||
      isNonEmptyString(participant.signatureUrl))
  );
}

/**
 * Check that a participant has provided a drawn signature
 * @param participant
 */
export function hasValidDrawnSignature(
  participant: DocumentParticipantVm | DocumentParticipantSaveVm
): boolean {
  return (
    isNonEmptyString(participant.signatureDate) &&
    isNonEmptyString(participant.signatureUrl)
  );
}

/** Form validation handler for Formik */
function validate(
  values: DocumentVm,
  sectionParticipants?: DocumentParticipantVm[] | DocumentParticipantSaveVm[],
  bypassSubmissionCheck?: boolean
): { [key: number]: string } {
  const {
    form: { sections, displayConditions },
    participants: participantFromValues,
  } = values;
  const participants = sectionParticipants ?? participantFromValues;
  const errors: { [key: string]: string } = {};

  if (!bypassSubmissionCheck && values.submissionType === "SAVE_DRAFT") {
    return errors;
  }

  const questions = sections ? getFlattenedQuestions(sections) : [];
  const requiredQuestions = getRequiredQuestions(questions);
  const requiredComments = getRequiredSelectionComments(questions);
  const responses: Array<DocumentQuestionResponseVm> = values.responses || [];
  const esriQuestionRootIds = getEsriQuestionRootIds(sections);

  /*
   * Determine validity of the form submission using the following criteria:
   *  1.  all required questions have a response
   *  2.  all safety rating questions have comments where required
   *  3a. the number of selected OEs meets the number required by the form or...
   *  3b. no OEs have been made visible for selection based on user responses
   */
  for (const section of sections || []) {
    if (
      !Functions.isSectionVisible(section.rootId!, responses, displayConditions)
    ) {
      continue;
    }

    for (const item of section.items!) {
      if (
        !Functions.isQuestionVisible(
          item.rootId,
          section.rootId,
          responses,
          displayConditions
        )
      ) {
        continue;
      }

      if (
        get(item, ["answerSource", "type"], undefined) === "CURRENT_PARTICIPANT"
      ) {
        continue;
      }

      /* handle rating questions */
      if (item.subType === "RATING" && item.parentWidgetRootId) {
        const itemId = item.id;
        const response = responses.find((r) => r.questionId === item.id);
        // if the question has been answered, determine if a required comment was supplied
        if (response?.answer) {
          const ratingWidget = getItemByRootId(
            item.parentWidgetRootId,
            sections || []
          );
          if (
            ratingWidget &&
            ratingWidget.subType === "SAFETY_RATING" &&
            ratingWidget.requireCommentsFor?.includes(response.answer) && // and comment is required for answer
            !response.comments // and no comment was supplied
          ) {
            errors[item.id] = "Requires Comment"; // set error
          }
        }
      } else if (
        /* handle signature widget */
        item &&
        item.subType === "SIGNATURE" &&
        item.signatureRequired && // and is required
        participants?.length // and form has participants
      ) {
        // determine validation function based on properties
        const hasValidSignature = item.allowedTypes?.includes(
          SignatureType.TYPED
        )
          ? hasValidDrawnTypedSignature
          : hasValidDrawnSignature;
        for (const participant of participants) {
          if (!hasValidSignature(participant)) {
            errors[item.id] = "Required: Please complete all signature fields";
            break;
          }
        }
      }

      // handle all other questions
      else {
        // must allow submission for required esri questions without responses
        // https://rtslabs.atlassian.net/browse/CORE-1307?focusedCommentId=45744
        if (esriQuestionRootIds.includes(item.id)) {
          continue;
        }

        // required questions
        if (requiredQuestions.includes(item.id)) {
          const hasValidResponse = responses.some(
            (r) => r.questionId === item.id && Boolean(r.answer)
          );
          if (!hasValidResponse) {
            errors[item.id!] = "Required Field";
          }
        }

        const questionRequiredComments = requiredComments.filter(
          (rc) => rc.questionId === item.id
        );
        // required comments
        if (questionRequiredComments.length) {
          questionRequiredComments.forEach((rc) => {
            const response = responses.find(
              (r) =>
                r.questionId === item.id && r.associatedId === rc.associatedId
            );
            if (response && !response.comments) {
              errors[`${item.id}_${rc.associatedId}_comment`] =
                "Required Comment";
            }
          });
        }
      }
    }
  }

  return errors;
}

async function validateOEs(
  oes: DocumentOEVm[] | null,
  numberRequired: number,
  required: boolean,
  oeTerm: string
) {
  if (required && oes && oes.length < numberRequired) {
    return `You must include at least ${numberRequired} ${oeTerm}`;
  }
}

export { validate, validateOEs };
