import { API } from "@rtslabs/field1st-fe-common";
import React, { FC, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GenericButton } from "shared/src/components/Generic/Button/GenericButton";
import {
  getMinMaxSubmissionDatesFromTimeFilter,
  TimeFilter,
} from "shared/src/data/timeFilters";
import { Components, ElementType } from "shared/src/qa-slugs";
import { userWriteRoles } from "../../../routes/constants/permissionSets";
import WriteContent from "shared/src/components/common/permissions/WriteContent";
import { mapStatus } from "./helpers";
import { getParticipantType } from "./usersConstants";
import { FilterChangeProps, UsersFilters } from "./UsersFilters";
import { UsersStats } from "./UsersStats";
import { UsersTable } from "./UsersTable";
import { ContentWrapper } from "shared/src/components/Wrappers/Wrappers";
import { PageHeader } from "shared/src/components/PageHeader/PageHeader";
import styles from "./Users.module.scss";

export const Users: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState<number>(0);
  const [remoteClear, setRemoteClear] = useState<boolean>(false);

  // Get default filters from url
  const groupString = searchParams.get("group");
  const group = groupString ? Number(groupString) : undefined;

  // Filters
  const [participantQueryParams, setParticipantQueryParams] =
    useState<API.ParticipantQueryParams>({
      clientGroupId: group ? [group] : undefined,
    });
  const [status, setStatus] = useState<number>();
  const [timeFilterValue, setTimeFilterValue] = useState<TimeFilter>();

  const handleFilterChange = ({
    dateRange,
    group,
    role,
    status: filteredStatus,
    searchQuery,
    timeFilter,
  }: FilterChangeProps) => {
    setTimeFilterValue(timeFilter);
    const { maxSubmissionDate: maxLastLogin, minSubmissionDate: minLastLogin } =
      getMinMaxSubmissionDatesFromTimeFilter(timeFilter, dateRange);
    const mappedStatus = mapStatus(filteredStatus);
    const participantType = role ? [getParticipantType(role)] : undefined;

    setStatus(filteredStatus);
    setPage(0);
    setParticipantQueryParams({
      clientGroupId: group ? [group] : undefined,
      maxLastLogin,
      minLastLogin,
      participantType,
      searchText: searchQuery,
      status: mappedStatus ? [mappedStatus] : undefined,
    });
  };

  const [isFiltered, setIsFiltered] = useState(false);

  const checkIfFiltered = (): void => {
    const isDefault =
      participantQueryParams.clientGroupId === group &&
      (!participantQueryParams.participantType ||
        participantQueryParams.participantType?.length === 0) &&
      (!timeFilterValue || timeFilterValue === TimeFilter.ALL_TIME) &&
      participantQueryParams.searchText === "" &&
      !status;

    setIsFiltered(!isDefault);
  };

  useEffect(() => {
    checkIfFiltered();
  }, [
    participantQueryParams.clientGroupId,
    participantQueryParams.participantType,
    participantQueryParams.searchText,
    status,
    timeFilterValue,
  ]);

  const handleClearFilters = () => {
    setPage(0);
    setRemoteClear(true);
  };

  useEffect(() => {
    setRemoteClear(false);
  }, [remoteClear]);

  const qaBase = Components.Users;
  const paths = [
    { pathName: "People", href: "/people/users" },
    { pathName: "Users", href: "/people/users" },
  ];

  return (
    <ContentWrapper id="mainContent">
      <PageHeader pageTitle="Users" paths={paths}>
        <WriteContent roles={userWriteRoles}>
          <GenericButton
            qa={`${qaBase}-${ElementType.Button}-add-user`}
            onClick={() => navigate("/people/users/add-user")}
            label="Add user"
          />
        </WriteContent>
      </PageHeader>

      <div className={styles.statsContainer}>
        <UsersStats
          participantQueryParams={participantQueryParams}
          status={status}
        />
      </div>

      <div className={styles.contentBody}>
        <UsersFilters
          groupParams={group ? Number(group) : undefined}
          participantQueryParams={participantQueryParams}
          onFilterChange={handleFilterChange}
          remoteClear={remoteClear}
        />

        <div className={styles.usersTableContainer}>
          <UsersTable
            participantQueryParams={participantQueryParams}
            page={page}
            setPage={setPage}
            isFiltered={isFiltered}
            onClearFilters={handleClearFilters}
          />
        </div>
      </div>
    </ContentWrapper>
  );
};
