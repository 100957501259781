import { API, FormVersionDTO, FormVm } from "@rtslabs/field1st-fe-common";
import { useFormikContext } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Params, useNavigate, useParams } from "react-router-dom";
import { Link } from "../../../../Link/Link";
import { joinClassNames } from "../../../../../helpers/theme.helpers";
import { Components, Page } from "../../../../../qa-slugs";
import usePrevious from "../../../../../util/hooks/usePrevious";
import { TextButton } from "../../../../common/Button";
import { Icon } from "../../../../Icon/Icon";
import useValidation from "../../create/validation/useValidation";
import ValidationStatus from "../../create/validation/ValidationStatus";
import { canEditForm, scrollToElement, unmarshallForm } from "../../helpers";
import { EditButton } from "../editButton/EditButton";
import baseStyles from "../../styles.module.scss";
import { FBForm } from "../../../types";
import Status from "./Status";
import styles from "./styles.module.scss";
import { useFBConfigs } from "../../../../../util/hooks/useFBConfigs";

export type FBTab = "create" | "settings" | "history";
export const FB_TABS: FBTab[] = ["create", "settings", "history"];

type FormBuilderHeaderProps = {
  selectedTab: number;
  handleNavigate: (tabIndex: number) => void;
  onSave: (values: FBForm) => Promise<FormVm | undefined>;
  formVersion: FormVersionDTO["version" | "formId"];
  setError: (error: string) => void;
};

const FormBuilderHeader: React.FC<FormBuilderHeaderProps> = ({
  selectedTab,
  handleNavigate,
  onSave,
  formVersion,
  setError,
}) => {
  const { formBuilderConfigs } = useFBConfigs();
  const { enableSettings } = formBuilderConfigs;
  const {
    values,
    errors,
    handleSubmit,
    isSubmitting,
    setSubmitting,
    setFieldValue,
    setValues,
  } = useFormikContext<FBForm>();
  const { t } = useTranslation("formBuilder");
  const [lastSaved, setLastSaved] = useState<string>("");
  const [isPublishing, setIsPublishing] = useState<boolean>(false);
  const [isDiscarding, setIsDiscarding] = useState<boolean>(false);
  const { alertLevel, getValidationErrors } = useValidation();
  const formConfig = useParams<Params>().config;

  const navigate = useNavigate();

  const errorCount = getValidationErrors(errors).length;

  const wasSubmitting = usePrevious(isSubmitting);
  useEffect(() => {
    if (!isSubmitting && wasSubmitting) {
      setLastSaved(moment().format("MM/DD/YYYY [at] h:mma"));
    }
  }, [isSubmitting]);

  useEffect(() => {
    if (values.lastModifiedDate) {
      setLastSaved(
        moment(values.lastModifiedDate).format("MM/DD/YYYY [at] h:mma")
      );
    }
  }, [values.lastModifiedDate]);

  async function handleSave(): Promise<FormVm | undefined> {
    setSubmitting(true);
    const response = await onSave(values);
    setSubmitting(false);
    return response;
  }

  async function discardDraft() {
    const doLoad = window.confirm(
      "Unsaved changes will be discarded. Proceed?"
    );
    if (!doLoad) return;

    try {
      throw new Error("Not implemented");
      setIsDiscarding(true);
      const response = await API.updateFormStatus({
        formId: values.id,
        status: "DISCARDED_DRAFT",
      });
      const fbForm = unmarshallForm(response);
      setValues(fbForm);
    } catch (err) {
      console.error(err);
      setError("Something went wrong");
    } finally {
      setIsDiscarding(false);
    }
  }

  function handlePublish(): void {
    setIsPublishing(true);
    handleSubmit();
  }

  async function handleUnpublish(): Promise<void> {
    const response = await handleSave();

    // update the form id
    if (response && response.id !== values.id) {
      setFieldValue("id", response.id);
    }
  }

  // unpublishing a form creates a new form id, update it in the URL
  useEffect(() => {
    if (values.id) {
      const qaConfigs = formConfig && JSON.parse(atob(formConfig)); // temp for QA testing
      !qaConfigs && navigate(`/forms/form/${values.id}`, { replace: true });
    }
  }, [values.id]);

  useEffect(() => {
    if (!isSubmitting && isPublishing) {
      setIsPublishing(false);
    }
  }, [isSubmitting]);

  function scrollToValidationBanner() {
    const errorTab = selectedTab === 0 ? "create" : "settings";
    const tabIndex = FB_TABS.indexOf(errorTab);

    handleNavigate(tabIndex);
    scrollToElement(`fb-validation-${errorTab}`);
  }

  const notFinalOrDeactivated =
    values.workflowType !== "FINAL" && values.workflowType !== "DEACTIVATED";

  return (
    <div className={styles.formBuilderHeader}>
      <div className={joinClassNames(styles.header, baseStyles.centerFlex)}>
        <div className={styles.headerLeft}>
          <Link className={styles.headerLink} to="/forms">
            <Icon type="chevron-left" color="" /> Back To Forms
          </Link>
        </div>

        <div className={styles.headerCenter}>
          <div className={baseStyles.centerFlex}>
            <h1
              className={styles.headerTitle}
              data-testid={`${Page.Desktop}-${Components.FormBuilder}`}
            >
              {values.name || "Untitled"}
            </h1>
            {!!enableSettings &&
              notFinalOrDeactivated &&
              canEditForm(values) && (
                <EditButton
                  iconColor="black"
                  label={t("header.editSettingsButtonLabel") ?? undefined}
                  onClick={() => handleNavigate(1)}
                />
              )}
          </div>
          <div className={styles.headerSavedAt}>
            {lastSaved && <span>Last Saved: {lastSaved}</span>}
            {notFinalOrDeactivated && canEditForm(values) && (
              <>
                <TextButton
                  disabled={isSubmitting || !values.name || isDiscarding}
                  className={styles.saveButton}
                  onClick={handleSave}
                >
                  {isSubmitting
                    ? isPublishing
                      ? "Publishing..."
                      : "Saving..."
                    : "Save"}
                </TextButton>
                <TextButton
                  disabled={isSubmitting || !values.name || isDiscarding}
                  className={styles.discardDraftButton}
                  onClick={discardDraft}
                >
                  {isDiscarding ? "Discarding..." : "Discard Draft"}
                </TextButton>
              </>
            )}
          </div>
        </div>

        <div className={styles.headerRight}>
          <ValidationStatus
            errorCount={errorCount}
            onClick={scrollToValidationBanner}
            alertLevel={alertLevel}
          />
          <Status
            onPublish={handlePublish}
            onSubmit={handleUnpublish}
            status={values.workflowType}
            isPublishing={isPublishing}
            formVersion={formVersion}
          />
        </div>
      </div>
    </div>
  );
};

export default FormBuilderHeader;
