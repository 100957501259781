import styled from "styled-components";

import ErrorTextBase from "../../ErrorText/ErrorText";
import { remCalc } from "../../../themes/helpers";

export const ShortFieldWrapper = styled.div`
  max-width: 395px;
`;

type PopperRowType = {
  error?: boolean;
  marginBottom?: boolean;
  ["data-testid"]?: string;
};

export const PopperRow = styled.div<PopperRowType>`
  align-items: center;
  color: ${({ error, theme }) =>
    error ? theme.colors.error : theme.masterColors.darkGrey};
  cursor: pointer;
  display: flex;
  font-size: 0.875rem;
  justify-content: space-between;
  margin-bottom: 15px;
  width: 100%;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const PopperChevron = styled.i.attrs({
  className: "icon icon-icons8-chevron-left",
})<{
  color?: string;
}>`
  color: ${({ color, theme }) =>
    color || theme.masterColors.darkGrey} !important;
  display: inline-block;
  transform: rotate(180deg);
  font-size: 25px;
  margin-left: 30px;
`;

export const FileUploadFieldWrapper = styled.div`
  margin-bottom: 1rem;
`;

export const FileUploadItemsContainer = styled.div``;
export const FileUploadItems = styled.ul`
  padding: ${remCalc(12)};
  margin: 0;
`;

export const FileUploadFieldPlaceholder = styled.label`
  color: ${({ theme }) => theme.masterColors.mediumGrey};
  cursor: pointer;
  display: flex;
  margin: 0;
`;

export const AddedFile = styled.div`
  display: flex;
  padding: 0.3125rem 0.625rem;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  background-color: ${({ theme }) => theme.colors.lightGrey};
`;

export const AddedFileLabel = styled.div`
  margin: 4px 0;
`;

export const UploadFileButton = styled.label`
  color: ${({ theme }) => theme.colors.primary};
  display: inline-flex;
  flex-direction: row;
  flex-shrink: 0;
  flex-wrap: no-wrap;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 1.25px;
  margin-bottom: 0;
  margin-left: 1rem;
  padding: 16px 0;
  text-transform: uppercase;
  cursor: pointer;
`;

export const ResourceHistoryItem = styled.p`
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.masterColors.darkGrey};

  b {
    color: ${({ theme }) => theme.masterColors.black};
    padding-right: 4px;
  }
`;

export const ErrorText = styled(ErrorTextBase)`
  white-space: pre-line;
`;

export const IconForEndAdornment = styled.i`
  color: ${({ theme }) => theme.masterColors.darkGrey};
  &:hover {
    cursor: pointer;
  }
`;
