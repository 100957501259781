import React, { FC } from "react";
import { joinClassNames } from "shared/src/helpers/theme.helpers";
import styles from "./StatsCard.module.scss";

type Value = string | number | JSX.Element;

interface RenderValueParams {
  notAvailable?: boolean;
  value: Value;
  clickable: boolean;
}

export const RenderValue: FC<RenderValueParams> = ({
  notAvailable,
  clickable,
  value,
}) => {
  const displayNA = notAvailable;

  return (
    <div
      className={joinClassNames(styles.value, clickable && styles.linkStyle)}
    >
      {displayNA ? "N/A" : value}
    </div>
  );
};
