import { DataSource, DataSourceStatus } from "@rtslabs/field1st-fe-common";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Alert } from "shared/src/components/Alert/Alert";
import { Components, ElementType } from "shared/src/qa-slugs";
import { DSTypeOption } from "./DSTypeOption";
import s from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { GenericModal } from "shared/src/components/Generic/Modal/GenericModal";
import { GenericButton } from "shared/src/components/Generic/Button/GenericButton";

interface Props {
  open: boolean;
  closeModal: () => void;
}

interface CreateProps extends Props {
  typeSelected: "uploaded" | "api" | "basic" | null;
  setTypeSelected: (ts: "uploaded" | "api" | "basic" | null) => void;
}

export const CreateModal: FC<CreateProps> = ({
  open,
  closeModal,
  typeSelected,
  setTypeSelected,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const qa = `${Components.DataSets}-createModal`;

  return (
    <GenericModal
      isOpen={open}
      onClose={closeModal}
      qa={`${qa}-${ElementType.Modal}`}
      title="Select the Type of Data Set"
    >
      <>
        <div className={s.modalText}>
          Choose the type of data set you want to create.
        </div>
        <div
          aria-label="Dataset Type"
          className={s.toggleContainer}
          role="group"
        >
          <DSTypeOption
            icon="justify_left"
            label="Basic"
            onSelect={setTypeSelected}
            qa={`${qa}-${ElementType.Button}-basic`}
            selectedType={typeSelected}
            type="basic"
          />
          <DSTypeOption
            icon="import_csv"
            label="Upload"
            onSelect={setTypeSelected}
            qa={`${qa}-${ElementType.Button}-upload`}
            selectedType={typeSelected}
            type="uploaded"
          />
          <DSTypeOption
            icon="api"
            label="API"
            onSelect={setTypeSelected}
            qa={`${qa}-${ElementType.Button}-api`}
            selectedType={typeSelected}
            type="api"
          />
        </div>
        <div className={s.buttonContainer}>
          <GenericButton
            buttonStyle="tertiary"
            onClick={closeModal}
            qa={`${qa}-${ElementType.Button}-cancel`}
            label="Cancel"
          />
          <GenericButton
            disabled={typeSelected == null}
            onClick={() => navigate(`/forms/data-sets/${typeSelected}`)}
            qa={`${qa}-${ElementType.Button}-create`}
            label="Create Data Set"
          />
        </div>
      </>
    </GenericModal>
  );
};

interface ConfirmationProps extends Props {
  loading: boolean;
  updateSourceStatus: (
    ds: DataSource,
    status: DataSourceStatus.UNPUBLISHED | DataSourceStatus.PUBLISHED
  ) => void;
  sourceSelected: DataSource | null;
}

export const ConfirmModal: FC<ConfirmationProps> = ({
  loading,
  open,
  closeModal,
  updateSourceStatus,
  sourceSelected,
}) => {
  const qa = `${Components.DataSets}-confirmModal`;
  const isPublishing =
    sourceSelected && sourceSelected.status === DataSourceStatus.UNPUBLISHED;
  return (
    <GenericModal
      isOpen={open}
      onClose={closeModal}
      qa={`${qa}-${ElementType.Modal}`}
      title={`${isPublishing ? "Publish" : "Unpublish"} Data Set`}
    >
      <>
        <Alert
          title={`Do you wish to ${
            isPublishing ? "publish" : "unpublish"
          } the “${sourceSelected?.title}” data set?`}
          variant="error"
        />
        <div className={s.buttonContainer}>
          <GenericButton
            buttonStyle="tertiary"
            onClick={closeModal}
            qa={`${qa}-${ElementType.Button}-cancel`}
            label="Cancel"
          />
          <GenericButton
            type="submit"
            loading={loading}
            onClick={() => {
              sourceSelected &&
                updateSourceStatus(
                  sourceSelected,
                  isPublishing
                    ? DataSourceStatus.PUBLISHED
                    : DataSourceStatus.UNPUBLISHED
                );
            }}
            qa={`${qa}-${ElementType.Button}-publish`}
            label={isPublishing ? "Publish" : "Unpublish"}
          />
        </div>
      </>
    </GenericModal>
  );
};
