import { Formik, FormikHelpers } from "formik";
import React, { FC, useEffect, useState } from "react";
import Checkbox from "shared/src/components/Checkbox/Checkbox";
import { TextInput } from "shared/src/components/TextInput/TextInput";
import { Components, ElementType } from "shared/src/qa-slugs";
import s from "./APIDS.module.scss";
import { APIForm, apiFormSchema } from "./apiDSHelpers";
import { GenericButton } from "shared/src/components/Generic/Button/GenericButton";

interface APIFormikFormProps {
  initialValues: APIForm;
  isLoadingTest?: boolean;
  isTestSuccessful?: boolean;
  onCancel: () => void;
  onSubmit: (
    values: APIForm,
    formikHelpers: FormikHelpers<APIForm>
  ) => Promise<void>;
  onTest: (values: APIForm) => Promise<void>;
  testResponse: string;
}

export const APIFormikForm: FC<APIFormikFormProps> = ({
  initialValues,
  isLoadingTest,
  isTestSuccessful,
  onCancel,
  onSubmit,
  onTest,
  testResponse,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={apiFormSchema}
      onSubmit={onSubmit}
    >
      {({
        errors,
        getFieldProps,
        handleSubmit,
        isSubmitting,
        isValid,
        setFieldValue,
        setTouched,
        touched,
        values,
      }) => {
        const handleClickTest = async () => {
          try {
            await onTest(values);
          } finally {
            // Touch all fields and force validation.
            const touched = Object.keys(values).reduce(
              (touched, name) => ({ ...touched, [name]: true }),
              {}
            );
            setTouched(touched, true);
          }
        };

        useEffect(() => {
          setFieldValue("data", testResponse);
        }, [testResponse]);

        const qaBase = Components.APIFormikForm;

        return (
          <form onSubmit={handleSubmit} noValidate className={s.formContainer}>
            <div className={s.fieldsContainer}>
              {/* API Name */}
              <TextInput
                qa={`${qaBase}-${ElementType.TextInput}-name`}
                label="Name"
                placeholder="API Name"
                error={!!touched.name && errors.name}
                {...getFieldProps("name")}
                required
              />

              {/* API Description */}
              <TextInput
                qa={`${qaBase}-${ElementType.TextInput}-description`}
                label="Description"
                placeholder="API Description"
                error={!!touched.description && errors.description}
                {...getFieldProps("description")}
                required
              />

              {/* Unique ID Field Name */}
              <TextInput
                qa={`${qaBase}-${ElementType.TextInput}-uniqueId`}
                label="Unique ID Field Name"
                placeholder="Field Name"
                error={!!touched.uniqueIdFieldName && errors.uniqueIdFieldName}
                success={
                  isTestSuccessful && !errors.uniqueIdFieldName
                    ? "Connected"
                    : undefined
                }
                {...getFieldProps("uniqueIdFieldName")}
                required
              />

              {/* Data Set Key */}
              <TextInput
                qa={`${qaBase}-${ElementType.TextInput}-dataset`}
                label="Data Set Key"
                placeholder="Data Set Key"
                error={!!touched.datasetKey && errors.datasetKey}
                {...getFieldProps("datasetKey")}
                required
              />
            </div>

            {/* URL */}
            <TextInput
              qa={`${qaBase}-${ElementType.TextInput}-url`}
              type="url"
              label="URL"
              placeholder="URL"
              error={!!touched.url && errors.url}
              {...getFieldProps("url")}
              required
            />

            <Checkbox
              {...getFieldProps({ name: "paginated", type: "checkbox" })}
              label="Paginated"
              onChange={(checked) => setFieldValue("paginated", checked)}
              qa={`${qaBase}-${ElementType.Checkbox}-paginated`}
            />

            {/* Test & Save Buttons */}
            <div className={s.apiDSButtonsWrapper}>
              <GenericButton
                qa={`${qaBase}-${ElementType.Button}-test`}
                onClick={handleClickTest}
                loading={isLoadingTest}
                label="Test"
              />
              <GenericButton
                type="submit"
                disabled={!isValid}
                qa={`${qaBase}-${ElementType.Button}-save`}
                loading={isSubmitting}
                label="Save & Close"
              />
              <GenericButton
                buttonStyle="tertiary"
                onClick={onCancel}
                qa={`${qaBase}-${ElementType.Button}-cancel`}
                label="Cancel"
              />
            </div>

            {/* Data */}
            <div>
              <TextInput
                qa={`${qaBase}-${ElementType.TextInput}-data`}
                disabled={true}
                label="Data"
                placeholder="Data String"
                multiline={true}
                rows={10}
                {...getFieldProps("data")}
              />
            </div>
          </form>
        );
      }}
    </Formik>
  );
};
