import {
  emailValidationPattern,
  nameValidationPattern,
  RequiredMessage,
} from "./constants";
import uniq from "lodash/uniq";
import {
  ClientGroupDTO,
  JWTAuthorityDTO,
  ParticipantSummary,
  ParticipantUserVm,
  roles,
  UsersParticipant,
  UserStatus,
  WorkLocationDTO,
} from "@rtslabs/field1st-fe-common";
import { OptionContent } from "shared/src/components/SearchableSelect/SearchSelect";
import { FormikErrors } from "formik";

// User is a Client Reviewer
const userIsClientReviewer = (authorities: JWTAuthorityDTO[]) => {
  const reviewerAuthority: JWTAuthorityDTO | undefined = authorities?.find(
    (authority) => authority.authority === roles.ROLE_CLIENT_REVIEWER
  );
  if (reviewerAuthority && reviewerAuthority.groups?.length > 0) {
    return true;
  }
  return false;
};

// Sync validation function for Formik
export const validateAddUserForm = (
  values: EditUserFormValues
): FormikErrors<EditUserFormValues> => {
  const errors: FormikErrors<EditUserFormValues> = {};

  if (!values.email) {
    errors.email = RequiredMessage;
  } else {
    if (!emailValidationPattern.test(values.email)) {
      errors.email = "Please enter a valid email";
    }
  }
  if (!values.firstName) {
    errors.firstName = RequiredMessage;
  } else {
    if (!nameValidationPattern.test(values.firstName)) {
      errors.firstName = "Letters, numbers and dash only";
    }
  }
  if (!values.lastName) {
    errors.lastName = RequiredMessage;
  } else {
    if (!nameValidationPattern.test(values.lastName)) {
      errors.lastName = "Letters, numbers and dash only";
    }
  }

  // Primary group field error if creating a participant (createUser is false) -- GK
  if (!values.primaryGroupId) {
    errors.primaryGroupId = RequiredMessage;
  }

  // workLocation not required -- GK
  if (!values.workLocationId) {
    errors.workLocationId = RequiredMessage;
  }

  return errors;
};

export interface EditUserFormValues {
  firstName: string;
  lastName: string;
  nickname: string;
  email: string;
  workLocationId?: number;
  supervisorId?: number;
  createUser: boolean;
  primaryGroupId?: number;
  isClientReviewer: boolean;
  status: UserStatus;
  // authorities handled separately
}

// Form's initial values
export const getInitialValues = (
  user: Partial<ParticipantUserVm>
): EditUserFormValues => {
  let result: EditUserFormValues = {
    // Default, for Add User
    createUser: true,
    email: "",
    firstName: "",
    isClientReviewer: false,
    lastName: "",
    nickname: "",
    primaryGroupId: undefined, // No default primary group
    status: UserStatus.DISABLED,
    supervisorId: undefined,
    workLocationId: undefined,
  };
  if (user?.email) {
    // For Edit user
    const _createUser = (user.authorities?.length || 0) > 0; // user.createUser is always `null` here -- GK
    const _isClientReviewer = userIsClientReviewer(user.authorities || []);
    result = {
      createUser: _createUser,
      email: user.email,
      firstName: user.firstName || "",
      isClientReviewer: _isClientReviewer,
      lastName: user.lastName || "",
      nickname: user.nickname || "",
      primaryGroupId: user.primaryGroupId,
      status: user.status || UserStatus.DISABLED,
      supervisorId: (user.supervisor && user.supervisor.id) || undefined,
      workLocationId: (user.workLocation && user.workLocation.id) || undefined,
    };
  }
  return result;
};

// Update authorities
// BE's model has `authority` as string, ideally we'd have actual roles here -- GK
export const updateAuthorities = (
  authorities: JWTAuthorityDTO[],
  updatedAuthority: JWTAuthorityDTO,
  method: "replace" | "add" = "add"
) => {
  const matching = authorities.filter(
    (a) => a.authority === updatedAuthority.authority
  );
  if (matching.length) {
    authorities = authorities.filter(
      (a) => a.authority !== updatedAuthority.authority
    );

    // if method is add, get the groups from the matching authorities
    if (method === "add") {
      updatedAuthority = {
        ...updatedAuthority,
        groups: uniq([...matching[0].groups, ...updatedAuthority.groups]),
      };
    }
  }

  return [...authorities, updatedAuthority]
    .filter((a) => a.isGlobal || a.groups.length > 0)
    .map((a) => (a.isGlobal ? { ...a, groups: [] } : a));
};

// If creating a user, there needs to be at least one role that has at least one group -- GK
export const atLeastOneRoleHasGroup = (authorities: JWTAuthorityDTO[]) => {
  return authorities.some((a) => a.isGlobal || a.groups.length > 0);
};

/**
 * Map work location content to id/label options
 * @param workLocations
 */
export function mapWorkLocations(
  workLocations: WorkLocationDTO[]
): OptionContent[] {
  return workLocations.map((workLocation) => {
    return {
      id: workLocation.id,
      label: workLocation.name,
    };
  });
}

/**
 * Map supervisors to id/label options
 * @param supervisors
 * @param currentUser
 */
export function mapSupervisorOptions(
  supervisors: ParticipantSummary[],
  currentUser: Partial<UsersParticipant>
): OptionContent[] {
  const result: OptionContent[] = supervisors.map((supervisor) => ({
    id: supervisor.id,
    label: supervisor.name!,
  }));
  // Add supervisor option to list of initial supervisors if not there already
  if (currentUser.supervisor && supervisors) {
    // getting TS error without this check below
    const _supervisorId = currentUser.supervisor?.id;
    if (!supervisors.find((e) => e.id === _supervisorId)) {
      result.unshift({
        id: currentUser.supervisor.id,
        label: currentUser.supervisor.name,
      });
    }
  }
  return result;
}

/**
 * Map client groups to id/label options
 * @param clientGroups
 */
export function mapClientGroupsOptions(
  clientGroups: ClientGroupDTO[]
): OptionContent[] {
  return clientGroups.map((group: ClientGroupDTO) => {
    return {
      id: group.id,
      label: group.name || "Group name missing",
    };
  });
}

export function buildBreadcrumbs(
  participantId?: number,
  currentUser?: Partial<UsersParticipant>
) {
  return [
    {
      pathName: "People",
      href: "/people/users",
    },
    {
      pathName: "Users",
      href: "/people/users",
    },
    {
      pathName: `${
        participantId
          ? `Edit User Profile${
              currentUser?.fullName ? `: ${currentUser?.fullName}` : ""
            }`
          : "Add New User"
      }`,
      href: "",
    },
  ];
}
