import {
  API,
  FilterParams,
  ResourceDTO,
  ResourceStatus,
} from "@rtslabs/field1st-fe-common";
import moment from "moment";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "../../Link/Link";
import { TableSummary } from "../../TableUI/TableSummary/TableSummary";
import { Components, ElementType } from "../../../qa-slugs";
import useGroupTerm from "../../../util/hooks/useGroupTerm";
import { resourceWriteRoles } from "../../routes/constants/permissionSets";
import WriteContent from "../../common/permissions/WriteContent";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "../../common/styled/Table";
import NoResults from "../../common/TableUI/NoResults";
import ResourceActionMenu from "./ResourceActionMenu";
import {
  getResourceStatusString,
  getResourceTypeString,
} from "./resourceHelpers";
import TableHeader from "./TableHeader";
import styles from "./MaintainResources.module.scss";
import { joinClassNames } from "../../../helpers/theme.helpers";

type ResourcesTableProps = {
  onSort: (sort: string) => void;
  onToggleResourceStatus: (resourceId: number, status: ResourceStatus) => void;
  params: FilterParams;
  resources: ResourceDTO[];
  totalElements: number;
  ifFiltered: boolean;
  onClearFilters: () => void;
};

const ResourcesTable = ({
  onSort,
  params,
  totalElements,
  resources,
  onToggleResourceStatus,
  ifFiltered,
  onClearFilters,
}: ResourcesTableProps) => {
  const navigate = useNavigate();
  // Group config terms
  const operationExperiencesTerm = useGroupTerm(
    "operationalExperiences",
    "noun",
    "singular",
    "Operational Experience"
  );
  const defensesTerm = useGroupTerm("defenses", "noun", "singular", "Defense");

  const whiteLabelTerms = useMemo(
    () => ({
      operationalExperience: operationExperiencesTerm,
      defense: defensesTerm,
    }),
    [operationExperiencesTerm, defensesTerm]
  );

  return (
    <>
      <TableSummary
        pageSize={params.size}
        currentPage={params.page}
        totalElements={totalElements}
        ofWhat="resources"
        exports={["print", "xls", "csv"]}
        onExport={API.downloadResources}
        exportParams={{ query: params.query }}
        isFiltered={ifFiltered}
        onClearFilters={onClearFilters}
      />
      <Table>
        <TableHeader
          onSort={onSort}
          currentSort={params.sort}
          columns={[
            { id: "resourceType", label: "type", className: styles.firstCell },
            { id: "title" },
            { id: "createdBy.name", label: "author" },
            { id: "uses" },
            { id: "lastModifiedDate", label: "Last Updated" },
            { id: "status" },
          ]}
        />
        <TableBody>
          {resources.length > 0 ? (
            resources.map((resource) => (
              <TableRow
                data-testid={`${Components.ResourcesTable}-${ElementType.TableRow}-${resource.id}`}
                key={resource.id}
              >
                <TableCell
                  qa={`${Components.ResourcesTable}-${ElementType.TableCell}-type-${resource.id}`}
                  className={joinClassNames(
                    styles.firstCell,
                    styles.resourceType
                  )}
                >
                  {getResourceTypeString(
                    resource.resourceType,
                    whiteLabelTerms
                  )}
                </TableCell>
                <TableCell
                  width="275px"
                  qa={`${Components.ResourcesTable}-${ElementType.TableCell}-title-${resource.id}`}
                >
                  <WriteContent
                    altComponent={<>{resource.id}</>}
                    roles={resourceWriteRoles}
                  >
                    <Link
                      qa={`${Components.ResourcesTable}-${ElementType.Link}-title-${resource.id}`}
                      to={`/content/resources/${resource.id}`}
                    >
                      {resource.title}
                    </Link>
                  </WriteContent>
                </TableCell>
                <TableCell
                  qa={`${Components.ResourcesTable}-${ElementType.TableCell}-createdBy-${resource.id}`}
                  width="175px"
                >
                  {resource.createdBy.name}
                </TableCell>
                <TableCell
                  align="left"
                  qa={`${Components.ResourcesTable}-${ElementType.TableCell}-uses-${resource.id}`}
                >
                  {resource.uses}
                </TableCell>
                <TableCell
                  qa={`${Components.ResourcesTable}-${ElementType.TableCell}-lastUpdated-${resource.id}`}
                >
                  {resource.lastModifiedDate
                    ? moment(resource.lastModifiedDate).format("MM/DD/YYYY")
                    : "--"}
                </TableCell>
                <TableCell
                  qa={`${Components.ResourcesTable}-${ElementType.TableCell}-status-${resource.id}`}
                  width="100px"
                  className={styles.resourceStatus}
                >
                  <span
                    className={joinClassNames(
                      resource.status === "ACTIVE" && styles.active,
                      resource.status === "DRAFT" && styles.draft,
                      resource.status === "DELETED" && styles.deleted
                    )}
                  >
                    {getResourceStatusString(resource.status)}
                  </span>
                </TableCell>
                <TableCell width="22px" className={styles.lastCell}>
                  {/* Leaving cell to keep table consistent. 
                      None of the menu links apply for Client Reviewer -- GK 
                  */}
                  <WriteContent roles={resourceWriteRoles}>
                    <ResourceActionMenu
                      resource={resource}
                      onToggleResourceStatus={onToggleResourceStatus}
                    />
                  </WriteContent>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <NoResults
              icon="document"
              header="No resources were found."
              body="Create a new resource to match your needs."
              button={{
                children: "Create a resource",
                onClick: () => navigate("/content/resources/new"),
              }}
              qa={`${Components.ResourcesTable}-${Components.NotFound}`}
              roles={resourceWriteRoles}
            />
          )}
        </TableBody>
      </Table>
    </>
  );
};

export default ResourcesTable;
