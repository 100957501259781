import React, { FC } from "react";
import {
  Button,
  TertiaryButton,
  SecondaryButton,
  DisabledButton,
} from "../../Button/Button";
import { Icon } from "../../Icon/Icon";
import styles from "./GenericButton.module.scss";
import { QAProps } from "../../../qa-slugs";

interface GenericButtonProps extends QAProps {
  buttonStyle?: "primary" | "secondary" | "tertiary" | "disabled";
  disabled?: boolean;
  label: string;
  loading?: boolean;
  loadingState?: "Loading" | "Completed" | "Checked" | "None";
  iconType?: string;
  onClick?: () => void;
  type?: "submit" | "reset";
}

export const GenericButton: FC<GenericButtonProps> = ({
  buttonStyle,
  disabled,
  label,
  loading,
  loadingState,
  iconType,
  onClick,
  type,
  qa,
}) => {
  let RenderButton;
  switch (buttonStyle) {
    case "disabled":
      RenderButton = DisabledButton;
      break;
    case "tertiary":
      RenderButton = TertiaryButton;
      break;
    case "secondary":
      RenderButton = SecondaryButton;
      break;
    case "primary":
    default:
      RenderButton = Button;
      break;
  }

  return (
    <RenderButton
      onClick={onClick}
      qa={qa}
      type={type}
      disabled={disabled}
      loading={loading}
      loadingState={loadingState}
    >
      <span className={styles.buttonWrapper}>
        {iconType && (
          <Icon type={iconType} size={12} className={styles.buttonIcon} />
        )}
        <span className={styles.buttonLabel}>{label}</span>
      </span>
    </RenderButton>
  );
};
