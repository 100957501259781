import {
  DocumentOEVm,
  DocumentQuestionResponseVm,
  DocumentSubmissionType,
  DocumentVm,
  Functions,
  MapWidgetDTO,
  QuestionDTO,
  ResponseContent,
  SignatureType,
} from "@rtslabs/field1st-fe-common";
import { useFormikContext } from "formik";
import { get } from "lodash";
import React from "react";
import { PhotoGallery } from "shared/src/components/Document/DocumentForm/PhotoGallery/PhotoGallery";
import Signatures, {
  UpdateSignatureArgs,
} from "shared/src/components/Document/DocumentForm/Signatures/Signatures";
import { FormWidgetProps } from "shared/src/components/Document/DocumentForm/types";
import { Components, Page, WidgetType } from "shared/src/qa-slugs";
import { Defenses } from "./Defenses/Defenses";
import { EsriContent } from "./EsriContent/EsriContent";
import { MapWidget } from "./MapWidget/MapWidget";
import { ClassicMapWidget } from "../MapWidget/ClassicMapWidget";
import { OperationalExperiences } from "./OperationalExperiences/OperationalExperiences";
import { useAppSelector } from "../../store/hooks";

/** Specific widget based on item subType */
export default function Widget({
  defenses,
  flattenedQuestions,
  getDataSourceValues,
  item,
  responses,
  sectionItems,
  setDocumentResponses,

  // validation errors
  errors,
}: FormWidgetProps) {
  const isEsriActive = useAppSelector(
    (state) => state.appConfigs.arcGisConfig?.active
  );
  const { values, setFieldValue } = useFormikContext<DocumentVm>();

  function setQuestionResponse(
    item: QuestionDTO,
    response?: DocumentQuestionResponseVm,
    content?: ResponseContent | null
  ) {
    setDocumentResponses(item, response ? [response] : [], content);
  }

  async function updateParticipantSignature(signature: UpdateSignatureArgs) {
    const updatedParticipants = values.participants?.map((p) => {
      if (p.participantId === signature.participantId) {
        return {
          ...p,
          signatureType: signature.signatureType || null,
          signatureTextValue: signature.signatureTextValue,
          signatureUrl: signature.signatureUrl,
          signatureDate: signature.currentTime,
          timeAdded: signature.currentTime || p.timeAdded,
        };
      }
      return p;
    });
    setFieldValue("submissionType", DocumentSubmissionType.AUTO_SYNC, false);
    setFieldValue("participants", updatedParticipants, false);
  }

  async function updateOes(operationalExperiences: DocumentOEVm[]) {
    setFieldValue("submissionType", DocumentSubmissionType.AUTO_SYNC, false);
    setFieldValue("operationalExperiences", operationalExperiences, false);
  }

  const qaBase = `${Page.Desktop}-${Components.Document}`;

  switch (item.subType) {
    case "DEFENSES":
      return (
        <Defenses
          defenses={defenses}
          flattenedQuestions={flattenedQuestions}
          responses={responses}
          setDocumentResponses={setDocumentResponses}
        />
      );

    case "MAP":
      const widgetProps = {
        errors,
        getDataSourceValues,
        item: item as MapWidgetDTO,
        responses,
        sectionItems,
        setQuestionResponse,
      };

      return !isEsriActive ? (
        <ClassicMapWidget {...widgetProps} />
      ) : (
        <MapWidget {...widgetProps} />
      );

    case "OPERATIONAL_EXPERIENCES":
      return (
        <OperationalExperiences
          widget={item}
          error={get(errors, item.id)}
          onUpdateOes={updateOes}
          includedOes={values.operationalExperiences || []}
        />
      );

    case "SIGNATURE":
      const filteredParticipantsNeedToSign =
        Functions.getParticipantsNeedToSign(
          sectionItems,
          values.participants,
          values.responses,
          values.form.sections
        );
      return (
        <Signatures
          allowTypedEmails={
            !!item.allowedTypes?.includes(SignatureType.TYPED_EMAIL)
          }
          error={get(errors, item.id)}
          participants={
            filteredParticipantsNeedToSign ?? values.participants ?? []
          }
          onUpdateSignature={updateParticipantSignature}
        />
      );

    case "PHOTO_GALLERY":
      return (
        <PhotoGallery
          qa={`${qaBase}-${WidgetType.PhotoGallery}-${item.id}`}
          name={`item_${item.id}`}
        />
      );

    case "ESRI_CONTENT":
      return !!isEsriActive ? <EsriContent item={item} /> : null;

    case "SAFETY_RATING":
    default:
      return null;
  }
}
