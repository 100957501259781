import React, { FC } from "react";
import { PermissionTable } from "./PermissionTable";
import styles from "./Permissions.module.scss";

export const Permissions: FC = () => {
  return (
    <div className={styles.permissionTable}>
      <PermissionTable />
    </div>
  );
};
