import React, { FC, useState } from "react";

import { AssistiveLink } from "../TextInput/types";
import styles from "./Label.module.scss";
import { joinClassNames } from "../../helpers/theme.helpers";
import { ElementType } from "../../qa-slugs";
import { Required } from "../../assets/svg/Required";

interface LabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
  assistiveLink?: AssistiveLink | null;
  containerClassName?: string;
  noMargin?: boolean;
  required?: boolean;
  hasError?: boolean;
  smallLabel?: boolean;
  qa?: string;
}

/** Basic HTML label element with optional assistive link */
const Label: FC<LabelProps> = ({
  assistiveLink,
  className,
  containerClassName,
  children,
  htmlFor,
  noMargin,
  required,
  hasError,
  smallLabel,
  qa,
}) => {
  const [isMouseFocused, setIsMouseFocused] = useState(false);

  const handleAssistiveLinkBlur = () => {
    setIsMouseFocused(false);
  };

  const handleAssistiveLinkClick = () => {
    if (assistiveLink && assistiveLink.onClick) {
      assistiveLink.onClick(isMouseFocused);
    }
  };

  const handleAssistiveLinkMouseDown = () => {
    setIsMouseFocused(true);
  };

  return (
    <div
      className={joinClassNames(
        styles.wrapper,
        containerClassName,
        noMargin ? styles.noMargin : "",
        assistiveLink && styles.withAssistiveLink
      )}
    >
      <label
        className={joinClassNames(
          styles.label,
          smallLabel ? styles.smallLabel : "",
          className,
          "fieldLabel"
        )}
        data-testid={qa ? `${qa}-${ElementType.Label}` : undefined}
        htmlFor={htmlFor}
      >
        {children}
        {required && (
          <span
            className={joinClassNames(
              styles.required,
              hasError && styles.error
            )}
          >
            <span className={styles.requiredIconWrapper}>
              <Required className={styles.requiredIcon} />
            </span>
            <span className={styles.requiredTooltip}>
              This field is required
            </span>
          </span>
        )}
      </label>
      {assistiveLink && (
        <>
          {assistiveLink.onClick && (
            <button
              data-testid={
                qa
                  ? `${qa}-${ElementType.Button}-${assistiveLink.label}`
                  : undefined
              }
              type="button"
              className={joinClassNames(
                styles.button,
                isMouseFocused ? styles.buttonMouseFocused : ""
              )}
              onBlur={handleAssistiveLinkBlur}
              onClick={handleAssistiveLinkClick}
              onMouseDown={handleAssistiveLinkMouseDown}
            >
              {assistiveLink.icon && (
                <i className={`icon icon-icons8-${assistiveLink.icon}`} />
              )}
              {assistiveLink.label}
            </button>
          )}
          {assistiveLink.url && (
            <a
              data-testid={
                qa
                  ? `${qa}-${ElementType.Link}-${assistiveLink.label}`
                  : undefined
              }
              className={styles.a}
              href={assistiveLink.url}
            >
              {assistiveLink.icon && (
                <i className={`icon icon-icons8-${assistiveLink.icon}`} />
              )}
              {assistiveLink.label}
            </a>
          )}
          {!(assistiveLink.onClick || assistiveLink.url) && (
            <span className={styles.span}>{assistiveLink.label}</span>
          )}
        </>
      )}
    </div>
  );
};

export default Label;
