import React, { FC, useEffect, useState } from "react";
import { Formik } from "formik";
import { TextInput } from "shared/src/components/TextInput/TextInput";
import Loader from "shared/src/components/Loader/Loader";
import {
  API,
  ClientGroupSaveVm,
  useAsyncEffect,
} from "@rtslabs/field1st-fe-common";
import { Components, Page } from "shared/src/qa-slugs";
import {
  errorToastOptions,
  Toast,
  ToastStatus,
  updateToast,
} from "shared/src/components/Toast/Toastify";
import { toast } from "react-toastify";
import styles from "./AddEditGroupForm.module.scss";
import { GenericButton } from "shared/src/components/Generic/Button/GenericButton";

// Constants

type ErrorType = {
  externalGroupId?: string;
  name?: string;
};
const validate = (values) => {
  // Validates Formik form
  const errors: ErrorType = {};
  if (values) {
    if (!values.name) {
      errors.name = "Group Name is required";
    }
    if (!values.externalGroupId) {
      errors.externalGroupId = "Group ID is required";
    }
  }
  return errors;
};

interface AddEditGroupFormProp {
  groupId?: number;
  onClose: () => void;
}

export const AddEditGroupForm: FC<AddEditGroupFormProp> = ({
  groupId,
  onClose,
}) => {
  const qaToastId = "addGroup";

  // Current group
  const [currentGroup, setCurrentGroup] = useState<ClientGroupSaveVm>();
  const [currentGroupLoading, setCurrentGroupLoading] = useState(false);

  // Async form submit loading (API)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  // On mount
  useAsyncEffect(async () => {
    if (groupId) {
      setCurrentGroupLoading(true);
      try {
        const res = await API.getClientGroupById({ id: groupId });
        setCurrentGroup(res);
      } catch (error) {
        console.error(error); // todo
      } finally {
        setCurrentGroupLoading(false);
      }
    }
  }, [groupId]);

  useEffect(() => {
    return () => toast.dismiss(qaToastId);
  }, []);

  // Submits the form
  const _handleSubmit = async (values) => {
    const { externalGroupId, name } = values;
    let group: ClientGroupSaveVm = {
      id: groupId,
      externalGroupId,
      name,
    };
    setSubmitLoading(true);
    try {
      await API.saveClientGroup({ group });
      setSubmitLoading(false);
      onClose();
    } catch (error) {
      updateToast(
        <Toast
          status={ToastStatus.Error}
          message={`Failed to ${groupId ? "Edit" : "Add"} Group`}
        />,
        qaToastId,
        errorToastOptions
      );
      setSubmitLoading(false);
    }
  };

  return (
    <Loader loading={currentGroupLoading}>
      <div data-testid={`${Page.Desktop}-${Components.EditGroup}`}>
        <Formik
          initialValues={
            currentGroup || {
              name: "",
              externalGroupId: "",
            }
          }
          onSubmit={(values, { setFieldTouched }) => {
            // Set all fields to touched
            setFieldTouched("name", true, true);
            _handleSubmit(values);
          }}
          enableReinitialize
          validate={validate}
          validateOnChange={false}
        >
          {(props) => {
            const { errors, handleSubmit, setValues, touched, values } = props;
            return (
              <form
                onSubmit={handleSubmit}
                className={styles.groupFormContainer}
              >
                <TextInput
                  error={touched["name"] && errors["name"]}
                  label="Group Name"
                  name="name"
                  onChange={(e) => {
                    setValues({
                      ...values,
                      name: e.target.value,
                    });
                  }}
                  placeholder="Group Name"
                  value={values["name"]}
                />
                <TextInput
                  error={
                    touched["externalGroupId"] && errors["externalGroupId"]
                  }
                  label="Group ID"
                  name="externalGroupId"
                  onChange={(e) => {
                    setValues({
                      ...values,
                      externalGroupId: e.target.value,
                    });
                  }}
                  placeholder="Group ID"
                  value={values["externalGroupId"]}
                />

                <div className={styles.buttonsWrapper}>
                  <GenericButton
                    onClick={handleSubmit}
                    loading={submitLoading}
                    type="submit"
                    label={`${groupId ? "update" : "add"} group`}
                  />
                  <GenericButton
                    buttonStyle="tertiary"
                    onClick={onClose}
                    disabled={submitLoading}
                    label="cancel"
                  />
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </Loader>
  );
};
