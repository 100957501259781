import React, { FC } from "react";
import Loader from "shared/src/components/Loader/Loader";
import { FormReportSafetyResponses } from "../../helpers";
import ChartHeader from "./ChartHeader";
import styles from "./SafetyScale.module.scss";

interface SafetyScaleProps {
  dayFilterValue: string;
  loading: boolean;
  ratingsFilter: string[];
  responses: FormReportSafetyResponses;
  totalResponseCount: number;
}

const SafetyScale: FC<SafetyScaleProps> = ({
  dayFilterValue,
  loading,
  responses,
  totalResponseCount,
}) => {
  const data = Object.keys(responses).map((k) => {
    const responseCountArr = responses[k].map((r) => r.responseCount);
    const value = responseCountArr.length
      ? responseCountArr.reduce((acc, cur) => acc + cur)
      : 0;
    return {
      id: k,
      value,
      label: k,
      totalResponseCount,
    };
  });

  return (
    <Loader loading={loading} overlay>
      <div className={styles.widgetContainer}>
        <ChartHeader
          totalResponseCount={totalResponseCount}
          dayFilterValue={dayFilterValue}
        />
        <div className={styles.chartContainer}>
          {data.map((d) => (
            <div className={styles.circleContainer} key={d.id}>
              <div>{d.value}</div>
              <span>{d.label}</span>
            </div>
          ))}
        </div>
      </div>
    </Loader>
  );
};

export default SafetyScale;
