import { API, useAPI } from "@rtslabs/field1st-fe-common";
import React, { FC, useEffect, useState } from "react";
import SearchBar from "shared/src/components/SearchBar/SearchBar";
import { FilterSelect } from "shared/src/components/Select/FilterSelect";
import { DateRangeValue, TimeFilter } from "shared/src/data/timeFilters";
import { Components, ElementType } from "shared/src/qa-slugs";
import { timeFilterOptions } from "shared/src/data/timeFilters";
import { generateGroupOptions } from "shared/src/components/clientAdmin/helpers";
import { DateRange } from "shared/src/components/DateRange/DateRange";
import { mockRoleOptions, statusOptions } from "./usersConstants";
import styles from "shared/src/components/Filters/Filters.module.scss";
import { joinClassNames } from "shared/src/helpers/theme.helpers";

export interface FilterChangeProps {
  dateRange: DateRangeValue | undefined;
  group: number | undefined;
  role: number | undefined;
  status: number | undefined;
  searchQuery: string;
  timeFilter?: TimeFilter;
}
interface UsersFiltersProps {
  groupParams?: number;
  participantQueryParams: API.ParticipantQueryParams;
  onFilterChange: (props: FilterChangeProps) => void;
  remoteClear: boolean;
}

export const UsersFilters: FC<UsersFiltersProps> = ({
  groupParams,
  onFilterChange,
  remoteClear,
}) => {
  // Filters
  const [dateRange, setDateRange] = useState<DateRangeValue>();
  const [group, setGroup] = useState<number | undefined>(
    groupParams || undefined
  );
  const [role, setRole] = useState<number>();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [status, setStatus] = useState<number>();
  const [timeFilter, setTimeFilter] = useState<TimeFilter>();

  const allGroupCall = useAPI(API.getClientGroups, {
    size: 200,
    sort: "name,asc",
  });

  useEffect(() => {
    onFilterChange({
      dateRange,
      group,
      role,
      searchQuery,
      status,
      timeFilter,
    });
  }, [dateRange, group, role, status, searchQuery, timeFilter]);

  const clearFilters = () => {
    setGroup(groupParams);
    setRole(undefined);
    setSearchQuery("");
    setStatus(undefined);
    setTimeFilter(undefined);
  };

  useEffect(() => {
    clearFilters();
  }, [remoteClear]);

  const isDateRange = timeFilter === TimeFilter.CUSTOM_RANGE;

  return (
    <div
      className={joinClassNames(
        styles.filtersContainer,
        isDateRange && styles.withDateRange
      )}
    >
      <div className={styles.searchWrapper}>
        <SearchBar
          qa={`${Components.Users}-${ElementType.TextInput}-searchBar`}
          onSearch={setSearchQuery}
          remoteClear={remoteClear}
        />
      </div>

      <div className={styles.filterOptions}>
        <div className={styles.filterSelect}>
          <FilterSelect
            qa={`${Components.Users}-${ElementType.SelectInput}-groupsFilter`}
            name="groups"
            value={group ?? 0}
            label=""
            placeholder="All Groups"
            options={[
              { value: 0, label: "All Groups" },
              ...generateGroupOptions(allGroupCall.data?.content),
            ]}
            onChange={(option) => setGroup(option?.value)}
          />
        </div>
        <div className={styles.filterSelect}>
          <FilterSelect
            qa={`${Components.Users}-${ElementType.SelectInput}-rolesFilter`}
            name="roles"
            value={role ?? 0}
            label=""
            placeholder="All Roles"
            options={mockRoleOptions}
            onChange={(option) => setRole(option?.value)}
          />
        </div>
        <div className={styles.filterSelect}>
          <FilterSelect
            qa={`${Components.Users}-${ElementType.SelectInput}-statusesFilter`}
            name="statuses"
            value={status ?? 0}
            label=""
            placeholder="All Statuses"
            options={statusOptions}
            onChange={(option) => setStatus(option?.value)}
          />
        </div>
        <div className={styles.filterSelect}>
          <FilterSelect
            qa={`${Components.Users}-${ElementType.SelectInput}-timeFilter`}
            name="timeFilter"
            value={timeFilter ?? TimeFilter.ALL_TIME}
            label=""
            placeholder="All Time"
            options={timeFilterOptions}
            onChange={(option) =>
              setTimeFilter(option?.value || TimeFilter.ALL_TIME)
            }
          />
        </div>

        {timeFilter === "CUSTOM_RANGE" && (
          <div className={styles.dateRangeRow}>
            <span className="visually-hidden" id="custom-range-label">
              Custom Range
            </span>
            <DateRange
              handleChange={setDateRange}
              labelId="custom-range-label"
              name="customRange"
              onClear={() => {
                setDateRange(undefined);
                setTimeFilter(TimeFilter.ALL_TIME);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
