import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
} from "@mui/material/Autocomplete";
import {
  AutocompleteInputChangeReason,
  AutocompleteValue,
} from "@mui/material/useAutocomplete";
import React, {
  ChangeEvent,
  HTMLAttributes,
  useEffect,
  useRef,
  useState,
} from "react";

import useDebounce from "../../util/hooks/useDebounce";
import { Listbox } from "./Listbox";
import { SelectionList } from "./SelectionList/SelectionList";
import styles from "./styles.module.scss";
import { TextField, TextFieldRef } from "./TextField";
import { joinClassNames } from "../../helpers/theme.helpers";
import { notFalse } from "../../helpers/utils";
import usePrevious from "../../util/hooks/usePrevious";
import Label from "../Label/Label";
import { Icon } from "../Icon/Icon";
import { Components } from "../../qa-slugs";
import { FieldMessagingWrapper } from "../Document/DocumentForm/FieldMessagingWrapper";

export interface OptionContent {
  id: number | null;
  label: string;
}

type SearchSelectProps<Multiple extends boolean | undefined> = {
  canSelectMultiple?: Multiple;
  className?: string;
  handleChange: (newValue: SearchSelectValue<Multiple>) => void;
  handleUpdateSearch?: (inputValue: string | null) => void;
  error?: string | false;
  touched?: boolean;
  label?: string;
  id?: string;
  name: string;

  /**
   * Enables debouncing for update search events. This is forced to be true when
   * pagination is used. Specifically, when 'onLoadMore' is set.
   */
  isApiSearch?: boolean;

  isClearDisabled?: boolean;
  isDisabled?: boolean;

  /** 'isEmptyAllowed' only prevents empty fields for single selection. */
  isEmptyAllowed?: boolean;

  isFinalPage?: boolean;
  isLoadingMore?: boolean;
  minCharsForSearch?: number;
  onLoadMore?: () => void;
  options: OptionContent[];
  placeholder?: string;
  value: SearchSelectValue<Multiple>;
  qa?: string;
};

type SearchSelectValue<Multiple> = Multiple extends false | undefined
  ? OptionContent | null
  : OptionContent[];

export function SearchSelect<Multiple extends boolean | undefined>({
  canSelectMultiple,
  className,
  handleChange,
  handleUpdateSearch,
  touched,
  error,
  id,
  isApiSearch,
  isClearDisabled,
  isDisabled,
  isEmptyAllowed = true,
  isFinalPage,
  isLoadingMore,
  minCharsForSearch = 3,
  onLoadMore,
  options,
  placeholder,
  value,
  name,
  label,
  qa,
}: SearchSelectProps<Multiple>) {
  const textField = useRef<TextFieldRef | null>(null);
  const [inputValue, setInputValue] = useState("");
  const isInputValueControlled = !!handleUpdateSearch;
  const isLoadMoreEnabled = onLoadMore !== undefined;
  const isFilterEnabled = !isLoadMoreEnabled;
  const shouldDebounceSearch = isApiSearch || isLoadMoreEnabled;
  const isOneOfMultipleSelected =
    canSelectMultiple && (value as OptionContent[]).length > 0;
  const debouncedHandleUpdateSearch = useDebounce({
    delayAmount: 500,
    method: (query) => handleUpdateSearch && handleUpdateSearch(query),
  });
  const [isLoadingSensorVisible, setIsLoadingSensorVisible] = useState(false);
  const priorOptions = usePrevious(options);
  const priorInputValue = usePrevious(inputValue);

  const localHandleChange = (
    event: ChangeEvent<{}>,
    value: AutocompleteValue<OptionContent, Multiple, boolean, false>,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<OptionContent>
  ) => {
    if (reason === "removeOption") {
      // Ignore back space to remove selections. Since our tags are displayed
      // above the caret, this control method wasn't intuitive.
      return;
    }
    handleChange && handleChange(value as SearchSelectValue<Multiple>);
  };

  const handleInputChange = (
    event: React.ChangeEvent<{}>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    setInputValue(value);
    const handleUpdate = shouldDebounceSearch
      ? debouncedHandleUpdateSearch
      : handleUpdateSearch;

    if (handleUpdate) {
      handleUpdate(value.length >= minCharsForSearch ? value : null);
    }
  };

  useEffect(() => {
    if (
      priorInputValue &&
      priorInputValue !== inputValue &&
      priorOptions &&
      options.length !== priorOptions.length
    ) {
      if (isLoadingSensorVisible && onLoadMore) {
        // Assume that we just finished loading, because options changed. if the
        // loading sensor is still visible, we won't receive a visibility change
        // event. So, trigger a load manually.
        onLoadMore();
      }
    }
  }, [isLoadingSensorVisible, options, priorOptions, priorInputValue]);

  useEffect(() => {
    if (!canSelectMultiple && value && "label" in (value as OptionContent)) {
      setInputValue((value as OptionContent).label);
    }
  }, [value]);

  return (
    <FieldMessagingWrapper error={error}>
      {label && <Label htmlFor={name}>{label}</Label>}
      <Autocomplete
        ChipProps={{
          classes: {
            root: styles.chipRoot,
          },
        }}
        classes={{
          endAdornment: styles.endAdornment,
          listbox: styles.listbox,
          noOptions: styles.noOptions,
          option: styles.option,
          paper: styles.paper,
        }}
        className={className}
        clearOnBlur={true}
        disabled={isDisabled}
        disableClearable={isClearDisabled || !isEmptyAllowed}
        disableListWrap={true}
        filterOptions={isFilterEnabled ? undefined : (options) => options}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        id={id}
        inputValue={isInputValueControlled ? inputValue : undefined}
        ListboxComponent={isLoadMoreEnabled ? Listbox : undefined}
        ListboxProps={
          {
            isFinalPage,
            isLoadingMore,
            onChangeVisibility: setIsLoadingSensorVisible,
            onLoadMore,
          } as HTMLAttributes<HTMLElement>
        }
        multiple={canSelectMultiple}
        onChange={localHandleChange}
        onInputChange={isInputValueControlled ? handleInputChange : undefined}
        options={options}
        popupIcon={
          <Icon
            type="arrow_drop_down"
            className={joinClassNames(
              styles.popupIndicator,
              isDisabled && styles.popupIndicatorDisabled
            )}
          />
        }
        renderInput={(params) => (
          <TextField
            hasError={notFalse(touched) && !!error}
            isExpanded={isOneOfMultipleSelected}
            placeholder={placeholder}
            ref={textField}
            {...params}
            qa={qa ? `${qa}-${Components.SearchInput}` : undefined}
          />
        )}
        renderTags={(value) => (
          <SelectionList
            className={styles.selectionList}
            handleChangeSelection={
              handleChange as (selectedOptions: OptionContent[]) => void
            }
            inputElement={textField.current}
            options={value as OptionContent[]}
          />
        )}
        value={
          value as AutocompleteValue<OptionContent, Multiple, boolean, false>
        }
      />
    </FieldMessagingWrapper>
  );
}
