import React, { FC } from "react";
import { ElementType, QAProps } from "../../qa-slugs";
import styles from "./SuccessText.module.scss";

export interface SuccessTextProps extends QAProps {
  children?: React.ReactNode;
}
const SuccessText: FC<SuccessTextProps> = ({ children, qa }) => (
  <span
    className={styles.successText}
    data-testid={qa ? `${qa}-${ElementType.Text}` : undefined}
  >
    {children}
  </span>
);

export default SuccessText;
