import {
  API,
  DefenseDocumentSaveVm,
  ResourceDTO,
  useAsyncEffect,
} from "@rtslabs/field1st-fe-common";
import { FormikHelpers } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../Loader/Loader";
import { ContentWrapper } from "../../Wrappers/Wrappers";
import { uploadDefenseDocuments } from "../../../api/formBuilder/defenseResource";
import { FormikResource } from "./FormikResource";
import { getOeTagsByQuery, ResourceValues } from "./resourceHelpers";
import {
  errorToastOptions,
  Toast,
  ToastStatus,
  updateToast,
} from "../../Toast/Toastify";
import { toast } from "react-toastify";
import { PageHeader } from "../../PageHeader/PageHeader";

const initialResource: ResourceValues = {
  description: "",
  title: "",
  tags: [],
  defenseDocuments: [],
  resourceType: -1,
  status: "ACTIVE",
};

const EditResource = () => {
  const [resource, setResource] = useState<ResourceValues>(initialResource);
  const [error, setError] = useState<string>();
  const [resourceSummary, setResourceSummary] = useState<ResourceDTO>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  // get resource id from url params
  const { id: resourceId } = useParams<{ id: string }>();
  const navigate = useNavigate();

  useAsyncEffect(async () => {
    if (resourceId) {
      setIsLoading(true);
      const res = await API.getResource({ id: Number(resourceId) });
      setResourceSummary(res);
      if (res.resourceType === "OPERATIONAL_EXPERIENCE") {
        const oe = await API.getOperationalExperience({
          id: Number(resourceId),
        });
        setResource({
          ...oe,
          title: oe.incident,
          resourceType: "OPERATIONAL_EXPERIENCE",
        });
      } else {
        const defense = await API.getDefense({ id: Number(resourceId) });
        setResource({ ...defense, resourceType: "DEFENSE" });
      }
    } else {
      setResource(initialResource);
    }
    setIsLoading(false);
  }, [resourceId]);

  const currentPathName = resourceId
    ? `Edit Resource${resourceSummary ? `: ${resourceSummary.title}` : ""}`
    : "Add Resource";

  const handleSearchOeTags = async (search: string) => {
    const results = await getOeTagsByQuery(search);
    return results;
  };

  async function handleSubmitForm(
    values: ResourceValues,
    { setFieldValue }: FormikHelpers<ResourceValues>
  ) {
    // We need local error, setError won't set error on same cycle -- GK
    let _error = false;

    // if it's a defense and there are files to upload, upload them and re-submit
    if (values?.resourceType === "DEFENSE") {
      try {
        const docsResponse = await uploadDefenseDocuments(
          values.id || 0,
          values.newDocuments || []
        );
        const defenseDocuments: DefenseDocumentSaveVm[] = [
          ...docsResponse,
          ...(values.defenseDocuments || []),
        ];
        setFieldValue("defenseDocuments", defenseDocuments);
        setFieldValue("newDocuments", []);
        await API.saveDefense({
          defense: {
            id: values.id,
            description: values.description!,
            questionSelectionIds: values.questionSelections?.map((s) => s.id),
            status: "ACTIVE",
            title: values.title!,
            defenseDocuments,
          },
        });
      } catch (e) {
        console.error(e);
        _error = true;
        setError("Problem while updating defense");
      }
    }

    // if it's an OE
    if (values?.resourceType === "OPERATIONAL_EXPERIENCE") {
      try {
        await API.saveOperationalExperience({
          oe: {
            id: values.id,
            incident: values.title!,
            description: values.description!,
            status: values.status,
            tags: values.tags?.map((t) => t.id),
          },
        });
      } catch (e) {
        _error = true;
        console.error(e);
        setError("There was a problem saving operational experience");
      }
    }

    if (!_error) {
      navigate("/content/resources");
    }
  }

  useEffect(() => {
    if (!!error) {
      updateToast(
        <Toast status={ToastStatus.Error} message={error} />,
        "resourceError",
        { ...errorToastOptions, onClose: () => setError("") }
      );
    }

    return () => toast.dismiss("resourceError");
  }, [error]);

  const paths = [
    {
      pathName: "Content",
      href: "/content/resources",
    },
    {
      pathName: "Resources",
      href: "/content/resources",
    },
    {
      pathName: currentPathName,
      href: `/content/resources/${resourceId ? resourceId : "new"}`,
    },
  ];

  return (
    <ContentWrapper id="mainContent">
      <PageHeader pageTitle={currentPathName} paths={paths} />

      <Loader overlay loading={isLoading}>
        <FormikResource
          initialValues={resource}
          handleSubmitForm={handleSubmitForm}
          onSearch={handleSearchOeTags}
          resourceSummary={resourceSummary}
        />
      </Loader>
    </ContentWrapper>
  );
};

export default EditResource;
