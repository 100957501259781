import React, { FC } from "react";
import { sortBy } from "lodash";
import {
  API,
  DocumentParticipantSaveVm,
  DocumentParticipantVm,
  DocumentVm,
  SignatureType,
} from "@rtslabs/field1st-fe-common";

import { FormSection } from "../FormSection";
import Signature from "./Signature";
import styles from "./Signatures.module.scss";
import { useAppSelector } from "../../../../redux/reduxHooks";
import { selectIsPreview } from "../../../../redux/document/documentSelectors";
import { uploadSignature } from "../../../../helpers/attachment.helpers";
import { useFormikContext } from "formik";
import { FieldMessagingWrapper } from "../FieldMessagingWrapper";

export interface UpdateSignatureArgs {
  participantId: number;
  signatureType?: SignatureType;
  signatureTextValue?: string;
  signatureUrl?: string;
  currentTime?: string;
}

interface SignatureProps {
  error?: string;
  allowTypedEmails: boolean;
  participants: DocumentParticipantVm[] | DocumentParticipantSaveVm[];
  onUpdateSignature: (signature: UpdateSignatureArgs) => Promise<void>;
}

const Signatures: FC<SignatureProps> = ({
  error,
  allowTypedEmails,
  onUpdateSignature,
  participants,
}) => {
  const isPreview = useAppSelector(selectIsPreview);
  const { values } = useFormikContext<DocumentVm>();

  const uploadSignatureData = async (signatureDataUrl: string) => {
    const { readableUrl, writableUrl } = await API.getWritableSignatureUrl({
      documentId: values.id,
    });
    if (!readableUrl || !writableUrl) {
      throw new Error("Failed to get a writable signature URL.");
    }
    await uploadSignature(writableUrl, signatureDataUrl);
    return readableUrl;
  };

  async function handleAddDrawnSignatureUrl(
    participantId: number,
    signatureDataUrl: string
  ): Promise<void> {
    const readableUrl = isPreview
      ? signatureDataUrl
      : await uploadSignatureData(signatureDataUrl);
    await onUpdateSignature({
      participantId,
      signatureType: SignatureType.DRAWN,
      currentTime: new Date().toISOString(),
      signatureUrl: readableUrl,
    });
  }

  function handleAddTypedSignature(
    participantId: number,
    signatureTextValue: string
  ) {
    onUpdateSignature({
      participantId,
      signatureType: SignatureType.TYPED, // @TODO shouldn't this come from the signature widget properties?
      currentTime: new Date().toISOString(),
      signatureTextValue,
    });
  }

  function handleClearSignature(participantId: number) {
    onUpdateSignature({ participantId });
  }

  const sortedParticipants = sortBy(participants, "lastName");

  return (
    <FieldMessagingWrapper error={error}>
      <FormSection title="Signatures" hasError={!!error}>
        {sortedParticipants.map((participant) => (
          <div className={styles.signature} key={participant.participantId}>
            <Signature
              participantId={`${participant.participantId}`}
              label={participant.name!}
              error={!!error}
              allowTypedEmails={allowTypedEmails}
              signatureTextValue={participant.signatureTextValue ?? ""}
              drawnSignatureUrl={participant.signatureUrl ?? undefined}
              onAddDrawnSignature={(signatureDataUrl) =>
                handleAddDrawnSignatureUrl(
                  participant.participantId!,
                  signatureDataUrl
                )
              }
              onUpdateTypedSignature={(signatureTextValue) =>
                handleAddTypedSignature(
                  participant.participantId!,
                  signatureTextValue
                )
              }
              onClearSignature={() =>
                handleClearSignature(participant.participantId!)
              }
            />
          </div>
        ))}
      </FormSection>
    </FieldMessagingWrapper>
  );
};

export default Signatures;
