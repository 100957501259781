import React, { FC } from "react";
import { QAProps } from "shared/src/qa-slugs";
import styles from "./StatsCard.module.scss";
import { useNavigate } from "react-router-dom";
import { RenderValue } from "./StatsRenderValue";
import { joinClassNames } from "shared/src/helpers/theme.helpers";

interface StatsCardProps extends QAProps {
  header: string;
  value: number;
  notAvailable?: boolean;
  linkTo?: string;
}

export const StatsCard: FC<StatsCardProps> = ({
  header,
  value,
  notAvailable,
  linkTo,
  qa,
}) => {
  const navigate = useNavigate();
  const clickable = !!linkTo;

  return (
    <div
      className={joinClassNames(
        styles.statsWrapper,
        clickable && styles.clickableStyle
      )}
      onClick={() => clickable && navigate(linkTo)}
      data-testid={qa}
    >
      <div className={styles.header}>{header}</div>
      <RenderValue
        notAvailable={notAvailable}
        clickable={clickable}
        value={value}
      />
    </div>
  );
};
