import {
  DataSource,
  DataSourceDTO,
  SortParams,
} from "@rtslabs/field1st-fe-common";
import React from "react";
import { useNavigate } from "react-router-dom";
import BackToTop from "shared/src/components/TableUI/BackToTop/BackToTop";
import {
  Pagination,
  PaginationWrapper,
} from "shared/src/components/TableUI/Pagination/Pagination";
import { RowsPerPage } from "shared/src/components/TableUI/RowsPerPage/RowsPerPage";
import { Table } from "shared/src/components/TableUI/Table/Table";
import {
  SortableColumn,
  TableHead,
} from "shared/src/components/TableUI/TableHead/TableHead";
import { SortOrder } from "shared/src/components/TableUI/TableHeader/SortableTableHeader";
import {
  getSortDirection,
  getSortOrder,
} from "shared/src/helpers/mapping.helpers";
import { Components, ElementType } from "shared/src/qa-slugs";
import { dataSetWriteRoles } from "../../../routes/constants/permissionSets";
import NoResults from "shared/src/components/common/TableUI/NoResults";
import { DSTableRow } from "./DSTableRow";
import styles from "./DataSets.module.scss";

type Props = {
  canEdit?: boolean;
  dataSources: DataSource[];
  downloadCSV: (id: number, title?: string | null) => void;
  page: number;
  setConfirmModalOpen: (open: boolean) => void;
  setCreateModalOpen: (open: boolean) => void;
  setPage: (page: number) => void;
  setSize: (size: number) => void;
  setSort: (sort: SortParams | undefined) => void;
  setSourceSelected: (ds: DataSource) => void;
  size: number;
  sort: SortParams | undefined;
  totalPages: number;
};

/** Table for rendering Data Sets */
export const DSTable = ({
  canEdit,
  dataSources,
  downloadCSV,
  page,
  setConfirmModalOpen,
  setCreateModalOpen,
  setPage,
  setSize,
  sort,
  setSort,
  setSourceSelected,
  size,
  totalPages,
}: Props) => {
  const navigate = useNavigate();

  const handleChangeStatus = (source: DataSourceDTO) => {
    setSourceSelected(source);
    setConfirmModalOpen(true);
  };

  const handleDownloadCsv = (source: DataSourceDTO) => {
    downloadCSV(source.id!, source.title);
  };

  const handleEdit = (source: DataSourceDTO) => {
    const url =
      source.managed === "API"
        ? `/forms/data-sets/api/${source.id}`
        : source.type === "BASIC"
        ? `/forms/data-sets/basic/${source.id}`
        : `/forms/data-sets/uploaded/${source.id}/view`;
    navigate(url);
  };

  const handleSortOrderChange = (
    column: SortableColumn,
    sortOrder: SortOrder
  ) => {
    const direction = getSortDirection(sortOrder);
    setSort(direction ? [column.id, direction] : undefined);
  };

  return (
    <>
      <Table>
        <TableHead
          columns={[
            {
              id: "title",
              label: "Title & Description",
              qa: `${Components.DSTable}-${ElementType.Link}-sortByTitle`,
              className: styles.firstCell,
            },
            {
              id: "author",
              label: "Author",
              qa: `${Components.DSTable}-${ElementType.Link}-sortByAuthor`,
            },
            {
              id: "managed",
              label: "Managed",
              qa: `${Components.DSTable}-${ElementType.Link}-sortByManaged`,
            },
            {
              id: "uses",
              label: "Uses",
              qa: `${Components.DSTable}-${ElementType.Link}-sortByUses`,
            },
            {
              id: "status",
              label: "Status",
              qa: `${Components.DSTable}-${ElementType.Link}-sortByStatus`,
            },
            { id: "action", label: "", className: styles.lastCell },
          ]}
          initialSort={{
            columnId: sort ? sort[0] : "",
            order: sort ? getSortOrder(sort[1]) : "none",
          }}
          handleSortOrderChange={handleSortOrderChange}
        />
        <tbody>
          {dataSources.length > 0 ? (
            dataSources.map((source) => (
              <DSTableRow
                canEdit={canEdit}
                key={source.id}
                onChangeStatus={handleChangeStatus}
                onDownloadCsv={handleDownloadCsv}
                onEdit={handleEdit}
                source={source}
              />
            ))
          ) : (
            <NoResults
              icon="document"
              header="No Data Set found"
              body="Create a new Data Set"
              button={{
                children: "Create a Data Set",
                onClick: () => setCreateModalOpen(true),
              }}
              qa={`${Components.DataSets}-${Components.NotFound}`}
              roles={dataSetWriteRoles}
            />
          )}
        </tbody>
      </Table>
      <div className={styles.paginationWrapper}>
        <PaginationWrapper>
          <RowsPerPage
            pageSize={size}
            onClick={(size) => {
              setSize(size);
              setPage(0);
            }}
            qaBase={`${Components.DSTable}-${ElementType.SelectInput}`}
          />
          <Pagination
            currentPage={page}
            totalPages={totalPages}
            onClick={(page) => setPage(page)}
            qaBase={`${Components.DSTable}-${ElementType.Link}`}
          />
          <BackToTop
            qa={`${Components.DSTable}-${ElementType.Link}-backToTop`}
          />
        </PaginationWrapper>
      </div>
    </>
  );
};
