import {
  API,
  DataSourceUIListItem,
  DocumentQuestionResponseVm,
  DocumentVm,
  QuestionDTO,
  useDataSourceValues,
} from "@rtslabs/field1st-fe-common";
import { useFormikContext } from "formik";
import React, { FC, useMemo, useState } from "react";
import {
  createOrUpdateResponse,
  deleteResponse,
} from "../../../api/responseRequests";
import { joinClassNames } from "../../../helpers/theme.helpers";
import { ElementType } from "../../../qa-slugs";
import Label from "../../Label/Label";
import { getFlattenedItems } from "../DocumentForm/formEntity.helpers";
import CheckboxGroup, {
  CheckboxGroupOption,
} from "./CheckboxField/CheckboxGroup";
import { MinimumSelection } from "./dataSourceValues.helpers";
import MoreOptionsLoader from "./MoreOptionsLoader/MoreOptionsLoader";
import styles from "./MultiSelectField.module.scss";
import { useQuestion } from "./useQuestion";
import { FieldMessagingWrapper } from "./FieldMessagingWrapper";

export interface MultiSelectFieldOption extends CheckboxGroupOption {
  rootId?: number;
}

interface MultiSelectFieldProps {
  question: QuestionDTO;
  appPath?: string;
}

export const MultiSelectField: FC<MultiSelectFieldProps> = ({
  question,
  appPath,
}) => {
  const { values, setValues } = useFormikContext<DocumentVm>();
  const [oldDSValue, setOldDSValue] = useState<CheckboxGroupOption[]>();

  const {
    allowPhotos,
    assistiveText,
    enableComments,
    error,
    label,
    onBlur,
    qa,
    questionSelections,
    required,
    responses,
    setResponses,
    shouldUpdateForm,
  } = useQuestion(question);

  let loadMore: () => void | undefined;
  let isLoading: boolean;
  let isLastPage: boolean;
  let dataSourceUIListItems: DataSourceUIListItem[] = [];
  let options: CheckboxGroupOption[];
  const isSafetyRatingParent =
    question.title === "Safety Rating Parent Question";

  if (question.answerSource?.dataSourceKey) {
    const answerField = question.answerSource?.properties?.answerField;
    const defaultValues = useDataSourceValues({
      answerField,
      dataSourceKey: question.answerSource!.dataSourceKey!,
      getDataSourceValues: API.getDataSourceValues,
      sort: "displayOrder",
    });

    ({ loadMore, isLoading, isLastPage, dataSourceUIListItems } =
      defaultValues);
    options = buildMultiSelectOptions(
      dataSourceUIListItems.map((i) => ({
        id: i.id,
        title: i.title,
        content: i.content,
      })),
      responses,
      error
    );

    if (!!options.length) {
      if (!oldDSValue) {
        const oldDSArray: CheckboxGroupOption[] = [];
        responses.forEach((response) => {
          if (
            response &&
            !options.find((item) => item.value === response.associatedId)
          ) {
            oldDSArray.push({
              value: response.associatedId!,
              label: { text: response.answer },
            });
          }
        });

        setOldDSValue(oldDSArray);
      }

      if (!!oldDSValue) {
        options = [...oldDSValue, ...options];
      }
    }
  } else {
    options = buildMultiSelectOptions(questionSelections, responses, error);
    isLastPage = true;
    isLoading = false;
    loadMore = () => {
      throw new Error("Not a datasource. No more items to load");
    };
  }

  const selected: CheckboxGroupOption[] = useMemo(
    () =>
      responses.map((r) => ({
        id: r.id,
        value: r.associatedId || r.questionId,
        label: { text: r.answer },
        comment: r.comments ?? undefined,
        rootId: r.associatedRootId,
      })),
    [responses]
  );

  const onChange = async (option: CheckboxGroupOption, checked: boolean) => {
    const updatedResponse = {
      questionId: question.id,
      questionRootId: question.rootId,
      answer: option.label.text,
      associatedId: option.value,
      associatedRootId: option?.rootId,
      comments: option.comment,
      timeAnswered: new Date().toISOString(),
    };

    if (checked) {
      const addedResponse = shouldUpdateForm
        ? updatedResponse
        : await createOrUpdateResponse(values.id, updatedResponse);
      await setResponses([...responses, addedResponse], option.content);
    } else {
      const responseIdToDelete = responses.find(
        (r) => r.associatedId === option.value
      )?.id;
      if (!shouldUpdateForm && responseIdToDelete)
        await deleteResponse(values.id, responseIdToDelete);
      const updatedResponses = await setResponses(
        responses.filter((r) => r.associatedId !== option.value),
        option.content
      );

      if (isSafetyRatingParent) {
        // finds Safety Rating MultiSelect Selection
        const safetySelection = question.selections?.find(
          (s) => s.rootId === updatedResponse.associatedRootId
        );

        // finds all Safety Rating child questions and maps selection rootIds
        const selectionRatingResponseRootIds = (
          getFlattenedItems(values.form.sections, "QUESTION", [
            "RATING",
          ]) as QuestionDTO[]
        )
          .filter((s) => s.parentWidgetRootId === question.parentWidgetRootId)
          .filter(
            (s) => s.parentWidgetSelectionRootId === safetySelection?.rootId
          )
          .flatMap((q) => q.selections)
          .map((r) => r?.rootId);

        const selectedRatingResponses = values.responses.filter((r) =>
          selectionRatingResponseRootIds.find(
            (s) => s === r.associatedRootId || s === r.associatedId
          )
        );

        // removes ratingResponses assocated with safetySelection
        for (const ratingResponse of selectedRatingResponses) {
          if (!shouldUpdateForm && ratingResponse.id)
            await deleteResponse(values.id, ratingResponse.id);
        }

        // filters out removed ratingResponses
        const newResponses = updatedResponses.filter(
          (r) =>
            !selectionRatingResponseRootIds.find(
              (s) => s === r.associatedRootId || s === r.associatedId
            )
        );

        setValues(
          {
            ...values,
            responses: newResponses,
          },
          false
        );
      }
    }
  };

  async function handleChangeComment(
    comments: string,
    responseToUpdate?: DocumentQuestionResponseVm
  ) {
    if (responseToUpdate) {
      const updatedResponse = shouldUpdateForm
        ? { ...responseToUpdate, comments }
        : await createOrUpdateResponse(values.id, {
            ...responseToUpdate,
            comments,
          });
      await setResponses([
        ...responses.filter(
          (r) => r.associatedId !== updatedResponse.associatedId
        ),
        updatedResponse,
      ]);
    }
  }

  async function handleClearComment(
    responseToUpdate?: DocumentQuestionResponseVm
  ): Promise<void> {
    if (responseToUpdate) {
      const updatedResponse = shouldUpdateForm
        ? { ...responseToUpdate, comments: "" }
        : await createOrUpdateResponse(values.id, {
            ...responseToUpdate,
            comments: "",
          });
      await setResponses([
        ...responses.filter(
          (r) => r.associatedId !== updatedResponse.associatedId
        ),
        updatedResponse,
      ]);
    }
  }

  return (
    <FieldMessagingWrapper assistiveText={assistiveText} error={error}>
      <div onBlur={onBlur} className={joinClassNames(error && styles.error)}>
        <Label required={required} hasError={!!error}>
          {label}
        </Label>
        <CheckboxGroup
          options={options}
          selected={selected}
          onChange={onChange}
          onUpdateComment={handleChangeComment}
          onClearComment={handleClearComment}
          enableComments={enableComments}
          allowPhotos={isSafetyRatingParent ? false : allowPhotos}
          photoButtonPerOption={
            question.subType === "MULTI_SELECT" &&
            !isSafetyRatingParent &&
            allowPhotos
          }
          qa={`${qa}-${ElementType.CheckboxGroup}`}
          questionRootId={question.rootId}
          responses={responses}
          appPath={appPath}
        />
        {!isLastPage && (
          <MoreOptionsLoader
            onLoadMore={loadMore}
            isLoadingMore={isLoading}
            isFinalPage={isLastPage}
          />
        )}
      </div>
    </FieldMessagingWrapper>
  );
};

function buildMultiSelectOptions(
  questionSelections: MinimumSelection[],
  responses: DocumentQuestionResponseVm[],
  error: string
): CheckboxGroupOption[] {
  return questionSelections?.map((s) => ({
    value: s.id,
    label: {
      icon: s.properties?.icon,
      text: s.title,
    },
    hideLabel: s.properties?.hideChoiceLabel,
    rootId: s.rootId,
    commentRequired: s.properties?.commentRequired,
    commentError: error,
    comment:
      responses.find((qr) => (qr.associatedId || qr.questionId) === s.id)
        ?.comments || "",
    content: s.content,
  }));
}
