import {
  ActivationStatusVm,
  API,
  ParticipantSummary,
  UserStatus,
} from "@rtslabs/field1st-fe-common";
import React, { FC, useState } from "react";
import { DeleteUserFailure } from "./modalContent/DeleteUserFailure";
import { DeleteUserSuccess } from "./modalContent/DeleteUserSuccess";
import { GenericModal } from "shared/src/components/Generic/Modal/GenericModal";
import { DeleteUserConfirmation } from "./modalContent/DeleteUserConfirmation";

interface Props {
  anchorUser?: ParticipantSummary;
  open: boolean;
  onClose: () => void;
}

export const DeleteUserModal: FC<Props> = ({ anchorUser, onClose, open }) => {
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState<"CONFIRM" | "SUCCESS" | "FAIL">("CONFIRM");

  const handleSubmit = () => {
    const payload: ActivationStatusVm = anchorUser
      ? {
          isActive: false, // Function sets it to opposite
          participantId: anchorUser.id,
          userId: anchorUser.userId,
          status: UserStatus.DELETED,
        }
      : {};
    setLoading(true);
    API.changeActivationStatus({ vm: payload })
      .then(() => {
        setStep("SUCCESS");
      })
      .catch(() => setStep("FAIL"))
      .finally(() => setLoading(false));
  };

  let title: string;

  switch (step) {
    case "SUCCESS":
      title = "User has been deleted";
      break;
    case "FAIL":
      title = "Error - Unable to delete user";
      break;
    default:
      title = "Delete User";
  }

  return (
    <GenericModal isOpen={open} onClose={onClose} title={title}>
      <>
        {step == "CONFIRM" && (
          <DeleteUserConfirmation
            displayName={anchorUser?.fullName ?? "this user"}
            loading={loading}
            onCancel={onClose}
            onSubmit={handleSubmit}
          />
        )}

        {step === "SUCCESS" && <DeleteUserSuccess onClose={onClose} />}
        {step === "FAIL" && <DeleteUserFailure onClose={onClose} />}
      </>
    </GenericModal>
  );
};
