import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";

import { Text, TextProps } from "shared/src/components/clientAdmin/styles";
import { toRgba, remCalc } from "shared/src/themes/helpers";

export const DeleteDocumentButton: React.FC<TextProps> = (props) => {
  const theme = useContext(ThemeContext);
  return <Text color={theme.colors.error} fontSize="0.9rem" {...props} />;
};

export const IdLabel: React.FC<TextProps> = (props) => {
  const theme = useContext(ThemeContext);
  return (
    <Text
      color={theme.masterColors.darkGrey}
      fontSize="0.875rem"
      fontWeight={400}
      {...props}
    />
  );
};

export const ProfileInformationWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  max-width: 500px;
`;

export const ProfileName = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-size: 2.25rem;
  letter-spacing: 0.63px;
  text-align: left;
`;

export const ProfileContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const ProfileContent = styled.div.attrs({ className: "col-6 pl-0" })`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  max-width: 250px;
`;

export const ProfileContentHeader = styled.div`
  color: ${({ theme }) => theme.masterColors.darkGrey};
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 1.88px;
  text-transform: uppercase;
`;

type ProfileContentValueType = {
  active?: boolean;
};
export const ProfileContentValue = styled.div<ProfileContentValueType>`
  color: ${({ active, theme }) =>
    active ? theme.colors.primary : theme.colors.black};
  cursor: ${({ active }) => active && "pointer"};
  font-size: 1rem;
  letter-spacing: 0.28px;
  margin-top: 8px;
  // max-width: 765px;
  // min-width: 300px;
  text-align: left;
  text-decoration: ${({ active }) => active && "underline"};
`;

export const ProfilePermissionsCopy = styled.p`
  color: ${({ theme }) => theme.masterColors.darkGrey};
  font-size: 1rem;
  letter-spacing: 0.15px;
  margin-top: 24px;
`;

export const ProfilePermissionsHeader = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.19px;
  margin: 24px 0;
`;

// This wraps user's info (email, group, ..) and Total Created Documents by Form Type
export const ProfileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: -33px;
`;

export const StarIcon = styled.i.attrs({
  className: "icon-icons8-star-filled",
})`
  color: ${({ theme }) => theme.masterColors.warning};
  font-size: 0.875rem;
  padding-right: ${remCalc(2)};
`;

export const TableData: React.FC<TextProps> = (props) => {
  const theme = useContext(ThemeContext);
  return (
    <Text
      color={toRgba(theme.colors.black, 0.6)}
      fontSize="0.875rem"
      fontWeight={400}
      {...props}
    />
  );
};

export const Time: React.FC<TextProps> = (props) => {
  const theme = useContext(ThemeContext);
  return (
    <Text
      color={theme.masterColors.mediumGrey}
      fontSize="0.875rem"
      fontWeight={400}
      {...props}
    />
  );
};

export const DateRangeWrapper = styled.div`
  display: flex;
  padding-top: 8px;
  margin-left: 15px;
`;
