import React, { FC } from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import { Components, QAProps } from "../../qa-slugs";

interface PageBreadcrumbsProps extends QAProps {
  paths: { pathName: string; href: string }[];
}

export const PageBreadcrumbs: FC<PageBreadcrumbsProps> = ({
  paths,
  qa = "PageBreadcrumbs",
}) => {
  return (
    <div data-testid={`${qa}-${Components.Breadcrumbs}`}>
      <Breadcrumbs paths={paths} />
    </div>
  );
};
