import { API, useAPI } from "@rtslabs/field1st-fe-common";
import React, { FC, useMemo } from "react";
import Loader from "shared/src/components/Loader/Loader";
import { Components, ElementType } from "shared/src/qa-slugs";
import { StatsCard } from "../../common/StatsCard/StatsCard";
import styles from "./UsersStats.module.scss";

interface UsersStatsProps {
  participantQueryParams: API.ParticipantQueryParams;
  status: number | undefined;
}

export const UsersStats: FC<UsersStatsProps> = ({
  participantQueryParams,
  status,
}) => {
  const statsCall = useAPI(
    API.getParticipantSummaryAggregateData,
    participantQueryParams
  );

  // Values for stat cards above the table
  const statsMap = useMemo(() => {
    const activeUsersNotAvailable =
      status !== undefined && ![0, 1].includes(status);
    return [
      {
        key: "numberOfAllUsers",
        name: "Number of Users",
      },
      {
        key: "numberOfGroups",
        linkTo: "/people/groups",
        name: "Number of Groups",
      },
      {
        key: "numberOfActiveUsers",
        name: "Number of Active Users",
        notAvailable: activeUsersNotAvailable,
      },
    ];
  }, [status]);

  return (
    <Loader loading={statsCall.isLoading}>
      <div className={styles.statsContainer}>
        {statsMap.map((e, i) => (
          <StatsCard
            qa={`${Components.UsersStats}-${ElementType.Card}-${e.key}`}
            header={e.name}
            key={i}
            linkTo={e.linkTo}
            notAvailable={e.notAvailable}
            value={statsCall.data?.[e.key]}
          />
        ))}
      </div>
    </Loader>
  );
};
