import { FormTypeDTO } from "@rtslabs/field1st-fe-common";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { IconBox } from "shared/src/components/Icon/IconBox";
import { TableCell } from "shared/src/components/TableUI/TableCell/TableCell";
import { TableRow } from "shared/src/components/TableUI/TableRow/TableRow";
import { Components, ElementType } from "shared/src/qa-slugs";
import TableRowMenu from "../../common/TableUI/TableRowMenu/TableRowMenu";
import styles from "./FormTypeRow.module.scss";
import stylesFT from "./FormTypes.module.scss";
import { joinClassNames } from "shared/src/helpers/theme.helpers";

interface Props {
  formType: FormTypeDTO;
  hasFormsRole: boolean;
  onDelete: (formType: FormTypeDTO) => void;
  onEditView: (formType: FormTypeDTO) => void;
}

export const FormTypeRow: FC<Props> = ({
  formType,
  hasFormsRole,
  onDelete,
  onEditView,
}) => {
  const { t } = useTranslation();

  const deleteFormType = () => {
    onDelete(formType);
  };

  const editViewFormType = () => {
    onEditView(formType);
  };

  return (
    <TableRow className={styles.row}>
      <TableCell>
        <div
          className={joinClassNames(styles.nameWrapper, stylesFT.firstCell)}
          data-testid={`${Components.FormTypes}-${ElementType.Text}-name-${formType.id}`}
        >
          <IconBox
            className={styles.icon}
            color={formType.iconColor ?? undefined}
            type={formType.iconName || "add_file"}
          />
          {formType.name}
        </div>
      </TableCell>
      <TableCell
        className={joinClassNames(styles.actionsCell, stylesFT.lastCell)}
      >
        <TableRowMenu
          buttonId={`${Components.FormTypes}-${ElementType.Button}-actions-${formType.id}`}
          label={t("common:table.rowMenuButtonLabel")}
          menuId={`${Components.FormTypes}-${ElementType.Menu}-actions-${formType.id}`}
          menuOptions={
            hasFormsRole
              ? [
                  {
                    iconType: "edit",
                    id: `${Components.FormTypes}-${ElementType.MenuItem}-edit-${formType.id}`,
                    label: "Edit form type",
                    onSelect: editViewFormType,
                  },
                  {
                    iconType: "trash",
                    id: `${Components.FormTypes}-${ElementType.MenuItem}-delete-${formType.id}`,
                    label: "Delete form type",
                    onSelect: deleteFormType,
                    variant: "error",
                  },
                ]
              : [
                  {
                    iconType: "edit",
                    id: `${Components.FormTypes}-${ElementType.MenuItem}-edit-${formType.id}`,
                    label: "View form type",
                    onSelect: editViewFormType,
                  },
                ]
          }
        />
      </TableCell>
    </TableRow>
  );
};
