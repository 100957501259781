import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import GlobalStyle from "shared/src/themes/GlobalStyle";
import {
  API,
  ApplicationConfigKey,
  CoreRedux,
  Functions,
  roles,
  useAsyncEffect,
} from "@rtslabs/field1st-fe-common";
import AuthenticatedRoutes from "./routes/AuthenticatedRoutes";
import LoginScreen from "./components/LoginScreen";
import PasswordHelpScreen from "./components/PasswordHelpScreen";
import PasswordResetScreen from "./components/PasswordResetScreen";
import DevAppFlag from "shared/src/components/DevAppFlag";
import { ActivationScreen } from "./components/ActivationScreen";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import { configureArcGis } from "./util/arcgis";
import { setIsLoadingConfig } from "./store/arcGis/arcGisActionCreators";
import { BottomNavigationContextProvider } from "./components/navigation/Context";
import { ALL_ROLES } from "./routes/constants/permissionSets";
import { ThemeProvider } from "styled-components";
import COLORS from "shared/src/themes/colors";
import { animations } from "../../shared/src/themes/animations";

const App: React.FC = () => {
  const user = useAppSelector(CoreRedux.selectUser);
  const dispatch = useAppDispatch();
  const rehydrated = useAppSelector(CoreRedux.selectRehydrated);

  useAsyncEffect(async () => {
    await getClientAppConfigs();
  }, []);

  useAsyncEffect(async () => {
    user && (await getAuthorizedClientAppConfigs());
  }, [user]);

  const getClientAppConfigs = async () => {
    /**
     * Multiple try/catch blocks are needed because if the first request fails then
     * the subsequent requests won't be made. These requests can easily "fail" when
     * there are no app configs in an environment and the endpoint returns 404.
     */
    try {
      await dispatch(
        CoreRedux.getApplicationConfig(ApplicationConfigKey.clientConfig)
      );
    } catch (err) {
      console.error(err);
    }

    try {
      await dispatch(
        CoreRedux.getApplicationConfig(ApplicationConfigKey.logoConfigs)
      );
    } catch (err) {
      console.error(err);
    }
  };

  const getAuthorizedClientAppConfigs = async () => {
    dispatch(setIsLoadingConfig(true));

    /**
     * Multiple try/catch blocks are needed because if the first request fails then
     * the subsequent requests won't be made. These requests can easily "fail" when
     * there are no app configs in an environment and the endpoint returns 404.
     */

    try {
      const config = await dispatch(
        CoreRedux.getApplicationConfig(ApplicationConfigKey.arcGisConfig)
      );
      if (Functions.isArcGisConfig(config) && config.active) {
        await configureArcGis({ apiKey: config.properties.apiKey });
      }
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(setIsLoadingConfig(false));
    }

    try {
      await dispatch(
        CoreRedux.getApplicationConfig(ApplicationConfigKey.chatConfig)
      );
    } catch (err) {
      console.error(err);
    }

    try {
      await dispatch(
        CoreRedux.getApplicationConfig(ApplicationConfigKey.chatbaseConfig)
      );
    } catch (err) {
      console.error(err);
    }
  };

  // todo this could be better...
  if (!rehydrated) {
    return <div>Loading...</div>;
  }
  const userRoles = API.Environment.getRoles();
  const isAdminRole = userRoles.some((r: any) =>
    ALL_ROLES.filter((role) => role !== roles.ROLE_USER).includes(r)
  );

  const interfaceColors = isAdminRole ? COLORS.adminTheme : COLORS.userTheme;

  const theme = {
    animations,
    colors: { ...COLORS.lightTheme, ...interfaceColors },
    masterColors: COLORS.masterColors,
  };

  React.useEffect(() => {
    document.documentElement.setAttribute(
      "data-theme",
      isAdminRole ? "admin" : "user"
    );
  }, [userRoles]);

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <DevAppFlag appName="Field1st" />
        <BottomNavigationContextProvider>
          <Routes>
            {/* Unprotected */}
            <Route element={<LoginScreen />} path="/login" />
            <Route element={<PasswordHelpScreen />} path="/help" />
            <Route element={<PasswordResetScreen />} path="/password-reset" />
            <Route element={<ActivationScreen />} path="/activate" />

            {/* Protected */}
            <Route element={<AuthenticatedRoutes />} path="/*" />
          </Routes>
        </BottomNavigationContextProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
