import * as React from "react";
import styled from "styled-components";
import ExpandableItemList from "../../../common/ExpandableItemList/ExpandableItemList";
import { Components, ElementType, Page } from "shared/src/qa-slugs";
import { ReportingFormStatsVm } from "@rtslabs/field1st-fe-common";
import styles from "./FormReportHeader.module.scss";

interface FormReportHeaderProps {
  formStats: ReportingFormStatsVm;
}

const GroupItemWrapper = styled.div`
  font-size: 1rem;
`;

const FormReportHeader = ({ formStats }: FormReportHeaderProps) => {
  // const dayFilterValue = (daysFilterOptions.find((o) => o.id === dateFilter) || { value: "All Time" }).value;

  return (
    <div className={styles.formReportHeader}>
      <div className={styles.formTitle}>
        <h4>Form Title</h4>
        <h2 data-testid={`${Page.Desktop}-${Components.FormReport}`}>
          {formStats.form.name}
        </h2>
      </div>

      <div className={styles.formSubtitleWrapper}>
        <div
          data-testid={`${Components.ReportHeader}-${ElementType.Label}-formType`}
          className={styles.formSubtitle}
        >
          <h4>Form Type</h4>
          {formStats.form.type.name}
        </div>

        <div className={styles.formSubtitle}>
          <h4>Groups</h4>
          <ExpandableItemList
            qa={`${Components.ReportHeader}-${ElementType.Text}-group`}
            items={formStats.clientGroups.map((group) => group.name) || []}
            Component={GroupItemWrapper}
          />
        </div>
      </div>
    </div>
  );
};

export default FormReportHeader;
