import React, { FC, useMemo, useState } from "react";
import { orderBy } from "lodash";
import * as S from "./styles";
import { DownloadResultsButton } from "shared/src/components/TableUI/TableSummary/DownloadResultsButton";
import SafetyResponseCommentsDrawer from "./drawer/SafetyResponseCommentsDrawer";
import { SmallText } from "shared/src/components/clientAdmin/styles";
import { FormReportSafetyResponses, useFetchComments } from "../helpers";
import { API } from "@rtslabs/field1st-fe-common";
import styles from "../ResponseSummaries.module.scss";
import {
  BarChartResponse,
  BarChartResponseColorProps,
} from "../BarChartResponse";
import { joinClassNames } from "shared/src/helpers/theme.helpers";

interface SafetyReportProps {
  formId: number;
  totalResponses: number;
  ratingsFilter: string[];
  mappedRiskResponses: FormReportSafetyResponses;
}

const SafetySectionDetails = ({ question, openDrawer }) => (
  <div>
    <div style={{ display: "flex", alignItems: "flex-start" }}>
      <S.QuestionText className="font-weight-bold mr-4">
        {question.responseCount}
      </S.QuestionText>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <S.QuestionText>{question.question.title}</S.QuestionText>
        <S.AreaText>{`Area: ${question.area}`}</S.AreaText>
      </div>
      <SmallText className="d-flex ml-auto caExportAllResponsesLink">
        <S.StyledDownloadButton onClick={openDrawer}>
          View Comments
        </S.StyledDownloadButton>
        <S.SendCommentIcon />
      </SmallText>
    </div>
  </div>
);

export const SafetyReport: FC<SafetyReportProps> = ({
  formId,
  totalResponses,
  mappedRiskResponses,
}) => {
  const colors: BarChartResponseColorProps[] = [
    { textColor: "#CD0000", backColor: "#FFEBE0" },
    { textColor: "#F9AC19", backColor: "#FFF9EF" },
    { textColor: "#0172CE", backColor: "#E5F1FA" },
    { textColor: "#00853C", backColor: "#E5FFF2" },
  ];
  const keyIndexs = Object.keys(mappedRiskResponses);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerRiskLevel, setDrawerRiskLevel] = useState<
    "Risk" | "Coaching Moment" | "Satisfactory" | "Best Practice"
  >("Risk");
  const [selectedQuestionIds, setSelectedQuestionIds] = useState<number[]>([]);
  const responseComments = useFetchComments(
    formId,
    mappedRiskResponses[drawerRiskLevel]
  );
  const openDrawer = (selectionIds: number[], riskLevel) => {
    setSelectedQuestionIds(selectionIds);
    setDrawerRiskLevel(riskLevel);
    setDrawerOpen(true);
  };
  const headerText = useMemo(
    () => (
      <div className="d-flex align-items-center">
        <S.Dot color={colors[keyIndexs.indexOf(drawerRiskLevel)].textColor} />
        <span className="d-block">
          {drawerRiskLevel} Comments ({responseComments.length})
        </span>
      </div>
    ),
    [drawerRiskLevel, responseComments]
  );
  const responsesLoaded =
    !!responseComments.length &&
    responseComments.length === mappedRiskResponses[drawerRiskLevel].length;
  const handleExpansionChange = (id: number) => () => {
    setSelectedQuestionIds((prevState) => {
      if (prevState.includes(id)) return prevState.filter((s) => s !== id);
      return [...prevState, id];
    });
  };

  return (
    <>
      <SafetyResponseCommentsDrawer
        closeDrawer={() => {
          setDrawerOpen(false);
          setSelectedQuestionIds([]);
        }}
        handleExpansionChange={handleExpansionChange}
        headerText={headerText}
        responseComments={responseComments}
        responsesLoaded={responsesLoaded}
        selectedQuestionIds={selectedQuestionIds}
        show={drawerOpen}
        formId={formId}
      />

      {Object.keys(mappedRiskResponses).map((riskLevel, i) => {
        const count = mappedRiskResponses[riskLevel].reduce(
          (acc, x) => acc + x.responseCount,
          0
        );

        const data = {
          label: riskLevel,
          percentage: !totalResponses
            ? 0
            : parseInt(((count / totalResponses) * 100).toFixed(0)),
          value: count,
        };

        const questions = mappedRiskResponses[riskLevel];
        const questionsSorted = orderBy(questions, ["responseCount"], ["desc"]);
        const selectionIds =
          (questions && questions.map((question) => question.selectionId)) ||
          [];
        const showAllCommentsLink = !(
          !selectionIds || selectionIds.length === 0
        );
        const showExportAllResponsesLink = !!count;

        return (
          <div
            className={joinClassNames(
              styles.responseSummaries,
              styles.safetyRatings
            )}
            key={riskLevel}
          >
            <BarChartResponse data={data} index={i} colorObject={colors[i]} />

            <div className={styles.responses}>
              <div
                className={styles.mostPopular}
              >{`Most Frequently Reported with “${riskLevel}” Rating`}</div>
              <div className={styles.viewExportAllComments}>
                {showAllCommentsLink && (
                  <SmallText className="d-block mb-1 caExportAllResponsesLink">
                    <S.StyledDownloadButton
                      onClick={() => openDrawer(selectionIds, riskLevel)}
                      bold
                    >
                      View All Comments
                    </S.StyledDownloadButton>
                  </SmallText>
                )}
                {showAllCommentsLink && showExportAllResponsesLink && "|"}
                {showExportAllResponsesLink && (
                  <SmallText className="d-block caExportAllResponsesLink">
                    <DownloadResultsButton
                      onExportPress={API.downloadQuestionSelectionComments}
                      exportParams={{
                        formId,
                        questionSelectionIds: selectionIds,
                      }}
                      iconType="export_csv"
                      isTextVariant={true}
                      mediaType="text/csv"
                      label="Export All Comments"
                    />
                  </SmallText>
                )}
              </div>
            </div>

            {questionsSorted && questionsSorted.length > 0 ? (
              <div className={styles.questionsWrapper}>
                {questionsSorted.map((question) => (
                  <SafetySectionDetails
                    key={question.selectionId}
                    openDrawer={() =>
                      openDrawer([question.selectionId], riskLevel)
                    }
                    question={question}
                  />
                ))}
              </div>
            ) : (
              <>No responses</>
            )}
          </div>
        );
      })}
    </>
  );
};
