import React from "react";
import styled, { useTheme } from "styled-components";
import styles from "./SafetyScale.module.scss";
import { Icon } from "shared/src/components/Icon/Icon";

const DayFilter = styled.span.attrs({
  className: "d-block",
})`
  color: ${({ theme }) => theme.masterColors.darkGrey};
  font-size: 0.75rem;
`;

const ConstructionIcon = () => {
  const theme = useTheme();
  return (
    <Icon color={theme.masterColors.warning} type="icons8-under_construction" />
  );
};

const ChartHeader = ({ dayFilterValue, totalResponseCount }) => (
  <div className={styles.chartHeaderContainer}>
    <div>
      <span className={styles.chartTitle}>Safety Scale Responses</span>
      <DayFilter>{dayFilterValue}</DayFilter>
      <div className={styles.countIconWrapper}>
        <span className={styles.safetyCount}>{totalResponseCount}</span>
        <div className={styles.iconWrapper}>
          <ConstructionIcon />
        </div>
      </div>
    </div>
  </div>
);

export default ChartHeader;
