import {
  API,
  CoreRedux,
  FeedbackSaveVm,
  PlatformType,
  useAPI,
} from "@rtslabs/field1st-fe-common";
import { Formik, FormikHelpers } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "shared/src/components/Loader/Loader";
import { Select, SelectOption } from "shared/src/components/Select/Select";
import { TextInput } from "shared/src/components/TextInput/TextInput";
import { getGitCommitHash, getVersionName } from "shared/src/helpers/version";
import { Components, ElementType } from "shared/src/qa-slugs";
import * as S from "./styles";
import { GenericButton } from "shared/src/components/Generic/Button/GenericButton";

// Types
type FeedbackOptions = Array<SelectOption<number>>;

interface FormikValues {
  selectFeedbackType: number;
  commentInput: string;
}

// Formik State Initial Values
const initialValues: FormikValues = {
  selectFeedbackType: -1,
  commentInput: "",
};

// Formik Validation
type ErrorsType = {
  selectFeedbackType?: string;
  commentInput?: string;
};

const validate = (values) => {
  const errors: ErrorsType = {};

  // Check if selection has not been made
  if (values.selectFeedbackType < 0) {
    errors.selectFeedbackType = "Please select a feedback type";
  }
  // Check if comment is less than 3 characters
  if (values.commentInput.length < 3) {
    errors.commentInput = "Please provide a meaningful comment";
  }

  return errors;
};

const ReportFeedbackForm: React.FC = () => {
  const [feedbackOptions, setFeedbackOptions] = useState<FeedbackOptions>([]);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [submitStatus, setSubmitStatus] = useState<
    "success" | "failure" | null
  >(null);

  const navigate = useNavigate();
  const user = useSelector(CoreRedux.selectUser);

  const { data, isLoading } = useAPI(API.getAllFeedbackTypes, {
    sort: "displayOrder,asc",
  });

  useEffect(() => {
    if (data) {
      setFeedbackOptions(
        data.map((ft) => ({ label: ft.selectionText, value: ft.id }))
      );
    }
  }, [data]);

  const _handleSubmit = (
    values: FormikValues,
    resetForm: FormikHelpers<FormikValues>["resetForm"]
  ) => {
    const { selectFeedbackType, commentInput } = values;

    if (user?.user.id && user.authorities) {
      // Get browserInfo from navigator object
      let browserInfo = "";
      for (const obj in navigator) {
        browserInfo += obj + ":" + JSON.stringify(navigator[obj]) + "\n";
      }
      const payload: FeedbackSaveVm = {
        browserInfo,
        clientGroupId: user.primaryGroupId,
        feedback: commentInput,
        feedbackTypeId: selectFeedbackType,
        platform: PlatformType.WEB,
        uiGitCommit: getGitCommitHash(),
        uiVersion: getVersionName(),
      };

      setSubmitLoading(true);
      setSubmitStatus(null);

      API.saveFeedback({ feedback: payload })
        .then(() => {
          // Change status to success
          setSubmitStatus("success");
          // And reset the form
          resetForm();
          // Redirect to /help-desk
          setTimeout(() => navigate("/help-desk"), 2000);
        })
        .catch(() => setSubmitStatus("failure"))
        .finally(() => setSubmitLoading(false));
    }
  };

  const handleSubmitStatus = (
    status: string | null,
    loading: boolean,
    defaultValue: string
  ): string => {
    //First, check if loading.
    switch (loading) {
      case true:
        return "Submitting...";
      default:
        // If not loading, check response status
        switch (status) {
          case "success":
            return "FEEDBACK SENT";
          case "failure":
            return "FAILED TO SUBMIT";
          default:
            // If not loading and do not have a response status, show default value
            return defaultValue.toUpperCase();
        }
    }
  };

  // Toggles the placeholder hint for the comment input field depending on selected feedback type
  const handleCommentPlaceholder = (feedbackTypeId: number) => {
    const matchedFeedbackType = data?.find((ft) => ft.id === feedbackTypeId);
    return matchedFeedbackType?.placeholderText
      ? matchedFeedbackType.placeholderText
      : "Enter a detailed comment here";
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setFieldTouched, resetForm }) => {
          if (submitStatus === "success") return;
          //Set fields to touched
          setFieldTouched("selectFeedbackType", true, true);
          setFieldTouched("commentInput", true, true);

          _handleSubmit(values, resetForm);
        }}
        validate={validate}
        validateOnChange={false}
      >
        {(props) => {
          const { errors, handleSubmit, setValues, touched, values } = props;
          const { selectFeedbackType, commentInput } = values;
          return (
            <>
              <Loader loading={isLoading}>
                <form onSubmit={handleSubmit}>
                  <S.FeedbackTypeWrapper>
                    <Select
                      qa={`${Components.FeedbackForm}-${ElementType.SelectInput}`}
                      placeholder="Select a feedback type"
                      error={errors["selectFeedbackType"]}
                      label={"Type of Feedback"}
                      name={"selectFeedbackType"}
                      onChange={(option) =>
                        setValues({
                          ...values,
                          selectFeedbackType: option?.value || -1,
                        })
                      }
                      options={feedbackOptions}
                      required={true}
                      value={selectFeedbackType}
                    />
                  </S.FeedbackTypeWrapper>
                  <TextInput
                    qa={`${Components.FeedbackForm}-${ElementType.TextInput}`}
                    error={touched["commentInput"] && errors["commentInput"]}
                    rows={5}
                    label="Comment"
                    multiline={true}
                    name="commentInput"
                    onChange={(e) =>
                      setValues({ ...values, commentInput: e.target.value })
                    }
                    placeholder={handleCommentPlaceholder(selectFeedbackType)}
                    value={commentInput}
                    required={true}
                  />
                  <S.ButtonsWrapper>
                    <GenericButton
                      qa={`${Components.FeedbackForm}-${ElementType.Button}-submitFeedback`}
                      type={submitStatus !== "success" ? "submit" : undefined}
                      loading={submitLoading}
                      disabled={submitLoading}
                      iconType={
                        submitStatus === "success" ? "checkmark" : undefined
                      }
                      label={handleSubmitStatus(
                        submitStatus,
                        submitLoading,
                        "Submit Feedback"
                      )}
                    />

                    {submitStatus !== "success" && (
                      <GenericButton
                        buttonStyle="tertiary"
                        qa={`${Components.FeedbackForm}-${ElementType.Button}-cancelFeedback`}
                        onClick={() => navigate("/help-desk")}
                        disabled={submitLoading}
                        label="Cancel"
                      />
                    )}
                  </S.ButtonsWrapper>
                </form>
              </Loader>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default ReportFeedbackForm;
