import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import {
  API,
  BasicDSValue,
  CSVMapping,
  DataSource,
  DataSourceStatus,
  DataSourceType,
  roles,
} from "@rtslabs/field1st-fe-common";
import DelimitedExample from "../../../assets/png/delimited_list_example.png";
import ColumnExample from "../../../assets/png/column_headers_example.png";
import { Select, SelectOption } from "shared/src/components/Select/Select";
import s from "./styles.module.scss";
import { Components, ElementType } from "shared/src/qa-slugs";
import { TextInput } from "shared/src/components/TextInput/TextInput";
import { GenericButton } from "shared/src/components/Generic/Button/GenericButton";
import { joinClassNames } from "shared/src/helpers/theme.helpers";

/**
 * Create a basic value list for basic data source submission
 * @param dStr - string of newline delimited values
 */
const generateBasicList = (dStr: string) =>
  dStr.split("\n").map((d, i) => ({ value: d, displayOrder: i }));

/**
 * Create CSV mapping for uploaded data source submission
 * @param dStr - string of newline delimited values
 */
const generateAdvList = (dStr: string) =>
  dStr
    .split("\n")
    .map(
      (d): CSVMapping => ({
        id: null,
        columnName: d.replace("*", ""),
        required: d[d.length - 1] === "*",
      })
    )
    .filter((cm) => cm.columnName !== "");

/**
 * Returns proper button label for form submit button
 * @param editing   - data source is being edited
 * @param uploaded  - data source is uploaded
 */
function generateSubmitLabel(editing: boolean, uploaded?: boolean) {
  if (uploaded) {
    return "Save & Continue";
  }
  // basic
  if (editing) {
    return "Save";
  }
  return "Add Data Set";
}

interface Values {
  title: string;
  dataSourceKey: string;
  description: string;
  dataList: string;
  uniqueId?: SelectOption;
}

const validation = yup.object().shape({
  title: yup.string().required("Please enter a title"),
  dataSourceKey: yup.string().required("Please enter a Data Set Source Key"),
  description: yup.string(),
  dataList: yup.string().required("Please enter a list of headers or values"),
  uniqueId: yup.string(),
});

/**
 * Format an incoming data list into a EOL delimited string for rendering / user interaction
 * @param csvMappings
 */
const formatCSVMappings = (csvMappings: CSVMapping[]) =>
  csvMappings.map((m) => m.columnName).join("\n");

interface Props {
  dataSource: DataSource | null;
  dataSourceValues?: string[];
  uploaded?: boolean;
  setFormComplete?: (c: boolean) => void;
  setDataSource: (ds: DataSource) => void;
  setColumnHeaders?: (cm: CSVMapping[]) => void;
}

/** Form utilized in add/edit screens for basic and uploaded data sets */
export function DSForm({
  dataSource,
  dataSourceValues,
  uploaded,
  setFormComplete,
  setDataSource,
  setColumnHeaders,
}: Props) {
  const navigate = useNavigate();
  const readOnly = !API.Environment.hasRoleAccess([
    roles.ROLE_FORM_ARCHITECT,
    roles.ROLE_CLIENT_ADMIN,
  ]);

  const [colOptions, setColOptions] = React.useState<SelectOption[]>([]);

  /**
   * Format the request body depending on the type of Data Source being processed.
   * @param values
   */
  function formatRequestBody(values: Values): DataSource {
    let csvMappings: CSVMapping[] = [];
    // if data source is uploaded, create csv mappings. else use empty array
    if (uploaded) {
      csvMappings = generateAdvList(values.dataList);
    }
    return {
      csvMappings,
      dataSourceKey: values.dataSourceKey,
      description: values.description,
      id: dataSource?.id || null,
      title: values.title,
      softDeleteMissing: true,
      type: uploaded ? DataSourceType.ADVANCED : DataSourceType.BASIC,
      // this type check is necessary here because Formik coerces uniqueId to string behind the scenes -JA
      uniqueIdField:
        uploaded && typeof values.uniqueId === "string"
          ? values.uniqueId
          : "value",
      status: uploaded
        ? DataSourceStatus.UNPUBLISHED
        : DataSourceStatus.PUBLISHED,
    };
  }

  const submitBasicDS = async (id: number, values: BasicDSValue[]) => {
    try {
      await API.uploadDataSourceContent({
        dataSourceId: id,
        content: values,
      });
      return navigate("/forms/data-sets");
    } catch (err) {
      // TODO error handling -JA
      console.error(err);
    }
  };

  /** Format incoming data source depending on source type */
  function formatDataList() {
    // uploaded data source with csv mappings
    if (uploaded && dataSource?.csvMappings) {
      return formatCSVMappings(dataSource.csvMappings);
    }
    // basic data source values
    if (dataSourceValues) {
      return dataSourceValues.join("\n");
    }
    return "";
  }

  const formik = useFormik<Values>({
    enableReinitialize: true,
    initialValues: {
      title: dataSource?.title || "",
      dataSourceKey: dataSource?.dataSourceKey || "",
      description: dataSource?.description || "",
      dataList: formatDataList(),
      uniqueId: undefined,
    },
    onSubmit: async (values) => {
      let ds: DataSource | null = null;
      if (!dataSource) {
        // user is creating a new data set
        ds = await API.createDataSource({
          dataSource: formatRequestBody(values),
        });
      } else {
        ds = await API.updateDataSource({
          dataSource: formatRequestBody(values),
        });
      }

      setDataSource(ds);
      setColumnHeaders && setColumnHeaders(ds.csvMappings || []);
      if (!uploaded && ds.id) {
        submitBasicDS(ds.id, generateBasicList(values.dataList));
      }
      setFormComplete && setFormComplete(true);
    },
    validationSchema: validation,
  });

  React.useMemo(() => {
    let options: SelectOption<string>[] = [];
    if (formik.values.dataList !== "") {
      options = [
        ...options,
        ...formik.values.dataList.split("\n").map((opt) => ({
          value: opt.replace("*", ""),
          label: opt.replace("*", ""),
        })),
      ].filter((option) => option.value !== "");
    }
    setColOptions(options);
  }, [formik.values.dataList]);

  const [allowSubmit, setAllowSubmit] = React.useState(false);

  useEffect(() => {
    if (
      formik.values.title &&
      formik.values.dataSourceKey &&
      formik.values.dataList
    ) {
      setAllowSubmit(true);
    } else {
      setAllowSubmit(false);
    }
  }, [formik.values]);

  return (
    <div className={s.dataSetContainer}>
      {uploaded && (
        <div className={s.subtitle}>
          Every uploaded data set needs a title, a description, and a list of
          column headers. Column headers label the columns of data in your set
          and help map to fields in the CSV. After specifying the items below,
          select Save &amp; Continue.
        </div>
      )}
      <form onSubmit={formik.handleSubmit} className={s.dataSetInfo}>
        {dataSource?.id && (
          <div className={s.dataItemWrapper}>
            <span className={s.propTitle}>Data Set ID</span>
            <span className={s.dataValue}>{dataSource.id}</span>
          </div>
        )}
        <div className={s.inputWithNote}>
          <TextInput
            wrapperClassName={s.input}
            name="title"
            label="Data Set Title"
            placeholder="Title"
            onChange={formik.handleChange}
            value={formik.values.title}
            error={formik.touched.title && formik.errors.title}
            disabled={readOnly}
            qa={`${Components.DSForm}-${ElementType.TextInput}-title`}
            required
          />
          <div className={s.noteContainer}>
            <div className={s.note}>
              <span>
                <span className={s.medium}>NOTE</span>: Title and description
                are used to identify data set in Form Builder and won&apos;t
                display anywhere on the Form.
              </span>
            </div>
          </div>
        </div>
        <div className={s.inputWithNote}>
          <TextInput
            wrapperClassName={s.input}
            name="dataSourceKey"
            label="Data Set Source Key"
            placeholder="Data Set Source Key"
            onChange={formik.handleChange}
            value={formik.values.dataSourceKey}
            error={formik.touched.dataSourceKey && formik.errors.dataSourceKey}
            disabled={readOnly}
            qa={`${Components.DSForm}-${ElementType.TextInput}-key`}
            required
          />
          <div className={s.noteContainer}>
            <div className={s.note}>
              <span>
                <span className={s.medium}>NOTE</span>: The source key is used
                to add data sets in Form Builder as well as create a data list
                that can incorporate multiple data sets.
              </span>
            </div>
          </div>
        </div>
        <div className={s.inputWithNote}>
          <TextInput
            wrapperClassName={s.input}
            name="description"
            label="Data Set Description"
            placeholder="Description"
            onChange={formik.handleChange}
            value={formik.values.description}
            error={formik.touched.description && formik.errors.description}
            disabled={readOnly}
            qa={`${Components.DSForm}-${ElementType.TextInput}-description`}
          />
        </div>
        <div className={joinClassNames(s.inputWithNote, s.textfieldWrapper)}>
          <TextInput
            wrapperClassName={s.input}
            qa={`${Components.DSForm}-${ElementType.TextInput}-${
              uploaded ? "columnHeaders" : "delimitedDataList"
            }`}
            name="dataList"
            label={uploaded ? "Column Headers" : "Delimited Data List"}
            placeholder={
              uploaded
                ? "List the name of each column in your data set on its own line and mark" +
                  " required columns with an (*)"
                : ""
            }
            assistiveText={
              !formik.errors.dataList
                ? "Each item on your list must be on a new line"
                : ""
            }
            multiline={true}
            rows={10}
            onChange={formik.handleChange}
            value={formik.values.dataList}
            error={formik.touched.dataList && formik.errors.dataList}
            disabled={readOnly}
            required
          />
          <div className={s.noteContainer}>
            {!uploaded && (
              <>
                <div className={s.note}>
                  <span>
                    <span className={s.medium}>NOTE</span>: Don&apos;t use
                    commas, periods, or other elements to separate list items;
                    each item on the list just needs to be on its own line.
                  </span>
                </div>
                <div className={s.note}>
                  <span>
                    <span className={s.medium}>TIP</span>: The order that a list
                    is entered is how the list will display on a form.
                  </span>
                </div>
              </>
            )}
            <div className={s.note}>
              <span>
                <span className={s.medium}>EXAMPLE</span>:{" "}
                {uploaded
                  ? "The list of column headers will be used to generate a .csv template for your data set " +
                    "list. List the name of each column of your data set and mark those columns that require records " +
                    "with a (*)."
                  : "A delimited list can be used throughout the form building process to create answer options for " +
                    "a variety of question types. Below is an example of how a list appears in a form as a dropdown."}
              </span>
              <img
                className={s.png}
                src={uploaded ? ColumnExample : DelimitedExample}
                alt="Delimited data example"
              />
            </div>
          </div>
        </div>
        {uploaded && (
          <div className={s.inputWithNote}>
            <Select
              className={s.select}
              name="uniqueId"
              label="Unique ID Column"
              value={formik.values.uniqueId?.value}
              onChange={(e) => formik.setFieldValue("uniqueId", e?.value)}
              options={colOptions}
              placeholder="no unique ID column"
              disabled={readOnly}
              qa={`${Components.DSForm}-${ElementType.SelectInput}-uniqueId`}
            />
            <div className={s.noteContainer}>
              <div className={s.note}>
                <span>
                  <span className={s.medium}>TIP</span>: Select &quot;no unique
                  ID column&quot; if applicable and a column will be generated
                  automatically in the template.
                </span>
              </div>
            </div>
          </div>
        )}
        <div className={s.buttonWrapper}>
          <GenericButton
            buttonStyle={allowSubmit ? "primary" : "disabled"}
            loading={formik.isSubmitting}
            disabled={readOnly}
            type="submit"
            qa={`${Components.DSForm}-${ElementType.Button}-submit`}
            label={generateSubmitLabel(!!dataSource, uploaded)}
          />
          {!(formik.isSubmitting || readOnly) && (
            <GenericButton
              buttonStyle="tertiary"
              onClick={() => navigate("/forms/data-sets")}
              qa={`${Components.DSForm}-${ElementType.Button}-cancel`}
              label="CANCEL"
            />
          )}
        </div>
      </form>
    </div>
  );
}
