import { FormTypeDTO } from "@rtslabs/field1st-fe-common";
import React, { Dispatch, FC, SetStateAction } from "react";
import { Components, ElementType } from "shared/src/qa-slugs";
import { DeleteFormTypeConfirmation } from "./DeleteFormTypeConfirmation";
import { GenericModal } from "shared/src/components/Generic/Modal/GenericModal";

interface DeleteFormTypeModalProps {
  formType: FormTypeDTO;
  deleteFormTypeIsVisible: boolean;
  setDeleteFormTypeIsVisible: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
}

export const DeleteFormTypeModal: FC<DeleteFormTypeModalProps> = ({
  formType,
  deleteFormTypeIsVisible,
  setDeleteFormTypeIsVisible,
  onClose,
}) => {
  return (
    <>
      <GenericModal
        title="Delete Form Type"
        qa={`${Components.FormTypes}-${ElementType.Modal}-delete-modal`}
        isOpen={deleteFormTypeIsVisible}
        onClose={() => setDeleteFormTypeIsVisible(false)}
      >
        <DeleteFormTypeConfirmation
          onClose={onClose}
          onCancel={() => setDeleteFormTypeIsVisible(false)}
          formType={formType}
          setDeleteFormTypeIsVisible={setDeleteFormTypeIsVisible}
        />
      </GenericModal>
    </>
  );
};
