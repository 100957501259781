import {
  API,
  FilterParams,
  TagDTO,
  UpdateTagLibraryVM,
} from "@rtslabs/field1st-fe-common";
import moment from "moment";
import React, { FC, useCallback, useState } from "react";
import { TableSummary } from "shared/src/components/TableUI/TableSummary/TableSummary";
import { Components, ElementType } from "shared/src/qa-slugs";
import { tagWriteRoles } from "../../../../routes/constants/permissionSets";
// Resources
import TableHeader from "shared/src/components/clientAdmin/resources/TableHeader";
// Common
import WriteContent from "shared/src/components/common/permissions/WriteContent";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "shared/src/components/common/styled/Table";
import NoResults from "shared/src/components/common/TableUI/NoResults";
// Local
import TagActionMenu from "./TagActionMenu";
import { TagStatusModal, TagStatusModalProps } from "./TagStatusModal";
import styles from "../screens/TagLibrary.module.scss";
import { joinClassNames } from "shared/src/helpers/theme.helpers";
import { GenericModal } from "shared/src/components/Generic/Modal/GenericModal";

const emptyTagStatusModalState = {
  status: null,
  tag: null,
  visible: false,
};

interface TagLibraryTableProps {
  onAddTag: () => void;
  onFetch: () => void;
  onSort: (sort: string) => void;
  params: FilterParams;
  tags: TagDTO[];
  totalElements: number;
}

export const TagLibraryTable: FC<TagLibraryTableProps> = ({
  onAddTag,
  onFetch,
  onSort,
  params,
  tags = [],
  totalElements,
}) => {
  const [tagStatusModal, setTagStatusModal] = useState<{
    status: TagStatusModalProps["status"];
    tag: TagStatusModalProps["tag"];
    visible: boolean;
  }>({
    status: null,
    tag: null,
    visible: false,
  });

  // Update tag's status, we aren't changing the name -- Gk
  const onTagStatusUpdate = async () => {
    // Set loading state
    setTagStatusModal({
      ...tagStatusModal,
      status: "request",
    });

    // API request
    const payload: UpdateTagLibraryVM = {
      id: tagStatusModal.tag?.id!,
      archived: !tagStatusModal.tag?.archived,
    };

    try {
      await API.updateOETag({ tag: payload });
      setTagStatusModal({
        ...tagStatusModal,
        status: "success",
      });
      onFetch(); // Fetch table with current filters
    } catch (e) {
      setTagStatusModal({
        ...tagStatusModal,
        status: "failure",
      });
    }
  };

  // Makes tag.archived label user-friendly
  const prettifyStatus = useCallback(
    (archived?: boolean) => (archived ? "Archived" : "Active"),
    []
  );

  return (
    <>
      <GenericModal
        qa={`${Components.TagLibraryTable}-${ElementType.Modal}-updateStatusModal`}
        isOpen={tagStatusModal.visible}
        onClose={() => {
          setTagStatusModal({
            ...emptyTagStatusModalState,
            visible: false,
          });
        }}
        title="Are you sure?"
      >
        <TagStatusModal
          onCancel={() => {
            setTagStatusModal({
              ...emptyTagStatusModalState,
              visible: false,
            });
          }}
          onSubmit={onTagStatusUpdate}
          status={tagStatusModal.status}
          tag={tagStatusModal.tag}
        />
      </GenericModal>
      <TableSummary
        pageSize={params.size}
        currentPage={params.page}
        totalElements={totalElements}
        ofWhat="tags"
        exports={["print", "xls", "csv"]}
        onExport={API.downloadOETags}
        exportParams={{
          query: params.query,
        }}
      />
      <Table>
        <TableHeader
          onSort={onSort}
          currentSort={params.sort}
          columns={[
            { id: "name", label: "tag", className: styles.firstCell },
            { id: "useCount", label: "uses" },
            { id: "createdDate", label: "date added" },
            { id: "archived", label: "Status" },
          ]}
        />
        <TableBody>
          {tags.length > 0 ? (
            tags.map((tag, i) => (
              <TableRow
                data-testid={`${Components.TagLibraryTable}-${ElementType.TableRow}`}
                key={i}
              >
                <TableCell
                  qa={`${Components.TagLibraryTable}-${ElementType.TableCell}-tagName-${tag.id}`}
                  width="350px"
                  className={styles.firstCell}
                >
                  {tag.name}
                </TableCell>
                <TableCell
                  qa={`${Components.TagLibraryTable}-${ElementType.TableCell}-useCount-${tag.id}`}
                  width="100px"
                >
                  {tag.useCount}
                </TableCell>
                <TableCell
                  qa={`${Components.TagLibraryTable}-${ElementType.TableCell}-dateAdded-${tag.id}`}
                  width="150px"
                >
                  {moment(tag.createdDate).format("MM/DD/YYYY")}
                </TableCell>
                <TableCell
                  qa={`${Components.TagLibraryTable}-${ElementType.TableCell}-status-${tag.id}`}
                  width="100px"
                  className={styles.statusCell}
                >
                  <span
                    className={joinClassNames(!tag.archived && styles.active)}
                  >
                    {prettifyStatus(tag.archived)}
                  </span>
                </TableCell>
                <TableCell width="22px" className={styles.lastCell}>
                  {/* None of the menu items are (currently) visible to Client Reviewer -- GK */}
                  <WriteContent roles={tagWriteRoles}>
                    <TagActionMenu
                      tag={tag}
                      onTagStatusUpdate={(tag) => {
                        setTagStatusModal({
                          ...tagStatusModal,
                          tag,
                          visible: true,
                        });
                      }}
                    />
                  </WriteContent>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <NoResults
              icon="document"
              header="No tags were found."
              body="Create a new tag to match your needs."
              button={{
                children: "Create a tag",
                onClick: onAddTag,
              }}
              qa={`${Components.TagLibraryTable}-${Components.NotFound}`}
              roles={tagWriteRoles}
            />
          )}
        </TableBody>
      </Table>
    </>
  );
};
