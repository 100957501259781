import React, { FC } from "react";
import Loader from "../Loader/Loader";
import { Link } from "../Link/Link";
import { Components, ElementType } from "../../qa-slugs";
import styles from "./auth.module.scss";
import ErrorText from "../ErrorText/ErrorText";
import { AuthRequestStatus } from "./types";
import Logo from "../Logo";
import { LogoImgProps } from "@rtslabs/field1st-fe-common";
import { PasswordResetForm } from "./PasswordResetForm";

const buttonText = (status?: AuthRequestStatus): string => {
  switch (status) {
    case "success":
      return "Password Reset Successful";
    case "failure":
      return "Unable to Reset Password";
    default:
      return "Save New Password";
  }
};

interface PasswordResetProps {
  username: string;
  loading: boolean;
  status?: AuthRequestStatus;
  keyExpired?: boolean;
  onReturnToLogin: () => void;
  onResetPassword: (password: string) => Promise<void>;
  logo?: LogoImgProps;
}

const PasswordReset: FC<PasswordResetProps> = ({
  username,
  keyExpired,
  loading,
  status,
  onReturnToLogin,
  onResetPassword,
  logo,
}) => {
  const qaBase = Components.PasswordReset;

  return (
    <div className={styles.passwordReset}>
      <Logo logo={logo} />

      <Loader loading={loading}>
        {keyExpired ? (
          <span className={styles.validationError}>
            Your key has expired. Please restart the reset process and try
            again.
          </span>
        ) : (
          <>
            <span className={styles.title}>Reset Your Password</span>
            <span className={styles.message}>
              Enter a new password for username: <b>{username}</b>
            </span>

            <PasswordResetForm
              onSubmit={onResetPassword}
              loading={loading}
              submitStatus={status}
              onReturnToLogin={onReturnToLogin}
              buttonText={buttonText(status)}
              qa={qaBase}
            />

            {status === "failure" && (
              <ErrorText>
                The password reset process has failed. Please restart the reset
                process and try again.
              </ErrorText>
            )}
          </>
        )}
        <div className={styles.returnLink}>
          <Link
            to="/login"
            qa={`${qaBase}-${ElementType.Link}-returnToLoginFromPasswordReset`}
          >
            Return to login
          </Link>
        </div>
      </Loader>
    </div>
  );
};

PasswordReset.displayName = "PasswordReset";
export default PasswordReset;
