/**
 * Helper function to test if a boolean is true or undefined, in other words, default to true
 * @param b
 * @returns if b is true, undefined, or null
 */
export function notFalse(b?: boolean | null): boolean {
  return !(b === false);
}

export const pluralize = (
  word: string,
  count: number,
  includeNumberInResponse = true
) => {
  const pluralized = count === 1 ? word : `${word}s`;

  return includeNumberInResponse && count >= 0
    ? `${count.toLocaleString()} ${pluralized}`
    : pluralized;
};
