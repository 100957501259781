import React, {
  Dispatch,
  FC,
  JSXElementConstructor,
  SetStateAction,
  createRef,
  useEffect,
  useMemo,
  useState,
} from "react";
import styles from "./DocumentSection.module.scss";
import { PieSectionProgress } from "./PieSectionProgress";
import { SectionDesciption } from "./SectionDescription";
import { DocumentSectionBreadcrumb } from "./DocumentSectionBreadcrumb";
import { DocumentTitle } from "./DocumentTitle";
import { ActionButtons } from "./ActionButtons";
import { Section } from "./Section";
import { useFormikContext } from "formik";
import {
  DocumentQuestionResponseVm,
  DocumentSubmissionType,
  DocumentVm,
  SectionItem,
  useAsyncEffect,
} from "@rtslabs/field1st-fe-common";
import { useDocumentSection } from "./hooks/useDocumentSection";
import { Components, QAProps } from "../../../qa-slugs";
import { getFlattenedItems } from "../DocumentForm/formEntity.helpers";
import { FormWidgetProps } from "../DocumentForm/types";
import useDebounce from "../../../util/hooks/useDebounce";
import usePrevious from "../../../util/hooks/usePrevious";
import { isEqual } from "lodash";
import { joinClassNames } from "../../../helpers/theme.helpers";
import {
  getDocumentRehuddleInfo,
  getDocumentSubmissionInfo,
  getVisibleSections,
} from "../document.helpers";
import { useSectionalContext } from "./hooks/useSectionalContext";

interface DocumentSectionContentProps extends QAProps {
  documentTerm: string;
  formWidget: JSXElementConstructor<FormWidgetProps>;
  onPutDocument: (
    values: DocumentVm,
    responsesEqual?: boolean
  ) => Promise<void>;
  setShowSidebarDrawer: Dispatch<SetStateAction<boolean>>;
}

export const DocumentSectionContent: FC<DocumentSectionContentProps> = ({
  documentTerm,
  formWidget,
  onPutDocument,
  setShowSidebarDrawer,
  qa = Components.DocumentSectionContent,
}) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { values, dirty, isValid } = useFormikContext<DocumentVm>();
  const { handleUpdateDocumentTitle } = useDocumentSection();
  const { visibleSections, setCurrentSection, setVisibleSections } =
    useSectionalContext();

  const wasDocSaved = values.status === "IN_PROGRESS";

  useAsyncEffect(async () => {
    if (wasDocSaved) {
      const sortByRecentAnswered = values.responses.sort((x, y) => {
        return new Date(x.timeAnswered) < new Date(y.timeAnswered) ? 1 : -1;
      });
      const jumpToSectionOnSaved =
        !!sortByRecentAnswered.length &&
        visibleSections.find((vs) =>
          vs.items.find(
            (item) => item.rootId === sortByRecentAnswered[0].questionRootId
          )
        );

      jumpToSectionOnSaved && setCurrentSection(jumpToSectionOnSaved);
    }
  }, []);

  const flattenedItems: SectionItem[] = useMemo(
    () => getFlattenedItems(values.form.sections),
    [document]
  );

  const itemRefs: Map<number, React.RefObject<HTMLDivElement>> = useMemo(
    () =>
      flattenedItems.reduce((result, fi) => {
        result.set(fi.id, createRef<HTMLDivElement>());
        return result;
      }, new Map<number, React.RefObject<HTMLDivElement>>()),
    [flattenedItems]
  );

  const mapResponsesWithoudIds = (responses: DocumentQuestionResponseVm[]) =>
    responses.map((r) => ({
      ...r,
      id: undefined,
    }));

  /**** Effects ****/
  // debounced autosave function
  const autoSync = useDebounce({
    async method(values: DocumentVm, responsesEqual?: boolean): Promise<void> {
      setSubmitting(true);
      await onPutDocument(values, responsesEqual);
      setSubmitting(false);
    },
  });

  const previousAutoSync = usePrevious(autoSync);
  const previousResponses = usePrevious(values.responses) ?? [];
  const previousValues = usePrevious(values);

  function shouldAutoSync(): boolean {
    if (!previousAutoSync) return false;
    if (!previousResponses) return false;
    if (values.submissionType !== DocumentSubmissionType.AUTO_SYNC)
      return false;
    if (!dirty) return false;

    //compare prev and current values, don't autosync if only response ids have changed
    const currentValues = {
      ...values,
      responses: mapResponsesWithoudIds(values.responses),
    };
    const prevValues = {
      ...previousValues,
      responses: mapResponsesWithoudIds(previousResponses),
    };

    return !isEqual(currentValues, prevValues);
  }

  useEffect(() => {
    handleUpdateDocumentTitle();

    if (shouldAutoSync()) {
      const responsesEqual = isEqual(
        mapResponsesWithoudIds(values.responses),
        mapResponsesWithoudIds(previousResponses)
      );
      autoSync(values, responsesEqual);
    }
  }, [values]);

  useEffect(() => {
    const updatedVisibleSections = getVisibleSections(values);
    if (!isEqual(updatedVisibleSections, visibleSections)) {
      setVisibleSections(updatedVisibleSections);
    }
  }, [values.responses]);

  const isDocSaved = isValid && values.submissionType === "SAVE_DRAFT";
  const isDocSubmitted = isValid && values.submissionType === "SUBMIT";

  return (
    <div className={styles.mainContainer}>
      <DocumentSectionBreadcrumb
        qa={`${qa}-DocSec-${Components.Breadcrumbs}`}
      />

      <DocumentTitle
        setShowSidebarDrawer={setShowSidebarDrawer}
        qa={`${qa}-DocSec-title`}
      />

      <div
        className={joinClassNames(
          styles.sectionContainer,
          isDocSaved && styles.documentSaved,
          isDocSubmitted && styles.documentSubmitted,
          values.isRehuddle && styles.documentRehuddle,
          !isValid && styles.documentErrors
        )}
      >
        <div className={styles.progressHeader}>
          {values.isRehuddle && (
            <div
              className={joinClassNames(
                styles.statusTabWrapper,
                styles.rehuddleTab
              )}
            >
              <span className={styles.statusTabInfo}>
                {getDocumentRehuddleInfo(values)}
              </span>
              <span className={styles.statusTab}>Rehuddle</span>
            </div>
          )}
          {!isValid && (
            <div
              className={joinClassNames(
                styles.statusTabWrapper,
                styles.errorsTab
              )}
            >
              <span className={styles.statusTab}>Errors</span>
            </div>
          )}
          {isValid && (isDocSaved || isDocSubmitted) && (
            <div
              className={joinClassNames(
                styles.statusTabWrapper,
                isDocSubmitted && styles.submittedTab,
                isDocSaved && styles.savedTab
              )}
            >
              <span className={styles.statusTabInfo}>
                {getDocumentSubmissionInfo(values)}
              </span>
              <span className={styles.statusTab}>
                {isDocSaved && "Saved (draft)"}
                {isDocSubmitted && "Submitted"}
              </span>
            </div>
          )}
          <div className={styles.headerContainer}>
            <PieSectionProgress qa={`${qa}-DocSec-pieProgress`} />
            <SectionDesciption
              isSubmitting={submitting}
              qa={`${qa}-DocSec-description`}
            />
          </div>
        </div>
        <Section
          qa={`${qa}-DocSec-section`}
          itemRefs={itemRefs}
          formWidget={formWidget}
        />

        <ActionButtons
          documentTerm={documentTerm}
          qa={`${qa}-DocSec-actionButtons`}
        />
      </div>
    </div>
  );
};
