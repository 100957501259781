import { useEffect, useState } from "react";
import { getAnimationData, setAnimationData } from "./animationCache";

export const usePreloadLottie = (src?: string) => {
  const [animationData, setAnimationDataState] = useState<any>(
    getAnimationData()
  );

  useEffect(() => {
    if (!animationData) {
      const loadAnimation = async () => {
        const response = await fetch(
          src || "/assets/lottieFiles/F1st-Animation.json"
        );
        const data = await response.json();
        setAnimationData(data);
        setAnimationDataState(data);
      };

      loadAnimation();
    }
  }, [src, animationData]);

  return animationData;
};
