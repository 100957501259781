import React, { FC, useState } from "react";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useMatch,
} from "react-router-dom";

// Profile routes
import EditProfile from "../components/screens/EditProfile";
import Profile from "../components/screens/Profile";
import Settings from "../components/screens/Settings";

// Tags routes
import { TagLibrary } from "../components/clientAdmin/tags/screens/TagLibrary";

// Remaining routes
import Privacy from "shared/src/components/privacy/Privacy";
import AddUser from "../components/clientAdmin/addUser";
import APIDS from "../components/clientAdmin/dataSets/APIDS";
import BasicDS from "../components/clientAdmin/dataSets/BasicDS";
import { DataSets } from "../components/clientAdmin/dataSets/DataSets";
import UploadedDS from "../components/clientAdmin/dataSets/UploadedDS";
import ViewUploadedDS from "../components/clientAdmin/dataSets/ViewUploadedDS";
import { FormBuilderScreen } from "../components/FormBuilderScreen";
import { FormBuilderDebugScreen } from "../components/FormBuilderDebugScreen";
import { FormList } from "shared/src/components/clientAdmin/formList/FormList";
import FormTypes from "../components/clientAdmin/formTypes/FormTypes";
import Groups from "../components/clientAdmin/groups";
import { FormReport } from "../components/clientAdmin/reports/FormReport/FormReport";
import { Reports } from "../components/clientAdmin/reports/Reports";
import { MaintainResources } from "shared/src/components/clientAdmin/resources/MaintainResources";
import Resource from "shared/src/components/clientAdmin/resources/Resource";
import ViewGroup from "../components/clientAdmin/viewGroup";
import { ViewUserProfile } from "../components/clientAdmin/viewUserProfile/ViewUserProfile";
import Dashboard from "../components/dashboard/Dashboard";
import Document from "../components/Document/Document";
import { DocumentsScreen } from "../components/DocumentsScreen";
import { HelpDesk } from "../components/helpDesk/HelpDesk";
import { HazardAnalysisProvider } from "shared/src/components/HazardAnalysis/HazardAnalysisProvider";

import {
  AuthenticatedRoute,
  AuthenticatedRouteProps,
} from "shared/src/components/routes/AuthenticatedRoute";
import {
  ALL_ROLES,
  dataSetReadRoles,
  dataSetWriteRoles,
  formTemplateReadRoles,
  formTypeReadRoles,
  groupReadRoles,
  groupWriteRoles,
  resourceReadRoles,
  settingsReadRoles,
  tagReadRoles,
  tagWriteRoles,
  userReadRoles,
  userWriteRoles,
} from "./constants/permissionSets";
import {
  formTemplateWriteRoles,
  resourceWriteRoles,
} from "shared/src/components/routes/constants/permissionSets";

// Not found
import { API, CoreRedux } from "@rtslabs/field1st-fe-common";
import { useSelector } from "react-redux";
import Loader from "shared/src/components/Loader/Loader";
import { NoPermissions } from "shared/src/components/routes/NoPermissions";
import { RouteNotFound } from "shared/src/components/routes/RouteNotFound";
import { ContentWrapper } from "shared/src/components/Wrappers/Wrappers";
import { Users } from "../components/clientAdmin/users/Users";
import { MainLayout } from "../components/navigation/MainLayout/MainLayout";
import { AppState } from "../store";
import { DocumentSection } from "../components/Document/DocumentSection/DocumentSection";
import AiChatDrawer from "../components/Document/AiChatDrawer/AiChatDrawer";
import AiChatButton from "../components/Document/AiChatDrawer/AiChatButton";
import scssVariables from "shared/src/sass/jsExports.module.scss";
import { useMediaQuery } from "react-responsive";
import { SettingsScreen } from "../components/clientAdmin/settings/SettingsScreen";
import { ToastContainer } from "react-toastify";

type AuthedRouteProps = Pick<
  AuthenticatedRouteProps,
  "Component" | "accessRoles"
>;

const AuthedRoute: FC<AuthedRouteProps> = ({ Component, accessRoles }) => {
  // Get location
  const location = useLocation();

  // Get user roles
  const userRoles = API.Environment.getRoles();

  // Is user authenticated
  const isAuthenticated = useSelector((state: AppState): boolean => {
    return !!(state.auth.token && state.user?.data?.participant.id);
  });

  // build the redirect URL for users to return to after authentication
  let redirectUrl = "";
  const pattern = /\/document\/(\d)+/; // Matches `/document/:id` route
  const routeMatches = pattern.test(location.pathname);
  if (routeMatches) {
    redirectUrl = location?.pathname;
  }

  return (
    <AuthenticatedRoute
      Component={Component}
      accessRoles={accessRoles}
      redirectPath={redirectUrl}
      userRoles={userRoles}
      isAuthenticated={isAuthenticated}
    />
  );
};

const AuthenticatedRoutes = () => {
  const user = useSelector(CoreRedux.selectUser);
  const [isChatDrawerOpen, setIsChatDrawerOpen] = useState<boolean>(false);

  // Breakpoint where we have bottom navigation
  const isMobile = useMediaQuery({
    query: `(max-width: ${scssVariables.breakpointExtraLarge})`,
  });

  // Breakpoint where we have bottom navigation if it's document view/edit
  const isDocumentViewEdit = useMatch("/document/:documentId");
  const isLargeBreakPoint = useMediaQuery({
    query: `(max-width: ${scssVariables.breakpointLarge})`,
  });
  const isMobileForDocumentViewEdit = !!isDocumentViewEdit && isLargeBreakPoint;

  // show chatbot tab if it's not mobile or if it's document view/edit and not mobile
  const showChatBotTab = isDocumentViewEdit
    ? !isMobileForDocumentViewEdit
    : !isMobile;

  // Is user authenticated with an unexpired token
  if (!API.Environment.isTokenValid()) {
    // build the redirect URL for users to return to after authentication
    let redirectUrl = location?.pathname || "";

    return <Navigate replace state={{ redirect: redirectUrl }} to="/login" />;
  }

  return (
    <Loader loading={!user}>
      <ToastContainer />
      <HazardAnalysisProvider>
        {showChatBotTab && (
          <>
            <AiChatDrawer
              open={isChatDrawerOpen}
              onClose={() => setIsChatDrawerOpen(false)}
            />
            <AiChatButton handleClick={() => setIsChatDrawerOpen(true)} />
          </>
        )}
        <Routes>
          <Route
            element={
              <AuthedRoute Component={Document} accessRoles={ALL_ROLES} />
            }
            path="/document/:documentId/legacy"
          />
          <Route
            element={
              <AuthedRoute
                Component={DocumentSection}
                accessRoles={ALL_ROLES}
              />
            }
            path="/document/:documentId"
          />

          {/* Form Builder */}
          <Route
            element={
              <AuthedRoute
                Component={FormBuilderScreen}
                accessRoles={formTemplateWriteRoles}
              />
            }
            path="/forms/form/:id"
          />
          <Route
            element={
              <AuthedRoute
                Component={FormBuilderDebugScreen}
                accessRoles={formTemplateWriteRoles}
              />
            }
            path="/forms/form/debug/:id/:config"
          />

          {/* NEW MAINLAYOUT/CONTENT WRAPPER DESIGN */}
          <Route element={<MainLayout />} path="/">
            <Route
              element={
                <AuthedRoute Component={Dashboard} accessRoles={ALL_ROLES} />
              }
              index
            />
            {/*
            This route is purely to provide a content wrapper around all the
            subroutes. Pages that don't need the content wrapper can be moved
            to the parent route.
          */}
            <Route
              element={
                <ContentWrapper>
                  <Outlet />
                </ContentWrapper>
              }
            >
              {/* Documents */}
              <Route
                element={
                  <AuthedRoute
                    Component={DocumentsScreen}
                    accessRoles={ALL_ROLES}
                  />
                }
                path="documents"
              />
              <Route
                element={
                  <AuthedRoute
                    Component={DocumentsScreen}
                    accessRoles={ALL_ROLES}
                  />
                }
                path="documents/:formType"
              />
            </Route>

            {/* Reports  */}
            <Route
              element={
                <AuthedRoute Component={Reports} accessRoles={ALL_ROLES} />
              }
              path="reports"
            />
            <Route
              element={
                <AuthedRoute Component={FormReport} accessRoles={ALL_ROLES} />
              }
              path="reports/:id"
            />

            {/* Help Desk */}
            <Route
              element={
                <AuthedRoute Component={HelpDesk} accessRoles={ALL_ROLES} />
              }
              path="help-desk"
            />

            {/* Groups  */}
            <Route
              element={
                <AuthedRoute Component={Groups} accessRoles={groupReadRoles} />
              }
              path="people/groups"
            />
            <Route
              element={
                <AuthedRoute
                  Component={ViewGroup}
                  accessRoles={groupWriteRoles}
                />
              }
              path="people/groups/view/:id"
            />

            {/* Users  */}
            <Route
              element={
                <AuthedRoute Component={Users} accessRoles={userReadRoles} />
              }
              path="people/users"
            />
            <Route
              element={
                <AuthedRoute Component={AddUser} accessRoles={userWriteRoles} />
              }
              path="people/users/add-user"
            />
            <Route
              element={
                <AuthedRoute Component={AddUser} accessRoles={userWriteRoles} />
              }
              path="people/users/edit-user/:id"
            />
            <Route
              element={
                <AuthedRoute
                  Component={ViewUserProfile}
                  accessRoles={userReadRoles}
                />
              }
              path="people/users/view/:id"
            />

            {/* Form List */}
            <Route
              element={
                <AuthedRoute
                  Component={FormList}
                  accessRoles={formTemplateReadRoles}
                />
              }
              path="forms"
            />

            {/* Data Sets */}
            <Route
              element={
                <AuthedRoute
                  Component={DataSets}
                  accessRoles={dataSetReadRoles}
                />
              }
              path="forms/data-sets"
            />
            <Route
              element={
                <AuthedRoute
                  Component={BasicDS}
                  accessRoles={dataSetWriteRoles}
                />
              }
              path="forms/data-sets/basic"
            />
            <Route
              element={
                <AuthedRoute
                  Component={BasicDS}
                  accessRoles={dataSetWriteRoles}
                />
              }
              path="forms/data-sets/basic/:id"
            />
            <Route path="forms/data-sets/uploaded">
              <Route
                element={
                  <AuthedRoute
                    Component={UploadedDS}
                    accessRoles={dataSetWriteRoles}
                  />
                }
                index
              />
              <Route
                element={
                  <AuthedRoute
                    Component={UploadedDS}
                    accessRoles={dataSetWriteRoles}
                  />
                }
                path=":id"
              />
              <Route
                element={
                  <AuthedRoute
                    Component={ViewUploadedDS}
                    accessRoles={dataSetReadRoles}
                  />
                }
                path=":id/view"
              />
            </Route>
            <Route
              element={
                <AuthedRoute
                  Component={APIDS}
                  accessRoles={dataSetWriteRoles}
                />
              }
              path="forms/data-sets/api"
            />
            <Route
              element={
                <AuthedRoute
                  Component={APIDS}
                  accessRoles={dataSetWriteRoles}
                />
              }
              path="forms/data-sets/api/:id"
            />

            {/* Form Types */}
            <Route
              element={
                <AuthedRoute
                  Component={FormTypes}
                  accessRoles={formTypeReadRoles}
                />
              }
              path="forms/form-types"
            />

            {/* Resources */}
            <Route
              element={
                <AuthedRoute
                  Component={MaintainResources}
                  accessRoles={resourceReadRoles}
                />
              }
              path="content/resources"
            />
            <Route
              element={
                <AuthedRoute
                  Component={Resource}
                  accessRoles={resourceWriteRoles}
                />
              }
              path="content/resources/new"
            />
            <Route
              element={
                <AuthedRoute
                  Component={Resource}
                  accessRoles={resourceWriteRoles}
                />
              }
              path="content/resources/:id"
            />

            {/* Tags Library */}
            <Route
              element={
                <AuthedRoute
                  Component={TagLibrary}
                  accessRoles={tagReadRoles}
                />
              }
              path="content/tag-library"
            />
            <Route
              element={
                <AuthedRoute
                  Component={TagLibrary}
                  accessRoles={tagWriteRoles}
                />
              }
              path="content/tag-library/add-tag"
            />

            {/* Settings */}
            <Route
              element={
                <AuthedRoute
                  Component={SettingsScreen}
                  accessRoles={settingsReadRoles}
                />
              }
              path="settings/company-info"
            />
            <Route
              element={
                <AuthedRoute
                  Component={SettingsScreen}
                  accessRoles={settingsReadRoles}
                />
              }
              path="settings/permissions"
            />

            {/* Profile */}
            <Route
              element={
                <AuthedRoute Component={Settings} accessRoles={ALL_ROLES} />
              }
              path="profile/settings"
            />
            <Route
              element={
                <AuthedRoute Component={EditProfile} accessRoles={ALL_ROLES} />
              }
              path="profile/edit"
            />
            <Route
              element={
                <AuthedRoute Component={Profile} accessRoles={ALL_ROLES} />
              }
              path="profile"
            />

            {/* Privacy Policy */}
            <Route
              element={
                <AuthedRoute Component={Privacy} accessRoles={ALL_ROLES} />
              }
              path="privacy"
            />

            {/* No Permissions */}
            <Route
              element={
                <AuthedRoute
                  Component={NoPermissions}
                  accessRoles={ALL_ROLES}
                />
              }
              path="no-permissions"
            />

            <Route
              element={
                <AuthedRoute
                  Component={RouteNotFound}
                  accessRoles={ALL_ROLES}
                />
              }
              path="*"
            />
          </Route>
        </Routes>
      </HazardAnalysisProvider>
    </Loader>
  );
};

export default AuthenticatedRoutes;
