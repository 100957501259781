import React, { FC, ReactNode } from "react";
import styles from "./PageHeader.module.scss";
import { QAProps } from "../../qa-slugs";
import { PageBreadcrumbs } from "./PageBreadcrumbs";

interface PageHeaderProps extends QAProps {
  children?: ReactNode;
  pageTitle?: string;
  paths: { pathName: string; href: string }[];
}

export const PageHeader: FC<PageHeaderProps> = ({
  children,
  pageTitle,
  paths,
  qa = "PageHeader",
}) => {
  const updatedPaths = [{ pathName: "Dashboard", href: "/" }, ...paths];

  return (
    <div className={styles.mainContainer}>
      <PageBreadcrumbs paths={updatedPaths} qa={qa} />
      <h2 className={styles.pageTitle}>
        {pageTitle && (
          <span
            data-testid={`${qa}-${pageTitle}Title`}
            className={styles.titleText}
          >
            {pageTitle}
          </span>
        )}
        {children}
      </h2>
    </div>
  );
};
