import React, { useState, FC } from "react";
import Loader from "../Loader/Loader";
import styles from "./auth.module.scss";
import { AuthRequestStatus } from "./types";
import Logo from "../Logo";
import { getVersionName } from "../../helpers/version";
import { LogoImgProps, PasswordResetVm } from "@rtslabs/field1st-fe-common";
import { PasswordResetForm } from "./PasswordResetForm";
import { Components } from "../../qa-slugs";

interface Props {
  activationStatus?: AuthRequestStatus;
  keyValidationStatus?: AuthRequestStatus;
  loadingActivation: boolean;
  loadingValidation?: boolean;
  onReturnToLogin: () => void;
  username: string | null;
  logo?: LogoImgProps;
  onActivate: (newPassword: PasswordResetVm["newPassword"]) => Promise<void>;
}

export const Activation: FC<Props> = ({
  activationStatus,
  keyValidationStatus,
  loadingActivation,
  loadingValidation,
  onReturnToLogin,
  onActivate,
  username,
  logo,
}) => {
  const buttonText = (status?: AuthRequestStatus): string => {
    switch (status) {
      case "success":
        return "New Password Saved";
      case "failure":
        return "Unable to Save Password";
      default:
        return "Activate";
    }
  };

  return (
    <div className={styles.activation}>
      <Logo logo={logo} />
      <span className="Version">VERSION {getVersionName()}</span>

      <Loader loading={loadingValidation}>
        {keyValidationStatus === "failure" ? (
          <span className={styles.validationError}>
            Your key has expired. Please contact your system administrator for
            support.
          </span>
        ) : (
          <>
            <span className={styles.header}>Choose a Password</span>
            {username && (
              <span className={styles.message}>
                Choose a new password for username: <b>{username}</b>
              </span>
            )}

            <PasswordResetForm
              onSubmit={onActivate}
              loading={loadingActivation}
              submitStatus={activationStatus}
              onReturnToLogin={onReturnToLogin}
              buttonText={buttonText(activationStatus)}
              qa={Components.Activation}
            />
          </>
        )}
      </Loader>
    </div>
  );
};
