import {
  API,
  BuilderFormSummaryVM,
  SortParams,
  useAPI,
  WorkflowType,
} from "@rtslabs/field1st-fe-common";
import React, { FC, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../../Loader/Loader";
import BackToTop from "../../TableUI/BackToTop/BackToTop";
import {
  Pagination,
  PaginationWrapper,
} from "../../TableUI/Pagination/Pagination";
import { RowsPerPage } from "../../TableUI/RowsPerPage/RowsPerPage";
import { TableSummary } from "../../TableUI/TableSummary/TableSummary";
import { ContentWrapper } from "../../Wrappers/Wrappers";
import { Components, ElementType } from "../../../qa-slugs";
import useDebounce from "../../../util/hooks/useDebounce";
import { GenericButton } from "../../Generic/Button/GenericButton";
import WriteContent from "../../common/permissions/WriteContent";
import { ConfirmationModal } from "./ConfirmationModal";
import { getWorkflowTypes } from "./formConstants";
import { FormListModal } from "./FormListModal";
import { FormListTable } from "./FormListTable";
import styles from "./FormList.module.scss";
import { PageHeader } from "../../PageHeader/PageHeader";
import { FormListFilters } from "./FormListFilters";

interface FormListProps {
  accessRoles: any;
}

export const FormList: FC<FormListProps> = ({ accessRoles }) => {
  const formTemplateWriteRoles = accessRoles; // it should be same access as menu item
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const formTypeParam = searchParams.get("formType");
  const groupIdParam = searchParams.get("group");
  const searchQueryParam = searchParams.get("query");
  const statusParam = searchParams.get("status");
  const initialFormType = formTypeParam ? parseInt(formTypeParam) : undefined;
  const initialGroupId = groupIdParam ? parseInt(groupIdParam) : undefined;
  const initialStatusIndex = statusParam ? parseInt(statusParam) : undefined;
  const initialQuery = searchQueryParam ? searchQueryParam : undefined;

  /* loading statuses */
  const [workflowTypeLoading, setWorkflowTypeLoading] =
    useState<boolean>(false);

  /* modals */
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [modalDesiredWorkflowType, setModalDesiredWorkflowType] = useState<
    WorkflowType | undefined
  >(undefined);

  /* form and sort data */
  const [currSummary, setCurrSummary] = useState<
    BuilderFormSummaryVM | undefined
  >();
  const [sort, setSort] = useState<SortParams>(["name", "asc"]);
  const [groupId, setGroupId] = useState<number | undefined>(initialGroupId);
  const [formType, setFormType] = useState<number | undefined>(initialFormType);
  const [statusIndex, setStatusIndex] = useState<number | undefined>(
    initialStatusIndex
  );
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const [query, setQuery] = useState<string | undefined>(initialQuery);

  const debouncedSetQuery = useDebounce({
    method: (value: string) => {
      setQuery(value);
    },
    delayAmount: 1000,
  });

  // TODO getAndSetForms is always one state mutation behind, can be improved -jA
  /** Search for forms using sort state and possible supplied query */
  const formsCall = useAPI(API.getFormTemplates, {
    sort: `${sort[0]},${sort[1]}`,
    page,
    size,
    formTypeId: formType ? [formType] : undefined,
    clientGroupIds: groupId ? [groupId] : undefined,
    workflowTypes: getWorkflowTypes(statusIndex || 0),
    query,
  });

  /**
   * Get a list of all available form types for creating a new form
   */
  const formTypesCall = useAPI(API.getAllFormTypes);

  /** Map form types to options for filters + FormListModal */
  const formTypeOptions = (formTypesCall.data || []).map((type) => ({
    label: type.name,
    value: type.id,
  }));

  /**
   * Clone a form with given id
   * @param id
   */
  const clone = async (id: number) => {
    const res = await API.cloneForm({ formId: id });
    navigate(`/forms/form/${res.id}`);
  };

  /** Deactivate a form corresponding with formId in state */
  async function setWorkflowType(workflowType: WorkflowType) {
    const formId = currSummary?.id || -1;
    if (formId > -1) {
      setWorkflowTypeLoading(true);
      try {
        await API.updateFormStatus({ formId, status: workflowType });
        formsCall.refresh();
      } catch (e) {
        console.error(e);
      }
    }
    setWorkflowTypeLoading(false);
    setModalDesiredWorkflowType(undefined);
    return false;
  }

  const [remoteClear, setRemoteClear] = useState<boolean>(false);
  const [isFiltered, setIsFiltered] = useState(false);

  const handleClearFilters = () => {
    setPage(0);
    setQuery("");
    setGroupId(initialGroupId);
    setFormType(initialFormType);
    setStatusIndex(initialStatusIndex);
    setRemoteClear(true);
  };

  useEffect(() => {
    setRemoteClear(false);
  }, [remoteClear]);

  const checkIfFiltered = (): void => {
    const isDefault =
      groupId === initialGroupId &&
      formType === initialFormType &&
      statusIndex === initialStatusIndex &&
      !query;

    setIsFiltered(!isDefault);
  };

  useEffect(() => {
    checkIfFiltered();
  }, [query, groupId, formType, statusIndex]);

  const qaBase = Components.FormList;
  const paths = [{ pathName: "Forms", href: "/forms" }];

  return (
    <ContentWrapper id="mainContent">
      <FormListModal
        qaBase={qaBase}
        modalOpen={createModalOpen}
        closeModal={() => setCreateModalOpen(false)}
        formTypeOptions={formTypeOptions}
        formTypes={formTypesCall.data || []}
      />
      <ConfirmationModal
        qaBase={`${qaBase}-${modalDesiredWorkflowType}`}
        modalOpen={!!modalDesiredWorkflowType}
        desiredWorkflowType={
          modalDesiredWorkflowType || currSummary?.workflowType || "DRAFT"
        }
        closeModal={() => setModalDesiredWorkflowType(undefined)}
        setWorkflowType={setWorkflowType}
        loading={workflowTypeLoading}
      />

      <PageHeader pageTitle="Forms" paths={paths}>
        <WriteContent roles={formTemplateWriteRoles}>
          <GenericButton
            qa={`${qaBase}-${ElementType.Button}-create-new-form`}
            onClick={() => setCreateModalOpen(true)}
            label="Create New Form"
          />
        </WriteContent>
      </PageHeader>

      <div className={styles.listContainer}>
        <FormListFilters
          initialQuery={initialQuery}
          debouncedSetQuery={debouncedSetQuery}
          groupId={groupId}
          setFormType={setFormType}
          setGroupId={setGroupId}
          setPage={setPage}
          setStatusIndex={setStatusIndex}
          formTypeOptions={formTypeOptions}
          formType={formType}
          statusIndex={statusIndex}
          remoteClear={remoteClear}
        />
        <Loader loading={formsCall.isLoading}>
          <TableSummary
            pageSize={size}
            currentPage={page}
            totalElements={formsCall.data?.totalElements}
            ofWhat="forms"
            exports={["print", "xls", "csv"]}
            onExport={API.downloadFormTemplates}
            exportParams={{
              formTypeId: formType ? [formType] : undefined,
              clientGroupIds: groupId ? [groupId] : undefined,
              query,
            }}
            isFiltered={isFiltered}
            onClearFilters={handleClearFilters}
          />
          <FormListTable
            sort={sort}
            setSort={setSort}
            forms={formsCall.data?.content || []}
            onCreateNew={setCreateModalOpen}
            setDesiredWorkflowType={setModalDesiredWorkflowType}
            setCurrSummary={setCurrSummary}
            cloneForm={clone}
            formTemplateWriteRoles={formTemplateWriteRoles}
          />
        </Loader>
        {!formsCall.isLoading && (
          <div className={styles.paginationWrapper}>
            <PaginationWrapper>
              <RowsPerPage
                qaBase={`${qaBase}-view`}
                pageSize={size || 10}
                onClick={(size) => {
                  setPage(0);
                  setSize(size);
                }}
              />
              <Pagination
                qaBase={`${qaBase}-pagination`}
                currentPage={page}
                totalPages={formsCall.data?.totalPages}
                onClick={(page) => setPage(page)}
              />
              <BackToTop qa={`${qaBase}-${ElementType.Link}-backToTop`} />
            </PaginationWrapper>
          </div>
        )}
      </div>
    </ContentWrapper>
  );
};
