import * as React from "react";
import moment from "moment";
import styles from "./Statistics.module.scss";
import useGroupTerm from "shared/src/util/hooks/useGroupTerm";
import { Row } from "shared/src/components/clientAdmin/styles";
import { LegacyStatsCard } from "../../../common/StatsCard";
import { ReportingFormStatsVm } from "@rtslabs/field1st-fe-common";

interface StatisticsProps {
  formStats: ReportingFormStatsVm;
}

export const Statistics = ({ formStats }: StatisticsProps) => {
  const {
    averageCrewSize,
    averageOEsViewed,
    averageStartTime,
    averageTimeToComplete,
    submissionsWithWorkOrders,
    totalSubmissions,
  } = formStats;

  // averageTimeToComplete: seconds since start of day
  // averageStartTime: average seconds between start and submission

  // @isDST() was necessary because time was off by an hour. At this time, time is off again so BE is requesting
  // that the DST check is removed. If this continues to be an issue and further changes are required in the future,
  // BE values would need to be re-checked for consistency and FE formulas sync'ed and documented in requirements -- GK
  // const _averageStartTime = averageStartTime
  //  && moment().startOf("day").subtract(moment().isDST() ? 1 : 0, "hours").add(averageStartTime, "seconds")
  //   .add(moment().utcOffset(), "minutes") || 0;

  const _averageStartTime =
    (averageStartTime &&
      moment()
        .startOf("day")
        .add(averageStartTime, "seconds")
        .add(moment().utcOffset(), "minutes")) ||
    0; // See above
  const startTime =
    (_averageStartTime && _averageStartTime.format("h:mm")) || "NA";
  const startTimeName =
    (_averageStartTime && _averageStartTime.format("a")) || "";

  // Group config terms
  const workOrderTerm = useGroupTerm(
    "workOrder",
    "noun",
    undefined,
    "Work Order"
  );

  const operationExperiencesTerm = useGroupTerm(
    "operationalExperiences",
    "noun",
    "plural",
    "Operational Experiences"
  );

  return (
    <div className={styles.statisticsContainer}>
      <div className={styles.statsRow}>
        <LegacyStatsCard
          header="Total Submissions"
          stretch
          value={totalSubmissions}
        />
        <LegacyStatsCard
          header="Average Time To Complete"
          stretch
          value={averageTimeToComplete}
          type="time"
        />
        <LegacyStatsCard
          header="Start Time"
          stretch
          value={startTime}
          valueName={startTimeName}
        />
      </div>
      <div className={styles.statsRow}>
        <LegacyStatsCard
          header="Crew Size"
          stretch
          value={Math.round(averageCrewSize)}
          valueName="Average"
        />
        <LegacyStatsCard
          header={operationExperiencesTerm}
          stretch
          value={Math.round(averageOEsViewed)}
          valueName="Average"
        />
        <LegacyStatsCard
          header={`${workOrderTerm} vs Blank`}
          stretch
          value={submissionsWithWorkOrders}
          valueName={`of ${totalSubmissions}`}
        />
      </div>
    </div>
  );
};
