import React from "react";

import { DownloadParams, DownloadResultsButton } from "./DownloadResultsButton";
import { CurrentPage, PageSize, TotalElements } from "../Pagination/types";
import { Components, ElementType } from "../../../qa-slugs";

import styles from "./TableSummary.module.scss";
import { Icon } from "../../Icon/Icon";
import { API } from "@rtslabs/field1st-fe-common";
import { ActionButton } from "../../ActionButton/ActionButton";
import { useTranslation } from "react-i18next";

export type ExportFormat = "csv" | "print" | "xls";

interface TableSummaryWithExports<T extends DownloadParams>
  extends TableSummaryBaseProps<T> {
  exports: ExportFormat[];
  onExport: (params: T) => Promise<API.GetFileResponse>;
  exportParams: Omit<T, "mediaType">;
}

interface TableSummaryWithoutExports<T extends DownloadParams>
  extends TableSummaryBaseProps<T> {
  exports?: ["print"];
  onExport?: undefined;
  exportParams?: undefined;
}

interface TableSummaryBaseProps<T extends DownloadParams> {
  currentPage?: CurrentPage;
  exports?: ExportFormat[];
  ofWhat: string;
  pageSize?: PageSize;
  summary?: string | JSX.Element | null; // pass this to manually customize the summary text
  totalElements?: TotalElements;
  onPrint?: () => void;
  onShowAll?: (all: boolean) => void;
  isFiltered?: boolean;
  onClearFilters?: () => void;
}

const getFrom = (
  currentPage: CurrentPage,
  pageSize: PageSize,
  totalElements: TotalElements
) => {
  if (currentPage === 0) {
    if (totalElements === 0) return 0;
    return 1;
  }
  return currentPage * pageSize + 1;
};

const getTo = (
  currentPage: CurrentPage,
  pageSize: PageSize,
  totalElements: TotalElements
) => {
  return Math.min((currentPage + 1) * pageSize, totalElements);
};

const Exports = <T extends DownloadParams>({
  exports,
  onExport,
  exportParams,
  onPrint,
}: Pick<
  TableSummaryWithExports<T>,
  "exports" | "onExport" | "exportParams" | "onPrint"
>) => {
  const { t } = useTranslation("common");

  return (
    <>
      {exports.map((option: ExportFormat, ix: number) => {
        switch (option) {
          case "print":
            return (
              <ActionButton
                className={styles.actionButton}
                IconComponent={<Icon className={styles.icon} type="print" />}
                key={ix}
                label={t("table.printButtonLabel")}
                onClick={onPrint ?? window.print}
                qa={`${Components.TableSummary}-${ElementType.Button}-print`}
              />
            );
          case "xls":
            return (
              <DownloadResultsButton
                className={styles.downloadButton}
                mediaType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                key={ix}
                onExportPress={onExport}
                exportParams={exportParams}
                qa={`${Components.TableSummary}-${ElementType.Button}-xlsExport`}
                iconType="xls_export"
                label={t("table.exportXlsButtonLabel")}
              />
            );
          case "csv":
            return (
              <DownloadResultsButton
                className={styles.downloadButton}
                mediaType="text/csv"
                key={ix}
                onExportPress={onExport}
                exportParams={exportParams}
                qa={`${Components.TableSummary}-${ElementType.Button}-csvExport`}
                iconType="export_csv"
                label={t("table.exportCsvButtonLabel")}
              />
            );
          default:
            return false;
        }
      })}
    </>
  );
};
/** Banner for rendering a summary of data shown in a table */
export const TableSummary = <T extends DownloadParams>({
  summary = null,
  currentPage = 0,
  pageSize = 0,
  totalElements = 0,
  ofWhat,
  exports,
  onExport,
  exportParams,
  onPrint,
  onShowAll,
  isFiltered,
  onClearFilters,
}: TableSummaryWithExports<T> | TableSummaryWithoutExports<T>) => {
  const isShowingAll = pageSize > 100;

  return (
    <div className={styles.wrapper}>
      <div className={styles.summaryText} data-testid="tableSummary">
        {summary ||
          getSummaryText(currentPage, pageSize, totalElements, ofWhat)}
        {onShowAll && (
          <>
            <span> | </span>
            <span
              className={styles.showAll}
              onClick={() => onShowAll(!isShowingAll)}
            >
              Show {isShowingAll ? "less" : "all"}
            </span>
          </>
        )}
      </div>
      <div className={styles.exportClearContainer}>
        {exports && onExport && exportParams && (
          <div className={styles.summaryExports}>
            <Exports
              exports={exports}
              onExport={onExport}
              exportParams={exportParams}
              onPrint={onPrint}
            />
          </div>
        )}
        {isFiltered && (
          <div className={styles.clearFilters} onClick={onClearFilters}>
            Clear Filters
          </div>
        )}
      </div>
    </div>
  );
};

/**
 * Format a summary string into the following format:
 * "Showing (starting number) to (ending number) of (total number) (items)"
 */
function getSummaryText(
  page: number,
  size: number,
  totalEl: number,
  ofWhat: string
): string {
  return `Showing ${getFrom(page, size, totalEl)} to ${getTo(
    page,
    size,
    totalEl
  )} of ${totalEl} ${ofWhat}`;
}
