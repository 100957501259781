import {
  API,
  CoreRedux,
  DocumentStatus,
  DocumentSummaryVm,
  QuestionDTO,
  useAPI,
} from "@rtslabs/field1st-fe-common";
import {
  PageableRequest,
  SubmissionRequestParameters,
} from "@rtslabs/field1st-fe-common/src/types/index";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import ErrorText from "shared/src/components/ErrorText/ErrorText";
import Loader from "shared/src/components/Loader/Loader";
import { TableSummary } from "shared/src/components/TableUI/TableSummary/TableSummary";
import { ContentWrapper } from "shared/src/components/Wrappers/Wrappers";
import {
  DateRangeValue,
  getMinMaxSubmissionDatesFromTimeFilter,
  TimeFilter,
} from "shared/src/data/timeFilters";
import { pluralize } from "shared/src/helpers/utils";
import { Components, ElementType } from "shared/src/qa-slugs";
import useGroupTerm from "shared/src/util/hooks/useGroupTerm";
import { useMergeState } from "shared/src/util/hooks/useMergeState";
import { timeFilterOptions } from "shared/src/data/timeFilters";
import { formTemplateWriteRoles } from "shared/src/components/routes/constants/permissionSets";
import { useAppSelector } from "../../../../store/hooks";
import * as UserStyles from "../../users/styles";
import Modal from "shared/src/components/common/Modal";
import Popper from "shared/src/components/common/Popper";
import { VirtualElement } from "shared/src/components/common/Popper/Popper";
import { default as CommonText } from "shared/src/components/common/Text";
import {
  DocumentDrawer,
  DocumentDrawerSection,
} from "../../documents/DocumentDrawer";
import { generateGroupOptions } from "shared/src/components/clientAdmin/helpers";
import { SubmitButton, Text } from "shared/src/components/clientAdmin/styles";
import { DeleteDocumentConfirmation } from "../../users/modalContent/DeleteDocumentConfirmation";
import { PopperChevron, PopperRow, PopperWrapper } from "../../users/styles";
import { ReportsDocumentsSorting } from "./DocumentsTable";
import Filters from "./Filters";
import FormReportHeader from "./FormReportHeader";
import FormTabs from "./FormTabs";
import { useRatingQuestions } from "./helpers";
import { Statistics } from "./Statistics";
import * as S from "./styles";
import { PageHeader } from "shared/src/components/PageHeader/PageHeader";
import styles from "./FormReport.module.scss";
import SafetyScale from "./SafetyObservationReport/SafetyScale";
import { getTimeFilterFromDays } from "shared/src/helpers/filters";

const DeleteDocumentMenuItem = ({
  loading,
  error,
  closeMenu,
  openConfirmationModal,
  qa,
}: {
  loading: boolean;
  error: boolean;
  closeMenu: () => void;
  openConfirmationModal: () => void;
  qa?: string;
}) => {
  // Group config terms
  const documentTerm = useGroupTerm("document", "noun", undefined, "Document");
  const documentTermLower = documentTerm.toLowerCase();

  return (
    <Loader loading={loading}>
      <S.DeleteDocumentButton
        onClick={() => {
          openConfirmationModal();
          closeMenu();
        }}
        data-testid={qa}
      >
        Delete {documentTermLower} (permission required)
        {error && (
          <CommonText className="ml-2" variant="error">
            An error occurred
          </CommonText>
        )}
      </S.DeleteDocumentButton>
    </Loader>
  );
};

interface Params extends Record<string, string> {
  id: string;
}

export interface SafetyResponse {
  question: QuestionDTO;
  responseCount: number;
  area: string;
  selectionId: number;
}

export const FormReport = () => {
  const participantId = useAppSelector(CoreRedux.selectParticipantId);
  const params = useParams<Params>();
  const [queryString] = useSearchParams();
  const [ratingsFilter, setRatingsFilter] = useState<string[]>([]);
  const [tabIndex, setTabIndex] = useState(0);
  const clientGroupIdString = queryString.get("clientGroupId");
  const clientGroupId = clientGroupIdString
    ? Number(clientGroupIdString)
    : undefined;
  const formId = Number(params.id);
  const pastDaysString = queryString.get("pastDays") ?? undefined;
  const timeFilterString = getTimeFilterFromDays(Number(pastDaysString));

  // print report
  let componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  // groups
  const allGroupCall = useAPI(API.getClientGroups, {
    size: 200,
    sort: "name,asc",
  });
  const groupFilterOptions = generateGroupOptions(allGroupCall.data?.content);

  // date range
  const [timeFilter, setTimeFilter] = useState<TimeFilter>(timeFilterString);
  const [dateRange, setDateRange] = useState<DateRangeValue>();

  // Sorting
  const [sorting, setSorting] = useState<ReportsDocumentsSorting>({
    // Order is by appearance in table
    title: {
      direction: "asc",
    },
    ["clientGroup.name"]: {
      direction: "asc",
    },
    ["owner.lastName"]: {
      direction: "asc",
    },
    dateCreated: {
      direction: "asc",
    },
    submissionDate: {
      direction: "asc",
    },
    primarySort: {
      // Sort by this column
      direction: "desc",
      type: "submissionDate",
    },
  });

  // api parameters
  const [docParams, mergeDocParams] =
    useMergeState<SubmissionRequestParameters>({
      formId,
      clientGroupId,
      status: DocumentStatus.SUBMITTED,
      minSubmissionDate: "",
      maxSubmissionDate: "",
    });
  const [pageable, mergePageable] = useMergeState<PageableRequest>({
    page: 0,
    size: 10,
    sort: [sorting.primarySort.type, sorting.primarySort.direction],
  });

  // if sorting changes, update pageable
  useEffect(
    () =>
      mergePageable({
        sort: [sorting.primarySort.type, sorting.primarySort.direction],
      }),
    [sorting]
  );

  // api calls
  const formStatsCall = useAPI(API.getFormReport, {
    ...docParams,
    formId,
  });
  const documentsCall = useAPI(API.getDocuments, {
    ...docParams,
    ...pageable,
  });

  const minMaxDate = useMemo(() => {
    const res = getMinMaxSubmissionDatesFromTimeFilter(timeFilter, dateRange);
    mergeDocParams({ ...res });
    return res;
  }, [timeFilter, dateRange]);

  const { mappedResponses, totalResponses, SO_FLAG } = useRatingQuestions(
    formStatsCall.data
  );

  const subs = () =>
    pluralize("submission", formStatsCall.data?.totalSubmissions || 1, false);

  /** Generate summary text for summary line */
  const summaryText = () => {
    // @minDate and @maxDate are from component's state
    if (formStatsCall.data) {
      let text = `Showing responses for ${
        formStatsCall.data.totalSubmissions
      } ${subs()}`;
      if (minMaxDate.minSubmissionDate && minMaxDate.maxSubmissionDate) {
        const maxDate = moment(minMaxDate.maxSubmissionDate).format(
          "MM/DD/YYYY"
        );
        const minDate = moment(minMaxDate.minSubmissionDate).format(
          "MM/DD/YYYY"
        );
        text = `${text} from ${minDate}`;
        if (maxDate !== minDate) {
          text = `${text} - ${maxDate}`;
        }
      }
      return text;
    }
    return null;
  };

  // Action menu
  // const [selectedDocumentId, setSelectedDocumentId] = useState<number | null>(null);
  const [showDocumentDrawer, setShowDocumentDrawer] = useState<
    DocumentDrawerSection | undefined
  >();
  const [popperIsOpen, setPopperIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<VirtualElement>(null);

  // Delete document
  const [deleteDocumentError, setDeleteDocumentError] = useState(false);
  const [deleteDocumentIsLoading, setDeleteDocumentIsLoading] = useState(false);
  const [
    deleteDocumentConfirmationModalIsVisible,
    setDeleteDocumentConfirmationModalIsVisible,
  ] = useState(false);
  const [
    deleteDocumentOutcomeModalIsVisible,
    setDeleteDocumentOutcomeModalIsVisible,
  ] = useState(false);
  const [anchorDocument, setAnchorDocument] = useState<DocumentSummaryVm>();

  const _deleteDocument = async () => {
    if (!anchorDocument?.id) {
      return;
    }
    setDeleteDocumentIsLoading(true);
    try {
      await API.deleteDocumentById({ id: anchorDocument.id });
      setDeleteDocumentError(false);
      setShowDocumentDrawer(undefined); // Close drawer if document is deleted
    } catch (e) {
      setDeleteDocumentError(true);
    } finally {
      setDeleteDocumentConfirmationModalIsVisible(false);
      setDeleteDocumentOutcomeModalIsVisible(true);
      setDeleteDocumentIsLoading(false);
    }
  };

  const [isFiltered, setIsFiltered] = useState(false);
  const [remoteClear, setRemoteClear] = useState<boolean>(false);

  const handleClearFilters = () => {
    mergePageable({ page: 0 });
    mergeDocParams({ clientGroupId, query: "" });
    setTimeFilter(timeFilterString);
    setRemoteClear(true);
    setRatingsFilter([]);
  };

  useEffect(() => {
    setRemoteClear(false);
  }, [remoteClear]);

  const viewDocument = useCallback(() => {
    if (anchorDocument && anchorDocument.id) {
      setPopperIsOpen(false);
      setShowDocumentDrawer(DocumentDrawerSection.Document); // View doc
    }
  }, [anchorEl]);

  // Group config terms
  const documentTerm = useGroupTerm("document", "noun", undefined, "Document");
  const documentTermLower = documentTerm.toLowerCase();

  const datePeriod = useMemo(() => {
    // If custom range picked and range is selected
    if (timeFilter === "CUSTOM_RANGE" && dateRange) {
      return `${moment(dateRange.startDate).format("MM/DD/YYYY")} - ${moment(
        dateRange?.endDate
      ).format("MM/DD/YYY")}`;
    }
    return (
      timeFilterOptions.find((option) => option.value === timeFilter)?.label ||
      ""
    );
  }, [dateRange, timeFilter]);

  const isCurrentOwner = anchorDocument?.owner.participantId === participantId;
  const canWriteDelete =
    API.Environment.hasRoleAccess(formTemplateWriteRoles) || isCurrentOwner;

  const qaBase = Components.FormReport;
  const paths = [
    { pathName: "Reports", href: "/reports" },
    {
      pathName: `Form Submission Report: ${
        formStatsCall.data?.form.name ?? ""
      }`,
      href: `/reports/${formId}`,
    },
  ];

  const checkIfFiltered = (): void => {
    const isDefault =
      timeFilter === timeFilterString &&
      clientGroupId === docParams.clientGroupId &&
      !docParams.query &&
      ratingsFilter.length === 0;

    setIsFiltered(!isDefault);
  };

  useEffect(() => {
    checkIfFiltered();
  }, [docParams.clientGroupId, docParams.query, timeFilter, ratingsFilter]);

  return (
    <>
      {showDocumentDrawer && anchorDocument?.id && (
        <DocumentDrawer
          closeDrawer={() => {
            setShowDocumentDrawer(undefined);
            // setSelectedDocumentId(null);
          }}
          documentId={anchorDocument?.id}
          show={showDocumentDrawer}
          onClose={() => {
            setShowDocumentDrawer(undefined);
            // setSelectedDocumentId(null);
          }}
        />
      )}
      {/* Modal for deleting a user */}
      <Modal
        border="none"
        open={deleteDocumentConfirmationModalIsVisible}
        handleClose={() => {
          setDeleteDocumentConfirmationModalIsVisible(false);
        }}
        height="auto"
        width="652px"
        padding="24px 23px"
        content={
          <DeleteDocumentConfirmation
            // user data
            created={anchorDocument && anchorDocument.dateCreated}
            group={
              anchorDocument &&
              anchorDocument.clientGroup &&
              anchorDocument.clientGroup.name
            }
            title={anchorDocument && anchorDocument.title}
            // rest
            loading={deleteDocumentIsLoading}
            onCancel={() => setDeleteDocumentConfirmationModalIsVisible(false)}
            onSubmit={() => _deleteDocument()}
            qa={`${qaBase}-deleteUser`}
          />
        }
        qa={`${qaBase}-deleteUser`}
      />
      {/* Modal for the outcome when deleting a document */}
      <Modal
        border="none"
        open={deleteDocumentOutcomeModalIsVisible}
        handleClose={() => {
          !deleteDocumentError && documentsCall.refresh();
          setDeleteDocumentError(false);
          setDeleteDocumentOutcomeModalIsVisible(false);
        }}
        height="auto"
        width="652px"
        padding="24px 23px"
        content={
          <UserStyles.ModalPromptWrapper>
            <UserStyles.ModalPromptTitle>
              {deleteDocumentError
                ? `Error: ${documentTerm} could not be deleted`
                : `${documentTerm} has been deleted`}
            </UserStyles.ModalPromptTitle>
            <UserStyles.ModalPromptSubtitle>
              {deleteDocumentError
                ? `A problem has occurred while attempting to delete the selected ${documentTermLower}. Try again later or contact your system administrator for support.`
                : `The selected ${documentTermLower} has been successfully deleted. ${documentTerm} will no longer impact relevant reporting.`}
            </UserStyles.ModalPromptSubtitle>
            <UserStyles.DisableUserButtonsWrapper>
              <SubmitButton
                onClick={() => {
                  !deleteDocumentError && documentsCall.refresh();
                  setDeleteDocumentError(false);
                  setDeleteDocumentOutcomeModalIsVisible(false);
                }}
              >
                <span>close </span>
              </SubmitButton>
            </UserStyles.DisableUserButtonsWrapper>
            <UserStyles.ModalPromptCloseIcon
              onClick={() => {
                !deleteDocumentError && documentsCall.refresh();
                setDeleteDocumentError(false);
                setDeleteDocumentOutcomeModalIsVisible(false);
              }}
            />
          </UserStyles.ModalPromptWrapper>
        }
        qa={`${qaBase}-deleteDocument`}
      />
      <Popper
        anchorEl={anchorEl}
        onClose={() => {
          // setPopperIsOpen(false);
        }}
        open={popperIsOpen}
        placement="left"
        qa="action-modal"
      >
        <PopperWrapper>
          <PopperRow
            data-testid={`view-${documentTermLower}-${ElementType.Button}`}
            onClick={() => viewDocument()}
          >
            <Text fontSize="0.9rem">View {documentTermLower}</Text>
            <PopperChevron />
          </PopperRow>
          <PopperRow
            onClick={() => {
              setPopperIsOpen(false);
              // setSelectedDocumentId(anchorEl && anchorEl.id);
              setShowDocumentDrawer(DocumentDrawerSection.History);
            }}
            data-testid={`view-${documentTermLower}-history-${ElementType.Button}`}
          >
            <Text fontSize="0.9rem">View {documentTermLower} history</Text>
            <PopperChevron />
          </PopperRow>
          {(canWriteDelete ||
            anchorDocument?.owner.participantId === participantId) && (
            <PopperRow>
              <DeleteDocumentMenuItem
                loading={deleteDocumentIsLoading}
                error={deleteDocumentError}
                closeMenu={() => setPopperIsOpen(false)}
                openConfirmationModal={() =>
                  setDeleteDocumentConfirmationModalIsVisible(true)
                }
                qa={`delete-${documentTermLower}-${ElementType.Button}`}
              />
              <PopperChevron />
            </PopperRow>
          )}
        </PopperWrapper>
      </Popper>
      <ContentWrapper id="mainContent">
        <PageHeader paths={paths} qa={qaBase} />

        {formStatsCall.data && (
          <FormReportHeader formStats={formStatsCall.data} />
        )}

        <div className={styles.formReport}>
          <div id="caFormFiltersAndStats">
            <Filters
              dateRange={dateRange}
              groupFilter={{
                groupId: docParams.clientGroupId,
                setGroupId: (clientGroupId) =>
                  mergeDocParams({
                    clientGroupId:
                      clientGroupId !== 0 ? clientGroupId : undefined,
                  }),
                groupFilterOptions,
              }}
              handleChangeDateRange={setDateRange}
              ratingsFilter={{
                ratingsFilter,
                setRatingsFilter,
                ratingOptions: Object.keys(mappedResponses),
              }}
              safetyFlag={SO_FLAG}
              setQuery={(query) => mergeDocParams({ query })}
              timeFilter={timeFilter}
              setTimeFilter={setTimeFilter}
              remoteClear={remoteClear}
            />
          </div>

          <Loader loading={formStatsCall.isLoading}>
            <div id="caFormTableSummary">
              <TableSummary
                pageSize={pageable.size}
                currentPage={pageable.page}
                totalElements={documentsCall.data?.totalElements}
                ofWhat="submissions"
                exports={["print", "xls", "csv"]}
                onExport={API.downloadFormResponses}
                exportParams={{
                  formId,
                  clientGroupId: docParams.clientGroupId,
                  ...minMaxDate,
                }}
                onPrint={handlePrint}
                summary={summaryText()}
                isFiltered={isFiltered}
                onClearFilters={handleClearFilters}
              />
            </div>

            {formStatsCall.data && (
              <Statistics formStats={formStatsCall.data} />
            )}

            {SO_FLAG && (
              <SafetyScale
                dayFilterValue={datePeriod}
                loading={!!formStatsCall.isLoading}
                ratingsFilter={ratingsFilter}
                responses={mappedResponses}
                totalResponseCount={totalResponses}
              />
            )}

            <div className={styles.summaryContainer}>
              {!!formStatsCall.error && (
                <ErrorText>
                  An error occurred when loading the form. Please try again"
                </ErrorText>
              )}

              {!formStatsCall.error && (
                <FormTabs
                  ref={componentRef}
                  formId={formId}
                  onPaginate={(page, size) => mergePageable({ page, size })}
                  formStats={formStatsCall}
                  value={tabIndex}
                  handleTabChange={(event, newIndex) => setTabIndex(newIndex)}
                  pagination={{
                    currentPage: pageable.page || 0,
                    pageSize: pageable.size || 10,
                  }}
                  popper={{
                    popperIsOpen,
                    setAnchorDocument,
                    setAnchorEl,
                    setPopperIsOpen,
                    setShowDocumentDrawer,
                  }}
                  shouldRenderSafetyTab={SO_FLAG}
                  sorting={{
                    setSorting,
                    sorting,
                  }}
                  safetyReportProps={{
                    totalResponses: totalResponses,
                    ratingsFilter: ratingsFilter,
                    mappedRiskResponses: mappedResponses,
                  }}
                  documents={documentsCall}
                />
              )}
            </div>
          </Loader>
        </div>
      </ContentWrapper>
    </>
  );
};
