import React, { FC, useState } from "react";
import { API, TagDTO } from "@rtslabs/field1st-fe-common";
import TagForm from "./TagForm";
import Loader from "shared/src/components/Loader/Loader";
import { GenericModal } from "shared/src/components/Generic/Modal/GenericModal";
import { tagWriteRoles } from "../../../../routes/constants/permissionSets";

interface AddTagProps {
  visible: boolean;
  onClose: (refresh: boolean) => void;
}

export const AddTag: FC<AddTagProps> = ({ visible, onClose }) => {
  const [tag, setTag] = useState<TagDTO>({
    createdDate: "",
    id: 0,
    name: "",
    useCount: 0,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Commented out edit tag related code since it's not used and for clean up - AE 9/19/24

  // get tag id from url params
  //   const { id: tagId } = useParams<{ id: string }>();

  //   const fetchTag = async (id: string) => {
  //     setIsLoading(true);
  //     try {
  //       const res = await API.getOETag({ id: Number(id) });
  //       setTag(res);
  //     } catch (e) {
  //       console.error(e);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  //   useEffect(() => {
  //     if (tagId) {
  //       fetchTag(tagId);
  //     }
  //   }, []);

  //   const currentPathName = tagId ? "Edit Tag" : "Add Tag";
  const currentPathName = "Add Tag";

  const handleClose = () => {
    onClose(false);
  };

  const hasTagWriteRoles = API.Environment.hasRoleAccess(tagWriteRoles);

  return (
    <GenericModal
      isOpen={!!visible}
      title={currentPathName}
      onClose={handleClose}
      hasAccess={hasTagWriteRoles}
    >
      <Loader loading={isLoading}>
        <TagForm initialValues={tag} onClose={onClose} />
      </Loader>
    </GenericModal>
  );
};
