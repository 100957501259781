import React, { FocusEvent, useEffect, useMemo, useState } from "react";
import { TextInput } from "./TextInput";
import { TextInputProps } from "./types";
import { debounce } from "lodash";
import { Suggestions } from "./Suggestions";
import { FieldMessagingWrapper } from "../Document/DocumentForm/FieldMessagingWrapper";

export interface TextInputSuggestion {
  [keyName: string]: any;
}

interface TextInputWithSuggestionsProps<T> extends TextInputProps {
  idField: string;
  labelField: string;
  onInputChange: (value: string) => void;
  onSelectSuggestion: (suggestion: T) => void;
  suggestions: T[];
  showSuggestions: boolean;
  value: string;
  debounceDelay?: number;
  disabled?: boolean;
  isMultiInput?: boolean;
  warning?: string;
  // save on blur is used to save the value when the input is blurred, usually for Location inputs
  // rather than autosaving while typing inside input.
  saveOnBlur?: boolean;
}

const TextInputWithSuggestions = <T extends TextInputSuggestion>({
  idField,
  labelField,
  onInputChange,
  onSelectSuggestion,
  suggestions,
  showSuggestions,
  value,
  assistiveText,
  error,
  response,
  debounceDelay = 0,
  disabled,
  isMultiInput,
  warning,
  saveOnBlur,
  ...textInputProps
}: TextInputWithSuggestionsProps<T>): JSX.Element => {
  const [input, setInput] = useState<string>(value);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  // updates input when selecting suggestion and updating Map markers
  useEffect(() => {
    if (!isFocused || showSuggestions) {
      if (!saveOnBlur && response) {
        setInput(response?.answer);
      } else if (value) {
        setInput(value);
      } else {
        setInput("");
      }
    }
  }, [response, value, isFocused]);

  const debouncedChangeHandler = useMemo(
    () =>
      debounce(onInputChange, debounceDelay, {
        trailing: true,
      }),
    [response, value]
  );

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => {
    setIsFocused(false);

    if (saveOnBlur && textInputProps.onBlur) {
      textInputProps.onBlur(e);
    }
  };

  return (
    <FieldMessagingWrapper
      assistiveText={assistiveText}
      error={error}
      warning={warning}
      qa={textInputProps.qa}
    >
      <TextInput
        {...textInputProps}
        value={input}
        onChange={(
          e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => {
          setInput(e.target.value);
          debouncedChangeHandler.cancel();
          debouncedChangeHandler(e.target.value);
        }}
        {...(!isMultiInput && {
          onFocus: handleFocus,
          onBlur: handleBlur,
        })}
        error={!!error}
        disabled={disabled}
      />
      {showSuggestions && value && suggestions.length > 0 && (
        <Suggestions
          suggestions={suggestions}
          labelField={labelField}
          onSelectSuggestion={onSelectSuggestion}
        />
      )}
    </FieldMessagingWrapper>
  );
};

export default TextInputWithSuggestions;
