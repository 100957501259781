import React from "react";

import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import PrivacyPolicyContent from "./PrivacyPolicyContent";
import { ContentWrapper } from "../Wrappers/Wrappers";
import useGroupTerm from "../../util/hooks/useGroupTerm";
import { PageHeader } from "../PageHeader/PageHeader";
import { Components } from "../../qa-slugs";

const Privacy = () => {
  const terms = {
    employee: {
      singular: useGroupTerm("employee", "noun", undefined, "Employee"),
      plural: useGroupTerm("employee", "noun", "plural", "Employees"),
    },
  };

  const qaBase = Components.Privacy;
  const paths = [{ pathName: "Privacy", href: "/privacy" }];

  return (
    <ContentWrapper id="mainContent">
      <PageHeader pageTitle="Privacy Policy" paths={paths} qa={qaBase} />
      <PrivacyPolicyContent terms={terms} />
    </ContentWrapper>
  );
};

export default Privacy;
