import { Formik, FormikHelpers } from "formik";
import React from "react";
import { ButtonRow } from "shared/src/components/Profile/EditProfile/ButtonRow";
import { TextInput } from "shared/src/components/TextInput/TextInput";
import { Components, ElementType } from "shared/src/qa-slugs";
import CompanyAddress from "./CompanyAddress";
import { CompanyForm, companySchema } from "./companyInfoHelpers";
import CompanyLogoView from "./CompanyLogoView";
import styles from "./CompanyInfo.module.scss";

interface FormikCompanyInfoProps {
  handleSubmitForm: (
    values: CompanyForm,
    formikHelpers: FormikHelpers<CompanyForm>
  ) => void;
  companyForm: CompanyForm;
  isReadOnly?: boolean;
  setPrimaryImageFile: React.Dispatch<React.SetStateAction<File | undefined>>;
  setCondensedImageFile: React.Dispatch<React.SetStateAction<File | undefined>>;
}

export const FormikCompanyInfo = ({
  handleSubmitForm,
  companyForm,
  isReadOnly,
  setPrimaryImageFile,
  setCondensedImageFile,
}: FormikCompanyInfoProps) => {
  return (
    <Formik
      initialValues={companyForm}
      validationSchema={companySchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleSubmitForm}
    >
      {({
        errors,
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        setFieldValue,
        resetForm,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            {/* Primary and Condensed Logos */}
            <CompanyLogoView
              setFieldValue={setFieldValue}
              setPrimaryImageFile={setPrimaryImageFile}
              setCondensedImageFile={setCondensedImageFile}
              primaryLogo={values["primaryLogo"]}
              condensedLogo={values["condensedLogo"]}
              companyName={values["name"]}
              isReadOnly={isReadOnly}
            />
            <div className={styles.formFieldsContainer}>
              {/* Company Name */}
              <TextInput
                qa={`${Components.CompanyInfo}-${ElementType.TextInput}-company-name`}
                name="name"
                label="Company Name"
                placeholder="Company Name"
                onChange={handleChange}
                error={errors["name"]}
                value={values["name"]}
                disabled={isReadOnly}
              />

              {/* Email */}
              <TextInput
                qa={`${Components.CompanyInfo}-${ElementType.TextInput}-email`}
                name="email"
                label="Company Contact Email"
                placeholder="Company Contact Email"
                type="email"
                onChange={handleChange}
                error={errors["email"]}
                value={values["email"]}
                disabled={isReadOnly}
              />

              {/* Company Address */}
              <CompanyAddress
                address1={values["address1"]}
                address2={values["address2"]}
                city={values["city"]}
                state={values["state"]}
                zip={values["zip"]}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                errors={errors}
                disabled={isReadOnly}
              />

              {/* Buttons */}
              {!isReadOnly && (
                <ButtonRow
                  onSubmit={handleSubmit}
                  onCancel={() => {
                    setPrimaryImageFile(undefined);
                    setCondensedImageFile(undefined);
                    resetForm();
                  }}
                  loading={isSubmitting}
                  qa={Components.CompanyInfo}
                />
              )}
            </div>
          </form>
        );
      }}
    </Formik>
  );
};
