import {
  DataSourceDTO,
  DataSourceManagementType,
  DataSourceStatus,
} from "@rtslabs/field1st-fe-common";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { TableCell } from "shared/src/components/TableUI/TableCell/TableCell";
import { TableRow } from "shared/src/components/TableUI/TableRow/TableRow";
import { joinClassNames } from "shared/src/helpers/theme.helpers";
import { Components, ElementType } from "shared/src/qa-slugs";
import { toTitleCase } from "shared/src/util/toTitleCase";
import TableRowMenu from "../../common/TableUI/TableRowMenu/TableRowMenu";
import styles from "./styles.module.scss";
import stylesDS from "./DataSets.module.scss";

interface Props {
  canEdit?: boolean;
  onChangeStatus: (source: DataSourceDTO) => void;
  onDownloadCsv: (source: DataSourceDTO) => void;
  onEdit: (source: DataSourceDTO) => void;
  source: DataSourceDTO;
}

export const DSTableRow: FC<Props> = ({
  canEdit = true,
  onChangeStatus,
  onDownloadCsv,
  onEdit,
  source,
}) => {
  const { t } = useTranslation();

  const changeStatus = () => onChangeStatus(source);
  const downloadCsv = () => onDownloadCsv(source);
  const editDataSet = () => onEdit(source);

  return (
    <TableRow qa={`${Components.DSTable}-${ElementType.TableRow}-${source.id}`}>
      <TableCell
        qa={`${Components.DSTable}-titleAndDescription-${source.id}`}
        className={joinClassNames(styles.titleCell, stylesDS.firstCell)}
      >
        <div
          data-testid={`${Components.DSTable}-${ElementType.Text}-title-${source.id}`}
          className={styles.dataSetTitle}
        >
          {source.title}
        </div>
        <div
          data-testid={`${Components.DSTable}-${ElementType.Text}-description-${source.id}`}
          className={styles.dataSetDescription}
        >
          {source.description}
        </div>
      </TableCell>
      <TableCell qa={`${Components.DSTable}-author-${source.id}`}>
        {source.author?.fullName}
      </TableCell>
      <TableCell qa={`${Components.DSTable}-managed-${source.id}`}>
        {source.managed === "API" ? "API" : "Manual"}
      </TableCell>
      <TableCell qa={`${Components.DSTable}-uses-${source.id}`}>
        {source.uses}
      </TableCell>
      <TableCell
        qa={`${Components.DSTable}-status-${source.id}`}
        className={joinClassNames(
          source.status === DataSourceStatus.PUBLISHED &&
            styles.statusPublished,
          source.status === DataSourceStatus.UNPUBLISHED &&
            styles.statusUnpublished
        )}
      >
        {toTitleCase(source.status)}
      </TableCell>
      <TableCell className={stylesDS.lastCell}>
        <TableRowMenu
          buttonId={`${Components.DataSets}-${ElementType.Button}-actions-${source.id}`}
          label={t("common:table.rowMenuButtonLabel")}
          menuId={`${Components.DataSets}-${ElementType.Menu}-actions-${source.id}`}
          menuOptions={[
            canEdit && {
              iconType: "edit",
              id: `${Components.DataSets}-${ElementType.MenuItem}-edit-${source.id}`,
              label: "View/edit data set",
              onSelect: editDataSet,
            },
            source.managed === DataSourceManagementType.MANUAL && {
              iconType: "download",
              id: `${Components.DataSets}-${ElementType.MenuItem}-downloadCsv-${source.id}`,
              label: "Download CSV",
              onSelect: downloadCsv,
            },
            canEdit &&
              source.status === DataSourceStatus.UNPUBLISHED && {
                iconType: "circle_check",
                id: `${Components.DataSets}-${ElementType.MenuItem}-publish-${source.id}`,
                label: "Publish data set",
                onSelect: changeStatus,
                variant: "success",
              },
            canEdit &&
              source.status !== DataSourceStatus.UNPUBLISHED && {
                iconType: "trash",
                id: `${Components.DataSets}-${ElementType.MenuItem}-unpublish-${source.id}`,
                label: "Unpublish data set",
                onSelect: changeStatus,
                variant: "error",
              },
          ]}
        />
      </TableCell>
    </TableRow>
  );
};
