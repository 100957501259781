import React, { FC } from "react";
import styles from "../DeleteUserModal.module.scss";
import { GenericButton } from "shared/src/components/Generic/Button/GenericButton";

interface Props {
  onClose: () => void;
}
export const DeleteUserFailure: FC<Props> = ({ onClose }) => (
  <>
    <div className={styles.modalContent}>
      The system encountered an error while trying to delete the selected user.
      Please try again.
    </div>
    <div className={styles.buttonWrapper}>
      <GenericButton onClick={onClose} label="Close" />
    </div>
  </>
);
