import { FormTypeDTO } from "@rtslabs/field1st-fe-common";
import { SelectOption } from "../components/Select/Select";
import { TimeFilter } from "../data/timeFilters";

export const mapFormTypesToFilter = (
  formTypes?: Array<FormTypeDTO>
): SelectOption<number>[] => {
  if (formTypes) {
    return formTypes.map((formType: FormTypeDTO) => ({
      value: formType.id,
      label: formType.name,
    }));
  }
  return [];
};

export const getPastDays = (timeFilter: TimeFilter): number | undefined => {
  switch (timeFilter) {
    case TimeFilter.FIFTEEN_DAYS:
      return 15;
    case TimeFilter.SEVEN_DAYS:
      return 7;
    case TimeFilter.THIRTY_DAYS:
      return 30;
    case TimeFilter.TODAY:
      return 1;
    case TimeFilter.YESTERDAY:
      return 2;
    default:
    case TimeFilter.ALL_TIME:
    case TimeFilter.CUSTOM_RANGE:
      return undefined;
  }
};

export const getTimeFilterFromDays = (pastDays: number): TimeFilter => {
  switch (pastDays) {
    case 15:
      return TimeFilter.FIFTEEN_DAYS;
    case 7:
      return TimeFilter.SEVEN_DAYS;
    case 30:
      return TimeFilter.THIRTY_DAYS;
    case 1:
      return TimeFilter.TODAY;
    case 2:
      return TimeFilter.YESTERDAY;
    default:
      return TimeFilter.ALL_TIME;
  }
};
