import {
  API,
  AuthProviderType,
  JWTAuthorityDTO,
  ParticipantType,
  ParticipantUserVm,
} from "@rtslabs/field1st-fe-common";
import { Formik } from "formik";
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import Label from "shared/src/components/Label/Label";
import Loader from "shared/src/components/Loader/Loader";
import { Modal } from "shared/src/components/Modal/Modal";
import { RadioButton } from "shared/src/components/RadioButtons/RadioButton";
import {
  RadioButtonOption,
  RadioButtonsGroup,
} from "shared/src/components/RadioButtons/RadioButtonsGroup";
import {
  OptionContent,
  SearchSelect,
} from "shared/src/components/SearchableSelect/SearchSelect";
import { TextInput } from "shared/src/components/TextInput/TextInput";
import { Components, ElementType, Page } from "shared/src/qa-slugs";
import useGroupTerm from "shared/src/util/hooks/useGroupTerm";
import { useInfiniteScrollAPI } from "shared/src/util/hooks/useInfiniteScrollAPI";
import { AppState } from "../../../store";
import { ErrorText } from "shared/src/components/clientAdmin/resources/styles";
import { RolesAndGroups } from "./components/RolesAndGroups";
import {
  atLeastOneRoleHasGroup,
  buildBreadcrumbs,
  EditUserFormValues,
  getInitialValues,
  mapClientGroupsOptions,
  mapSupervisorOptions,
  mapWorkLocations,
  updateAuthorities,
  validateAddUserForm,
} from "./helpers";
import {
  errorToastOptions,
  Toast,
  ToastStatus,
  updateToast,
} from "../../../../../shared/src/components/Toast/Toastify";
import { toast } from "react-toastify";
import { ContentWrapper } from "../../../../../shared/src/components/Wrappers/Wrappers";
import { PageHeader } from "../../../../../shared/src/components/PageHeader/PageHeader";
import styles from "./AddEditUser.module.scss";
import { GenericButton } from "../../../../../shared/src/components/Generic/Button/GenericButton";

interface Params extends Record<string, string> {
  id: string;
}

// Status options
const statusOptions: RadioButtonOption<string>[] = [
  { data: "ACTIVE", label: { text: "Active" } },
  { data: "DISABLED", label: { text: "Inactive" } },
];

const AddEditUser: FC = () => {
  const navigate = useNavigate();
  const params = useParams<Params>();
  const dispatch = useDispatch<ThunkDispatch<AppState, void, Action>>();
  const qaToastId = Components.EditUser;

  // Group config terms
  const supervisorTerm = useGroupTerm(
    "supervisor",
    "noun",
    undefined,
    "Supervisor"
  );
  // Get user id from url
  const participantId = params.id ? Number(params.id) : undefined;

  // current user
  const [currentUser, setCurrentUser] = useState<Partial<ParticipantUserVm>>(
    {}
  );
  const [currentUserIsLoading, setCurrentUserIsLoading] = useState<boolean>(
    !!participantId
  );
  const [currentUserError, setCurrentUserError] = useState<boolean>(false);
  // authorities
  const [authorities, setAuthorities] = useState<JWTAuthorityDTO[]>([]);

  // form submission
  const [submitStatus, setSubmitStatus] = useState<string | null>(null);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const topOfForm = useRef<HTMLDivElement>(null);

  // form cancellation
  const [isCancelled, setIsCancelled] = useState<boolean>(false);

  function showError(message: string) {
    updateToast(
      <Toast status={ToastStatus.Error} message={message} />,
      qaToastId,
      errorToastOptions
    );
  }

  const workLocationsCall = useInfiniteScrollAPI(
    API.getWorkLocations,
    { sort: "name,asc" },
    () => showError("Problem getting work locations")
  );

  const supervisorsCall = useInfiniteScrollAPI(
    API.getParticipants,
    { sort: "name,asc" },
    () => showError("Problem getting supervisors")
  );

  const primaryGroupsCall = useInfiniteScrollAPI(
    API.getClientGroups,
    { sort: "name,asc" },
    () => showError("Problem getting primary groups")
  );

  /** get user data */
  const getUserData = useCallback(() => {
    if (participantId) {
      API.getUserByParticipantId({ participantId })
        .then((e) => {
          setCurrentUser(e);
          setAuthorities(e.authorities || []);
        })
        .catch(() => setCurrentUserError(true))
        .finally(() => setCurrentUserIsLoading(false));
    }
  }, [dispatch, participantId]);

  // Fetch current user data to prefill form
  useEffect(() => getUserData(), [getUserData, participantId]);

  // Initial values for formik
  const initialValues = useMemo(
    () => getInitialValues(currentUser),
    [currentUser.email, participantId]
  );

  /***
   * Form Submission
   ***/

  /**
   * Submit formik form
   * @param values Form values
   */
  const _handleSubmit = (values: EditUserFormValues) => {
    if (values?.createUser && !atLeastOneRoleHasGroup(authorities)) {
      showError("At least one Group / Role set must be present");
      return;
    } else {
      toast.dismiss(qaToastId);
    }

    if (values) {
      // API call to submit
      setSubmitLoading(true);
      setSubmitStatus(null);

      let callPromise: Promise<ParticipantUserVm>;
      if (!participantId) {
        callPromise = API.createUser({
          user: {
            // editable
            authorities,
            createUser: values.createUser,
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            nickname: values.nickname,
            primaryGroupId: values.primaryGroupId,
            supervisorId: values.supervisorId,
            workLocationId: values.workLocationId!,

            // standard
            authProviderType: AuthProviderType.LOCAL,
            participantType: "EMPLOYEE" as ParticipantType,
          },
        });
      } else {
        callPromise = API.updateParticipant({
          user: {
            // editable
            authorities,
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            nickname: values.nickname,
            primaryGroupId: values.primaryGroupId!,
            supervisorId: values.supervisorId,
            workLocationId: values.workLocationId!,

            // unchanged
            userId: currentUser.userId!,
            accountLocked: currentUser.accountLocked!,
            authProviderType: currentUser.authProviderType!,
            authProviderId: currentUser.authProviderId!,
            imageUrl: currentUser.imageUrl,
            langKey: currentUser.langKey,
            participantType: currentUser.participantType,
          },
        });
      }
      callPromise
        .then(() => setSubmitStatus("success"))
        .catch(() => setSubmitStatus("failure"))
        .finally(() => setSubmitLoading(false));
    }
  };

  // On successful form submit, go to `people/users`
  useEffect(() => {
    if (submitStatus === "success") {
      navigate("/people/users", { replace: true });
    } else if (submitStatus === "failure") {
      showError("Failed to submit");
    }
  }, [history, submitStatus]);

  useEffect(() => {
    return () => toast.dismiss(qaToastId);
  }, []);

  /***
   * Build field options
   ***/

  // Supervisor options
  const supervisorOptions = useMemo(() => {
    const result = mapSupervisorOptions(supervisorsCall.data, currentUser);
    // Add default empty option
    result.unshift({
      id: null,
      label: "No supervisor",
    });
    return result;
  }, [supervisorsCall.data]);

  // Work location options
  const workLocationOptions = useMemo(() => {
    const result = mapWorkLocations(workLocationsCall.data);
    // Add default empty option
    result.unshift({
      id: null,
      label: "No work location",
    });
    return result;
  }, [workLocationsCall.data]);

  // Client group options
  const clientGroupOptions = useMemo(() => {
    return mapClientGroupsOptions(primaryGroupsCall.data);
  }, [primaryGroupsCall.data]);

  const qaBase = Components.EditUser;
  const paths = useMemo(
    () => buildBreadcrumbs(participantId, currentUser),
    [currentUser.firstName, participantId]
  );

  return (
    <ContentWrapper id="mainContent">
      <Modal
        qaBase={qaBase}
        action={{
          text: "Yes",
          callback: () => navigate("/people/users"),
          loading: false,
        }}
        secondaryText="No"
        alert={{
          variant: "error",
          title: "Are you sure you want to cancel?",
          message: "Changes you made will not be saved.",
          isVisible: true,
        }}
        handleClose={() => setIsCancelled(!isCancelled)}
        open={isCancelled}
        title="Are you sure?"
      />

      <PageHeader
        pageTitle={`${
          participantId
            ? `Edit User Profile${
                currentUser?.fullName ? `: ${currentUser?.fullName}` : ""
              }`
            : "Add New User"
        }`}
        paths={paths}
      />
      <div
        className={styles.pageContent}
        data-testid={`${Page.Desktop}-${qaBase}`}
      >
        <Loader loading={currentUserIsLoading} spinnerProps={{ size: 20 }}>
          <Formik
            initialValues={initialValues}
            onSubmit={_handleSubmit}
            enableReinitialize
            validate={validateAddUserForm}
            validateOnChange={false}
          >
            {(props) => {
              const {
                errors,
                handleSubmit,
                isValid,
                setFieldValue,
                touched,
                values,
                getFieldProps,
                submitCount,
              } = props;

              // Lift this state up so we can use it for form level validation
              useEffect(() => {
                if (submitCount > 0 && !isValid) {
                  showError(
                    `Oops, there was an issue submitting the form. 
                    Check the fields below for more helpful error messages.`
                  );
                  topOfForm.current?.scrollIntoView({ behavior: "smooth" });
                }
              }, [submitCount, isValid]);

              return (
                <form onSubmit={handleSubmit} className={styles.formContainer}>
                  <div className={styles.inputContainer}>
                    {/* First Name */}
                    <TextInput
                      {...getFieldProps("firstName")}
                      label="First Name"
                      placeholder="First Name"
                      error={
                        (touched.firstName || submitCount > 0) &&
                        errors.firstName
                      }
                      qa={`${qaBase}-${ElementType.TextInput}-firstName`}
                    />

                    {/* Last Name */}
                    <TextInput
                      {...getFieldProps("lastName")}
                      label="Last Name"
                      placeholder="Last Name"
                      error={
                        (touched.lastName || submitCount > 0) && errors.lastName
                      }
                      qa={`${qaBase}-${ElementType.TextInput}-lastName`}
                    />

                    {/* Nickname */}
                    <TextInput
                      {...getFieldProps("nickname")}
                      label="Nickname"
                      placeholder="Nickname"
                      qa={`${qaBase}-${ElementType.TextInput}-nickName`}
                    />

                    {/* Emil Address */}
                    <TextInput
                      {...getFieldProps("email")}
                      label="Email Address"
                      placeholder="Email Address"
                      error={(touched.email || submitCount > 0) && errors.email}
                      qa={`${qaBase}-${ElementType.TextInput}-email`}
                    />
                  </div>

                  <div className={styles.selectContainer}>
                    {/* Work Location */}
                    <SearchSelect
                      {...getFieldProps("workLocationId")}
                      label="Work Location"
                      handleChange={(value) => {
                        setFieldValue(
                          "workLocationId",
                          Array.isArray(value) ? undefined : value?.id,
                          true
                        );
                      }}
                      handleUpdateSearch={(s) =>
                        workLocationsCall.setQuery(s ?? "")
                      }
                      isApiSearch
                      onLoadMore={workLocationsCall.loadMore}
                      isFinalPage={workLocationsCall.isLastPage}
                      placeholder={"Work Location"}
                      options={workLocationOptions}
                      value={
                        workLocationOptions.find(
                          (e) => e.id === values["workLocationId"]
                        ) || null
                      }
                      error={
                        (touched.workLocationId || submitCount > 0) &&
                        errors.workLocationId
                      }
                      qa={`${qaBase}-${ElementType.TextInput}-workLocation`}
                    />

                    {/* Supervisor */}
                    <SearchSelect
                      {...getFieldProps("supervisorId")}
                      label={supervisorTerm}
                      handleChange={(value) => {
                        setFieldValue(
                          "supervisorId",
                          Array.isArray(value) ? undefined : value?.id
                        );
                      }}
                      handleUpdateSearch={(s) =>
                        supervisorsCall.setQuery(s ?? "")
                      }
                      isApiSearch
                      onLoadMore={supervisorsCall.loadMore}
                      placeholder={supervisorTerm}
                      options={supervisorOptions}
                      value={
                        supervisorOptions.find(
                          (e) => e.id === values["supervisorId"]
                        ) || null
                      }
                      qa={`${qaBase}-${ElementType.TextInput}-supervisor`}
                    />

                    {/* Primary Group */}
                    <SearchSelect
                      {...getFieldProps("primaryGroupId")}
                      label="Primary Group"
                      handleChange={(
                        value: OptionContent | OptionContent[] | null
                      ) => {
                        setFieldValue(
                          "primaryGroupId",
                          Array.isArray(value) ? undefined : value?.id,
                          true
                        );
                      }}
                      handleUpdateSearch={(s) =>
                        primaryGroupsCall.setQuery(s ?? "")
                      }
                      isApiSearch
                      onLoadMore={primaryGroupsCall.loadMore}
                      placeholder={"Select group"}
                      options={clientGroupOptions}
                      touched={touched["primaryGroupId"]}
                      value={
                        clientGroupOptions.find(
                          (e) => e.id === values["primaryGroupId"]
                        ) || null
                      }
                      error={
                        (touched.primaryGroupId || submitCount > 0) &&
                        errors.primaryGroupId
                      }
                      qa={`${qaBase}-${ElementType.TextInput}-primaryGroup`}
                    />
                  </div>

                  {/* Status */}
                  <RadioButtonsGroup<string | undefined>
                    {...getFieldProps("status")}
                    label="Status"
                    name="status"
                    onChange={(e) =>
                      e === "ACTIVE"
                        ? setFieldValue("status", e)
                        : setFieldValue("status", "DISABLED")
                    }
                    options={statusOptions}
                    value={values["status"]}
                    qa={`${qaBase}-${ElementType.RadioGroup}-status`}
                  />

                  {/* User Type */}
                  {!participantId && (
                    <div>
                      <Label>User Type</Label>
                      <RadioButton
                        {...getFieldProps("createUser")}
                        checked={values.createUser}
                        label="User (with system login)"
                        onChange={() => {
                          setFieldValue("createUser", true);
                        }}
                        qa={`${qaBase}-userType_user`}
                      />
                      <RadioButton
                        {...getFieldProps("createUser")}
                        checked={!values.createUser}
                        label="Crew Member Participant (no system login)"
                        onChange={() => {
                          setFieldValue("createUser", false);
                        }}
                        qa={`${qaBase}-userType_crewMember`}
                      />
                    </div>
                  )}

                  {/* This shows if you're creating a user only, doesn't show for participants -- GK */}
                  {/* Groups & Roles */}
                  {values.createUser && (
                    <RolesAndGroups
                      authorities={authorities}
                      updateAuthority={(
                        authority: JWTAuthorityDTO,
                        method?: "replace"
                      ) => {
                        setAuthorities(
                          updateAuthorities(authorities, authority, method)
                        );
                      }}
                      removeAuthority={(authority: string) => {
                        setAuthorities(
                          authorities.filter((a) => a.authority !== authority)
                        );
                      }}
                    />
                  )}

                  <div className={styles.buttonsWrapper}>
                    <GenericButton
                      data-testid={`${qaBase}-${ElementType.Button}-addOrEdit`}
                      loading={submitLoading}
                      type="submit"
                      label={`${participantId ? "edit" : "add"} user`}
                    />
                    <GenericButton
                      data-testid={`${qaBase}-${ElementType.Button}-cancel`}
                      disabled={submitLoading}
                      onClick={() => setIsCancelled(true)}
                      label="Cancel"
                      buttonStyle="tertiary"
                    />
                  </div>
                </form>
              );
            }}
          </Formik>
          {currentUserError && <ErrorText>{currentUserError}</ErrorText>}
        </Loader>
      </div>
    </ContentWrapper>
  );
};

export default AddEditUser;
