import {
  OETagDTO,
  ResourceDTO,
  ResourceType,
  TagLibraryVM,
} from "@rtslabs/field1st-fe-common";
import { Field, useField, useFormikContext } from "formik";
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Select, SelectOption } from "../../Select/Select";
import { Components, ElementType } from "../../../qa-slugs";
import useGroupTerm from "../../../util/hooks/useGroupTerm";
import {
  isFile,
  isUploadedFile,
  UploadedFile,
} from "../../common/form/FileUpload/FileUploadField";
import { PillBox } from "../../common/Pill/PillBox";
import ResourceDefenseDocUploadField from "./fields/ResourceDefenseDocUploadField";
import ResourceDescriptionField from "./fields/ResourceDescriptionField";
import ResourceTitleField from "./fields/ResourceTitleField";
import { ResourceValues } from "./resourceHelpers";
import * as S from "./styles";
import {
  errorToastOptions,
  Toast,
  ToastStatus,
  updateToast,
} from "../../Toast/Toastify";
import { toast } from "react-toastify";
import { GenericButton } from "../../Generic/Button/GenericButton";
import styles from "./ResourceForm.module.scss";

const ResourceHistoryItem = ({
  author,
  label,
  date,
}: {
  author: string;
  label: string;
  date: Moment;
}) => {
  return (
    <S.ResourceHistoryItem>
      <b>{label}:</b> {date.format("MM/DD/YYYY")} at {date.format("h:mma")} by{" "}
      {author}
    </S.ResourceHistoryItem>
  );
};

const ResourceHistory = ({
  resourceSummary,
}: {
  resourceSummary: ResourceDTO;
}) => {
  const { createdDate, lastModifiedDate } = resourceSummary;
  const createdAuthor = resourceSummary.createdBy.name;
  const createdMoment = moment(createdDate);
  const lastUpdatedAuthor = resourceSummary.lastModifiedBy.name;
  const lastUpdatedMoment = moment(lastModifiedDate);

  return (
    <div>
      <ResourceHistoryItem
        label="Last Updated"
        author={lastUpdatedAuthor}
        date={lastUpdatedMoment}
      />
      <ResourceHistoryItem
        label="Created"
        author={createdAuthor}
        date={createdMoment}
      />
    </div>
  );
};

interface ResourceFormProps {
  /** The resource summary from /resources/{id} */
  resourceSummary?: ResourceDTO;
  onSearch: (search: string) => Promise<TagLibraryVM[] | undefined>;
}

const ResourceForm = ({ resourceSummary, onSearch }: ResourceFormProps) => {
  const { setFieldValue, values, isSubmitting, status, errors, touched } =
    useFormikContext<ResourceValues>();
  const navigate = useNavigate();
  const [oeTags, setOeTags] = useState<OETagDTO[]>([]);

  const handleSearch = async (search: string) => {
    if (search) {
      const filteredTags = await onSearch(search);
      if (filteredTags) setOeTags(filteredTags);
    } else {
      setOeTags([]);
    }
  };

  const defenseDocuments: (File | UploadedFile)[] = [
    ...(values.defenseDocuments || []),
    ...(values.newDocuments || []),
  ];

  // Group config terms
  const operationExperiencesTerm = useGroupTerm(
    "operationalExperiences",
    "noun",
    "plural",
    "Operational Experiences"
  );
  const defensesTerm = useGroupTerm("defenses", "noun", "plural", "Defenses");

  // Resource types for filter
  const resourceTypes: SelectOption<ResourceType>[] = [
    { value: "OPERATIONAL_EXPERIENCE", label: operationExperiencesTerm },
    { value: "DEFENSE", label: defensesTerm },
  ];

  // formik field objects
  const [tagsField] = useField("tags");

  useEffect(() => {
    if (status?.type === "failure") {
      updateToast(
        <Toast status={ToastStatus.Error} message={status.error} />,
        "resourceFormError",
        errorToastOptions
      );
    }

    return () => toast.dismiss("resourceFormError");
  }, [status]);

  return (
    <div className={styles.resourceForm}>
      <Field
        as={ResourceTitleField}
        name="title"
        touched={touched.title}
        error={errors.title}
        required
      />
      <div className={styles.shortField} id="resourceType">
        <Field
          as={Select}
          disabled={!!values.id} // it's already been saved, cannot update the type
          name="resourceType"
          label="Type"
          placeholder="Select resource type"
          options={resourceTypes}
          error={touched.resourceType && errors.resourceType}
          qa={`${Components.ResourceForm}-${ElementType.SelectInput}-resourceType`}
          onChange={(option?: SelectOption<ResourceType>) =>
            setFieldValue("resourceType", option?.value)
          }
          required={!values.id}
        />
      </div>
      <Field
        as={ResourceDescriptionField}
        label="Description"
        name="description"
        placeholder="Resource description"
        onChangeContent={(content: string) =>
          setFieldValue("description", content)
        }
        error={touched.description && errors.description}
        qa={`${Components.ResourceForm}-${ElementType.TextInput}-resourceDescription`}
        required
      />
      {values?.resourceType === "OPERATIONAL_EXPERIENCE" && (
        <PillBox<OETagDTO>
          {...tagsField}
          label="Tags"
          labelField="name"
          suggestions={oeTags}
          suggestionsLabel="Recommended Tags"
          placeholder={`Add a${values?.tags?.length ? "nother" : ""} tag...`}
          onChange={(newValues) => setFieldValue("tags", newValues)}
          onSearch={handleSearch}
          values={values.tags || []}
          qa={`${Components.ResourceForm}-${ElementType.PillBox}`}
        />
      )}
      {values?.resourceType === "DEFENSE" && (
        <S.ShortFieldWrapper>
          <Field
            as={ResourceDefenseDocUploadField}
            name="defenseDocuments"
            files={defenseDocuments}
            onUpdateFiles={(files: (File | UploadedFile)[]) => {
              setFieldValue("defenseDocuments", files.filter(isUploadedFile));
              setFieldValue("newDocuments", files.filter(isFile));
            }}
            qa={`${Components.ResourceForm}-${ElementType.FileUpload}-defenseDocuments`}
          />
        </S.ShortFieldWrapper>
      )}
      {resourceSummary && <ResourceHistory resourceSummary={resourceSummary} />}
      <div className={styles.buttonContainer}>
        <GenericButton
          qa={`${Components.ResourceForm}-${ElementType.Button}-save`}
          type="submit"
          loading={isSubmitting}
          label="Save"
        />
        <GenericButton
          qa={`${Components.ResourceForm}-${ElementType.Button}-cancel`}
          buttonStyle="tertiary"
          onClick={() => navigate(-1)}
          label="Cancel"
        />
      </div>
    </div>
  );
};

export default ResourceForm;
